@use "../common/colors";
@use "../common/scrollbar";
@use '../common/typography';
@use '../common/drop-down-input' as drop-down-input;

.sales-report-time-range-filter-widget {
  display: grid;
  border-radius: 8px;
  background-color: colors.$blue-gray-50;
  align-items: center;
  margin-top: 20px;
  padding: 8px;
  text-align: center;

  .report-form-iso-date-picker-input {
    .DayPickerInput > input {
      @include typography.caption-1-400;
      padding: 13.5px 14px;
    }
  }

  .day-interval-filter {
    padding-top: 8px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 8px;
    align-items: center;

    .day-interval-selector {
      .form-label {
        @include typography.caption-2-400;
        color: colors.$blue-gray-500;
      }

      .form-item {
        &.invalid {
          .drop-down-input {
            border: 1px solid colors.$rose-500;
          }
        }
      }

      .form-input {
        padding-top: 8px;
      }

      .drop-down-input {
        @include typography.caption-1-400;
        @include drop-down-input.form-drop-down-input;
        min-width: 200px;
        padding: 12px 14px;
        border: 1px solid colors.$blue-gray-100;
        border-radius: 8px;
        text-align: left;
        background-color: colors.$white;
        align-items: center;
        z-index: 1;

        .value {
          padding: 0;
        }

        .down-arrow-icon {
          margin: 0;
          img {
            display: none;
          }

          &::after {
            content: url('../images/icon-clock.svg');
            align-self: baseline;
          }
        }

        .options {
          margin-top: 5px;
          max-height: 265px;
          overflow-y: scroll;
          @include scrollbar.hide;
        }
      }
    }
  }
}
