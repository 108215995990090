@use '../common/default-theme';
@use '../common/typography';
@use '../common/scrollbar';
@use '../common/colors';
@use '../common/z-indexes';

.sidebar {
  height: 100%;
  width: 70px;
  position: fixed;
  z-index: z-indexes.$sidebar;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #{default-theme.$fg};
  overflow-y: auto;
  @include scrollbar.hide();

  .restoplus-logo {
    .icon {
      width: 45px;
    }
  }

  .link {
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    align-items: center;
    place-items: center;
    padding: 0;

    .label {
      @include typography.p;
      font-size: 12px;
      text-align: center;
      color: #{default-theme.$secondary-fg};
    }
  }

  .main-menu {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .offline.show {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 22px;
      height: 22px;
    }

    span {
      color: colors.$rose-500;
      margin-top: 8px;
    }
  }

  .offline.hide {
    display: none;
  }

  .refreshing-menu.show {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
    }

    span {
      color: colors.$yellow-500;
      margin-top: 3px;
      font-size: 10px;
    }
  }

  .refreshing-menu.hide {
    display: none;
  }

  .refresh-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    div {
      width: 39px !important;
      height: 39.5px !important;
    }
  }

  .not-available {
    opacity: 0.4;
    cursor: not-allowed;
  }
}
