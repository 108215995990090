@use '../common/card' as card;
@use '../common/colors' as colors;
@use '../common/typography' as typography;
@use '../common/buttons' as buttons;

.sales-report-charts-template {
  > .header {
    @include typography.h1;
    border-bottom: 1px solid #{colors.$light-gray-100};
    display: grid;
    grid-template-columns: 1fr min-content;
    padding: 22px 24px;
    align-items: center;
    margin-bottom: 0;
  }

  .body {
    padding: 24px 32px;

    .filters {
      padding-bottom: 24px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .report > .sales-charts {
      display: grid;
      gap: 24px;
    }

    .no-report {
      margin: 56px auto;
      text-align: center;
      color: #{colors.$blue-gray-700};
      opacity: 0.5;
    }
  }
}
