h1 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

h2 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 22px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

h3 {
  letter-spacing: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

h4 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

h5 {
  letter-spacing: 0;
  color: #1c1d3e;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

p, pre {
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

form input[type="text"], form input[type="number"], form input[type="password"], form input[type="tel"], form textarea {
  font-size: 15px;
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

button {
  text-align: center;
  cursor: pointer;
  border: 0;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

button .icon {
  margin-right: 7px;
  font-size: 20px;
}

button.primary {
  color: #fff;
  background-color: #3f9c86;
}

button.success {
  color: #fff;
  background-color: #454545;
}

button.failure {
  color: #fff;
  background-color: red;
}

button.disabled {
  color: #fff;
  opacity: .4;
  cursor: not-allowed;
  background-color: #4cbba1;
  border-color: #4cbba1;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinner-container {
  width: 100%;
  height: 100%;
  text-align: center;
  flex-flow: column;
  grid-column: 1 / -1;
  grid-template-rows: min-content min-content;
  place-content: center;
  display: grid;
}

.spinner-container .spinner {
  color: #4cbba1;
  text-align: center;
  font-size: 30px;
  animation-name: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.spinner-container > p {
  margin: 0;
}

.form-switch {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
}

.form-switch i {
  width: 46px;
  height: 26px;
  vertical-align: text-bottom;
  background-color: #e6e6e6;
  border-radius: 23px;
  margin-right: 4px;
  transition: all .3s linear;
  display: inline-block;
  position: relative;
}

.form-switch i:before {
  content: "";
  width: 42px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transition: all .25s linear;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0)scale3d(1, 1, 1);
}

.form-switch i:after {
  content: "";
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transition: all .2s ease-in-out;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0);
  box-shadow: 0 2px 2px #0000003d;
}

.form-switch:active i:after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked + i:after {
  transform: translate3d(16px, 2px, 0);
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i {
  background-color: #4bd763;
}

.form-switch input:checked + i:before {
  transform: translate3d(18px, 2px, 0)scale3d(0, 0, 0);
}

.form-switch input:checked + i:after {
  transform: translate3d(22px, 2px, 0);
}

@media (max-width: 767px) {
  .form-item.switch {
    grid-template-columns: auto max-content;
    grid-template-areas: "a b"
                         "c d";
    display: grid;
  }

  .form-item.switch label {
    grid-area: a;
    align-self: center;
  }

  .form-item.switch .form-input {
    grid-area: b;
    justify-self: end;
  }

  .form-item.switch .meta {
    margin-top: -3px;
    grid-area: c !important;
  }
}

form.default .form-item.switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

form.default .form-item.switch label {
  grid-area: a;
  align-self: center;
}

form.default .form-item.switch .form-input {
  grid-area: b;
  justify-self: end;
}

form.default .form-item.switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

#toasts-container {
  grid-gap: 15px;
  z-index: 10000;
  display: grid;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

#toasts-container .wrapper {
  text-align: center;
}

.toast {
  border-radius: 4px;
  grid-template-columns: min-content max-content;
  grid-template-areas: "icon message";
  align-items: center;
  padding: 10px 15px;
  display: inline-grid;
  box-shadow: 0 0 3px #687a9b40;
}

.toast .icon {
  grid-area: icon;
  margin-right: 10px;
  font-size: 30px;
}

.toast .message {
  max-width: 585px;
  grid-area: message;
  margin: 0;
  font-size: .85rem;
  font-weight: 400;
}

.toast button.close {
  box-shadow: none;
  opacity: .5;
  color: #000;
  background-color: #0000;
  border: 0;
  margin-left: 10px;
  padding: 0;
  font-size: 1.4rem;
}

.toast.success {
  color: #064;
  background-color: #e3fcef;
}

.toast.info {
  color: #1e40af;
  background-color: #dbeafe;
  border: 1px solid #3b82f6;
}

.toast.error {
  color: #9f1239;
  background-color: #ffe4e6;
  border: 1px solid #f43f5e;
}

.toast.warning {
  color: #92400e;
  background-color: #fef3c7;
  border: 1px solid #f59e0b;
}

.toast.loading {
  color: #505f79;
  background-color: #fff;
}

.toast.loading .spinner {
  color: #4cbba1;
  text-align: center;
  font-size: 30px;
  animation-name: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.toast.loading .icon {
  grid-area: icon;
  margin-top: -5px;
}

#modal-container > .ReactModalPortal > .ReactModal__Overlay {
  z-index: 1000;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #0c0d3473 !important;
}

#modal-container > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content {
  border-radius: 20px !important;
  padding: 0 !important;
}

#modal-container > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content .modal {
  max-height: calc(100vh - 56px);
  overflow: auto;
}

.modal .close {
  width: 27px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}

.alert {
  border-radius: 10px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "icon message";
  padding: 10px 15px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.alert.has-title {
  grid-template-areas: "icon title"
                       ". message";
}

.alert .icon {
  flex-direction: column;
  grid-area: icon;
  justify-self: center;
  font-size: 20px;
  display: flex;
}

.alert .icon i {
  margin-right: 10px;
  font-size: 25px;
}

.alert .title {
  letter-spacing: 0;
  grid-area: title;
  align-self: center;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.alert .message {
  grid-area: message;
  align-self: center;
  font-size: .9rem;
  font-weight: 300;
}

.alert.success {
  background-color: #e3fcef;
}

.alert.success .icon, .alert.success .title, .alert.success .message {
  color: #064;
}

.alert.info {
  color: #0071b3;
  background-color: #e6edfa;
}

.alert.info .icon, .alert.info .title, .alert.info .message {
  color: #0071b3;
}

.alert.error {
  color: #bf2600;
  background-color: #ffebe6;
}

.alert.error .icon, .alert.error .title, .alert.error .message {
  color: #bf2600;
}

.alert.warning {
  color: #ff8b00;
  background-color: #fffae6;
}

.alert.warning .icon, .alert.warning .title, .alert.warning .message {
  color: #ff8b00;
}

form label {
  z-index: 1;
  margin-left: 4px;
  font-size: .8rem;
}

form input[type="text"], form input[type="number"], form input[type="password"], form input[type="tel"], form textarea {
  box-sizing: border-box;
  color: #44444f;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-weight: 400;
  transition: all .3s;
}

form input[type="file"]::-webkit-file-upload-button {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

form input[type="file"] {
  font-family: Poppins, sans-serif;
}

form .preview {
  margin-bottom: 15px;
}

form .preview img {
  max-width: 100px;
  border: 3px solid #f1f1f5;
  border-radius: 20px;
  margin: 0;
  padding: 0;
}

form .form-input.has-icon {
  box-sizing: border-box;
  color: #44444f;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  transition: all .3s;
  display: flex;
}

form .form-input.has-icon .icon {
  width: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 12px;
  display: flex;
}

form .form-input.has-icon img {
  width: 100%;
  align-self: center;
}

form .form-input.has-icon input {
  border: 0;
  margin: 0;
}

form .form-input .has-prepend {
  display: flex;
}

form .form-input .has-prepend .prepend-text {
  box-sizing: border-box;
  color: #44444f;
  width: 100%;
  width: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-right: none;
  border-radius: 10px 0 0 10px;
  margin: 0;
  padding: 10px 14px;
  font-weight: 400;
  transition: all .3s;
}

form .form-input .has-prepend input {
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

form .form-items {
  grid-row-gap: 18px;
  display: grid;
}

form .form-buttons {
  margin-top: 20px;
}

form .select {
  box-sizing: border-box;
  color: #44444f;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-weight: 400;
  transition: all .3s;
}

form .meta {
  margin-top: 5px;
  margin-left: 5px;
}

form .error {
  color: #fe5b5b;
  font-size: 12px;
}

form .help {
  color: #6c6c6c;
  font-size: 12px;
}

form select {
  box-sizing: none;
  padding: none;
  width: 100%;
  background: none;
  border: none;
  margin: 0;
}

form .checkbox {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  form.responsive {
    grid-template-columns: 180px 4fr 1fr;
    display: grid;
  }

  form.responsive .form-items > .form-item > label {
    text-align: right;
    align-self: center;
    margin-right: 15px;
  }

  form.responsive .form-items {
    grid-area: 1 / 1 / auto / -1;
    row-gap: 25px;
  }

  form.responsive .form-items > .form-item {
    grid-template-columns: 180px 4fr 1fr;
    display: grid;
  }

  form.responsive .form-items > .form-item label {
    grid-area: 1 / 1 / auto / 2;
  }

  form.responsive .form-items > .form-item .form-input {
    grid-area: 1 / 2 / auto / 3;
  }

  form.responsive .form-items > .form-item .meta {
    grid-area: 2 / 2 / auto / 3;
  }

  form.responsive .form-input {
    margin-top: 0;
  }

  form.responsive .form-buttons {
    grid-area: 2 / 2 / auto / 3;
    margin-top: 30px;
    padding-left: 4px;
  }

  form.horizontal .form-items {
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    display: grid;
  }
}

textarea.invalid, input.invalid, .input-box.invalid {
  border-color: #fe5b5b !important;
}

input:disabled, .form-input.disabled {
  background: #f9f9f9 !important;
}

.form-item.inline-title {
  background-color: #f9f9f9;
  font-weight: 400;
  padding: 8px 18px !important;
}

.DayPicker {
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  display: grid;
}

.DayPicker-wrapper {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  padding-bottom: 1em;
  position: relative;
}

.DayPicker-Months {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.DayPicker-Month {
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-user-select: none;
  user-select: none;
  margin: 1em 1em 0;
  display: table;
}

.DayPicker-NavButton {
  width: 1.25em;
  height: 1.25em;
  color: #8b9898;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  margin-top: 2px;
  display: inline-block;
  position: absolute;
  top: 1em;
  left: auto;
  right: 1.5em;
}

.DayPicker-NavButton:hover {
  opacity: .8;
}

.DayPicker-NavButton--prev {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC");
  margin-right: 1.5em;
}

.DayPicker-NavButton--next {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==");
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  text-align: left;
  margin-bottom: .5em;
  padding: 0 .5em;
  display: table-caption;
}

.DayPicker-Caption > div {
  font-size: 1.15em;
  font-weight: 500;
}

.DayPicker-Weekdays {
  margin-top: 1em;
  display: table-header-group;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  color: #8b9898;
  text-align: center;
  padding: .5em;
  font-size: .875em;
  display: table-cell;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  padding: .5em;
  display: table-cell;
}

.DayPicker-WeekNumber {
  min-width: 1em;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  cursor: pointer;
  border-right: 1px solid #eaecec;
  padding: .5em;
  font-size: .75em;
  display: table-cell;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: .5em;
}

.DayPicker-TodayButton {
  box-shadow: none;
  color: #4a90e2;
  cursor: pointer;
  background-color: #0000;
  background-image: none;
  border: none;
  font-size: .875em;
}

.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
}

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: #f0f8ff;
  background-color: #4a90e2;
  position: relative;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f0f8ff;
}

.DayPickerInput {
  display: inline-block;
  position: relative;
}

.DayPickerInput-OverlayWrapper {
  z-index: 998;
  background: #000000b3;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 60px;
  left: 0;
}

@media (min-width: 1024px) {
  .DayPickerInput-OverlayWrapper {
    background: unset;
  }
}

.DayPickerInput-Overlay {
  z-index: 1;
  background: #fff;
  border-radius: 10px;
  left: 0;
  box-shadow: 0 2px 5px #00000026;
}

.DayPickerInput {
  width: 100%;
}

.DayPickerInput input {
  box-sizing: border-box;
  color: #44444f;
  width: 100%;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-weight: 400;
  transition: all .3s;
}

.cta {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.cta:focus {
  border: none;
  outline: none;
}

.secondary-cta {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #4cbba1;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
}

.tertiary-cta {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
}

.tertiary-cta:focus {
  border: none;
  outline: none;
}

.link-cta {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.container {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  margin-bottom: 22px;
  padding-left: 35px;
  font-size: 22px;
  display: block;
  position: relative;
}

.container.checkbox > input[type="checkbox"] {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  position: absolute;
}

.container.checkbox > input[type="checkbox"]:checked ~ .checkmark {
  background-color: #4cbba1;
  border: 2px solid #4cbba1;
}

.container.checkbox > input[type="checkbox"]:checked ~ .checkmark:after {
  display: block;
}

.container.checkbox > .checkmark {
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 2px solid #d9d9e6;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.container.checkbox > .checkmark:after {
  content: "";
  width: 5px;
  height: 10px;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  display: none;
  position: absolute;
  top: 1px;
  left: 5px;
  transform: rotate(45deg);
}

.container.radio > input[type="radio"] {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  position: absolute;
}

.container.radio > input[type="radio"]:checked ~ .checkmark {
  background-color: #4cbba1;
  border: 2px solid #4cbba1;
}

.container.radio > input[type="radio"]:checked ~ .checkmark:after {
  display: block;
}

.container.radio > .checkmark {
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 2px solid #d9d9e6;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.container.radio > .checkmark:after {
  content: "";
  width: 4px;
  height: 4px;
  background: #fff;
  border: 4px solid #fff;
  border-radius: 50%;
  display: none;
  position: absolute;
  top: 3px;
  left: 3px;
}

.page > .header {
  margin-bottom: 20px;
}

.page > .header .navigation {
  padding: 11.5px 0 4px 30px;
}

.page > .header .navigation .back-button {
  cursor: pointer;
  column-gap: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.page > .header .wrapper {
  width: calc(100vw - 84px);
  border-bottom: 1px solid #d9d9e6;
  grid-template-areas: "title actions"
                       "subtitle actions";
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  display: grid;
}

.page > .header .title {
  letter-spacing: 0;
  color: #1c1d3e;
  grid-area: title;
  margin: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.page > .header .subtitle {
  color: #1c1d3e;
  opacity: .6;
  grid-area: subtitle;
}

.page > .header .actions {
  grid-area: actions;
  display: flex;
}

.page > .header .actions > div:not(:last-child) {
  margin-right: 32px;
}

.page > .header .actions > .store-filter-widget {
  position: unset;
}

.page > .body {
  display: grid;
}

.swal2-container {
  background-color: #0c0d3473 !important;
}

.swal2-container .swal2-modal {
  width: 340px !important;
  border-radius: 20px !important;
  padding: 15px 0 25px !important;
}

.swal2-container .swal2-modal .swal2-header {
  padding: 10px 30px;
  position: relative;
}

.swal2-container .swal2-modal .swal2-content {
  white-space: pre-wrap;
  margin: 0;
  padding: 0 30px;
  font-size: 14px;
}

.swal2-container .swal2-modal .swal2-title {
  width: 100%;
  justify-content: center;
  margin-top: 15px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #1c1d3e !important;
  margin: 0 !important;
}

.swal2-container .swal2-modal .swal2-html-container {
  color: #4c4e68;
  text-align: center;
  margin: 0 auto;
  position: relative;
}

.swal2-container .swal2-modal .swal2-html-container .highlight {
  color: #3f9c86;
}

.swal2-container .swal2-modal .swal2-footer {
  border-top: none;
  margin: 0;
  padding: 24px 25px 0;
  font-size: 14px;
}

.swal2-container .swal2-modal .swal2-actions {
  flex-direction: column;
  gap: 15px;
  margin: 0;
  padding: 24px 24px 0;
  display: flex;
}

.swal2-container .swal2-modal .swal2-actions button {
  text-transform: uppercase;
  width: 100%;
  margin: 0;
  padding: 14px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  text-align: center !important;
}

.swal2-container .swal2-modal .swal2-actions .confirm-button {
  background: #3f9c86;
  border-radius: 4px;
}

.swal2-container .swal2-modal .swal2-actions .cancel-button {
  color: #4c4e68;
  background: #fff !important;
}

.swal2-container .swal2-modal.troubleshoot-logout .swal2-content, .swal2-container .swal2-modal.troubleshoot-clear-cache .swal2-content, .swal2-container .swal2-modal.edit-saved-order-warning .swal2-content {
  color: #4c4e68;
  font-size: 16px;
  line-height: 24px;
}

.swal2-container .swal2-modal.troubleshoot-logout .swal2-actions button, .swal2-container .swal2-modal.troubleshoot-clear-cache .swal2-actions button, .swal2-container .swal2-modal.edit-saved-order-warning .swal2-actions button {
  text-transform: none;
}

.swal2-container .swal2-modal.troubleshoot-logout .swal2-actions .confirm-button, .swal2-container .swal2-modal.troubleshoot-clear-cache .swal2-actions .confirm-button, .swal2-container .swal2-modal.edit-saved-order-warning .swal2-actions .confirm-button {
  background: #9f1239;
  padding: 14px;
  font-size: 16px;
  line-height: 24px;
}

.swal2-container .swal2-modal.troubleshoot-logout .swal2-actions .cancel-button, .swal2-container .swal2-modal.troubleshoot-clear-cache .swal2-actions .cancel-button, .swal2-container .swal2-modal.edit-saved-order-warning .swal2-actions .cancel-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 12px;
  font-size: 16px;
  line-height: 24px;
  transition: all .2s ease-in-out;
}

.swal2-container .swal2-modal.has-icon {
  width: 573px !important;
}

.swal2-container .swal2-modal.has-icon .swal2-error.swal2-icon-show {
  height: 64px;
  width: 64px;
  border-radius: unset;
  background: url("icon-error.d5c53330.svg") center no-repeat;
  border: none;
}

.swal2-container .swal2-modal.has-icon .swal2-error.swal2-icon-show .swal2-x-mark {
  display: none;
}

.swal2-container .swal2-modal.has-icon .swal2-success.swal2-icon-show {
  height: 64px;
  width: 64px;
  border-radius: unset;
  background: url("icon-success.85cdf8e7.gif") center / 64px no-repeat;
  border: none;
}

.swal2-container .swal2-modal.has-icon .swal2-success.swal2-icon-show > * {
  display: none;
}

.swal2-container .swal2-icon-warning.swal2-show {
  width: 357px !important;
}

.swal2-container .swal2-icon-warning.swal2-show .swal2-header {
  padding: 32px 24px 16px;
}

.swal2-container .swal2-icon-warning.swal2-show .swal2-warning.swal2-icon-show {
  height: 64px;
  width: 64px;
  background: url("icon-warning.464bf84a.svg") center no-repeat;
  border: none;
  margin: 0;
}

.swal2-container .swal2-icon-warning.swal2-show .swal2-warning.swal2-icon-show .swal2-icon-content {
  padding-top: 16px;
  display: none;
}

.swal2-container .swal2-icon-warning.swal2-show .swal2-actions {
  padding-bottom: 32px !important;
}

.ar-4-2 {
  aspect-ratio: 4 / 2;
  object-fit: cover;
}

.ar-4-2-5 {
  aspect-ratio: 4 / 2.5;
  object-fit: cover;
}

.ar-8-3 {
  aspect-ratio: 8 / 3;
  object-fit: cover;
}

.ar-4-3 {
  aspect-ratio: 4 / 3;
  object-fit: cover;
}

.ar-1-1 {
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.ar-4-5 {
  aspect-ratio: 4 / 5;
  object-fit: cover;
}

.ar-4-6 {
  aspect-ratio: 4 / 6;
  object-fit: cover;
}

.popout.arrow-right-bottom:before, .popout.arrow-right-center:before, .popout.arrow-right-top:before, .popout.arrow-down-right:before, .popout.arrow-down-center:before, .popout.arrow-down-left:before, .popout.arrow-left-bottom:before, .popout.arrow-left-center:before, .popout.arrow-left-top:before, .popout.arrow-up-right:before, .popout.arrow-up-center:before, .popout.arrow-up-left:before {
  content: "";
  position: absolute;
}

.popout.arrow-up-right:before, .popout.arrow-up-center:before, .popout.arrow-up-left:before {
  border-bottom: 12px solid #fff;
  border-left: 12px solid #0000;
  border-right: 12px solid #0000;
  top: -12px;
}

.popout.arrow-left-bottom:before, .popout.arrow-left-center:before, .popout.arrow-left-top:before {
  border-top: 12px solid #0000;
  border-bottom: 12px solid #0000;
  border-right: 12px solid #fff;
  left: -12px;
}

.popout.arrow-down-right:before, .popout.arrow-down-center:before, .popout.arrow-down-left:before {
  border-top: 12px solid #fff;
  border-left: 12px solid #0000;
  border-right: 12px solid #0000;
  bottom: -12px;
}

.popout.arrow-right-bottom:before, .popout.arrow-right-center:before, .popout.arrow-right-top:before {
  border-top: 12px solid #0000;
  border-bottom: 12px solid #0000;
  border-left: 12px solid #fff;
  right: -12px;
}

.popout.arrow-right-center:before, .popout.arrow-left-center:before {
  top: 50%;
  transform: translateY(-50%);
}

.popout.arrow-up-center:before, .popout.arrow-down-center:before {
  left: 50%;
  transform: translateX(-50%);
}

.popout.arrow-right-top:before, .popout.arrow-left-top:before {
  top: 12px;
}

.popout.arrow-right-bottom:before, .popout.arrow-left-bottom:before {
  bottom: 12px;
}

.popout.arrow-up-left:before, .popout.arrow-down-left:before {
  left: 12px;
}

.popout.arrow-up-right:before, .popout.arrow-down-right:before {
  right: 12px;
}

.popout {
  z-index: 99;
  background-color: #fff;
  border-radius: 10px;
  padding: 16px;
  position: absolute;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.popout.arrow-down-left, .popout.arrow-down-center, .popout.arrow-down-right {
  margin-top: -12px;
}

.popout.arrow-left-top, .popout.arrow-left-center, .popout.arrow-left-bottom {
  margin-left: 12px;
}

.popout.arrow-up-left, .popout.arrow-up-center, .popout.arrow-up-right {
  margin-top: 12px;
}

.popout.arrow-right-top, .popout.arrow-right-center, .popout.arrow-right-bottom {
  margin-left: -12px;
}

.loading-widget {
  text-align: center;
  width: 573px;
  box-sizing: border-box;
  border-radius: 10px;
  gap: 16px;
  padding: 24px 24px 32px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.loading-widget > .title {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.loading-widget > .message {
  color: #4c4e68;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.loading-widget .action {
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 16px;
  margin-top: 10px;
  display: grid;
}

.loading-widget .action .cancel-button {
  width: 100%;
  color: #4c4e68;
  background: none;
  border: 1px solid #4c4e68;
  border-radius: 4px;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container .confirm-clock-out-question {
  width: 391px !important;
  padding: 0 !important;
}

.swal2-container .confirm-clock-out-question .swal2-header {
  padding: 24px 24px 0;
}

.swal2-container .confirm-clock-out-question .swal2-header .swal2-title {
  color: #1c1d3e;
  margin: 0;
  padding: 0 60px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container .confirm-clock-out-question .swal2-content {
  padding-top: 10px;
}

.swal2-container .confirm-clock-out-question .swal2-actions {
  padding: 24px;
}

.swal2-container .confirm-clock-out-question .swal2-actions button {
  text-transform: capitalize;
  border-radius: 4px;
  padding: 14px 12px;
  font-size: 16px;
}

.swal2-container .confirm-clock-out-question .swal2-actions .confirm-button {
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.swal2-container .confirm-clock-out-question .swal2-actions .cancel-button {
  color: #1c1d3e;
  border: 1px solid #dee0e6;
}

.swal2-container .confirm-quit-editing-question {
  width: 391px !important;
  padding: 0 !important;
}

.swal2-container .confirm-quit-editing-question .swal2-header {
  padding: 24px 24px 0;
}

.swal2-container .confirm-quit-editing-question .swal2-header .swal2-title {
  color: #1c1d3e;
  margin: 0;
  padding: 0 60px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container .confirm-quit-editing-question .swal2-content {
  padding-top: 10px;
}

.swal2-container .confirm-quit-editing-question .swal2-actions {
  padding: 24px;
}

.swal2-container .confirm-quit-editing-question .swal2-actions button {
  text-transform: capitalize;
  border-radius: 4px;
  padding: 14px 12px;
  font-size: 16px;
}

.swal2-container .confirm-quit-editing-question .swal2-actions .confirm-button {
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.swal2-container .confirm-quit-editing-question .swal2-actions .cancel-button {
  color: #1c1d3e;
  border: 1px solid #dee0e6;
}

.swal2-container .confirm-quit-editing-question .swal2-content {
  padding-top: 12px;
  font-size: 15px;
}

.swal2-container .invalid-warning {
  width: 391px !important;
  padding: 0 !important;
}

.swal2-container .invalid-warning .swal2-header {
  padding: 24px 24px 0;
}

.swal2-container .invalid-warning .swal2-header .swal2-title {
  color: #1c1d3e;
  margin: 0;
  padding: 0 60px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container .invalid-warning .swal2-content {
  padding-top: 10px;
}

.swal2-container .invalid-warning .swal2-actions {
  padding: 24px;
}

.swal2-container .invalid-warning .swal2-actions button {
  text-transform: capitalize;
  border-radius: 4px;
  padding: 14px 12px;
  font-size: 16px;
}

.swal2-container .invalid-warning .swal2-actions .confirm-button {
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.swal2-container .invalid-warning .swal2-actions .cancel-button {
  color: #1c1d3e;
  border: 1px solid #dee0e6;
}

.duration-input {
  border: 1px solid #c6c7d1;
  grid-auto-flow: column;
  gap: 10px;
  padding: 0 8px;
  display: grid;
}

.duration-input .hours-input, .duration-input .minutes-input {
  grid-auto-flow: column;
  display: grid;
}

.duration-input .hours-input input[type="text"], .duration-input .minutes-input input[type="text"] {
  width: 22px;
  border: none;
  outline: none;
}

.duration-input .hours-input .label, .duration-input .minutes-input .label {
  font-size: 17px;
}

.duration-input.invalid {
  border: 1px solid #f43f5e;
}

.removable-tag {
  color: #368875;
  width: max-content;
  background-color: #ecfdf5;
  border-radius: 6px;
  margin: 3px;
  padding: 4px 8px;
  font-weight: 500;
  display: flex;
}

.removable-tag .remove-button {
  cursor: pointer;
  padding-left: 6px;
}

.employee-time-clock-header {
  grid-area: header;
  grid-template-rows: repeat(2, min-content);
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "go-back-link date-today"
                       "header-title date-today";
  justify-content: space-between;
  padding-bottom: 50px;
  display: grid;
}

.employee-time-clock-header .header-title {
  letter-spacing: 0;
  color: #1c1d3e;
  grid-area: header-title;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
}

.employee-time-clock-header .go-back-link {
  cursor: pointer;
  grid-area: go-back-link;
  padding: 0 0 5px 10px;
  display: flex;
}

.employee-time-clock-header .go-back-link > .arrow-left {
  width: 10px;
  height: 12px;
  background-image: url("icon-chevron-left.88d05a8c.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 3px;
  margin-right: 12px;
}

.employee-time-clock-header .go-back-link span {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #7d7e92;
  align-self: center;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.employee-time-clock-header .go-back-link.hide {
  visibility: hidden;
}

.employee-time-clock-header .date-today {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: right;
  grid-area: date-today;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.employee-time-clock-header .date-today span {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #7d7e92;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.employee-time-clock-date-range-picker {
  letter-spacing: 0;
  color: #1c1d3e;
  cursor: pointer;
  grid-area: date-picker;
  align-items: center;
  margin-bottom: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
}

.employee-time-clock-date-range-picker .previous-date-range-button {
  padding-right: 20px;
}

.employee-time-clock-date-range-picker .next-date-range-button {
  padding-left: 20px;
}

.employee-time-clock-date-range-picker .next-date-range-button.disabled {
  pointer-events: none;
}

.employee-time-clock-start-end-shift-confirmation {
  width: 391px;
  background-color: #3f9c86;
  border-radius: 20px;
  outline: 2px solid #3f9c86;
  justify-items: center;
  padding: 48px 24px;
  display: grid;
}

.employee-time-clock-start-end-shift-confirmation .label {
  color: #fff;
  margin-top: 24px;
  font-size: 18px;
  line-height: 27px;
}

.employee-time-clock-start-end-shift-confirmation .time {
  color: #fff;
  margin-top: 4px;
  font-size: 24px;
  line-height: 29px;
}

#modal-container:has(.employee-time-clock-start-end-shift-confirmation) {
  z-index: 2001;
  position: absolute;
}

.employee-time-clock-start-end-break-confirmation {
  width: 391px;
  background-color: #d97706;
  border-radius: 20px;
  outline: 2px solid #d97706;
  justify-items: center;
  padding: 48px 24px;
  display: grid;
}

.employee-time-clock-start-end-break-confirmation .label {
  color: #fff;
  margin-top: 24px;
  font-size: 18px;
  line-height: 27px;
}

.employee-time-clock-start-end-break-confirmation .time {
  color: #fff;
  margin-top: 4px;
  font-size: 24px;
  line-height: 29px;
}

.employee-time-clock-start-end-break-confirmation .duration {
  color: #fff;
  margin-top: 4px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

#modal-container:has(.employee-time-clock-start-end-break-confirmation) {
  z-index: 2001;
  position: absolute;
}

.sales-report-form-iso-date-picker-input.invalid > .DayPickerInput input {
  border: 1px solid #f43f5e;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput input {
  min-width: 200px;
  background-image: url("icon-calendar.4ddcc08d.svg");
  background-position: 95%;
  background-repeat: no-repeat;
  border-radius: 8px;
  padding: 13.5px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput input:focus {
  outline: none;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper {
  top: inherit;
  margin-top: 8px;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay {
  box-shadow: none;
  border: 1px solid #dee0e6;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker {
  margin-top: 40px;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper {
  -webkit-user-select: none;
  user-select: none;
  padding: 0;
  position: relative;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper > .DayPicker-NavBar {
  position: absolute;
  top: -40px;
  right: 0;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper > .DayPicker-NavBar > .DayPicker-NavButton {
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper > .DayPicker-NavBar > .DayPicker-NavButton--prev {
  background-image: url("icon-chevron-down-filled.5bee599b.svg");
  transform: rotate(90deg);
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper > .DayPicker-NavBar > .DayPicker-NavButton--next {
  background-image: url("icon-chevron-down-filled.5bee599b.svg");
  right: 10px;
  transform: rotate(270deg);
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month {
  table-layout: fixed;
  border-spacing: 14px 12px;
  border-collapse: separate;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors {
  display: flex;
  position: absolute;
  top: -28px;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector.month-selector {
  margin-right: 12px;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .form-label {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #7d7e92;
  margin-bottom: 22px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input {
  z-index: 999;
  cursor: pointer;
  color: #1c1d3e;
  text-align: center;
  background-color: #f4f4f6;
  border-radius: 8px;
  padding: 6px 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  position: relative;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .value {
  flex-grow: 1;
  padding: 0 12px;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .down-arrow-icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin: -10px 12px 0 0;
  display: flex;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .down-arrow-icon > img {
  height: 100%;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-top: 16px;
  position: absolute;
  top: 100%;
  left: 0;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options .option {
  border-bottom: 1px solid #dee0e6;
  justify-content: space-between;
  padding: 10px 12px;
  display: flex;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options .option:last-child {
  border-bottom: 0;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .value {
  padding: 0;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .down-arrow-icon {
  display: none;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options {
  max-height: 280px;
  min-width: 200px;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  margin-top: 5px;
  overflow-y: scroll;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options::-webkit-scrollbar {
  width: 1px;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options::-webkit-scrollbar-track {
  background: none;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options .option {
  font-weight: 500;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .DayPicker-Weekday {
  color: #7d7e92;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .DayPicker-Day {
  color: #1c1d3e;
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .DayPicker-Day:hover {
  background-color: unset !important;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .DayPicker-Day--disabled {
  color: #dee0e6;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .DayPicker-Day--selected {
  color: #4cbba1;
  background-color: #0000;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker-Day {
  color: #1c1d3e;
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker-Day:hover {
  background-color: unset !important;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker-Day--disabled {
  color: #dee0e6;
}

.sales-report-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker-Day--selected {
  color: #4cbba1;
  background-color: #0000;
}

.period-coverage-form-iso-date-picker-input.date-range-end-date > .DayPickerInput > .DayPickerInput-OverlayWrapper {
  left: -22px;
}

.period-coverage-form-iso-date-picker-input > label {
  color: #7d7e92;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput {
  padding-top: 8px;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput input {
  min-width: 200px;
  background-image: url("icon-calendar.4ddcc08d.svg");
  background-position: 95%;
  background-repeat: no-repeat;
  border-radius: 8px;
  padding: 13.5px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput input:focus {
  outline: none;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper {
  top: inherit;
  margin-top: 8px;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay {
  box-shadow: none;
  border: 1px solid #dee0e6;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker {
  margin-top: 40px;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper {
  -webkit-user-select: none;
  user-select: none;
  padding: 0;
  position: relative;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper > .DayPicker-NavBar {
  position: absolute;
  top: -40px;
  right: 0;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper > .DayPicker-NavBar > .DayPicker-NavButton {
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper > .DayPicker-NavBar > .DayPicker-NavButton--prev {
  background-image: url("icon-chevron-down-filled.5bee599b.svg");
  transform: rotate(90deg);
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper > .DayPicker-NavBar > .DayPicker-NavButton--next {
  background-image: url("icon-chevron-down-filled.5bee599b.svg");
  right: 10px;
  transform: rotate(270deg);
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month {
  table-layout: fixed;
  border-spacing: 14px 12px;
  border-collapse: separate;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors {
  display: flex;
  position: absolute;
  top: -28px;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector.month-selector {
  margin-right: 12px;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .form-label {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #7d7e92;
  margin-bottom: 22px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input {
  z-index: 999;
  cursor: pointer;
  color: #1c1d3e;
  text-align: center;
  background-color: #f4f4f6;
  border-radius: 8px;
  padding: 6px 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  position: relative;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .value {
  flex-grow: 1;
  padding: 0 12px;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .down-arrow-icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin: -10px 12px 0 0;
  display: flex;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .down-arrow-icon > img {
  height: 100%;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-top: 16px;
  position: absolute;
  top: 100%;
  left: 0;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options .option {
  border-bottom: 1px solid #dee0e6;
  justify-content: space-between;
  padding: 10px 12px;
  display: flex;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options .option:last-child {
  border-bottom: 0;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .value {
  padding: 0;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .down-arrow-icon {
  display: none;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options {
  max-height: 280px;
  min-width: 200px;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  margin-top: 5px;
  overflow-y: scroll;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options::-webkit-scrollbar {
  width: 1px;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options::-webkit-scrollbar-track {
  background: none;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options .option {
  font-weight: 500;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .DayPicker-Weekday {
  color: #7d7e92;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .DayPicker-Day {
  color: #1c1d3e;
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .DayPicker-Day:hover {
  background-color: unset !important;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .DayPicker-Day--disabled {
  color: #dee0e6;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .DayPicker-Day--selected {
  color: #4cbba1;
  background-color: #0000;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker-Day {
  color: #1c1d3e;
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker-Day:hover {
  background-color: unset !important;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker-Day--disabled {
  color: #dee0e6;
}

.period-coverage-form-iso-date-picker-input > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker-Day--selected {
  color: #4cbba1;
  background-color: #0000;
}

@media screen and (max-width: 1160px) {
  .period-coverage-form-iso-date-picker-input.date-range-end-date > .DayPickerInput > .DayPickerInput-OverlayWrapper {
    left: unset;
  }
}

.orders-list-item-store-name {
  color: #4c4e68;
  max-width: max-content;
  border: 1px solid #dee0e6;
  border-radius: 4px;
  margin-bottom: 4px;
  padding: 2px 12px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
}

.orders-list-item-store-name .store-icon {
  margin-right: 8px;
}

form label {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 15px;
  margin-left: 4px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

form input[type="text"], form input[type="number"], form input[type="password"], form input[type="tel"], form textarea {
  box-sizing: border-box;
  color: #44444f;
  width: 100%;
  resize: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-weight: 400;
  transition: all .3s;
}

form input[type="text"]::placeholder, form input[type="number"]::placeholder, form input[type="password"]::placeholder, form input[type="tel"]::placeholder, form textarea::placeholder {
  color: #535a64;
}

form input[type="file"]::-webkit-file-upload-button {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

form input[type="file"] {
  font-family: Poppins, sans-serif;
}

form .preview {
  margin-bottom: 15px;
}

form .preview img {
  max-width: 100px;
  border: 3px solid #f1f1f5;
  border-radius: 20px;
  margin: 0;
  padding: 0;
}

form .form-input.has-icon {
  box-sizing: border-box;
  color: #44444f;
  width: 100%;
  resize: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  transition: all .3s;
  display: flex;
}

form .form-input.has-icon::placeholder {
  color: #535a64;
}

form .form-input.has-icon .icon {
  width: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 12px;
  display: flex;
}

form .form-input.has-icon img {
  width: 100%;
  align-self: center;
}

form .form-input.has-icon input {
  border: 0;
  margin: 0;
}

form .form-items {
  grid-row-gap: 18px;
  display: grid;
}

form .form-item {
  position: relative;
}

form .form-item .form-input > input {
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

form .form-item .input-icon {
  opacity: .4;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  display: grid;
  position: absolute;
  top: 0;
  right: 10px;
}

form .form-buttons {
  margin-top: 20px;
}

form .select {
  box-sizing: border-box;
  color: #44444f;
  width: 100%;
  resize: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-weight: 400;
  transition: all .3s;
}

form .select::placeholder {
  color: #535a64;
}

form .meta {
  margin-top: 5px;
  margin-left: 5px;
}

form .error {
  color: #fe5b5b;
  font-size: 12px;
}

form .help {
  color: #6c6c6c;
  font-size: 12px;
}

form select {
  box-sizing: none;
  padding: none;
  width: 100%;
  background: none;
  border: none;
  margin: 0;
}

form .checkbox {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  form.responsive {
    grid-template-columns: 180px 4fr 1fr;
    display: grid;
  }

  form.responsive .form-items > .form-item > label {
    text-align: right;
    align-self: center;
    margin-right: 15px;
  }

  form.responsive .form-items {
    grid-area: 1 / 1 / auto / -1;
    row-gap: 25px;
  }

  form.responsive .form-items > .form-item {
    grid-template-columns: 180px 4fr 1fr;
    display: grid;
  }

  form.responsive .form-items > .form-item label {
    grid-area: 1 / 1 / auto / 2;
  }

  form.responsive .form-items > .form-item .form-input {
    grid-area: 1 / 2 / auto / 3;
  }

  form.responsive .form-items > .form-item .meta {
    grid-area: 2 / 2 / auto / 3;
  }

  form.responsive .form-input {
    margin-top: 0;
  }

  form.responsive .form-buttons {
    grid-area: 2 / 2 / auto / 3;
    margin-top: 30px;
    padding-left: 4px;
  }

  form.horizontal .form-items {
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    display: grid;
  }
}

textarea, input, .input-box {
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

textarea.invalid, input.invalid, .input-box.invalid {
  border-color: #fe5b5b !important;
}

textarea > span, input > span, .input-box > span {
  color: #44444fc4;
}

input:disabled, .form-input.disabled {
  background: #f9f9f9 !important;
}

.form-item.inline-title {
  background-color: #f9f9f9;
  font-weight: 400;
  padding: 8px 18px !important;
}

.form-select .input-box {
  box-sizing: border-box;
  color: #44444f;
  width: 100%;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 0;
  padding: 8px 14px;
  font-weight: 400;
  transition: all .3s;
}

.form-select.disabled .input-box {
  color: #696974;
  cursor: auto;
  background-color: #f5f5f5;
  border-color: #e2e2ea;
}

.form-select .options {
  grid-gap: 18px;
  cursor: pointer;
  max-height: 400px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 20px 25px 20px 18px;
  font-weight: 400;
  display: grid;
  overflow-y: scroll;
}

@media (max-width: 767px) {
  .form-select .options {
    max-width: 80vw;
    max-height: 80vh;
    overflow: scroll;
  }

  .form-select .overlay {
    z-index: 998;
    background: #000000b3;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    inset: 0;
  }
}

@media (min-width: 768px) {
  .form-select .options-wrapper {
    position: relative;
  }

  .form-select .options {
    z-index: 999;
    margin-top: 10px;
    position: absolute;
    top: 100%;
  }
}

.form-select .icon {
  color: #777;
  margin-right: 12px;
  font-size: 30px;
}

.form-select .selected .icon {
  color: #065f46;
}

.form-select .option {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.form-multi-select-checkbox .clear-selection-button {
  color: #1e40af;
  padding: 9.5px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: underline;
}

.form-multi-select-checkbox .option {
  cursor: pointer;
  margin: 8px 0;
  padding: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.form-multi-select-checkbox .option.checked {
  background-color: #ecf9f6;
  border-radius: 8px;
}

.form-multi-select-checkbox .option.disabled {
  opacity: .5;
}

.form-multi-select-checkbox label {
  color: #4c4e68;
  padding-left: 15px;
}

.form-single-select-pill {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.form-single-select-pill .label {
  color: #1c1d3e;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

.form-single-select-pill .help-text {
  color: #f43f5e;
  margin-bottom: 1rem;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
}

.form-single-select-pill .error {
  margin-left: 10px;
  margin-right: auto;
}

.form-single-select-pill .options.small {
  flex-wrap: wrap;
  justify-self: end;
  display: flex;
}

.form-single-select-pill .options.small .option {
  cursor: pointer;
  color: #1c1d3e;
  text-align: center;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  padding: 5.5px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.form-single-select-pill .options.small .option.selected {
  color: #fff;
  background: #1c1d3e;
}

.form-multi-select-pill {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.form-multi-select-pill .label {
  color: #1c1d3e;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

.form-multi-select-pill .help-text {
  color: #f43f5e;
  margin-bottom: 1rem;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
}

.form-multi-select-pill .error {
  margin-left: 10px;
  margin-right: auto;
}

.form-multi-select-pill .options.small {
  flex-wrap: wrap;
  justify-self: end;
  display: flex;
}

.form-multi-select-pill .options.small .option {
  cursor: pointer;
  color: #1c1d3e;
  text-align: center;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  padding: 5.5px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.form-multi-select-pill .options.small .option.selected {
  color: #fff;
  background: #1c1d3e;
}

.form-color-picker .form-input {
  min-width: 251px;
  max-width: 283px;
  height: 100%;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.form-color-picker .form-input .header {
  min-height: 80px;
  color: #1c1d3e;
  border-bottom: 1px solid #f1f1f5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 12px 18px;
  display: flex;
}

.form-color-picker .form-input .header > .icon img {
  width: 40px;
  margin-bottom: 18px;
  padding-top: 12px;
}

.form-color-picker .form-input .header > .label {
  letter-spacing: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.form-color-picker .form-input .header > .sub-text {
  margin-top: 15px;
}

.form-color-picker .form-input .picker {
  padding: 15px 18px;
}

.form-color-picker .form-input .picker .color-palette {
  grid-template-columns: repeat(5, 1fr);
  place-items: center;
  gap: 10px;
  margin-bottom: 10px;
  display: grid;
}

.form-color-picker .form-input .picker .color-palette .color {
  aspect-ratio: 1 / 1;
  height: 35px;
  cursor: pointer;
  border: 1px solid;
  border-radius: 4px;
  display: inline-block;
  position: relative;
}

.form-color-picker .form-input .picker .color-palette .color.selected {
  background-image: url("icon-check-black.6727bc5a.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #000 !important;
}

.form-select-pax .increment-and-decrement-value > .decrement, .form-select-pax .increment-and-decrement-value > .increment {
  cursor: pointer;
  border-radius: 50%;
  padding: 4px 6px 0;
  box-shadow: 0 2px 4px #2223334d;
}

.form-select-pax .increment-and-decrement-value > .decrement .icon, .form-select-pax .increment-and-decrement-value > .increment .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.form-select-pax .increment-and-decrement-value > .decrement .icon > img, .form-select-pax .increment-and-decrement-value > .increment .icon > img {
  height: 100%;
}

.form-select-pax {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 10px;
}

.form-select-pax label {
  color: #7d7e92;
  margin-bottom: 10px;
  margin-left: 0;
}

.form-select-pax .increment-and-decrement-value {
  color: #65667d;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  display: flex;
}

.form-select-pax .increment-and-decrement-value > .increment {
  background-color: #4cbba1;
}

.form-select-pax .increment-and-decrement-value > .decrement {
  background-color: #535a64;
}

.pos-layout {
  height: 100vh;
  width: 100vw;
}

.pos-layout:has(.refreshing-menu.show) > main {
  opacity: .4;
  pointer-events: none;
}

.pos-layout > main {
  height: inherit;
  margin-left: 70px;
}

.pos-layout .cannot-access {
  margin-top: 0;
  margin-left: 84px;
  padding: 20px;
}

.home-page {
  height: inherit;
}

.home-page > .header {
  width: 0;
  margin: 0 !important;
}

.home-page > .body {
  height: inherit;
}

.home-page .home {
  height: inherit;
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas: "menu-sidebar-widget menu-items-widget cart-widget";
  display: grid;
  overflow: hidden;
}

.ordering-page {
  height: inherit;
}

.ordering-page > .header {
  width: 0;
  margin: 0 !important;
}

.ordering-page > .body {
  height: inherit;
}

.order-history-page {
  height: 100vh;
  grid-template-rows: min-content 1fr;
  display: grid;
}

.order-history-page > .header > .wrapper {
  border-bottom: 0;
  margin-bottom: -30px;
  padding: 20px 10px 0 25px;
}

.order-history-page > .header .store-filter-widget .form-label {
  visibility: hidden;
}

.order-history-page > .header .store-filter-widget .form-input {
  background-image: url("icon-store-front.c4527e76.svg");
  background-position: 15px;
  background-repeat: no-repeat;
  background-size: 20.09px 18px;
}

.order-history-page > .header .store-filter-widget .drop-down-input {
  padding-left: 34px;
}

.order-history-page > .header .search-input {
  position: relative;
}

.order-history-page > .header .search-input input {
  width: 44px;
  cursor: pointer;
  text-indent: 32px;
  color: #1c1d3e;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 9px;
  background-repeat: no-repeat;
  background-size: 24px 24.41px;
  border: 1px solid #dee0e6;
  border-radius: 6px;
  outline: none;
  padding: 12px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  transition: all .3s linear;
}

.order-history-page > .header .search-input .clear-icon {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 10px;
}

.order-history-page > .header .search-input.open input {
  width: 310px;
  cursor: unset;
  background-position: 16px;
  background-size: 16px 16.28px;
}

.order-history-page > .header .search-input.has-input input {
  text-indent: unset;
  padding: 12px 40px 12px 46px;
  transition: unset;
}

.order-history-page > .body {
  overflow: hidden;
}

.pos-customer-list-page {
  display: grid;
}

.pos-customer-list-page > .header input {
  text-indent: 30px;
  width: 380px;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 15px;
  background-repeat: no-repeat;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  outline: none;
  padding: 12px 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
}

.pos-customer-list-page > .body {
  grid-template-rows: min-content 1fr;
}

.pos-customer-list-page > .body .result-count {
  margin: 20px 20px 0;
}

.pos-customer-list-page > .body .list-header {
  color: #fff;
  background-color: #1c1d3e;
  border: 1px solid #d9d9e6;
  border-bottom: none;
  border-radius: 10px;
  grid-template-columns: 1fr 1fr 1fr 3fr 10px;
  grid-auto-flow: column;
  gap: 15px;
  margin: 20px 30px;
  padding: 13px 20px;
  font-weight: 600;
  display: grid;
}

.pos-customer-list-page > .body .empty-message, .pos-customer-list-page > .body .no-results {
  text-align: center;
  margin-top: 80px;
}

.pos-customer-list-page > .body .spinner-container {
  margin-top: 40px;
}

.pos-customer-list-page > .body .ais-SearchBox {
  display: none;
}

.pos-customer-list-page > .body .ais-Hits-list {
  margin: 20px 30px;
  list-style: none;
}

.pos-customer-list-page > .body .ais-Hits-list .ais-Hits-item {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 10px 0;
  box-shadow: 0 3px 10px -3px #0000004d;
}

.pos-customer-list-page > .body .ais-Pagination-list {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.pos-customer-list-page > .body .ais-Pagination {
  color: #3a4570;
}

.pos-customer-list-page > .body .ais-Pagination-link {
  color: var(--color-primary);
  text-decoration: none;
  transition: color .2s ease-out;
}

.pos-customer-list-page > .body .ais-Pagination-list {
  justify-content: center;
}

.pos-customer-list-page > .body .ais-Pagination-item + .ais-Pagination-item {
  margin-left: .3rem;
}

.pos-customer-list-page > .body .ais-Pagination-link {
  border: 1px solid #c4c8d8;
  border-radius: 5px;
  padding: .3rem .6rem;
  transition: background-color .2s ease-out;
  display: block;
}

.pos-customer-list-page > .body .ais-Pagination-item--disabled .ais-Pagination-link {
  opacity: .6;
  cursor: not-allowed;
}

.pos-customer-list-page > .body .ais-Pagination-item--selected .ais-Pagination-link {
  color: #fff;
  background-color: #4cbba1;
  border-color: #4cbba1;
}

.print-station-list-page {
  height: 100vh;
  grid-template-rows: min-content 1fr;
  display: grid;
}

.print-station-list-page .body {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  height: unset;
  padding: 24px;
  overflow: scroll;
}

.print-station-list-page .body::-webkit-scrollbar {
  width: 1px;
}

.print-station-list-page .body::-webkit-scrollbar-track {
  background: none;
}

.print-station-list-page .body::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.sales-report-page {
  height: 100vh;
}

.sales-report-page .header {
  margin-bottom: unset;
}

.manage-sold-out-items-page input {
  text-indent: 30px;
  width: 380px;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 15px;
  background-repeat: no-repeat;
  border: 1px solid #dee0e6;
  border-radius: 6px;
  outline: none;
  padding: 12px 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

.manage-sold-out-items-page > .header .wrapper {
  align-items: end;
  padding: 24px 30px;
}

.manage-sold-out-items-page .store-filter-widget {
  top: 24px;
}

.manage-sold-out-items-page .store-filter-widget .form-input {
  border-radius: 8px;
}

.lock-screen-page > .header {
  width: 0;
  margin: 0 !important;
}

.lock-screen-page > .body {
  height: 100%;
}

.pos-more-page {
  height: inherit;
}

.pos-more-page > .header {
  margin: 0 !important;
}

.pos-more-page > .body {
  height: inherit;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  padding: 24px;
  overflow: scroll;
}

.pos-more-page > .body::-webkit-scrollbar {
  width: 1px;
}

.pos-more-page > .body::-webkit-scrollbar-track {
  background: none;
}

.pos-more-page > .body::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.category-colors-page {
  height: 100vh;
  grid-template-rows: min-content 1fr;
  display: grid;
}

.category-colors-page > .header {
  margin-bottom: 0;
}

.category-colors-page > .header input[type="text"] {
  text-indent: 30px;
  width: 380px;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 15px;
  background-repeat: no-repeat;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  box-shadow: 0 3px 15px -5px #0000004d;
}

.category-colors-page > .body {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  padding: 24px;
  overflow: scroll;
}

.category-colors-page > .body::-webkit-scrollbar {
  width: 1px;
}

.category-colors-page > .body::-webkit-scrollbar-track {
  background: none;
}

.category-colors-page > .body::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.item-colors-page {
  height: 100vh;
  grid-template-rows: min-content 1fr;
  display: grid;
}

.item-colors-page > .header {
  margin-bottom: 0;
}

.item-colors-page > .header input[type="text"] {
  text-indent: 30px;
  width: 380px;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 15px;
  background-repeat: no-repeat;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  box-shadow: 0 3px 15px -5px #0000004d;
}

.item-colors-page > .body {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  padding: 24px;
  overflow: scroll;
}

.item-colors-page > .body::-webkit-scrollbar {
  width: 1px;
}

.item-colors-page > .body::-webkit-scrollbar-track {
  background: none;
}

.item-colors-page > .body::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.third-party-deliveries-page {
  height: 100vh;
  grid-template-rows: min-content 1fr;
  display: grid;
}

.third-party-deliveries-page > .header {
  margin-bottom: 24px;
  padding: 0 24px;
}

.third-party-deliveries-page > .header > .wrapper {
  width: inherit;
  border-bottom: none;
  grid-template-areas: "title actions"
                       "subtitle empty";
  justify-content: start;
  align-items: baseline;
  column-gap: 24px;
  padding: 20px 24px;
}

.third-party-deliveries-page > .header .action-link {
  color: #7d7e92;
  cursor: pointer;
  grid-auto-flow: column;
  align-items: center;
  gap: 12px;
  display: grid;
}

.third-party-deliveries-page > .body {
  overflow: hidden;
}

.third-party-deliveries-page .disabled {
  margin: 30px;
}

.third-party-deliveries-page.delivery-complete > .header > .wrapper {
  grid-template-areas: "actions"
                       "title"
                       "subtitle";
}

.third-party-deliveries-page.delivery-complete > .header > .wrapper .action-link {
  margin-bottom: 13.5px;
}

.third-party-completed-deliveries-page {
  height: 100vh;
  grid-template-rows: min-content 1fr;
  display: grid;
}

.third-party-completed-deliveries-page > .header {
  margin-bottom: 0;
}

.third-party-completed-deliveries-page > .header > .wrapper {
  border-bottom: none;
  grid-template-areas: "actions"
                       "title"
                       "subtitle";
  justify-content: start;
  align-items: baseline;
  column-gap: 24px;
}

.third-party-completed-deliveries-page > .header .action-link {
  color: #7d7e92;
  cursor: pointer;
  grid-auto-flow: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 13.5px;
  display: grid;
}

.third-party-completed-deliveries-page > .body {
  overflow: hidden;
}

.third-party-completed-deliveries-page .disabled {
  margin: 30px;
}

.new-table-bookings-page {
  display: grid;
}

.new-table-bookings-page > .header {
  margin: 0 !important;
}

.new-table-bookings-page > .header .actions {
  justify-content: center;
  align-items: center;
  display: flex;
}

.new-table-bookings-page > .header .actions .add-table-booking-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #3f9c86;
  border-radius: 5px;
  margin: 0 20px 0 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
  color: #3f9c86 !important;
}

.new-table-bookings-page > .header .actions input {
  text-indent: 30px;
  width: 380px;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 15px;
  background-repeat: no-repeat;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  box-shadow: 0 3px 15px -5px #0000004d;
}

.new-table-bookings-page .table-booking-disabled {
  margin: 24px;
}

.table-bookings-page {
  height: 100vh;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-template-rows: min-content 1fr;
  display: grid;
  overflow: scroll;
}

.table-bookings-page::-webkit-scrollbar {
  width: 1px;
}

.table-bookings-page::-webkit-scrollbar-track {
  background: none;
}

.table-bookings-page::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.table-bookings-page > .header {
  margin: 0 !important;
}

.table-bookings-page > .header .wrapper {
  border-bottom: none;
}

.table-bookings-page > .header .actions {
  justify-content: center;
  align-items: center;
  display: flex;
}

.table-bookings-page > .header .actions .add-table-booking-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #3f9c86;
  border-radius: 5px;
  margin: 0 20px 0 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
  color: #3f9c86 !important;
}

.table-bookings-page > .header .actions input {
  text-indent: 30px;
  width: 380px;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 15px;
  background-repeat: no-repeat;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  box-shadow: 0 3px 15px -5px #0000004d;
}

.table-bookings-page > .body {
  padding: 0 0 24px;
}

.table-bookings-page .table-booking-disabled {
  margin: 24px;
}

.table-bookings-page.no-header .header {
  display: none;
}

.integrations-page {
  height: 100vh;
}

.integrations-page .header {
  margin-bottom: unset;
}

.employee-time-clock-staff-records-page {
  height: 100%;
}

.employee-time-clock-staff-records-page > .header {
  display: none;
}

.employee-time-clock-staff-records-page > .body {
  height: inherit;
}

.employee-time-clock-timesheet-page {
  height: 100%;
}

.employee-time-clock-timesheet-page > .header {
  display: none;
}

.employee-time-clock-timesheet-page > .body {
  height: inherit;
}

.pizza-sales-reports-page {
  min-height: 100vh;
  position: relative;
}

.pizza-sales-reports-page > .spinner-container {
  position: absolute;
  top: 0;
}

.pizza-sales-reports-page > .header {
  letter-spacing: 0;
  color: #1c1d3e;
  grid-template-columns: 1fr min-content;
  align-items: center;
  margin-bottom: 0;
  padding: 22px 24px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  display: grid;
}

.pizza-sales-reports-page > .display-selector {
  border-bottom: 1px solid #f4f4f6;
  padding-left: 24px;
  display: flex;
}

.pizza-sales-reports-page > .display-selector > .option {
  cursor: pointer;
  color: #4c4e68;
  letter-spacing: .005em;
  text-align: center;
  padding: 12px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.pizza-sales-reports-page > .display-selector > .option.active {
  color: #3f9c86;
  border-bottom: 4px solid #3f9c86;
  font-weight: 600;
}

.pizza-sales-reports-page .pizza-size-sales-report-widget > .header {
  display: none;
}

.pizza-sales-reports-page .pizza-size-sales-report-widget .filters {
  justify-content: space-between;
  display: flex;
}

.pizza-sales-reports-page .pizza-size-sales-report-widget .help-text {
  color: #4c4e68;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
}

.pizza-sales-reports-page .pizza-size-sales-report-widget .help-text img {
  padding-right: 8px;
}

.pizza-sales-reports-page .pizza-item-sales-report-widget > .header {
  display: none;
}

.pizza-sales-reports-page .pizza-item-sales-report-widget .order-source-and-pizza-size-filters {
  gap: 14px;
  display: flex;
}

.pizza-sales-reports-page .pizza-item-sales-report-widget .help-text {
  color: #4c4e68;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
}

.pizza-sales-reports-page .pizza-item-sales-report-widget .help-text img {
  padding-right: 8px;
}

.incoming-online-orders-page > .header .wrapper {
  padding: 25px 30px;
}

.incoming-online-orders-page .store-filter-widget {
  position: absolute;
  top: 15px;
  right: 30px;
}

.incoming-online-orders-page .store-filter-widget .form-input {
  width: 400px;
}

.pos-customer-view-page {
  height: 100vh;
  overflow: auto;
}

.dine-in-page {
  height: 100vh;
}

.dine-in-page > .header {
  margin-bottom: 0;
}

.customer-reviews-page {
  height: 100vh;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-template-rows: min-content 1fr;
  display: grid;
  overflow: scroll;
}

.customer-reviews-page::-webkit-scrollbar {
  width: 1px;
}

.customer-reviews-page::-webkit-scrollbar-track {
  background: none;
}

.customer-reviews-page::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.customer-reviews-page > .header {
  margin: 0;
}

.customer-reviews-page > .header .wrapper {
  width: calc(100vw - 70px);
  border-bottom: none;
  padding: 20px 24px 0;
}

.customer-reviews-page > .body {
  height: 100%;
}

.link {
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  padding: 0 10px;
  display: flex;
}

.link .icon {
  width: 32px;
}

.link .icon img {
  width: 100%;
}

.link .label {
  color: #7d7e92;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 38px;
}

.link .label.selected {
  color: #4cbba1;
}

header {
  height: 53px;
  z-index: 1;
  background-color: #454545;
  grid-area: header;
  align-items: center;
  padding: 0;
  display: grid;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

header .logo {
  margin-left: 20px;
}

header .logo img {
  width: 100%;
  display: block;
}

header .logo.icon {
  color: #fff;
  cursor: pointer;
  place-self: center start;
  font-size: 20px;
}

.sidebar {
  height: 100%;
  width: 70px;
  z-index: 999;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #1c1d3e;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: fixed;
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  width: 1px;
}

.sidebar::-webkit-scrollbar-track {
  background: none;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.sidebar .restoplus-logo .icon {
  width: 45px;
}

.sidebar .link {
  grid-auto-flow: row;
  justify-content: center;
  place-items: center;
  padding: 0;
  display: grid;
}

.sidebar .link .label {
  text-align: center;
  color: #7d7e92;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

.sidebar .main-menu {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  display: flex;
}

.sidebar .offline.show {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sidebar .offline.show img {
  width: 22px;
  height: 22px;
}

.sidebar .offline.show span {
  color: #f43f5e;
  margin-top: 8px;
}

.sidebar .offline.hide {
  display: none;
}

.sidebar .refreshing-menu.show {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sidebar .refreshing-menu.show img {
  width: 30px;
  height: 30px;
}

.sidebar .refreshing-menu.show span {
  color: #f59e0b;
  margin-top: 3px;
  font-size: 10px;
}

.sidebar .refreshing-menu.hide {
  display: none;
}

.sidebar .refresh-menu {
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.sidebar .refresh-menu div {
  width: 39px !important;
  height: 39.5px !important;
}

.sidebar .not-available {
  opacity: .4;
  cursor: not-allowed;
}

.search-widget {
  margin-bottom: 20px;
  display: grid;
}

.search-widget .inputbox {
  height: 44px;
  text-indent: 30px;
  min-width: 112.59px;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 12px;
  background-repeat: no-repeat;
  background-size: 18px;
  border: none;
  border-radius: 8px;
  outline: none;
  padding: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  box-shadow: 0 3px 15px -5px #0000004d;
}

.search-widget .inputbox:focus {
  outline: 2px solid #4cbba1;
}

.search-widget .clear-search-btn {
  margin-top: 10px;
}

.home-widget {
  color: #1c1d3e;
  height: 100vh;
  background-color: #f6f8fb;
  grid-template-rows: 1fr max-content 1.5fr;
  justify-items: center;
  display: grid;
}

.home-widget .shifts-button {
  color: #3f9c86;
  grid-column-gap: 8px;
  cursor: pointer;
  border: 1px solid #83d0be;
  border-radius: 4px;
  align-items: center;
  padding: 7px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  position: absolute;
  top: 17px;
  right: 21px;
}

.home-widget .store-info {
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.home-widget .store-info .image {
  margin-right: 12px;
}

.home-widget .store-info .image img {
  width: 48px;
  border-radius: 8px;
}

.home-widget .store-info .store-name {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #4c4e68;
  margin-bottom: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.home-widget .logout {
  place-items: center;
  gap: 12px;
  display: grid;
}

.home-widget .logout .employee-name {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #7d7e92;
  align-self: end;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.home-widget .logout .employee-name span {
  color: #1c1d3e;
  font-weight: 600;
}

.home-widget .logout .employee-logout-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #4cbba1;
  border-radius: 5px;
  align-self: start;
  align-items: center;
  margin: 0;
  padding: 15px 64px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  transition: all .2s ease-in-out;
  display: flex;
  color: #4cbba1 !important;
}

.home-widget .logout .employee-logout-button img {
  margin-right: 8px;
}

.home-widget .quick-actions {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  grid-template-rows: max-content 1fr;
  margin: 9px 0;
  padding: 22px;
  display: grid;
  box-shadow: 0 0 3px #2e2f4d0d, 0 10px 25px #59587b26;
}

.home-widget .quick-actions .header {
  border-bottom: 1px solid #dee0e6;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 18px;
  display: flex;
}

.home-widget .quick-actions .main-panel {
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  gap: 18px;
  padding-top: 15px;
  display: grid;
}

.home-widget .quick-actions .main-panel .quick-action {
  text-align: center;
  letter-spacing: 0;
  color: #1c1d3e;
  cursor: pointer;
  background: linear-gradient(#f2f7fd 0%, #fff 69.29%), #fff;
  border-radius: 8px;
  place-items: center;
  gap: 28px;
  margin-bottom: 0;
  padding: 30px 25px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  display: grid;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.home-widget .quick-actions .main-panel .quick-action img {
  width: 48px;
  align-self: end;
}

.home-widget .quick-actions .main-panel .quick-action .label {
  align-self: start;
}

.ordering-widget {
  height: inherit;
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas: "menu-sidebar-widget menu-items-widget cart-widget";
  display: grid;
  overflow: hidden;
}

.menu-items-widget {
  height: 100%;
}

.menu-items-widget > .menu-items {
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 12px;
  padding: 12px;
  display: grid;
}

.menu-items-widget > .footer {
  color: #7d7e92;
  text-align: center;
  margin-bottom: 12px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

.menu-items-widget > .no-items {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.menu-items-widget > .no-items .icon {
  height: 28px;
  width: 28px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.menu-items-widget > .no-items .icon > img {
  height: 100%;
}

.menu-items-widget > .no-items .label {
  letter-spacing: 0;
  margin-top: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.menu-item {
  grid-gap: 8px;
  height: 100%;
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  grid-template: "name name" 1fr
                 "description description" min-content
                 "price soldout"
                 / min-content 1fr;
  padding: 12px;
  display: grid;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.menu-item .name {
  color: #343553;
  grid-area: name;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}

.menu-item .price {
  text-align: left;
  color: #4cbba1;
  grid-area: price;
  align-self: center;
  font-size: 14px;
  line-height: 19px;
}

.menu-item .price:first-letter {
  opacity: .6;
}

.menu-item .description {
  color: #7d7e92;
  grid-area: description;
}

.menu-item .soldout {
  color: #fff;
  text-align: center;
  background-color: #1c1d3e;
  border-radius: 3px;
  grid-area: soldout;
  align-self: center;
  font-size: 11px;
}

.menu-item.soldout {
  cursor: not-allowed;
}

.menu-item.soldout .price {
  color: #1c1d3e;
}

.menu-item.custom-background-color .name, .menu-item.custom-background-color .price {
  color: #333348;
}

.menu-item.custom-background-color .price:first-letter {
  opacity: 1;
}

.reorder-list-widget > .orders {
  flex-direction: column;
  padding: 12px;
  display: flex;
}

.reorder-list-widget > .no-orders {
  text-align: center;
  color: #7d7e92;
  margin-top: 60px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

.reorder-list-widget > .spinner-container {
  width: auto;
}

.pos-reorder-list-item-widget {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 16px;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.pos-reorder-list-item-widget:last-child {
  margin-bottom: 0;
}

.pos-reorder-list-item-widget > .header {
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  display: grid;
}

.pos-reorder-list-item-widget > .header > .collection-type {
  align-items: center;
  display: flex;
}

.pos-reorder-list-item-widget > .header > .collection-type > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pos-reorder-list-item-widget > .header > .collection-type > .icon > img {
  height: 100%;
}

.pos-reorder-list-item-widget > .header > .collection-type > .label {
  letter-spacing: .05em;
  color: #7d7e92;
  margin-left: 8px;
  font-size: 10px;
  line-height: 14px;
}

.pos-reorder-list-item-widget > .header > .item-count {
  text-align: right;
  color: #7d7e92;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
}

.pos-reorder-list-item-widget > .header div:nth-child(3) {
  display: flex;
}

.pos-reorder-list-item-widget > .header div:nth-child(3) > .placed-time {
  letter-spacing: 0;
  color: #1c1d3e;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.pos-reorder-list-item-widget > .header div:nth-child(3) > .order-type {
  height: -moz-fit-content;
  height: fit-content;
  color: #4c4e68;
  background-color: #c8c8d0;
  border-radius: 3px;
  margin-left: 8px;
  padding: 4px;
  font-size: 10px;
  line-height: 14px;
}

.pos-reorder-list-item-widget > .header > .add-all-to-order-button {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}

.pos-reorder-list-item-widget > .header > .add-all-to-order-button > .label {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #4cbba1;
  cursor: pointer;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: inline-block;
}

.website-reorder-list-item-widget {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 16px;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.website-reorder-list-item-widget:last-child {
  margin-bottom: 0;
}

.website-reorder-list-item-widget > .header {
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  display: grid;
}

.website-reorder-list-item-widget > .header > .collection-type {
  align-items: center;
  display: flex;
}

.website-reorder-list-item-widget > .header > .collection-type > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.website-reorder-list-item-widget > .header > .collection-type > .icon > img {
  height: 100%;
}

.website-reorder-list-item-widget > .header > .collection-type > .label {
  letter-spacing: .05em;
  color: #7d7e92;
  margin-left: 8px;
  font-size: 10px;
  line-height: 14px;
}

.website-reorder-list-item-widget > .header > .item-count {
  text-align: right;
  color: #7d7e92;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
}

.website-reorder-list-item-widget > .header div:nth-child(3) {
  display: flex;
}

.website-reorder-list-item-widget > .header div:nth-child(3) > .placed-time {
  letter-spacing: 0;
  color: #1c1d3e;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.website-reorder-list-item-widget > .header div:nth-child(3) > .order-type {
  height: -moz-fit-content;
  height: fit-content;
  color: #4c4e68;
  background-color: #c8c8d0;
  border-radius: 3px;
  margin-left: 8px;
  padding: 4px;
  font-size: 10px;
  line-height: 14px;
}

.website-reorder-list-item-widget > .header > .add-all-to-order-button {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}

.website-reorder-list-item-widget > .header > .add-all-to-order-button > .label {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #4cbba1;
  cursor: pointer;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: inline-block;
}

.google-reorder-list-item-widget {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 16px;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.google-reorder-list-item-widget:last-child {
  margin-bottom: 0;
}

.google-reorder-list-item-widget > .header {
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  display: grid;
}

.google-reorder-list-item-widget > .header > .collection-type {
  align-items: center;
  display: flex;
}

.google-reorder-list-item-widget > .header > .collection-type > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.google-reorder-list-item-widget > .header > .collection-type > .icon > img {
  height: 100%;
}

.google-reorder-list-item-widget > .header > .collection-type > .label {
  letter-spacing: .05em;
  color: #7d7e92;
  margin-left: 8px;
  font-size: 10px;
  line-height: 14px;
}

.google-reorder-list-item-widget > .header > .item-count {
  text-align: right;
  color: #7d7e92;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
}

.google-reorder-list-item-widget > .header div:nth-child(3) {
  display: flex;
}

.google-reorder-list-item-widget > .header div:nth-child(3) > .placed-time {
  letter-spacing: 0;
  color: #1c1d3e;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.google-reorder-list-item-widget > .header div:nth-child(3) > .order-type {
  height: -moz-fit-content;
  height: fit-content;
  color: #4c4e68;
  background-color: #c8c8d0;
  border-radius: 3px;
  margin-left: 8px;
  padding: 4px;
  font-size: 10px;
  line-height: 14px;
}

.google-reorder-list-item-widget > .header > .add-all-to-order-button {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}

.google-reorder-list-item-widget > .header > .add-all-to-order-button > .label {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #4cbba1;
  cursor: pointer;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: inline-block;
}

.reorder-item-widget {
  letter-spacing: 0;
  color: #1c1d3e;
  padding: 12px 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.reorder-item-widget > .header {
  margin-bottom: 4px;
  display: flex;
}

.reorder-item-widget > .header > .info {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  display: flex;
}

.reorder-item-widget > .header > .info > div:first-child {
  justify-content: space-between;
  display: flex;
}

.reorder-item-widget > .header > .info > .original-price, .reorder-item-widget > .header > .info > .discount, .reorder-item-widget > .header > .info > .extra-charge {
  color: #9597a7;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
}

.reorder-item-widget > .header > .add-to-order-button {
  color: #4cbba1;
  height: -moz-fit-content;
  height: fit-content;
  white-space: nowrap;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #4cbba1;
  border-radius: 8px;
  margin-left: 16px;
  padding: 7px 12px;
}

.reorder-item-widget > .unavailability-status {
  width: -moz-fit-content;
  width: fit-content;
  color: #9191a1;
  background-color: #e9e9ec;
  border-radius: 3px;
  margin-block: 4px;
  padding: 4px;
  font-size: 10px;
  line-height: 12px;
}

.reorder-item-widget > .item-description-view-widget {
  color: #7d7e92;
  margin-top: 0;
}

.reorder-item-widget > .item-description-view-widget > .label {
  color: #7d7e92;
}

.reorder-item-widget > .comments {
  white-space: pre-wrap;
  background-color: #c4eae180;
  border-radius: 4px;
  justify-content: space-between;
  margin-top: 8px;
  padding: 4px 8px;
  font-size: 13px;
  line-height: 19px;
  display: flex;
}

.reorder-item-widget > .comments > .extra-charge {
  margin-left: 8px;
}

.reorder-item-widget.unavailable, .reorder-item-widget.unavailable > .header > .info > .original-price, .reorder-item-widget.unavailable > .header > .info > .discount, .reorder-item-widget.unavailable > .header > .info > .extra-charge {
  color: #9191a1;
}

.reorder-item-widget.unavailable > .header > .add-to-order-button {
  color: #babac4;
  cursor: default;
  background-color: #f4f4f6;
  border: 1px solid #babac4;
}

.reorder-item-widget.unavailable > .item-description-view-widget, .reorder-item-widget.unavailable > .item-description-view-widget > .label {
  color: #9191a1;
}

.reorder-item-widget.unavailable > .comments {
  background-color: #f4f4f6;
}

.basic-item-widget.with-customization {
  height: 90vh;
  width: 90vw;
  max-width: 1500px;
  max-width: 1200px;
  grid-template-columns: 70% 30%;
  grid-template-areas: "main-panel info-panel";
  display: grid;
}

.basic-item-widget.with-customization .info-panel {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #f6f8fb;
  border-radius: 0 20px 20px 0;
  grid-area: info-panel;
  grid-template-rows: min-content 1fr min-content;
  padding: 24px 24px 19px;
  display: grid;
  overflow-x: hidden;
  overflow-y: scroll;
}

.basic-item-widget.with-customization .info-panel::-webkit-scrollbar {
  width: 1px;
}

.basic-item-widget.with-customization .info-panel::-webkit-scrollbar-track {
  background: none;
}

.basic-item-widget.with-customization .info-panel::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.basic-item-widget.with-customization .info-panel .spec-info .title {
  letter-spacing: .26px;
  color: #1c1d3e;
  margin-bottom: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
}

.basic-item-widget.with-customization .info-panel .spec-info .description {
  color: #7d7e92;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.basic-item-widget.with-customization .info-panel .summary {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  overflow-y: hidden scroll;
  border-top: 1px solid #d9d9e6;
  margin: 20px 0;
  padding-top: 5px;
}

.basic-item-widget.with-customization .info-panel .summary::-webkit-scrollbar {
  width: 1px;
}

.basic-item-widget.with-customization .info-panel .summary::-webkit-scrollbar-track {
  background: none;
}

.basic-item-widget.with-customization .info-panel .summary::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.basic-item-widget.with-customization .info-panel .summary.hide {
  opacity: 0;
  transition: all .3s ease-out;
}

.basic-item-widget.with-customization .info-panel .summary.show {
  opacity: 1;
  transition: all .3s ease-in;
}

.basic-item-widget.with-customization .info-panel .actions > .quantity {
  background-color: #fff;
  border-radius: 10px;
  grid-template-columns: 1fr repeat(3, min-content);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 8px 15px 8px 14px;
  display: grid;
}

.basic-item-widget.with-customization .info-panel .actions > .quantity .icon {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.basic-item-widget.with-customization .info-panel .actions > .quantity .btn, .basic-item-widget.with-customization .info-panel .main-panel .modifier .options .option .actions > .quantity .inc, .basic-item-widget.with-customization .main-panel .modifier .options .option .info-panel .actions > .quantity .inc, .basic-item-widget.with-customization .info-panel .main-panel .modifier .options .option .actions > .quantity .dec, .basic-item-widget.with-customization .main-panel .modifier .options .option .info-panel .actions > .quantity .dec, .basic-item-widget.with-customization .info-panel .actions > .quantity .inc, .basic-item-widget.with-customization .info-panel .actions > .quantity .dec {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
}

.basic-item-widget.with-customization .info-panel .actions > .quantity .dec {
  background: #4c4e68;
}

.basic-item-widget.with-customization .info-panel .actions > .quantity .number {
  min-width: 34px;
  text-align: center;
  align-self: center;
  margin: 0 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
}

.basic-item-widget.with-customization .info-panel .actions > .quantity .inc {
  background: #4cbba1;
}

.basic-item-widget.with-customization .info-panel .actions > .quantity > h4 {
  color: #7d7e92;
  margin: 0;
}

.basic-item-widget.with-customization .info-panel .actions > .quantity .btn, .basic-item-widget.with-customization .info-panel .main-panel .modifier .options .option .actions > .quantity .inc, .basic-item-widget.with-customization .main-panel .modifier .options .option .info-panel .actions > .quantity .inc, .basic-item-widget.with-customization .info-panel .main-panel .modifier .options .option .actions > .quantity .dec, .basic-item-widget.with-customization .main-panel .modifier .options .option .info-panel .actions > .quantity .dec, .basic-item-widget.with-customization .info-panel .actions > .quantity .dec, .basic-item-widget.with-customization .info-panel .actions > .quantity .inc {
  width: 28px;
  height: 28px;
}

.basic-item-widget.with-customization .info-panel .actions > .quantity .btn i, .basic-item-widget.with-customization .info-panel .main-panel .modifier .options .option .actions > .quantity .inc i, .basic-item-widget.with-customization .main-panel .modifier .options .option .info-panel .actions > .quantity .inc i, .basic-item-widget.with-customization .info-panel .main-panel .modifier .options .option .actions > .quantity .dec i, .basic-item-widget.with-customization .main-panel .modifier .options .option .info-panel .actions > .quantity .dec i, .basic-item-widget.with-customization .info-panel .actions > .quantity .dec i, .basic-item-widget.with-customization .info-panel .actions > .quantity .inc i {
  flex-flow: column;
  justify-content: center;
  display: flex;
}

.basic-item-widget.with-customization .info-panel .actions > .quantity .number {
  color: #1c1d3e;
  font-size: 15px;
  line-height: 21px;
}

.basic-item-widget.with-customization .info-panel .actions .add-to-cart {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0;
  width: 100%;
  letter-spacing: .1px;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0 0 10px;
  padding: 14px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.basic-item-widget.with-customization .info-panel .actions .add-to-cart:focus {
  border: none;
  outline: none;
}

.basic-item-widget.with-customization .info-panel .actions .cancel {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  width: 100%;
  background-color: #0000;
  margin: 0;
  padding: 11px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.basic-item-widget.with-customization .info-panel .description > .text.hide {
  display: none;
}

.basic-item-widget.with-customization .info-panel .spec-info .description.hide {
  opacity: 0;
  max-height: 1px;
  transition: all .3s ease-out;
  position: absolute;
  bottom: 1000vh;
}

.basic-item-widget.with-customization .info-panel .spec-info .description.show {
  opacity: 1;
  max-height: 100vh;
  transition: all .3s ease-in;
  position: relative;
}

.basic-item-widget.with-customization .info-panel .spec-info .description-toggle {
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  color: #7d7e92;
  border: 1px solid #7d7e92;
  border-radius: 20px;
  margin-top: 15px;
  padding: 4px 14px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.basic-item-widget.with-customization .info-panel .summary {
  padding-top: 12px;
}

.basic-item-widget.with-customization .info-panel .summary .item-description-view-widget {
  color: #7d7e92;
}

.basic-item-widget.with-customization .info-panel .empty-summary {
  justify-content: center;
  margin: 15px 0;
  display: grid;
}

.basic-item-widget.with-customization .info-panel .empty-summary .icon {
  margin: 15px auto;
}

.basic-item-widget.with-customization .info-panel .empty-summary > p {
  color: #7d7e92;
  max-width: 60%;
  text-align: center;
  margin: 4px auto 17px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.basic-item-widget.with-customization .main-panel {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-area: main-panel;
  padding: 28px 24px;
  overflow-y: auto;
}

.basic-item-widget.with-customization .main-panel::-webkit-scrollbar {
  width: 1px;
}

.basic-item-widget.with-customization .main-panel::-webkit-scrollbar-track {
  background: none;
}

.basic-item-widget.with-customization .main-panel::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.basic-item-widget.with-customization .main-panel .modifier .header {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 18px;
  display: flex;
}

.basic-item-widget.with-customization .main-panel .modifier .header .badge {
  color: #535a64;
}

.basic-item-widget.with-customization .main-panel .modifier .header .badge.optional {
  color: #34c759;
}

.basic-item-widget.with-customization .main-panel .modifier .header .badge.required {
  color: #da6565;
}

.basic-item-widget.with-customization .main-panel .modifier .header .badge.is-selected {
  color: #7d7e92;
}

.basic-item-widget.with-customization .main-panel .modifier .options {
  padding: 15px 18px;
}

.basic-item-widget.with-customization .main-panel .modifier .options .option:last-child {
  border-bottom: 0;
}

.basic-item-widget.with-customization .main-panel .modifier .options .option {
  letter-spacing: 0;
  color: #1c1d3e;
  width: 100%;
  color: #1c1d3e90;
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  grid-template-columns: 1fr min-content min-content;
  grid-template-areas: "input qty charge";
  align-items: center;
  margin-bottom: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px;
  transition: all .2s ease-out;
  display: grid;
}

.basic-item-widget.with-customization .main-panel .modifier .options .option.selected {
  color: #fff;
  box-shadow: none;
  background-color: #1c1d3e;
}

.basic-item-widget.with-customization .main-panel .modifier .options .option .clickable {
  flex-direction: row;
  grid-area: input;
  align-items: center;
  margin-right: 10px;
  padding: 15px 0 15px 15px;
  display: flex;
}

.basic-item-widget.with-customization .main-panel .modifier .options .option .clickable > .container > input {
  cursor: pointer;
  margin-right: 12px;
}

.basic-item-widget.with-customization .main-panel .modifier .options .option .clickable .name {
  max-width: 20vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.basic-item-widget.with-customization .main-panel .modifier .options .option .quantity {
  flex-direction: row;
  grid-area: qty;
  place-self: end;
  align-items: center;
  margin-right: 25px;
  padding: 15px 0;
  display: flex;
}

.basic-item-widget.with-customization .main-panel .modifier .options .option .quantity .icon {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.basic-item-widget.with-customization .main-panel .modifier .options .option .quantity .btn, .basic-item-widget.with-customization .main-panel .modifier .options .option .quantity .inc, .basic-item-widget.with-customization .main-panel .modifier .options .option .quantity .dec {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
}

.basic-item-widget.with-customization .main-panel .modifier .options .option .quantity .dec {
  background: #4c4e68;
}

.basic-item-widget.with-customization .main-panel .modifier .options .option .quantity .number {
  min-width: 34px;
  text-align: center;
  align-self: center;
  margin: 0 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
}

.basic-item-widget.with-customization .main-panel .modifier .options .option .quantity .inc {
  background: #4cbba1;
}

.basic-item-widget.with-customization .main-panel .modifier .options .option .quantity .icon {
  padding-top: 2px;
}

.basic-item-widget.with-customization .main-panel .modifier .options .option .quantity .number {
  margin: 0 20px;
}

.basic-item-widget.with-customization .main-panel .modifier .options .option .extra-charge {
  white-space: nowrap;
  grid-area: charge;
  padding-right: 15px;
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier {
  margin-bottom: 24px;
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .header {
  margin: 0 0 29px;
  padding: 0;
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .header .wrapper {
  display: flex;
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .header .wrapper .badge {
  margin-left: 6px;
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .options {
  grid-gap: 15px;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  padding: 0;
  display: grid;
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .options::-webkit-scrollbar {
  display: none;
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .options .option {
  text-align: center;
  color: #1c1d3e;
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  flex-flow: column;
  justify-content: center;
  margin: 0;
  transition: unset;
  display: flex;
  position: relative;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .options .option.single-quantity.selected {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(#2c2d56 0%, #0c0d34 100%);
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .options .option.single-quantity.selected:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: #4cbba1;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .options .option.single-quantity.selected:after {
  content: "";
  width: 5px;
  height: 10px;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  display: block;
  position: absolute;
  top: -3px;
  right: 0;
  transform: rotate(45deg);
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .options .option.single-quantity.selected .charge {
  color: #fff;
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .options .option.single-select, .basic-item-widget.with-customization .main-panel > .modifiers .modifier .options .option.multi-select {
  padding-top: 12px;
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .options .option .name {
  margin-bottom: 4px;
  padding: 0 20px;
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .options .option .extra-charge {
  color: #7d7e92;
  margin-bottom: 14.5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .options .option .quantity {
  width: 100%;
  border-top: 1px solid #d9d9e6;
  justify-content: space-between;
  margin-right: 0;
  padding: 8px 10px;
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .options .option .clickable {
  margin: 0;
  padding: 0;
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .options .option .clickable .name {
  white-space: unset;
}

.basic-item-widget.with-customization .main-panel > .modifiers .modifier .options .option .clickable .container {
  display: none;
}

.basic-item-widget.without-customization {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  max-height: 90vh;
  background-color: #f6f8fb;
  border-radius: 20px;
  grid-area: info-panel;
  grid-template-rows: min-content 1fr min-content;
  padding: 60px 160px 30px;
  display: grid;
  overflow-x: hidden;
  overflow-y: scroll;
}

.basic-item-widget.without-customization::-webkit-scrollbar {
  width: 1px;
}

.basic-item-widget.without-customization::-webkit-scrollbar-track {
  background: none;
}

.basic-item-widget.without-customization::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.basic-item-widget.without-customization .spec-info .title {
  letter-spacing: .26px;
  color: #1c1d3e;
  margin-bottom: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
}

.basic-item-widget.without-customization .spec-info .description {
  color: #7d7e92;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.basic-item-widget.without-customization .summary {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  overflow-y: hidden scroll;
  border-top: 1px solid #d9d9e6;
  margin: 20px 0;
  padding-top: 5px;
}

.basic-item-widget.without-customization .summary::-webkit-scrollbar {
  width: 1px;
}

.basic-item-widget.without-customization .summary::-webkit-scrollbar-track {
  background: none;
}

.basic-item-widget.without-customization .summary::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.basic-item-widget.without-customization .summary.hide {
  opacity: 0;
  transition: all .3s ease-out;
}

.basic-item-widget.without-customization .summary.show {
  opacity: 1;
  transition: all .3s ease-in;
}

.basic-item-widget.without-customization .actions > .quantity {
  background-color: #fff;
  border-radius: 10px;
  grid-template-columns: 1fr repeat(3, min-content);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 8px 15px 8px 14px;
  display: grid;
}

.basic-item-widget.without-customization .actions > .quantity .icon {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.basic-item-widget.without-customization .actions > .quantity .btn, .basic-item-widget.without-customization .actions > .quantity .inc, .basic-item-widget.without-customization .actions > .quantity .dec, .basic-item-widget.without-customization .basic-item-widget.with-customization .info-panel .actions > .quantity .dec, .basic-item-widget.with-customization .info-panel .basic-item-widget.without-customization .actions > .quantity .dec, .basic-item-widget.without-customization .basic-item-widget.with-customization .info-panel .actions > .quantity .inc, .basic-item-widget.with-customization .info-panel .basic-item-widget.without-customization .actions > .quantity .inc, .basic-item-widget.without-customization .basic-item-widget.with-customization .main-panel .modifier .options .option .actions > .quantity .dec, .basic-item-widget.with-customization .main-panel .modifier .options .option .basic-item-widget.without-customization .actions > .quantity .dec, .basic-item-widget.without-customization .basic-item-widget.with-customization .main-panel .modifier .options .option .actions > .quantity .inc, .basic-item-widget.with-customization .main-panel .modifier .options .option .basic-item-widget.without-customization .actions > .quantity .inc {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
}

.basic-item-widget.without-customization .actions > .quantity .dec {
  background: #4c4e68;
}

.basic-item-widget.without-customization .actions > .quantity .number {
  min-width: 34px;
  text-align: center;
  align-self: center;
  margin: 0 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
}

.basic-item-widget.without-customization .actions > .quantity .inc {
  background: #4cbba1;
}

.basic-item-widget.without-customization .actions > .quantity > h4 {
  color: #7d7e92;
  margin: 0;
}

.basic-item-widget.without-customization .actions > .quantity .btn, .basic-item-widget.without-customization .basic-item-widget.with-customization .info-panel .actions > .quantity .dec, .basic-item-widget.with-customization .info-panel .basic-item-widget.without-customization .actions > .quantity .dec, .basic-item-widget.without-customization .basic-item-widget.with-customization .info-panel .actions > .quantity .inc, .basic-item-widget.with-customization .info-panel .basic-item-widget.without-customization .actions > .quantity .inc, .basic-item-widget.without-customization .basic-item-widget.with-customization .main-panel .modifier .options .option .actions > .quantity .dec, .basic-item-widget.with-customization .main-panel .modifier .options .option .basic-item-widget.without-customization .actions > .quantity .dec, .basic-item-widget.without-customization .basic-item-widget.with-customization .main-panel .modifier .options .option .actions > .quantity .inc, .basic-item-widget.with-customization .main-panel .modifier .options .option .basic-item-widget.without-customization .actions > .quantity .inc, .basic-item-widget.without-customization .actions > .quantity .dec, .basic-item-widget.without-customization .actions > .quantity .inc {
  width: 28px;
  height: 28px;
}

.basic-item-widget.without-customization .actions > .quantity .btn i, .basic-item-widget.without-customization .basic-item-widget.with-customization .info-panel .actions > .quantity .dec i, .basic-item-widget.with-customization .info-panel .basic-item-widget.without-customization .actions > .quantity .dec i, .basic-item-widget.without-customization .basic-item-widget.with-customization .info-panel .actions > .quantity .inc i, .basic-item-widget.with-customization .info-panel .basic-item-widget.without-customization .actions > .quantity .inc i, .basic-item-widget.without-customization .basic-item-widget.with-customization .main-panel .modifier .options .option .actions > .quantity .dec i, .basic-item-widget.with-customization .main-panel .modifier .options .option .basic-item-widget.without-customization .actions > .quantity .dec i, .basic-item-widget.without-customization .basic-item-widget.with-customization .main-panel .modifier .options .option .actions > .quantity .inc i, .basic-item-widget.with-customization .main-panel .modifier .options .option .basic-item-widget.without-customization .actions > .quantity .inc i, .basic-item-widget.without-customization .actions > .quantity .dec i, .basic-item-widget.without-customization .actions > .quantity .inc i {
  flex-flow: column;
  justify-content: center;
  display: flex;
}

.basic-item-widget.without-customization .actions > .quantity .number {
  color: #1c1d3e;
  font-size: 15px;
  line-height: 21px;
}

.basic-item-widget.without-customization .actions .add-to-cart {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0;
  width: 100%;
  letter-spacing: .1px;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0 0 10px;
  padding: 14px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.basic-item-widget.without-customization .actions .add-to-cart:focus {
  border: none;
  outline: none;
}

.basic-item-widget.without-customization .actions .cancel {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  width: 100%;
  background-color: #0000;
  margin: 0;
  padding: 11px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.basic-item-widget.without-customization .summary {
  display: none;
}

.basic-item-widget.without-customization .spec-info {
  width: 430px;
  margin-bottom: 24px;
}

.basic-item-widget.without-customization .spec-info .title {
  color: #1c1d3e;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: normal;
  line-height: 26px;
}

.basic-item-widget.without-customization .spec-info .description {
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}

.basic-item-widget.without-customization .actions .quantity {
  height: 48px;
  padding: 10px 14px;
}

.basic-item-widget.without-customization .actions .quantity > h4 {
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

.basic-item-widget.without-customization .actions .quantity .inc, .basic-item-widget.without-customization .actions .quantity .dec {
  width: 28px;
  height: 28px;
}

.basic-item-widget.without-customization .actions .quantity .inc *, .basic-item-widget.without-customization .actions .quantity .dec * {
  align-items: center;
  display: flex;
}

.basic-item-widget.without-customization .actions .quantity .number {
  text-align: center;
  width: 34px;
  margin: 0 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
}

.basic-item-widget.without-customization .actions .add-to-cart {
  padding: 14px;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.basic-item-widget.without-customization .actions .cancel {
  padding: 4px;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.custom-item-widget {
  width: 430px;
  margin: 60px 160px;
}

.custom-item-widget .title {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
}

.custom-item-widget .custom-item-price-input .label {
  letter-spacing: 0;
  color: #1c1d3e;
  margin: 0 4px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.custom-item-widget .custom-item-price-input .custom-item-price-input-box {
  border: 1px solid #ddd;
  border-radius: 8px;
  grid-template-columns: min-content 1fr;
  padding: 12.75px 20px;
  display: grid;
}

.custom-item-widget .custom-item-price-input .custom-item-price-input-box .custom-item-price-icon {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  padding-top: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.custom-item-widget .custom-item-price-input .custom-item-price-input-box .custom-item-price-value {
  align-items: center;
  display: grid;
}

.custom-item-widget .custom-item-price-input .custom-item-price-input-box .custom-item-price-value input {
  text-align: right;
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  font-size: 25px;
  overflow: hidden;
}

.custom-item-widget .custom-item-price-input .custom-item-price-input-box.invalid {
  border: 1px solid #fe5b5b;
}

.custom-item-widget .actions {
  padding-top: 20px;
}

.custom-item-widget .actions > .quantity {
  background-color: #fff;
  border-radius: 10px;
  grid-template-columns: 1fr repeat(3, min-content);
  align-items: center;
  margin-bottom: 15px;
  padding: 8px 15px 8px 14px;
  display: grid;
}

.custom-item-widget .actions > .quantity .icon {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.custom-item-widget .actions > .quantity .btn, .custom-item-widget .actions > .quantity .inc, .custom-item-widget .actions > .quantity .dec {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
}

.custom-item-widget .actions > .quantity .dec {
  background: #4c4e68;
}

.custom-item-widget .actions > .quantity .number {
  min-width: 34px;
  text-align: center;
  align-self: center;
  margin: 0 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
}

.custom-item-widget .actions > .quantity .inc {
  background: #4cbba1;
}

.custom-item-widget .actions > .quantity > h4 {
  color: #7d7e92;
  margin: 0;
}

.custom-item-widget .actions > .quantity .btn, .custom-item-widget .actions > .quantity .dec, .custom-item-widget .actions > .quantity .inc {
  width: 28px;
  height: 28px;
}

.custom-item-widget .actions > .quantity .btn i, .custom-item-widget .actions > .quantity .dec i, .custom-item-widget .actions > .quantity .inc i {
  flex-flow: column;
  justify-content: center;
  display: flex;
}

.custom-item-widget .actions > .quantity .number {
  font-size: 15px;
  line-height: 21px;
}

.custom-item-widget .actions .add-to-cart {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0;
  width: 100%;
  letter-spacing: .1px;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0 0 10px;
  padding: 14px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.custom-item-widget .actions .add-to-cart:focus {
  border: none;
  outline: none;
}

.custom-item-widget .cancel-btn {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  width: 100%;
  background-color: #0000;
  margin: 0;
  padding: 11px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.custom-item-widget .error {
  display: none;
}

.custom-item-widget .form-buttons {
  margin-top: 0;
}

.custom-item-widget input::-webkit-outer-spin-button, .custom-item-widget input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-item-widget input[type="number"] {
  -moz-appearance: textfield;
}

.pizza-widget {
  height: 90vh;
  width: 90vw;
  max-width: 1500px;
  grid-template-columns: 70% 30%;
  grid-template-areas: "main-panel info-panel";
  display: grid;
}

.pizza-widget .main-panel {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-area: main-panel;
  padding: 28px 24px;
  overflow-y: auto;
}

.pizza-widget .main-panel::-webkit-scrollbar {
  width: 1px;
}

.pizza-widget .main-panel::-webkit-scrollbar-track {
  background: none;
}

.pizza-widget .main-panel::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.pizza-widget .main-panel .modifier .header {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 18px;
  display: flex;
}

.pizza-widget .main-panel .modifier .header .badge {
  color: #535a64;
}

.pizza-widget .main-panel .modifier .header .badge.optional {
  color: #34c759;
}

.pizza-widget .main-panel .modifier .header .badge.required {
  color: #da6565;
}

.pizza-widget .main-panel .modifier .header .badge.is-selected {
  color: #7d7e92;
}

.pizza-widget .main-panel .modifier .options {
  padding: 15px 18px;
}

.pizza-widget .main-panel .modifier .options .option:last-child {
  border-bottom: 0;
}

.pizza-widget .main-panel .modifier .options .option {
  letter-spacing: 0;
  color: #1c1d3e;
  width: 100%;
  color: #1c1d3e90;
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  grid-template-columns: 1fr min-content min-content;
  grid-template-areas: "input qty charge";
  align-items: center;
  margin-bottom: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px;
  transition: all .2s ease-out;
  display: grid;
}

.pizza-widget .main-panel .modifier .options .option.selected {
  color: #fff;
  box-shadow: none;
  background-color: #1c1d3e;
}

.pizza-widget .main-panel .modifier .options .option .clickable {
  flex-direction: row;
  grid-area: input;
  align-items: center;
  margin-right: 10px;
  padding: 15px 0 15px 15px;
  display: flex;
}

.pizza-widget .main-panel .modifier .options .option .clickable > .container > input {
  cursor: pointer;
  margin-right: 12px;
}

.pizza-widget .main-panel .modifier .options .option .clickable .name {
  max-width: 20vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pizza-widget .main-panel .modifier .options .option .quantity {
  flex-direction: row;
  grid-area: qty;
  place-self: end;
  align-items: center;
  margin-right: 25px;
  padding: 15px 0;
  display: flex;
}

.pizza-widget .main-panel .modifier .options .option .quantity .icon {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pizza-widget .main-panel .modifier .options .option .quantity .btn, .pizza-widget .main-panel .modifier .options .option .quantity .inc, .pizza-widget .main-panel .modifier .options .option .quantity .dec {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
}

.pizza-widget .main-panel .modifier .options .option .quantity .dec {
  background: #4c4e68;
}

.pizza-widget .main-panel .modifier .options .option .quantity .number {
  min-width: 34px;
  text-align: center;
  align-self: center;
  margin: 0 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
}

.pizza-widget .main-panel .modifier .options .option .quantity .inc {
  background: #4cbba1;
}

.pizza-widget .main-panel .modifier .options .option .quantity .icon {
  padding-top: 2px;
}

.pizza-widget .main-panel .modifier .options .option .quantity .number {
  margin: 0 20px;
}

.pizza-widget .main-panel .modifier .options .option .extra-charge {
  white-space: nowrap;
  grid-area: charge;
  padding-right: 15px;
}

.pizza-widget .main-panel .sizes .header {
  padding-top: 0;
}

.pizza-widget .main-panel .sizes .header .wrapper {
  grid-gap: 8px;
  display: inline-flex;
}

.pizza-widget .main-panel .sizes .options {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  display: grid;
}

.pizza-widget .main-panel .sizes .options .option {
  text-align: center;
  color: #1c1d3e;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  flex-flow: column;
  margin: 0;
  transition: unset;
  display: flex;
  position: relative;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.pizza-widget .main-panel .sizes .options .option.selected {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(#2c2d56 0%, #0c0d34 100%);
}

.pizza-widget .main-panel .sizes .options .option.selected:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: #4cbba1;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
}

.pizza-widget .main-panel .sizes .options .option.selected:after {
  content: "";
  width: 5px;
  height: 10px;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  display: block;
  position: absolute;
  top: -3px;
  right: 0;
  transform: rotate(45deg);
}

.pizza-widget .main-panel .sizes .options .option.selected .extra-charge {
  color: #fff;
  padding: 0 10px 20px;
}

.pizza-widget .main-panel .sizes .options .option .clickable {
  display: unset;
  margin: 0;
  padding: 20px 10px 5px;
}

.pizza-widget .main-panel .sizes .options .option .clickable .radio, .pizza-widget .main-panel .sizes .options .option .clickable .checkbox {
  display: none;
}

.pizza-widget .main-panel .sizes .options .option .clickable .name {
  white-space: unset;
}

.pizza-widget .main-panel .sizes .options .option .extra-charge {
  color: #1c1d3e90;
  padding: 0 10px 20px;
}

.pizza-widget .main-panel .remove-toppings .header {
  padding-top: 20px;
}

.pizza-widget .main-panel .remove-toppings .options {
  grid-gap: 20px;
  flex-flow: wrap;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.pizza-widget .main-panel .remove-toppings .options .option {
  text-align: center;
  color: #1c1d3e;
  width: -moz-fit-content;
  width: fit-content;
  color: #343553;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 30px;
  flex-flow: column;
  margin: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  transition: unset;
  display: flex;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.pizza-widget .main-panel .remove-toppings .options .option.selected {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(#2c2d56 0%, #0c0d34 100%);
  text-decoration: line-through;
}

.pizza-widget .main-panel .remove-toppings .options .option.selected .extra-charge {
  color: #fff;
  padding: 0 10px 20px;
}

.pizza-widget .main-panel .remove-toppings .options .option .clickable {
  display: unset;
  margin: 0;
  padding: 10px 20px;
}

.pizza-widget .main-panel .remove-toppings .options .option .clickable .radio, .pizza-widget .main-panel .remove-toppings .options .option .clickable .checkbox {
  display: none;
}

.pizza-widget .main-panel .remove-toppings .options .option .clickable .name {
  word-break: break-all;
  white-space: unset;
  display: block;
}

.pizza-widget .main-panel .remove-toppings .options .option .extra-charge, .pizza-widget .main-panel .remove-toppings .badge {
  display: none;
}

.pizza-widget .main-panel .added-toppings .options {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  display: grid;
}

.pizza-widget .main-panel .added-toppings .options .option {
  cursor: unset;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  grid-template-columns: 1fr;
  grid-template-areas: "details"
                       "actions";
  margin: 0;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.pizza-widget .main-panel .added-toppings .options .option.add-new-topping {
  cursor: pointer;
  color: #535a64;
  border-bottom: 0;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
  font-size: 30px;
  display: flex;
}

.pizza-widget .main-panel .added-toppings .options .option .topping-details {
  text-align: center;
  border-bottom: 1px solid #d9d9e6;
  grid-area: details;
  padding: 10px;
}

.pizza-widget .main-panel .added-toppings .options .option .topping-details .extra-charge {
  opacity: .7;
  color: #7d7e92;
  padding: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.pizza-widget .main-panel .added-toppings .options .option .actions {
  grid-area: actions;
  justify-self: center;
  padding: 10px;
}

.pizza-widget .main-panel .added-toppings .options .option .actions .quantity {
  place-content: space-between;
  margin: 0;
  padding: 0;
  display: flex;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings {
  height: 100%;
  grid-template-rows: min-content 1fr min-content;
  display: grid;
  position: relative;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .done-btn {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  width: 120px;
  background-color: #3f9c86;
  border-radius: 8px;
  margin: 0;
  padding: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .done-btn:focus {
  border: none;
  outline: none;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings input {
  text-indent: 30px;
  width: 330px;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 15px;
  background-repeat: no-repeat;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  box-shadow: 0 3px 15px -5px #0000004d;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .header {
  padding-top: 0;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .header > .title {
  margin: auto 0;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .empty-options {
  margin: 100px auto auto;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .options {
  grid-gap: 15px;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 100px;
  display: grid;
  overflow-y: auto;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .options::-webkit-scrollbar {
  width: 1px;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .options::-webkit-scrollbar-track {
  background: none;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .options::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .options .option {
  color: #1c1d3e;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  margin: 0;
  padding: 10px;
  transition: unset;
  position: relative;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .options .option.selected {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(#2c2d56 0%, #0c0d34 100%);
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .options .option.selected:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: #4cbba1;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .options .option.selected:after {
  content: "";
  width: 5px;
  height: 10px;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  display: block;
  position: absolute;
  top: -3px;
  right: 0;
  transform: rotate(45deg);
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .options .option.selected .extra-charge {
  color: #fff;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .options .option .clickable {
  display: unset;
  height: 100%;
  width: 100%;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .options .option .clickable .radio, .pizza-widget .main-panel .add-toppings-list .add-toppings .options .option .clickable .checkbox {
  display: none;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .options .option .clickable .name {
  white-space: unset;
  text-align: center;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .options .option .quantity {
  display: none;
}

.pizza-widget .main-panel .add-toppings-list .add-toppings .options .option .extra-charge {
  color: #1c1d3e90;
  padding: 0;
}

.pizza-widget .info-panel {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #f6f8fb;
  border-radius: 0 20px 20px 0;
  grid-area: info-panel;
  grid-template-rows: min-content 1fr min-content;
  padding: 24px 24px 19px;
  display: grid;
  overflow-x: hidden;
  overflow-y: scroll;
}

.pizza-widget .info-panel::-webkit-scrollbar {
  width: 1px;
}

.pizza-widget .info-panel::-webkit-scrollbar-track {
  background: none;
}

.pizza-widget .info-panel::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.pizza-widget .info-panel .spec-info .title {
  letter-spacing: .26px;
  color: #1c1d3e;
  margin-bottom: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
}

.pizza-widget .info-panel .spec-info .description {
  color: #7d7e92;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.pizza-widget .info-panel .summary {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  overflow-y: hidden scroll;
  border-top: 1px solid #d9d9e6;
  margin: 20px 0;
  padding-top: 5px;
}

.pizza-widget .info-panel .summary::-webkit-scrollbar {
  width: 1px;
}

.pizza-widget .info-panel .summary::-webkit-scrollbar-track {
  background: none;
}

.pizza-widget .info-panel .summary::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.pizza-widget .info-panel .summary.hide {
  opacity: 0;
  transition: all .3s ease-out;
}

.pizza-widget .info-panel .summary.show {
  opacity: 1;
  transition: all .3s ease-in;
}

.pizza-widget .info-panel .actions > .quantity {
  background-color: #fff;
  border-radius: 10px;
  grid-template-columns: 1fr repeat(3, min-content);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 8px 15px 8px 14px;
  display: grid;
}

.pizza-widget .info-panel .actions > .quantity .icon {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pizza-widget .info-panel .actions > .quantity .btn, .pizza-widget .info-panel .actions > .quantity .inc, .pizza-widget .info-panel .actions > .quantity .dec, .pizza-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .dec, .pizza-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .dec, .pizza-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .inc, .pizza-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .inc {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
}

.pizza-widget .info-panel .actions > .quantity .dec {
  background: #4c4e68;
}

.pizza-widget .info-panel .actions > .quantity .number {
  min-width: 34px;
  text-align: center;
  align-self: center;
  margin: 0 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
}

.pizza-widget .info-panel .actions > .quantity .inc {
  background: #4cbba1;
}

.pizza-widget .info-panel .actions > .quantity > h4 {
  color: #7d7e92;
  margin: 0;
}

.pizza-widget .info-panel .actions > .quantity .btn, .pizza-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .dec, .pizza-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .dec, .pizza-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .inc, .pizza-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .inc, .pizza-widget .info-panel .actions > .quantity .dec, .pizza-widget .info-panel .actions > .quantity .inc {
  width: 28px;
  height: 28px;
}

.pizza-widget .info-panel .actions > .quantity .btn i, .pizza-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .dec i, .pizza-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .dec i, .pizza-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .inc i, .pizza-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .inc i, .pizza-widget .info-panel .actions > .quantity .dec i, .pizza-widget .info-panel .actions > .quantity .inc i {
  flex-flow: column;
  justify-content: center;
  display: flex;
}

.pizza-widget .info-panel .actions > .quantity .number {
  color: #1c1d3e;
  font-size: 15px;
  line-height: 21px;
}

.pizza-widget .info-panel .actions .add-to-cart {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0;
  width: 100%;
  letter-spacing: .1px;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0 0 10px;
  padding: 14px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.pizza-widget .info-panel .actions .add-to-cart:focus {
  border: none;
  outline: none;
}

.pizza-widget .info-panel .actions .cancel {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  width: 100%;
  background-color: #0000;
  margin: 0;
  padding: 11px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.pizza-widget .info-panel .spec-info .description.hide {
  opacity: 0;
  max-height: 1px;
  transition: all .3s ease-out;
  position: absolute;
  bottom: 1000vh;
}

.pizza-widget .info-panel .spec-info .description.show {
  opacity: 1;
  max-height: 100vh;
  transition: all .3s ease-in;
  position: relative;
}

.pizza-widget .info-panel .spec-info .description-toggle {
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  color: #7d7e92;
  border: 1px solid #7d7e92;
  border-radius: 20px;
  margin-top: 15px;
  padding: 4px 14px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.pizza-widget .info-panel .summary {
  margin: 12px 0;
  padding-top: 12px;
}

.pizza-widget .info-panel .summary:first-child {
  margin: 20px 0;
}

.pizza-widget .info-panel .summary .item-description-view-widget {
  color: #7d7e92;
}

.pizza-widget .info-panel .empty-summary {
  justify-content: center;
  margin: 15px 0;
  display: grid;
}

.pizza-widget .info-panel .empty-summary .icon {
  margin: 15px auto;
}

.pizza-widget .info-panel .empty-summary > p {
  color: #7d7e92;
  max-width: 60%;
  text-align: center;
  margin: 4px auto 17px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.pizza-widget .info-panel .convert-link {
  cursor: pointer;
  color: #4cbba1;
  align-items: center;
  margin-top: 12px;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.pizza-widget .info-panel .convert-link:first-child {
  margin-top: 20px;
}

.pizza-widget .info-panel .convert-link.deal {
  border-top: 1px solid #dee0e6;
  padding-top: 12px;
}

.pizza-widget .info-panel .convert-link img {
  margin-right: 8px;
}

.deal-widget {
  height: 90vh;
  width: 90vw;
  max-width: 1500px;
  grid-template-columns: 70% 30%;
  grid-template-areas: "main-panel info-panel";
  display: grid;
}

.deal-widget .main-panel {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-area: main-panel;
  padding: 28px 24px;
  overflow-y: auto;
}

.deal-widget .main-panel::-webkit-scrollbar {
  width: 1px;
}

.deal-widget .main-panel::-webkit-scrollbar-track {
  background: none;
}

.deal-widget .main-panel::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.deal-widget .main-panel .modifier .header {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 18px;
  display: flex;
}

.deal-widget .main-panel .modifier .header .badge {
  color: #535a64;
}

.deal-widget .main-panel .modifier .header .badge.optional {
  color: #34c759;
}

.deal-widget .main-panel .modifier .header .badge.required {
  color: #da6565;
}

.deal-widget .main-panel .modifier .header .badge.is-selected {
  color: #7d7e92;
}

.deal-widget .main-panel .modifier .options {
  padding: 15px 18px;
}

.deal-widget .main-panel .modifier .options .option:last-child {
  border-bottom: 0;
}

.deal-widget .main-panel .modifier .options .option {
  letter-spacing: 0;
  color: #1c1d3e;
  width: 100%;
  color: #1c1d3e90;
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  grid-template-columns: 1fr min-content min-content;
  grid-template-areas: "input qty charge";
  align-items: center;
  margin-bottom: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px;
  transition: all .2s ease-out;
  display: grid;
}

.deal-widget .main-panel .modifier .options .option.selected {
  color: #fff;
  box-shadow: none;
  background-color: #1c1d3e;
}

.deal-widget .main-panel .modifier .options .option .clickable {
  flex-direction: row;
  grid-area: input;
  align-items: center;
  margin-right: 10px;
  padding: 15px 0 15px 15px;
  display: flex;
}

.deal-widget .main-panel .modifier .options .option .clickable > .container > input {
  cursor: pointer;
  margin-right: 12px;
}

.deal-widget .main-panel .modifier .options .option .clickable .name {
  max-width: 20vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.deal-widget .main-panel .modifier .options .option .quantity {
  flex-direction: row;
  grid-area: qty;
  place-self: end;
  align-items: center;
  margin-right: 25px;
  padding: 15px 0;
  display: flex;
}

.deal-widget .main-panel .modifier .options .option .quantity .icon {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.deal-widget .main-panel .modifier .options .option .quantity .btn, .deal-widget .main-panel .modifier .options .option .quantity .inc, .deal-widget .main-panel .modifier .options .option .quantity .dec {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
}

.deal-widget .main-panel .modifier .options .option .quantity .dec {
  background: #4c4e68;
}

.deal-widget .main-panel .modifier .options .option .quantity .number {
  min-width: 34px;
  text-align: center;
  align-self: center;
  margin: 0 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
}

.deal-widget .main-panel .modifier .options .option .quantity .inc {
  background: #4cbba1;
}

.deal-widget .main-panel .modifier .options .option .quantity .icon {
  padding-top: 2px;
}

.deal-widget .main-panel .modifier .options .option .quantity .number {
  margin: 0 20px;
}

.deal-widget .main-panel .modifier .options .option .extra-charge {
  white-space: nowrap;
  grid-area: charge;
  padding-right: 15px;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza {
  border-bottom: 1px solid #d9d9e6;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza .header {
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  gap: 10px;
  display: grid;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza .header > .title {
  letter-spacing: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza .header > .badge {
  color: #fe5b5b;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza .header > .badge.selected {
  color: #7d7e92;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza .header .half-and-half-toggle {
  grid-gap: 10px;
  cursor: pointer;
  grid-template-columns: repeat(2, min-content);
  align-items: center;
  display: grid;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza .header .half-and-half-toggle .label {
  white-space: nowrap;
  color: #7d7e92;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza .header .half-and-half-toggle .toggle {
  width: 45px;
  height: 27px;
  background-color: #d9d9e6;
  border: 2px solid #d9d9e6;
  border-radius: 20px;
  transition: background-color .5s, border-color .5s;
  display: grid;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza .header .half-and-half-toggle .toggle.on {
  background-color: #34c759;
  border: 2px solid #34c759;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza .header .half-and-half-toggle .toggle .switch {
  width: 23px;
  height: 23px;
  transform-origin: 50%;
  background-color: #fff;
  border-radius: 20px;
  transition: all .15s;
  transform: rotate(45deg);
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza .header .half-and-half-toggle .toggle .switch.on {
  transform-origin: 90% 145%;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza {
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  display: grid;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza {
  grid-auto-flow: row;
  gap: 20px;
  margin-bottom: 15px;
  padding: 0;
  display: grid;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza .header, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza .header, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .header {
  gap: 14px;
  display: flex;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza .header .requiredness, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza .header .requiredness, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .header .requiredness {
  color: #7d7e92;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza .header .requiredness.red, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza .header .requiredness.red, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .header .requiredness.red {
  color: #fe5b5b;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza .pizza, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza .pizza, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .pizza {
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  align-items: center;
  padding: 20px;
  display: grid;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza .pizza .selected, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza .pizza .selected, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .pizza .selected {
  text-align: center;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza .pizza .selected .extra-charge, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza .pizza .selected .extra-charge, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .pizza .selected .extra-charge {
  opacity: .7;
  color: #7d7e92;
  margin-top: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza .pizza > i, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza .pizza > i, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .pizza > i {
  text-align: center;
  color: #535a64;
  padding: 8px 0;
  font-size: 30px;
  display: block;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza .header > .half-number, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza .header > .half-number, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .header > .half-number {
  margin-top: 22.07px;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza .pizza > .selected .name, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza .pizza > .selected .name, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .pizza > .selected .name {
  letter-spacing: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza .pizza-customization .remove-toppings, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza .pizza-customization .remove-toppings, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .pizza-customization .remove-toppings {
  margin-bottom: 20px;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza .pizza-customization .remove-toppings .header, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza .pizza-customization .remove-toppings .header, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .pizza-customization .remove-toppings .header {
  padding: 0;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza .pizza-customization .remove-toppings .header .badge, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza .pizza-customization .remove-toppings .header .badge, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .pizza-customization .remove-toppings .header .badge {
  display: none;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza .pizza-customization .remove-toppings .options, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza .pizza-customization .remove-toppings .options, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .pizza-customization .remove-toppings .options {
  padding: 10px 0;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza .pizza-customization .added-toppings .header, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza .pizza-customization .added-toppings .header, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .pizza-customization .added-toppings .header {
  padding: 0;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza .pizza-customization .added-toppings .header .badge, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza .pizza-customization .added-toppings .header .badge, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .pizza-customization .added-toppings .header .badge {
  display: none;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza-styles .selected-pizza .pizza-customization .added-toppings .options, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-half-and-half-pizza .selected-pizza .pizza-customization .added-toppings .options, .deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .pizza-customization .added-toppings .options {
  padding: 10px 0 0;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza {
  grid-template-columns: 1fr;
}

.deal-widget .main-panel .deal .deal-pizzas > .deal-pizza > .body .pizza .add-pizza .selected-pizza .pizza {
  width: calc(50% - 20px);
}

.deal-widget .main-panel .deal .modifiers > .modifier .header .wrapper {
  grid-gap: 8px;
  display: inline-flex;
}

.deal-widget .main-panel .deal .modifiers > .modifier .header .wrapper * {
  font-weight: 400;
}

.deal-widget .main-panel .deal .modifiers > .modifier .header .wrapper .title {
  color: #1c1d3e;
}

.deal-widget .main-panel .deal .deal-items > .deal-item {
  border-bottom: 1px solid #d9d9e6;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.deal-widget .main-panel .deal .deal-items > .deal-item > .header {
  padding-bottom: 12px;
}

.deal-widget .main-panel .deal .deal-items > .deal-item > .modifiers .modifier .header {
  padding: 12px 0;
}

.deal-widget .main-panel .deal .deal-items > .deal-item > .modifiers .modifier .header > .title {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #7d7e92;
  text-transform: uppercase;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.deal-widget .main-panel .deal .deal-items > .deal-item > .modifiers .modifier > .options {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  padding: 12px 0;
  display: grid;
}

.deal-widget .main-panel .deal .deal-items > .deal-item > .modifiers .modifier > .options .option {
  grid-template-columns: unset;
  grid-template-areas: unset;
  min-height: 80px;
  color: #1c1d3e;
  flex-flow: column;
  justify-content: center;
  margin: 0;
  display: flex;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.deal-widget .main-panel .deal .deal-items > .deal-item > .modifiers .modifier > .options .option.selected {
  box-shadow: none;
  color: #f6f8fb;
  background: linear-gradient(#2c2d56 0%, #0c0d34 100%);
}

.deal-widget .main-panel .deal .deal-items > .deal-item > .modifiers .modifier > .options .option.selected .extra-charge {
  color: #fff9;
}

.deal-widget .main-panel .deal .deal-items > .deal-item > .modifiers .modifier > .options .option .clickable {
  text-align: center;
  margin-right: 0;
  padding-bottom: 0;
  padding-right: 15px;
  display: block;
}

.deal-widget .main-panel .deal .deal-items > .deal-item > .modifiers .modifier > .options .option .clickable .container {
  display: none;
}

.deal-widget .main-panel .deal .deal-items > .deal-item > .modifiers .modifier > .options .option .clickable .name {
  white-space: unset;
}

.deal-widget .main-panel .deal .deal-items > .deal-item > .modifiers .modifier > .options .option .extra-charge {
  text-align: center;
  color: #7d7e92;
  padding: 4px 15px 15px;
  display: block;
}

.deal-widget .main-panel .deal .deal-items > .deal-item > .modifiers .modifier > .options .option .quantity {
  width: 100%;
  border-top: 1px solid #d9d9e6;
  justify-content: center;
  margin-right: 0;
  padding: 10px 0;
}

.deal-widget .main-panel .deal .deal-modifiers > .modifiers {
  border-bottom: 1px solid #d9d9e6;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.deal-widget .main-panel .deal .deal-modifiers > .modifiers .modifier .header {
  padding: 0 0 12px;
}

.deal-widget .main-panel .deal .deal-modifiers > .modifiers .modifier .options {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  padding: 20px 0;
  display: grid;
}

.deal-widget .main-panel .deal .deal-modifiers > .modifiers .modifier .options .option {
  grid-template-columns: unset;
  grid-template-areas: unset;
  min-height: 80px;
  color: #1c1d3e;
  flex-flow: column;
  justify-content: center;
  margin: 0;
  display: flex;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.deal-widget .main-panel .deal .deal-modifiers > .modifiers .modifier .options .option.selected {
  box-shadow: none;
  color: #f6f8fb;
  background: linear-gradient(#2c2d56 0%, #0c0d34 100%);
}

.deal-widget .main-panel .deal .deal-modifiers > .modifiers .modifier .options .option.selected .extra-charge {
  color: #fff9;
}

.deal-widget .main-panel .deal .deal-modifiers > .modifiers .modifier .options .option .clickable {
  text-align: center;
  margin-right: 0;
  padding-bottom: 0;
  padding-right: 15px;
  display: block;
}

.deal-widget .main-panel .deal .deal-modifiers > .modifiers .modifier .options .option .clickable .container {
  display: none;
}

.deal-widget .main-panel .deal .deal-modifiers > .modifiers .modifier .options .option .clickable .name {
  white-space: unset;
}

.deal-widget .main-panel .deal .deal-modifiers > .modifiers .modifier .options .option .extra-charge {
  text-align: center;
  color: #7d7e92;
  padding: 4px 15px 15px;
  display: block;
}

.deal-widget .main-panel .deal .deal-modifiers > .modifiers .modifier .options .option .quantity {
  width: 100%;
  border-top: 1px solid #d9d9e6;
  justify-content: center;
  margin-right: 0;
  padding: 10px 0;
}

.deal-widget .main-panel .deal-pizza-details > .header {
  grid-gap: 10px;
  grid-template-columns: 1fr repeat(2, min-content);
  grid-auto-flow: column;
  align-items: center;
  margin-bottom: 30px;
  display: grid;
}

.deal-widget .main-panel .deal-pizza-details > .header .half-and-half-toggle {
  grid-gap: 10px;
  cursor: pointer;
  grid-template-columns: repeat(2, min-content);
  align-items: center;
  display: grid;
}

.deal-widget .main-panel .deal-pizza-details > .header .half-and-half-toggle .label {
  white-space: nowrap;
  color: #7d7e92;
}

.deal-widget .main-panel .deal-pizza-details > .header .half-and-half-toggle .toggle {
  width: 45px;
  height: 27px;
  background-color: #d9d9e6;
  border: 2px solid #d9d9e6;
  border-radius: 20px;
  transition: background-color .5s, border-color .5s;
  display: grid;
}

.deal-widget .main-panel .deal-pizza-details > .header .half-and-half-toggle .toggle.on {
  background-color: #34c759;
  border: 2px solid #34c759;
}

.deal-widget .main-panel .deal-pizza-details > .header .half-and-half-toggle .toggle .switch {
  width: 23px;
  height: 23px;
  transform-origin: 50%;
  background-color: #fff;
  border-radius: 20px;
  transition: all .15s;
  transform: rotate(45deg);
}

.deal-widget .main-panel .deal-pizza-details > .header .half-and-half-toggle .toggle .switch.on {
  transform-origin: 90% 145%;
}

.deal-widget .main-panel .deal-pizza-details > .header .done-btn {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  width: 120px;
  background-color: #3f9c86;
  border-radius: 8px;
  margin: 0 0 0 30px;
  padding: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.deal-widget .main-panel .deal-pizza-details > .header .done-btn:focus {
  border: none;
  outline: none;
}

.deal-widget .main-panel .deal-pizza-details .body .half-selector {
  text-align: center;
  background-color: #ecf9f6;
  border-radius: 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: column;
  margin-bottom: 20px;
  display: grid;
}

.deal-widget .main-panel .deal-pizza-details .body .half-selector .half {
  color: #4cbba1;
  cursor: pointer;
  border-radius: 10px;
  padding: 12px;
  transition: all .2s ease-out;
}

.deal-widget .main-panel .deal-pizza-details .body .half-selector .half.disabled {
  cursor: not-allowed;
}

.deal-widget .main-panel .deal-pizza-details .body .half-selector .half.selected {
  color: #fff;
  background-color: #4cbba1;
}

.deal-widget .main-panel .deal-pizza-details .body .selected-deal-pizza > .selected-pizza {
  grid-auto-flow: row;
  gap: 20px;
  padding: 0 18px;
  display: grid;
}

.deal-widget .main-panel .deal-pizza-details .body .selected-deal-pizza > .selected-pizza .header {
  gap: 14px;
  display: flex;
}

.deal-widget .main-panel .deal-pizza-details .body .selected-deal-pizza > .selected-pizza .header .requiredness {
  color: #7d7e92;
}

.deal-widget .main-panel .deal-pizza-details .body .selected-deal-pizza > .selected-pizza .header .requiredness.red {
  color: #fe5b5b;
}

.deal-widget .main-panel .deal-pizza-details .body .selected-deal-pizza > .selected-pizza .pizza {
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  align-items: center;
  padding: 20px;
  display: grid;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.deal-widget .main-panel .deal-pizza-details .body .selected-deal-pizza > .selected-pizza .pizza .selected {
  text-align: center;
}

.deal-widget .main-panel .deal-pizza-details .body .selected-deal-pizza > .selected-pizza .pizza .selected .extra-charge {
  opacity: .7;
  color: #7d7e92;
  margin-top: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.deal-widget .main-panel .deal-pizza-details .body .selected-deal-pizza > .selected-pizza .pizza > i {
  text-align: center;
  color: #535a64;
  padding: 8px 0;
  font-size: 30px;
  display: block;
}

.deal-widget .main-panel .pizza-list {
  overflow-y: unset;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget {
  height: 100%;
  grid-template-rows: min-content 1fr min-content;
  display: grid;
  position: relative;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .done-btn {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  width: 120px;
  background-color: #3f9c86;
  border-radius: 8px;
  margin: 0;
  padding: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .done-btn:focus {
  border: none;
  outline: none;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget input {
  text-indent: 30px;
  width: 330px;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 15px;
  background-repeat: no-repeat;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  box-shadow: 0 3px 15px -5px #0000004d;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .header {
  padding-top: 0;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .empty-options {
  margin: 100px auto auto;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .options {
  grid-gap: 15px;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 100px;
  display: grid;
  overflow-y: auto;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .options::-webkit-scrollbar {
  width: 1px;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .options::-webkit-scrollbar-track {
  background: none;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .options::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .options .option {
  color: #1c1d3e;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  margin: 0;
  padding: 10px;
  transition: unset;
  position: relative;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .options .option.selected {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(#2c2d56 0%, #0c0d34 100%);
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .options .option.selected:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: #4cbba1;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .options .option.selected:after {
  content: "";
  width: 5px;
  height: 10px;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  display: block;
  position: absolute;
  top: -3px;
  right: 0;
  transform: rotate(45deg);
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .options .option.selected .extra-charge {
  color: #fff;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .options .option .clickable {
  display: unset;
  height: 100%;
  width: 100%;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .options .option .clickable .radio, .deal-widget .main-panel .pizza-list .pizza-list-widget .options .option .clickable .checkbox {
  display: none;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .options .option .clickable .name {
  white-space: unset;
  text-align: center;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .options .option .quantity {
  display: none;
}

.deal-widget .main-panel .pizza-list .pizza-list-widget .options .option .extra-charge {
  color: #1c1d3e90;
  padding: 4px 0;
}

.deal-widget .main-panel .modifier .header {
  align-items: center;
}

.deal-widget .main-panel .remove-toppings .header {
  padding-top: 20px;
}

.deal-widget .main-panel .remove-toppings .options {
  grid-gap: 20px;
  flex-flow: wrap;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.deal-widget .main-panel .remove-toppings .options .option {
  text-align: center;
  color: #1c1d3e;
  width: -moz-fit-content;
  width: fit-content;
  color: #343553;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 30px;
  flex-flow: column;
  margin: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  transition: unset;
  display: flex;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.deal-widget .main-panel .remove-toppings .options .option.selected {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(#2c2d56 0%, #0c0d34 100%);
  text-decoration: line-through;
}

.deal-widget .main-panel .remove-toppings .options .option.selected .extra-charge {
  color: #fff;
  padding: 0 10px 20px;
}

.deal-widget .main-panel .remove-toppings .options .option .clickable {
  display: unset;
  margin: 0;
  padding: 10px 20px;
}

.deal-widget .main-panel .remove-toppings .options .option .clickable .radio, .deal-widget .main-panel .remove-toppings .options .option .clickable .checkbox {
  display: none;
}

.deal-widget .main-panel .remove-toppings .options .option .clickable .name {
  word-break: break-all;
  white-space: unset;
  display: block;
}

.deal-widget .main-panel .remove-toppings .options .option .extra-charge {
  display: none;
}

.deal-widget .main-panel .added-toppings .options {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  display: grid;
}

.deal-widget .main-panel .added-toppings .options .option {
  cursor: unset;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  grid-template-columns: 1fr;
  grid-template-areas: "details"
                       "actions";
  margin: 0;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.deal-widget .main-panel .added-toppings .options .option.add-new-topping {
  cursor: pointer;
  color: #535a64;
  border-bottom: 0;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
  font-size: 30px;
  display: flex;
}

.deal-widget .main-panel .added-toppings .options .option .topping-details {
  text-align: center;
  border-bottom: 1px solid #d9d9e6;
  grid-area: details;
  padding: 10px;
}

.deal-widget .main-panel .added-toppings .options .option .topping-details .extra-charge {
  opacity: .7;
  color: #7d7e92;
  padding: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.deal-widget .main-panel .added-toppings .options .option .actions {
  grid-area: actions;
  justify-self: center;
  padding: 10px;
}

.deal-widget .main-panel .added-toppings .options .option .actions .quantity {
  place-content: space-between;
  margin: 0;
  padding: 0;
  display: flex;
}

.deal-widget .main-panel .add-toppings-list .add-toppings {
  height: 100%;
  grid-template-rows: min-content 1fr min-content;
  display: grid;
  position: relative;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .done-btn {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  width: 120px;
  background-color: #3f9c86;
  border-radius: 8px;
  margin: 0;
  padding: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .done-btn:focus {
  border: none;
  outline: none;
}

.deal-widget .main-panel .add-toppings-list .add-toppings input {
  text-indent: 30px;
  width: 330px;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 15px;
  background-repeat: no-repeat;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  box-shadow: 0 3px 15px -5px #0000004d;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .header {
  padding-top: 0;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .header > .title {
  margin: auto 0;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .empty-options {
  margin: 100px auto auto;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .options {
  grid-gap: 15px;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 100px;
  display: grid;
  overflow-y: auto;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .options::-webkit-scrollbar {
  width: 1px;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .options::-webkit-scrollbar-track {
  background: none;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .options::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .options .option {
  color: #1c1d3e;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  margin: 0;
  padding: 10px;
  transition: unset;
  position: relative;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .options .option.selected {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(#2c2d56 0%, #0c0d34 100%);
}

.deal-widget .main-panel .add-toppings-list .add-toppings .options .option.selected:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: #4cbba1;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .options .option.selected:after {
  content: "";
  width: 5px;
  height: 10px;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  display: block;
  position: absolute;
  top: -3px;
  right: 0;
  transform: rotate(45deg);
}

.deal-widget .main-panel .add-toppings-list .add-toppings .options .option.selected .extra-charge {
  color: #fff;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .options .option .clickable {
  display: unset;
  height: 100%;
  width: 100%;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .options .option .clickable .radio, .deal-widget .main-panel .add-toppings-list .add-toppings .options .option .clickable .checkbox {
  display: none;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .options .option .clickable .name {
  white-space: unset;
  text-align: center;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .options .option .quantity {
  display: none;
}

.deal-widget .main-panel .add-toppings-list .add-toppings .options .option .extra-charge {
  color: #1c1d3e90;
  padding: 0;
}

.deal-widget .info-panel {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #f6f8fb;
  border-radius: 0 20px 20px 0;
  grid-area: info-panel;
  grid-template-rows: min-content 1fr min-content;
  padding: 24px 24px 19px;
  display: grid;
  overflow-x: hidden;
  overflow-y: scroll;
}

.deal-widget .info-panel::-webkit-scrollbar {
  width: 1px;
}

.deal-widget .info-panel::-webkit-scrollbar-track {
  background: none;
}

.deal-widget .info-panel::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.deal-widget .info-panel .spec-info .title {
  letter-spacing: .26px;
  color: #1c1d3e;
  margin-bottom: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
}

.deal-widget .info-panel .spec-info .description {
  color: #7d7e92;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.deal-widget .info-panel .summary {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  overflow-y: hidden scroll;
  border-top: 1px solid #d9d9e6;
  margin: 20px 0;
  padding-top: 5px;
}

.deal-widget .info-panel .summary::-webkit-scrollbar {
  width: 1px;
}

.deal-widget .info-panel .summary::-webkit-scrollbar-track {
  background: none;
}

.deal-widget .info-panel .summary::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.deal-widget .info-panel .summary.hide {
  opacity: 0;
  transition: all .3s ease-out;
}

.deal-widget .info-panel .summary.show {
  opacity: 1;
  transition: all .3s ease-in;
}

.deal-widget .info-panel .actions > .quantity {
  background-color: #fff;
  border-radius: 10px;
  grid-template-columns: 1fr repeat(3, min-content);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 8px 15px 8px 14px;
  display: grid;
}

.deal-widget .info-panel .actions > .quantity .icon {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.deal-widget .info-panel .actions > .quantity .btn, .deal-widget .info-panel .actions > .quantity .inc, .deal-widget .info-panel .actions > .quantity .dec, .deal-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .dec, .deal-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .dec, .deal-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .inc, .deal-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .inc {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
}

.deal-widget .info-panel .actions > .quantity .dec {
  background: #4c4e68;
}

.deal-widget .info-panel .actions > .quantity .number {
  min-width: 34px;
  text-align: center;
  align-self: center;
  margin: 0 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
}

.deal-widget .info-panel .actions > .quantity .inc {
  background: #4cbba1;
}

.deal-widget .info-panel .actions > .quantity > h4 {
  color: #7d7e92;
  margin: 0;
}

.deal-widget .info-panel .actions > .quantity .btn, .deal-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .dec, .deal-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .dec, .deal-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .inc, .deal-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .inc, .deal-widget .info-panel .actions > .quantity .dec, .deal-widget .info-panel .actions > .quantity .inc {
  width: 28px;
  height: 28px;
}

.deal-widget .info-panel .actions > .quantity .btn i, .deal-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .dec i, .deal-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .dec i, .deal-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .inc i, .deal-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .inc i, .deal-widget .info-panel .actions > .quantity .dec i, .deal-widget .info-panel .actions > .quantity .inc i {
  flex-flow: column;
  justify-content: center;
  display: flex;
}

.deal-widget .info-panel .actions > .quantity .number {
  color: #1c1d3e;
  font-size: 15px;
  line-height: 21px;
}

.deal-widget .info-panel .actions .add-to-cart {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0;
  width: 100%;
  letter-spacing: .1px;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0 0 10px;
  padding: 14px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.deal-widget .info-panel .actions .add-to-cart:focus {
  border: none;
  outline: none;
}

.deal-widget .info-panel .actions .cancel {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  width: 100%;
  background-color: #0000;
  margin: 0;
  padding: 11px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.deal-widget .info-panel .spec-info .description.hide {
  opacity: 0;
  max-height: 1px;
  transition: all .3s ease-out;
  position: absolute;
  bottom: 1000vh;
}

.deal-widget .info-panel .spec-info .description.show {
  opacity: 1;
  max-height: 100vh;
  transition: all .3s ease-in;
  position: relative;
}

.deal-widget .info-panel .spec-info .description-toggle {
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  color: #7d7e92;
  border: 1px solid #7d7e92;
  border-radius: 20px;
  margin-top: 15px;
  padding: 4px 14px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.deal-widget .info-panel .item-description-view-widget {
  color: #7d7e92;
  padding: 12px 0;
}

.deal-widget .info-panel .item-description-view-widget .title {
  border-top: 1px solid #dee0e6;
  padding-top: 12px;
}

.deal-widget .info-panel .item-description-view-widget .title:first-child {
  border-top: none;
  padding-top: 0;
}

.deal-widget .info-panel .summary {
  padding-top: 0;
}

.deal-widget .info-panel .summary .empty-summary {
  justify-content: center;
  margin: 15px 0;
  display: grid;
}

.deal-widget .info-panel .summary .empty-summary .icon {
  margin: 15px auto;
}

.deal-widget .info-panel .summary .empty-summary > p {
  color: #7d7e92;
  max-width: 60%;
  text-align: center;
  margin: 4px auto 17px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.customer-notes-edit-widget {
  width: 65vw;
}

.customer-notes-edit-widget .form-items {
  grid-row-gap: 10px;
}

.customer-notes-edit-widget .heading {
  grid-template-columns: 1fr min-content;
  grid-template-areas: "title icon"
                       "title icon";
  padding: 30px 30px 0;
  display: grid;
}

.customer-notes-edit-widget .heading .title {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  grid-area: title;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.customer-notes-edit-widget .heading .icon {
  cursor: pointer;
  grid-area: icon;
}

.customer-notes-edit-widget .customer-notes-input {
  padding: 30px 30px 0;
}

.customer-notes-edit-widget .customer-notes-input textarea {
  background-color: #f6f8fb;
  border: none;
  padding: 20px;
}

.customer-notes-edit-widget .customer-notes-input textarea::placeholder {
  color: #9597a7;
}

.customer-notes-edit-widget .additional-text {
  color: #7d7e92;
  justify-content: space-between;
  padding: 0 30px;
  font-size: 13px;
  display: flex;
}

.customer-notes-edit-widget .actions {
  max-height: calc(90vh - 236px);
  grid-template-columns: 50% 50%;
  align-self: self-end;
  gap: 10px;
  padding: 30px;
  display: grid;
}

.customer-notes-edit-widget .actions button.clear-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #7d7e92;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.customer-notes-edit-widget .actions button.confirm-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.customer-notes-edit-widget .actions button.confirm-button:focus {
  border: none;
  outline: none;
}

.customer-info-and-requested-for-edit-widget {
  height: 90vh;
  min-width: 75vw;
  max-width: 92vw;
  z-index: 100;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-template-rows: repeat(2, min-content) 1fr min-content;
  display: grid;
  overflow: scroll;
}

.customer-info-and-requested-for-edit-widget::-webkit-scrollbar {
  width: 1px;
}

.customer-info-and-requested-for-edit-widget::-webkit-scrollbar-track {
  background: none;
}

.customer-info-and-requested-for-edit-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.customer-info-and-requested-for-edit-widget.start-order {
  height: unset;
  min-width: unset;
  max-width: unset;
}

.customer-info-and-requested-for-edit-widget.start-order:not(.start-order-customer-info-card) .main-panel {
  padding: 24px;
}

.customer-info-and-requested-for-edit-widget.start-order .iso-date-start-order-picker-container, .customer-info-and-requested-for-edit-widget.start-order .time-of-day-start-order-picker-container {
  width: 900px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  flex-direction: column;
  padding: 30px 25px;
  display: flex;
  box-shadow: 0 0 3px #2e2f4d0d, 0 10px 25px #59587b26;
}

.customer-info-and-requested-for-edit-widget.start-order .time-of-day-start-order-picker-container .back-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #1c1d3e;
  background-color: #0000;
  border: 2px solid #adafbc;
  margin: 18px 0 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.customer-info-and-requested-for-edit-widget.start-order.start-order-customer-info-card {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  width: 900px;
  min-width: unset;
  max-width: unset;
  height: calc(100vh - 74px);
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  margin-bottom: 0;
  display: grid;
  overflow: auto;
  box-shadow: 0 0 3px #2e2f4d0d, 0 10px 25px #59587b26;
}

.customer-info-and-requested-for-edit-widget.start-order.start-order-customer-info-card::-webkit-scrollbar {
  width: 1px;
}

.customer-info-and-requested-for-edit-widget.start-order.start-order-customer-info-card::-webkit-scrollbar-track {
  background: none;
}

.customer-info-and-requested-for-edit-widget.start-order.start-order-customer-info-card::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.customer-info-and-requested-for-edit-widget > .header {
  grid-template-columns: max-content 1fr;
  align-items: center;
  padding: 24px 24px 30px;
  display: grid;
}

.customer-info-and-requested-for-edit-widget > .header .collection-type {
  width: 150px;
}

.customer-info-and-requested-for-edit-widget > .header .collection-type .icon {
  vertical-align: middle;
}

.customer-info-and-requested-for-edit-widget > .header .collection-type > .label {
  vertical-align: middle;
  text-transform: uppercase;
  color: #7d7e92;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: inline;
}

.customer-info-and-requested-for-edit-widget > .header .title {
  text-align: center;
  color: #1c1d3e;
  margin-left: -150px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.customer-info-and-requested-for-edit-widget .main-panel {
  padding: 0 24px;
}

.customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector {
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-bottom: 16px;
  display: grid;
}

.customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .requested-for-asap, .customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .request-another-time {
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  box-shadow: 0 2px 3px #0003, 0 0 2px #0000001a;
}

.customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .requested-for-asap.selected, .customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .request-another-time.selected {
  background: #1c1d3e;
}

.customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .requested-for-asap.selected > .label, .customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .request-another-time.selected > .label {
  color: #fff;
}

.customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .requested-for-asap, .customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .request-another-time {
  position: relative;
}

.customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .requested-for-asap .selected-icon, .customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .request-another-time .selected-icon {
  z-index: 2;
  position: absolute;
  top: -6px;
  right: -8px;
}

.customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .request-another-time {
  cursor: pointer;
  z-index: 999;
  align-items: center;
  display: flex;
  position: relative;
}

.customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .request-another-time > .label {
  text-align: center;
  flex-grow: 1;
}

.customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .request-another-time > .request-another-time-options {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}

.customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .request-another-time > .request-another-time-options > .option {
  border-bottom: 1px solid #dee0e6;
  align-items: center;
  padding: 10px 16px;
  display: flex;
}

.customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .request-another-time > .request-another-time-options > .option img {
  margin-right: 14px;
}

.customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .request-another-time > .request-another-time-options > .option:last-child {
  border-bottom: 0;
}

.customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .request-another-time > .request-another-time-options > .option.selected {
  background-color: #f4f4f6;
  font-weight: 500;
}

.customer-info-and-requested-for-edit-widget .main-panel .requested-for-selector .request-another-time .drop-down-icon {
  position: absolute;
  right: 16px;
}

.customer-info-and-requested-for-edit-widget .main-panel .suburb-list {
  height: inherit;
}

.customer-info-and-requested-for-edit-widget .main-panel .empty-collection-times {
  height: inherit;
  width: inherit;
  color: #7d7e92;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  display: flex;
}

.customer-info-and-requested-for-edit-widget .main-panel .iso-date-picker > .iso-date-picker-widget, .customer-info-and-requested-for-edit-widget .main-panel .iso-date-picker .time-of-day-list-widget, .customer-info-and-requested-for-edit-widget .main-panel .time-of-day-picker > .iso-date-picker-widget, .customer-info-and-requested-for-edit-widget .main-panel .time-of-day-picker .time-of-day-list-widget {
  grid-template-rows: unset;
}

.customer-info-and-requested-for-edit-widget .main-panel .iso-date-picker > .iso-date-picker-widget > .header, .customer-info-and-requested-for-edit-widget .main-panel .iso-date-picker .time-of-day-list-widget > .header, .customer-info-and-requested-for-edit-widget .main-panel .time-of-day-picker > .iso-date-picker-widget > .header, .customer-info-and-requested-for-edit-widget .main-panel .time-of-day-picker .time-of-day-list-widget > .header {
  display: none;
}

.customer-info-and-requested-for-edit-widget .actions {
  grid-gap: 10px;
  height: -moz-fit-content;
  height: fit-content;
  align-self: self-end;
  padding: 24px;
  display: flex;
}

.customer-info-and-requested-for-edit-widget .actions > button {
  flex: 1 1 0;
}

.customer-info-and-requested-for-edit-widget .actions button.done-button, .customer-info-and-requested-for-edit-widget .actions button.start-order-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.customer-info-and-requested-for-edit-widget .actions button.done-button:focus, .customer-info-and-requested-for-edit-widget .actions button.start-order-button:focus {
  border: none;
  outline: none;
}

.customer-info-and-requested-for-edit-widget .actions button.cancel-button, .customer-info-and-requested-for-edit-widget .actions button.back-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #adafbc;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.half-and-half-widget {
  height: 90vh;
  width: 90vw;
  max-width: 1500px;
  grid-template-columns: 70% 30%;
  grid-template-areas: "main-panel info-panel";
  display: grid;
}

.half-and-half-widget .main-panel {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-area: main-panel;
  padding: 28px 6px;
  overflow-y: auto;
}

.half-and-half-widget .main-panel::-webkit-scrollbar {
  width: 1px;
}

.half-and-half-widget .main-panel::-webkit-scrollbar-track {
  background: none;
}

.half-and-half-widget .main-panel::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.half-and-half-widget .main-panel .modifier .header {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 18px;
  display: flex;
}

.half-and-half-widget .main-panel .modifier .header .badge {
  color: #535a64;
}

.half-and-half-widget .main-panel .modifier .header .badge.optional {
  color: #34c759;
}

.half-and-half-widget .main-panel .modifier .header .badge.required {
  color: #da6565;
}

.half-and-half-widget .main-panel .modifier .header .badge.is-selected {
  color: #7d7e92;
}

.half-and-half-widget .main-panel .modifier .options {
  padding: 15px 18px;
}

.half-and-half-widget .main-panel .modifier .options .option:last-child {
  border-bottom: 0;
}

.half-and-half-widget .main-panel .modifier .options .option {
  letter-spacing: 0;
  color: #1c1d3e;
  width: 100%;
  color: #1c1d3e90;
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  grid-template-columns: 1fr min-content min-content;
  grid-template-areas: "input qty charge";
  align-items: center;
  margin-bottom: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px;
  transition: all .2s ease-out;
  display: grid;
}

.half-and-half-widget .main-panel .modifier .options .option.selected {
  color: #fff;
  box-shadow: none;
  background-color: #1c1d3e;
}

.half-and-half-widget .main-panel .modifier .options .option .clickable {
  flex-direction: row;
  grid-area: input;
  align-items: center;
  margin-right: 10px;
  padding: 15px 0 15px 15px;
  display: flex;
}

.half-and-half-widget .main-panel .modifier .options .option .clickable > .container > input {
  cursor: pointer;
  margin-right: 12px;
}

.half-and-half-widget .main-panel .modifier .options .option .clickable .name {
  max-width: 20vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.half-and-half-widget .main-panel .modifier .options .option .quantity {
  flex-direction: row;
  grid-area: qty;
  place-self: end;
  align-items: center;
  margin-right: 25px;
  padding: 15px 0;
  display: flex;
}

.half-and-half-widget .main-panel .modifier .options .option .quantity .icon {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.half-and-half-widget .main-panel .modifier .options .option .quantity .btn, .half-and-half-widget .main-panel .modifier .options .option .quantity .inc, .half-and-half-widget .main-panel .modifier .options .option .quantity .dec {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
}

.half-and-half-widget .main-panel .modifier .options .option .quantity .dec {
  background: #4c4e68;
}

.half-and-half-widget .main-panel .modifier .options .option .quantity .number {
  min-width: 34px;
  text-align: center;
  align-self: center;
  margin: 0 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
}

.half-and-half-widget .main-panel .modifier .options .option .quantity .inc {
  background: #4cbba1;
}

.half-and-half-widget .main-panel .modifier .options .option .quantity .icon {
  padding-top: 2px;
}

.half-and-half-widget .main-panel .modifier .options .option .quantity .number {
  margin: 0 20px;
}

.half-and-half-widget .main-panel .modifier .options .option .extra-charge {
  white-space: nowrap;
  grid-area: charge;
  padding-right: 15px;
}

.half-and-half-widget .main-panel .half-and-half {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: column;
  display: grid;
}

.half-and-half-widget .main-panel .half-and-half .half .selected-pizza {
  grid-auto-flow: row;
  gap: 20px;
  margin-bottom: 15px;
  padding: 0 18px;
  display: grid;
}

.half-and-half-widget .main-panel .half-and-half .half .selected-pizza .header {
  gap: 14px;
  display: flex;
}

.half-and-half-widget .main-panel .half-and-half .half .selected-pizza .header .requiredness {
  color: #7d7e92;
}

.half-and-half-widget .main-panel .half-and-half .half .selected-pizza .header .requiredness.red {
  color: #fe5b5b;
}

.half-and-half-widget .main-panel .half-and-half .half .selected-pizza .pizza {
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  align-items: center;
  padding: 20px;
  display: grid;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.half-and-half-widget .main-panel .half-and-half .half .selected-pizza .pizza .selected {
  text-align: center;
}

.half-and-half-widget .main-panel .half-and-half .half .selected-pizza .pizza .selected .extra-charge {
  opacity: .7;
  color: #7d7e92;
  margin-top: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.half-and-half-widget .main-panel .half-and-half .half .selected-pizza .pizza > i {
  text-align: center;
  color: #535a64;
  padding: 8px 0;
  font-size: 30px;
  display: block;
}

.half-and-half-widget .main-panel .half-and-half .half .remove-toppings .header {
  padding-top: 20px;
}

.half-and-half-widget .main-panel .half-and-half .half .remove-toppings .options {
  grid-gap: 20px;
  flex-flow: wrap;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.half-and-half-widget .main-panel .half-and-half .half .remove-toppings .options .option {
  text-align: center;
  color: #1c1d3e;
  width: -moz-fit-content;
  width: fit-content;
  color: #343553;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 30px;
  flex-flow: column;
  margin: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  transition: unset;
  display: flex;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.half-and-half-widget .main-panel .half-and-half .half .remove-toppings .options .option.selected {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(#2c2d56 0%, #0c0d34 100%);
  text-decoration: line-through;
}

.half-and-half-widget .main-panel .half-and-half .half .remove-toppings .options .option.selected .extra-charge {
  color: #fff;
  padding: 0 10px 20px;
}

.half-and-half-widget .main-panel .half-and-half .half .remove-toppings .options .option .clickable {
  display: unset;
  margin: 0;
  padding: 10px 20px;
}

.half-and-half-widget .main-panel .half-and-half .half .remove-toppings .options .option .clickable .radio, .half-and-half-widget .main-panel .half-and-half .half .remove-toppings .options .option .clickable .checkbox {
  display: none;
}

.half-and-half-widget .main-panel .half-and-half .half .remove-toppings .options .option .clickable .name {
  word-break: break-all;
  white-space: unset;
  display: block;
}

.half-and-half-widget .main-panel .half-and-half .half .remove-toppings .options .option .extra-charge, .half-and-half-widget .main-panel .half-and-half .half .remove-toppings .badge {
  display: none;
}

.half-and-half-widget .main-panel .half-and-half .half .added-toppings .options {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  display: grid;
}

.half-and-half-widget .main-panel .half-and-half .half .added-toppings .options .option {
  cursor: unset;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  grid-template-columns: 1fr;
  grid-template-areas: "details"
                       "actions";
  margin: 0;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.half-and-half-widget .main-panel .half-and-half .half .added-toppings .options .option.add-new-topping {
  cursor: pointer;
  color: #535a64;
  border-bottom: 0;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
  font-size: 30px;
  display: flex;
}

.half-and-half-widget .main-panel .half-and-half .half .added-toppings .options .option .topping-details {
  text-align: center;
  border-bottom: 1px solid #d9d9e6;
  grid-area: details;
  padding: 10px;
}

.half-and-half-widget .main-panel .half-and-half .half .added-toppings .options .option .topping-details .extra-charge {
  opacity: .7;
  color: #7d7e92;
  padding: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.half-and-half-widget .main-panel .half-and-half .half .added-toppings .options .option .actions {
  grid-area: actions;
  justify-self: center;
  padding: 10px;
}

.half-and-half-widget .main-panel .half-and-half .half .added-toppings .options .option .actions .quantity {
  place-content: space-between;
  margin: 0;
  padding: 0;
  display: flex;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings {
  height: 100%;
  grid-template-rows: min-content 1fr min-content;
  display: grid;
  position: relative;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .done-btn {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  width: 120px;
  background-color: #3f9c86;
  border-radius: 8px;
  margin: 0;
  padding: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .done-btn:focus {
  border: none;
  outline: none;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings input {
  text-indent: 30px;
  width: 330px;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 15px;
  background-repeat: no-repeat;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  box-shadow: 0 3px 15px -5px #0000004d;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .header {
  padding-top: 0;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .header > .title {
  margin: auto 0;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .empty-options {
  margin: 100px auto auto;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .options {
  grid-gap: 15px;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 100px;
  display: grid;
  overflow-y: auto;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .options::-webkit-scrollbar {
  width: 1px;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .options::-webkit-scrollbar-track {
  background: none;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .options::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .options .option {
  color: #1c1d3e;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  margin: 0;
  padding: 10px;
  transition: unset;
  position: relative;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .options .option.selected {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(#2c2d56 0%, #0c0d34 100%);
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .options .option.selected:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: #4cbba1;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .options .option.selected:after {
  content: "";
  width: 5px;
  height: 10px;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  display: block;
  position: absolute;
  top: -3px;
  right: 0;
  transform: rotate(45deg);
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .options .option.selected .extra-charge {
  color: #fff;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .options .option .clickable {
  display: unset;
  height: 100%;
  width: 100%;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .options .option .clickable .radio, .half-and-half-widget .main-panel .add-toppings-list .add-toppings .options .option .clickable .checkbox {
  display: none;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .options .option .clickable .name {
  white-space: unset;
  text-align: center;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .options .option .quantity {
  display: none;
}

.half-and-half-widget .main-panel .add-toppings-list .add-toppings .options .option .extra-charge {
  color: #1c1d3e90;
  padding: 0;
}

.half-and-half-widget .main-panel .pizza-list {
  overflow-y: unset;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget {
  height: 100%;
  grid-template-rows: min-content 1fr min-content;
  display: grid;
  position: relative;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .done-btn {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  width: 120px;
  background-color: #3f9c86;
  border-radius: 8px;
  margin: 0;
  padding: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .done-btn:focus {
  border: none;
  outline: none;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget input {
  text-indent: 30px;
  width: 330px;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 15px;
  background-repeat: no-repeat;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  box-shadow: 0 3px 15px -5px #0000004d;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .header {
  padding-top: 0;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .empty-options {
  margin: 100px auto auto;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .options {
  grid-gap: 15px;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 100px;
  display: grid;
  overflow-y: auto;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .options::-webkit-scrollbar {
  width: 1px;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .options::-webkit-scrollbar-track {
  background: none;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .options::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .options .option {
  color: #1c1d3e;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  margin: 0;
  padding: 10px;
  transition: unset;
  position: relative;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .options .option.selected {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(#2c2d56 0%, #0c0d34 100%);
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .options .option.selected:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: #4cbba1;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .options .option.selected:after {
  content: "";
  width: 5px;
  height: 10px;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  display: block;
  position: absolute;
  top: -3px;
  right: 0;
  transform: rotate(45deg);
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .options .option.selected .extra-charge {
  color: #fff;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .options .option .clickable {
  display: unset;
  height: 100%;
  width: 100%;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .options .option .clickable .radio, .half-and-half-widget .main-panel .pizza-list .pizza-list-widget .options .option .clickable .checkbox {
  display: none;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .options .option .clickable .name {
  white-space: unset;
  text-align: center;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .options .option .quantity {
  display: none;
}

.half-and-half-widget .main-panel .pizza-list .pizza-list-widget .options .option .extra-charge {
  color: #1c1d3e90;
  padding: 4px 0;
}

.half-and-half-widget .main-panel .modifier .header {
  align-items: center;
}

.half-and-half-widget .info-panel {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #f6f8fb;
  border-radius: 0 20px 20px 0;
  grid-area: info-panel;
  grid-template-rows: min-content 1fr min-content;
  padding: 24px 24px 19px;
  display: grid;
  overflow-x: hidden;
  overflow-y: scroll;
}

.half-and-half-widget .info-panel::-webkit-scrollbar {
  width: 1px;
}

.half-and-half-widget .info-panel::-webkit-scrollbar-track {
  background: none;
}

.half-and-half-widget .info-panel::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.half-and-half-widget .info-panel .spec-info .title {
  letter-spacing: .26px;
  color: #1c1d3e;
  margin-bottom: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
}

.half-and-half-widget .info-panel .spec-info .description {
  color: #7d7e92;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.half-and-half-widget .info-panel .summary {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  overflow-y: hidden scroll;
  border-top: 1px solid #d9d9e6;
  margin: 20px 0;
  padding-top: 5px;
}

.half-and-half-widget .info-panel .summary::-webkit-scrollbar {
  width: 1px;
}

.half-and-half-widget .info-panel .summary::-webkit-scrollbar-track {
  background: none;
}

.half-and-half-widget .info-panel .summary::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.half-and-half-widget .info-panel .summary.hide {
  opacity: 0;
  transition: all .3s ease-out;
}

.half-and-half-widget .info-panel .summary.show {
  opacity: 1;
  transition: all .3s ease-in;
}

.half-and-half-widget .info-panel .actions > .quantity {
  background-color: #fff;
  border-radius: 10px;
  grid-template-columns: 1fr repeat(3, min-content);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 8px 15px 8px 14px;
  display: grid;
}

.half-and-half-widget .info-panel .actions > .quantity .icon {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.half-and-half-widget .info-panel .actions > .quantity .btn, .half-and-half-widget .info-panel .actions > .quantity .inc, .half-and-half-widget .info-panel .actions > .quantity .dec, .half-and-half-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .dec, .half-and-half-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .dec, .half-and-half-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .inc, .half-and-half-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .inc {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
}

.half-and-half-widget .info-panel .actions > .quantity .dec {
  background: #4c4e68;
}

.half-and-half-widget .info-panel .actions > .quantity .number {
  min-width: 34px;
  text-align: center;
  align-self: center;
  margin: 0 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
}

.half-and-half-widget .info-panel .actions > .quantity .inc {
  background: #4cbba1;
}

.half-and-half-widget .info-panel .actions > .quantity > h4 {
  color: #7d7e92;
  margin: 0;
}

.half-and-half-widget .info-panel .actions > .quantity .btn, .half-and-half-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .dec, .half-and-half-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .dec, .half-and-half-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .inc, .half-and-half-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .inc, .half-and-half-widget .info-panel .actions > .quantity .dec, .half-and-half-widget .info-panel .actions > .quantity .inc {
  width: 28px;
  height: 28px;
}

.half-and-half-widget .info-panel .actions > .quantity .btn i, .half-and-half-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .dec i, .half-and-half-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .dec i, .half-and-half-widget .info-panel .main-panel .modifier .options .option .actions > .quantity .inc i, .half-and-half-widget .main-panel .modifier .options .option .info-panel .actions > .quantity .inc i, .half-and-half-widget .info-panel .actions > .quantity .dec i, .half-and-half-widget .info-panel .actions > .quantity .inc i {
  flex-flow: column;
  justify-content: center;
  display: flex;
}

.half-and-half-widget .info-panel .actions > .quantity .number {
  color: #1c1d3e;
  font-size: 15px;
  line-height: 21px;
}

.half-and-half-widget .info-panel .actions .add-to-cart {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0;
  width: 100%;
  letter-spacing: .1px;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0 0 10px;
  padding: 14px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.half-and-half-widget .info-panel .actions .add-to-cart:focus {
  border: none;
  outline: none;
}

.half-and-half-widget .info-panel .actions .cancel {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  width: 100%;
  background-color: #0000;
  margin: 0;
  padding: 11px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.half-and-half-widget .info-panel .spec-info .description.hide {
  opacity: 0;
  max-height: 1px;
  transition: all .3s ease-out;
  position: absolute;
  bottom: 1000vh;
}

.half-and-half-widget .info-panel .spec-info .description.show {
  opacity: 1;
  max-height: 100vh;
  transition: all .3s ease-in;
  position: relative;
}

.half-and-half-widget .info-panel .spec-info .description-toggle {
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  color: #7d7e92;
  border: 1px solid #7d7e92;
  border-radius: 20px;
  margin-top: 15px;
  padding: 4px 14px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.half-and-half-widget .info-panel .summary .item-description-view-widget {
  color: #7d7e92;
}

.half-and-half-widget .info-panel .summary .convert-link {
  cursor: pointer;
  color: #4cbba1;
  align-items: center;
  padding: 12px 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.half-and-half-widget .info-panel .summary .convert-link.deal {
  border-bottom: 1px solid #d9d9e6;
  margin-bottom: 12px;
  padding-top: 12px;
}

.half-and-half-widget .info-panel .summary .convert-link img {
  margin-right: 8px;
}

.half-and-half-widget .info-panel .summary .half {
  padding-bottom: 2px;
}

.half-and-half-widget .info-panel .summary .half.second {
  border-top: 1px solid #d9d9e6;
}

.half-and-half-widget .info-panel .summary .half .header {
  cursor: pointer;
  grid-template-columns: 1fr min-content;
  align-items: center;
  padding-top: 12px;
  display: grid;
}

.half-and-half-widget .info-panel .summary .half .header.disable {
  cursor: not-allowed;
}

.half-and-half-widget .info-panel .summary .half .header.disable > svg {
  opacity: .4;
}

.half-and-half-widget .info-panel .summary .half .header .pizza > h4 {
  margin-bottom: unset;
}

.half-and-half-widget .info-panel .summary .half .header > .edit-icon > svg {
  color: #fff;
  background-color: #7d7e92;
  border-radius: 50%;
  padding: 6px;
  font-size: 25px;
}

.half-and-half-widget .info-panel .summary .half .summary-item {
  grid-gap: 4px;
  grid-template-columns: 40% 60%;
  grid-auto-flow: column;
  margin: 10px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: grid;
}

.half-and-half-widget .info-panel .summary .half .summary-item .name {
  color: #7d7e92;
}

.half-and-half-widget .info-panel .summary .half .summary-item .value > li {
  list-style: none;
}

.half-and-half-widget .info-panel .empty-summary {
  justify-content: center;
  margin: 15px 0;
  display: grid;
}

.half-and-half-widget .info-panel .empty-summary .icon {
  margin: 15px auto;
}

.half-and-half-widget .info-panel .empty-summary > p {
  color: #7d7e92;
  max-width: 60%;
  text-align: center;
  margin: 4px auto 17px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.confirm-convert #swal2-title {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 26px !important;
}

.confirm-convert #swal2-content {
  color: #9597a7;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
}

.cart-items-widget > .new-items-label:after, .cart-items-widget > .new-items-label:before {
  content: "";
  vertical-align: middle;
  height: 1px;
  width: 50%;
  background-color: #4cbba1;
  display: inline-block;
  position: relative;
}

.cart-items-widget.is-empty {
  height: 100%;
}

.cart-items-widget > .no-items {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cart-items-widget > .no-items > .icon {
  height: 73px;
  width: 73px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cart-items-widget > .no-items > .icon > img {
  height: 100%;
}

.cart-items-widget > .no-items > .label {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #fff9;
  margin-top: 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.cart-items-widget > .printed-items, .cart-items-widget > .unprinted-items {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  padding: 6px 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.cart-items-widget > .printed-items::-webkit-scrollbar, .cart-items-widget > .unprinted-items::-webkit-scrollbar {
  width: 1px;
}

.cart-items-widget > .printed-items::-webkit-scrollbar-track, .cart-items-widget > .unprinted-items::-webkit-scrollbar-track {
  background: none;
}

.cart-items-widget > .printed-items::-webkit-scrollbar-thumb, .cart-items-widget > .unprinted-items::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.cart-items-widget > .new-items-label {
  color: #4cbba1;
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
}

.cart-items-widget > .new-items-label:before {
  margin-left: -50%;
  right: .5em;
}

.cart-items-widget > .new-items-label:after {
  margin-right: -50%;
  left: .5em;
}

.cart-items-widget > .new-items-label:first-child {
  margin-top: 12px;
}

.cart-items-widget .convert-pizzas-to-deal-button {
  color: #fff;
  cursor: pointer;
  background-color: #ffffff20;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  display: flex;
}

.cart-items-widget .convert-pizzas-to-deal-button .pizza-icon {
  margin-right: 14px;
}

.cart-amount-widget > .loyalty-discount, .cart-amount-widget > .custom-discount, .cart-amount-widget > .custom-surcharge, .cart-amount-widget > .public-holiday-surcharge {
  cursor: pointer;
  border-radius: 4px;
  justify-content: space-between;
  padding-block: 4px;
  display: flex;
}

.cart-amount-widget > .loyalty-discount > .label, .cart-amount-widget > .custom-discount > .label, .cart-amount-widget > .custom-surcharge > .label, .cart-amount-widget > .public-holiday-surcharge > .label, .cart-amount-widget > .loyalty-discount > .value, .cart-amount-widget > .custom-discount > .value, .cart-amount-widget > .custom-surcharge > .value, .cart-amount-widget > .public-holiday-surcharge > .value {
  word-wrap: break-word;
}

@keyframes highlightSurcharge {
  0%, 100% {
    background-color: #f43f5e40;
  }

  50% {
    background-color: #f43f5e80;
  }
}

.cart-amount-widget {
  border-top: 1px solid #343553;
  flex-direction: column;
  padding: 12px;
  display: flex;
}

.cart-amount-widget > .amount {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #fff9;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-inline: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
}

.cart-amount-widget > .amount > .value {
  color: #fff;
}

.cart-amount-widget > .public-holiday-surcharge {
  background-color: #f43f5e40;
  animation-name: highlightSurcharge;
  animation-duration: 2s;
}

.cart-amount-widget > .custom-surcharge {
  background-color: #f43f5e40;
}

.cart-amount-widget > .custom-discount {
  background-color: #d9770640;
}

.cart-amount-widget > .loyalty-discount {
  background-color: #2563eb40;
}

.cart-amount-widget > .grand-total {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #fff;
  margin-bottom: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.cart-amount-widget > .grand-total > .value {
  color: #d97706;
  border-bottom: 1px solid #d9770680;
}

.pagination-widget {
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  display: flex;
}

.pagination-widget button {
  background-color: #0000;
  padding: 0;
}

.pagination-widget button > .label {
  align-items: center;
  display: flex;
}

.pagination-widget button > .label span {
  color: #1c1d3e;
  font-size: 16px;
  font-weight: 600;
}

.pagination-widget button > .label img {
  width: 7.2px;
  height: 12px;
}

.pagination-widget button.disabled {
  color: #65667d;
}

.pagination-widget .page-total-spinner-container {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.pagination-widget .page-total-spinner-container .message {
  color: #4c4e68;
  padding-left: 8px;
  font-size: 14px;
}

.pagination-widget .page-label {
  color: #4c4e68;
  font-size: 14px;
}

.pagination-widget .previous-button > .label > span {
  padding-left: 16px;
}

.pagination-widget .next-button > .label > span {
  padding-right: 16px;
}

.pos-time-range-select-widget {
  width: 80vw;
  max-width: 1000px;
  padding: 50px 50px 30px;
}

.pos-time-range-select-widget .time-range-widget {
  margin-bottom: 50px;
}

.pos-time-range-select-widget .time-range-widget .form-item .input-box {
  background-image: url("icon-calendar.4ddcc08d.svg");
  background-position: 95%;
  background-repeat: no-repeat;
  margin-top: 5px;
}

.pos-time-range-select-widget .time-range-widget .form-item:first-child .input-box {
  background-image: url("icon-ellipsis.b90d9bcd.svg");
}

.pos-time-range-select-widget .time-range-widget .form-item .daypicker-container input {
  background-image: url("icon-calendar.4ddcc08d.svg");
  background-position: 95%;
  background-repeat: no-repeat;
}

.pos-time-range-select-widget .time-range-widget .date-range {
  display: none;
}

.pos-time-range-select-widget .actions {
  max-width: 350px;
  margin: 0 auto;
}

.pos-time-range-select-widget .actions .done {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0;
  color: #1c1d3e;
  width: 100%;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.pos-time-range-select-widget .actions .done:focus {
  border: none;
  outline: none;
}

.pos-time-range-select-widget .actions .cancel {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  width: 100%;
  background-color: #0000;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.suburb-list-widget {
  height: 100%;
  grid-template-rows: min-content minmax(100px, max-content);
  display: grid;
  position: relative;
}

.suburb-list-widget .header {
  grid-template-columns: 1fr max-content min-content;
  align-items: center;
  gap: 20px;
  padding: 24px;
  display: grid;
}

.suburb-list-widget .header .search-suburb {
  border-radius: 10px;
  box-shadow: 0 0 5px #31303e26, 0 4px 6px #31303e26;
}

.suburb-list-widget .header > button {
  min-width: 20%;
  padding: 12px 40px;
}

.suburb-list-widget .no-suburb {
  text-align: center;
  margin-top: 100px;
}

.suburb-list-widget .suburbs {
  grid-gap: 15px;
  grid-template-columns: repeat(4, 1fr);
  padding: 10px 24px 24px;
  display: grid;
  overflow-x: hidden;
  overflow-y: scroll;
}

.suburb-list-widget .suburbs::-webkit-scrollbar {
  display: none;
}

.suburb-list-widget .suburbs .option {
  text-align: center;
  color: #1c1d3e;
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  flex-flow: column;
  justify-content: center;
  margin: 0;
  padding: 20px 10px;
  transition: unset;
  display: flex;
  position: relative;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.suburb-list-widget .suburbs .option.selected {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(#2c2d56 0%, #0c0d34 100%);
}

.suburb-list-widget .suburbs .option.selected:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: #4cbba1;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
}

.suburb-list-widget .suburbs .option.selected:after {
  content: "";
  width: 5px;
  height: 10px;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  display: block;
  position: absolute;
  top: -3px;
  right: 0;
  transform: rotate(45deg);
}

.suburb-list-widget .suburbs .option.selected .charge {
  color: #fff;
}

.suburb-list-widget .suburbs .option .charge {
  color: #1c1d3e90;
  margin: 7px 0 0;
}

.iso-date-picker-widget {
  height: auto;
  grid-template-rows: min-content minmax(100px, max-content) min-content;
  display: grid;
  position: relative;
}

.iso-date-picker-widget button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #1c1d3e;
  background-color: #0000;
  border: 2px solid #adafbc;
  margin: 18px 0 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.iso-date-picker-widget .header {
  grid-template-columns: 1fr max-content min-content;
  align-items: center;
  padding: 0 24px;
  display: grid;
}

.iso-date-picker-widget .header .title {
  text-align: center;
  color: #1c1d3e;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.iso-date-picker-widget .DayPicker-Caption {
  margin-top: 24px;
  margin-bottom: 24px;
}

.iso-date-picker-widget .DayPicker-Caption > div {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.iso-date-picker-widget .DayPicker-wrapper {
  background: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
}

.iso-date-picker-widget .DayPicker-Month {
  border-spacing: 46px 6px;
  border-collapse: separate;
  margin: 10px 26px;
}

.iso-date-picker-widget .DayPicker-Weekday {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.iso-date-picker-widget .DayPicker-Day {
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.iso-date-picker-widget .DayPicker-Day--outside {
  outline: unset !important;
}

.iso-date-picker-widget .DayPicker-Day--today {
  color: #000;
}

.iso-date-picker-widget .DayPicker-Day--highlighted {
  color: #000;
  outline-offset: -10px;
  border-radius: 25%;
  outline: 2px solid #4cbba1;
}

.iso-date-picker-widget .DayPicker-NavButton--prev {
  background-image: url("icon-chevron-left.88d05a8c.svg");
  top: 29px;
  left: 80px;
}

.iso-date-picker-widget .DayPicker-NavButton--next {
  background-image: url("icon-chevron-right.4e078f9d.svg");
  top: 29px;
  right: 80px;
}

.modal .iso-date-picker-widget {
  padding: 25px 24px;
}

.modal .iso-date-picker-widget .header .title {
  margin-bottom: 24px;
}

.iso-date-range-picker-widget {
  height: 90vh;
  width: 70vw;
  grid-template-rows: min-content 1fr min-content;
  display: grid;
}

.iso-date-range-picker-widget .header {
  text-align: center;
  align-items: center;
  display: grid;
}

.iso-date-range-picker-widget .header .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 0;
  padding: 25px 0 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
}

.iso-date-range-picker-widget .default {
  display: block;
}

.iso-date-range-picker-widget .default .form-items {
  width: 100%;
  height: 100%;
  display: block;
}

.iso-date-range-picker-widget .form-iso-date-range-picker {
  height: inherit;
  width: inherit;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker {
  height: inherit;
  width: inherit;
  padding: 24px 24px 18px;
  display: flex;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper {
  height: 100%;
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
  border: 2px solid #dee0e6;
  border-radius: 8px;
  display: flex;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-NavButton {
  content: "";
  height: 24px;
  width: 24px;
  background-size: 100%;
  position: absolute;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-NavButton--prev {
  background-image: url("icon-chevron-down-grey.b17677ff.svg");
  margin-left: 5%;
  left: 1.5em;
  transform: rotate(90deg);
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-NavButton--next {
  background-image: url("icon-chevron-down-grey.b17677ff.svg");
  margin-right: 5%;
  transform: rotate(270deg);
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-NavButton--interactionDisabled {
  display: block;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months {
  width: inherit;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month {
  width: inherit;
  margin: 16px 24px;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Caption {
  margin-bottom: 1.5em;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Caption div {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Weekday abbr[title] {
  color: #7d7e92;
  font-size: 16px;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Day--today {
  color: inherit;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Day {
  z-index: 1;
  cursor: pointer;
  position: relative;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Day:hover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--from):not(.DayPicker-Day--to) {
  background-color: #0000;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Day:hover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--from):not(.DayPicker-Day--to):after {
  content: "";
  width: 35%;
  height: 52%;
  color: #4cbba1;
  z-index: -1;
  background-color: #c4eae1;
  border-radius: 8px;
  position: absolute;
  top: 24%;
  left: 32%;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Day--selected {
  color: inherit;
  background-color: #0000;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Day--selected:before {
  content: "";
  width: 100%;
  height: 65%;
  z-index: -1;
  background-color: #ecf9f6;
  border-top: 2px solid #4cbba1;
  border-bottom: 2px solid #4cbba1;
  position: absolute;
  top: 15%;
  left: 0;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Day--selected:first-of-type:before, .iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Day--selected.DayPicker-Day--from:before {
  border-left: 2px solid #4cbba1;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Day--selected:last-of-type:before, .iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Day--selected.DayPicker-Day--to:before {
  border-right: 2px solid #4cbba1;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Day--from, .iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Day--to {
  color: #fff;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Day--from:after, .iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Day--to:after {
  content: "";
  width: 35%;
  height: 52%;
  color: #4cbba1;
  z-index: -1;
  background-color: #3f9c86;
  border-radius: 8px;
  position: absolute;
  top: 24%;
  left: 32%;
}

.iso-date-range-picker-widget .form-iso-date-range-picker .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Day.DayPicker-Day--disabled {
  color: #dce0e0;
}

.iso-date-range-picker-widget .back-button {
  background-color: #0000;
  border: 2px solid #adafbc;
  border-radius: 8px;
  margin: 0 18px 18px;
  padding: 14px 18px;
}

.time-of-day-list-widget {
  height: 100%;
  grid-template-rows: min-content minmax(100px, max-content);
  display: grid;
  position: relative;
  overflow: hidden;
}

.time-of-day-list-widget .header {
  grid-template-columns: 1fr max-content min-content;
  align-items: center;
  display: grid;
}

.time-of-day-list-widget .header .title {
  text-align: center;
  color: #1c1d3e;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.time-of-day-list-widget .no-times-of-day {
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  font-size: 21px;
  display: flex;
}

.time-of-day-list-widget .no-times-of-day > button {
  min-width: 20%;
  letter-spacing: .5px;
  border-radius: 8px;
  margin-top: 27px;
  padding: 13px;
  font-size: 17px;
}

.time-of-day-list-widget .times-of-day {
  grid-gap: 15px;
  max-height: calc(100vh - 300px);
  grid-template-columns: repeat(auto-fit, minmax(136px, 1fr));
  gap: 12px;
  padding: 10px 12px 24px;
  display: grid;
  overflow-x: hidden;
  overflow-y: scroll;
}

.time-of-day-list-widget .times-of-day::-webkit-scrollbar {
  display: none;
}

.time-of-day-list-widget .times-of-day .option {
  text-align: center;
  color: #1c1d3e;
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  flex-flow: column;
  justify-content: center;
  margin: 0;
  padding: 20px 10px;
  transition: unset;
  display: flex;
  position: relative;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.time-of-day-list-widget .times-of-day .option.selected {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(#2c2d56 0%, #0c0d34 100%);
}

.time-of-day-list-widget .times-of-day .option.selected:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: #4cbba1;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
}

.time-of-day-list-widget .times-of-day .option.selected:after {
  content: "";
  width: 5px;
  height: 10px;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  display: block;
  position: absolute;
  top: -3px;
  right: 0;
  transform: rotate(45deg);
}

.time-of-day-list-widget .times-of-day .option.selected .charge {
  color: #fff;
}

.time-of-day-list-widget .times-of-day .option .charge {
  color: #1c1d3e90;
  margin: 7px 0 0;
}

.time-of-day-list-widget .times-of-day .option {
  letter-spacing: .5px;
  padding: 22px;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

h1 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

h2 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 22px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

h3 {
  letter-spacing: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

h4 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

h5 {
  letter-spacing: 0;
  color: #1c1d3e;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

p, pre {
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

form input[type="text"], form input[type="number"], form input[type="password"], form input[type="tel"], form textarea {
  font-size: 15px;
}

.add-item-custom-discount-widget {
  width: 430px;
  margin: 60px 160px;
}

.add-item-custom-discount-widget .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 30px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.add-item-custom-discount-widget .unit-toggle {
  color: #1c1d3e;
  background: #f6f8fb;
  border: 1px solid #fff;
  border-radius: 8px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 25px;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  display: grid;
}

.add-item-custom-discount-widget .unit-toggle div {
  cursor: pointer;
  text-align: center;
  align-items: center;
  display: grid;
}

.add-item-custom-discount-widget .unit-toggle div.selected {
  color: #fff;
  background: #1c1d3e;
  border-radius: 8px;
  padding: 10px 12px;
}

.add-item-custom-discount-widget .discount-input-box {
  border-radius: 8px;
  grid-template-columns: min-content 1fr;
  padding: 12.75px 20px;
  display: grid;
}

.add-item-custom-discount-widget .discount-input-box .discount-icon {
  height: 40px;
  width: 40px;
  background: url("custom-discount-icon.c3db72c1.svg");
}

.add-item-custom-discount-widget .discount-input-box .discount-value {
  align-items: center;
  display: grid;
}

.add-item-custom-discount-widget .discount-input-box .discount-value input {
  text-align: right;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
  line-height: 35px;
  overflow: hidden;
}

.add-item-custom-discount-widget .valid.discount-input-box {
  border: 1px solid #4cbba1;
}

.add-item-custom-discount-widget .invalid.discount-input-box {
  border: 1px solid #da6565;
}

.add-item-custom-discount-widget .item-info {
  color: #7d7e92;
  background: #f6f8fb;
  border-radius: 8px;
  margin: 24px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
}

.add-item-custom-discount-widget .item-info .discount-info {
  grid-template-areas: "name . original-price"
                       "discount . discount-charge";
  padding: 15px;
  display: grid;
}

.add-item-custom-discount-widget .item-info .discount-info .name {
  grid-area: name;
  margin-bottom: 5px;
}

.add-item-custom-discount-widget .item-info .discount-info .original-price {
  text-align: right;
  grid-area: original-price;
}

.add-item-custom-discount-widget .item-info .discount-info .discount {
  grid-area: discount;
}

.add-item-custom-discount-widget .item-info .discount-info .discount-charge {
  color: #da6565;
  text-align: right;
  grid-area: discount-charge;
}

.add-item-custom-discount-widget .item-info .new-price-info {
  color: #1c1d3e;
  border-top: 1px solid #d9d9e6;
  grid-area: new-price-info;
  justify-content: space-between;
  padding: 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  display: flex;
}

.add-item-custom-discount-widget .add-discount.actions {
  grid-gap: 20px;
  grid-template-areas: "confirm"
                       "cancel";
  display: grid;
}

.add-item-custom-discount-widget .edit-discount.actions {
  grid-gap: 20px 10px;
  grid-template-areas: "clear confirm"
                       "cancel cancel";
  display: grid;
}

.add-item-custom-discount-widget button.confirm {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  height: 50px;
  background-color: #3f9c86;
  border-radius: 5px;
  grid-area: confirm;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.add-item-custom-discount-widget button.confirm:focus {
  border: none;
  outline: none;
}

.add-item-custom-discount-widget button.clear {
  color: #4cbba1;
  height: 50px;
  background-color: #0000;
  border: 1px solid #4cbba1;
  grid-area: clear;
  font-size: 17px;
  line-height: 23px;
}

.add-item-custom-discount-widget button.cancel {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  height: 40px;
  background-color: #0000;
  grid-area: cancel;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 22px;
  transition: all .2s ease-in-out;
}

.add-item-custom-discount-widget input::-webkit-outer-spin-button, .add-item-custom-discount-widget input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.add-item-custom-discount-widget input[type="number"] {
  -moz-appearance: textfield;
}

.item-custom-price-widget {
  width: 430px;
  margin: 60px 160px;
}

.item-custom-price-widget .title {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
}

.item-custom-price-widget .update-price-input-box {
  border: 1px solid #ddd;
  border-radius: 8px;
  grid-template-columns: min-content 1fr;
  padding: 12.75px 20px;
  display: grid;
}

.item-custom-price-widget .update-price-input-box .dollar-sign-icon {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  padding-top: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.item-custom-price-widget .update-price-input-box .update-item-price-value {
  align-items: center;
  display: grid;
}

.item-custom-price-widget .update-price-input-box .update-item-price-value input {
  text-align: right;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
  line-height: 35px;
  overflow: hidden;
}

.item-custom-price-widget .summary {
  color: #7d7e92;
  background: #f6f8fb;
  border-radius: 8px;
  margin: 24px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
}

.item-custom-price-widget .summary .item-custom-price-info {
  padding: 15px;
}

.item-custom-price-widget .summary .item-custom-price-info .item-info {
  grid-template-areas: "name . original-price";
  margin-bottom: 5px;
  display: grid;
}

.item-custom-price-widget .summary .item-custom-price-info .item-info .name {
  grid-area: name;
}

.item-custom-price-widget .summary .item-custom-price-info .item-info .original-price {
  text-align: right;
  grid-area: original-price;
}

.item-custom-price-widget .summary .item-custom-price-info .discount-info .value {
  color: #be123c;
}

.item-custom-price-widget .summary .item-custom-price-info .extra-charge-info .value {
  color: #4cbba1;
}

.item-custom-price-widget .summary .item-custom-price-info .discount-info, .item-custom-price-widget .summary .item-custom-price-info .extra-charge-info {
  grid-template-areas: "label . value";
  margin-bottom: 5px;
  display: grid;
}

.item-custom-price-widget .summary .item-custom-price-info .discount-info .label, .item-custom-price-widget .summary .item-custom-price-info .extra-charge-info .label {
  grid-area: label;
}

.item-custom-price-widget .summary .item-custom-price-info .discount-info .value, .item-custom-price-widget .summary .item-custom-price-info .extra-charge-info .value {
  text-align: right;
  grid-area: value;
}

.item-custom-price-widget .summary .new-price-info {
  color: #1c1d3e;
  border-top: 1px solid #d9d9e6;
  grid-area: new-price-info;
  justify-content: space-between;
  padding: 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  display: flex;
}

.item-custom-price-widget .add-customization {
  grid-gap: 20px;
  grid-template-areas: "confirm"
                       "cancel";
  display: grid;
}

.item-custom-price-widget .edit-customization {
  grid-gap: 20px 10px;
  grid-template-areas: "revert confirm"
                       "cancel cancel";
  display: grid;
}

.item-custom-price-widget .actions .confirm-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  height: 50px;
  background-color: #3f9c86;
  border-radius: 5px;
  grid-area: confirm;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.item-custom-price-widget .actions .confirm-button:focus {
  border: none;
  outline: none;
}

.item-custom-price-widget .actions .revert-button {
  grid-area: revert;
  color: #4cbba1;
  height: 50px;
  background-color: #0000;
  border: 1px solid #4cbba1;
  font-size: 17px;
  line-height: 23px;
}

.item-custom-price-widget .actions .cancel-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  height: 40px;
  background-color: #0000;
  grid-area: cancel;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 22px;
  transition: all .2s ease-in-out;
}

.item-custom-price-widget input::-webkit-outer-spin-button, .item-custom-price-widget input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.item-custom-price-widget input[type="number"] {
  -moz-appearance: textfield;
}

.item-comments-widget {
  min-width: 748px;
  z-index: 100;
  border-radius: 20px;
  padding: 60px 160px 20px;
}

.item-comments-widget .title {
  margin: 0;
}

.item-comments-widget .item-info {
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 23px;
  display: flex;
}

.item-comments-widget .item-info .name {
  color: #535a64;
  letter-spacing: .6px;
  font-weight: 600;
}

.item-comments-widget .item-info .price {
  color: #4cbba1;
  letter-spacing: .6px;
  font-weight: 600;
}

.item-comments-widget .comments-form {
  width: 100%;
}

.item-comments-widget .comments-form > form textarea {
  background-color: #f6f8fb;
  border: none;
  border-radius: 8px;
  padding-top: 14px;
  font-size: 16px;
}

.item-comments-widget .extra-charge-input {
  margin-top: 16px;
}

.item-comments-widget .extra-charge-input .label {
  color: #1c1d3e;
  margin-bottom: 6px;
}

.item-comments-widget .extra-charge-input .extra-charge-input-box {
  background-color: #f6f8fb;
  border-radius: 8px;
  grid-template-columns: min-content 1fr;
  padding: 12.75px 20px;
  display: grid;
}

.item-comments-widget .extra-charge-input .extra-charge-input-box .extra-charge-icon {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  padding-top: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.item-comments-widget .extra-charge-input .extra-charge-input-box .extra-charge-value {
  align-items: center;
  display: grid;
}

.item-comments-widget .extra-charge-input .extra-charge-input-box .extra-charge-value input {
  text-align: right;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
  line-height: 35px;
  overflow: hidden;
}

.item-comments-widget .actions {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.item-comments-widget .actions .with-input {
  display: flex;
}

.item-comments-widget .actions .with-input button.clear {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  border: 2px solid #4cbba1;
  border-radius: 8px;
  margin: 16px 10px 0 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.item-comments-widget .actions .with-input button.clear:focus {
  border: none;
  outline: none;
}

.item-comments-widget .actions .with-input button.clear span.label {
  color: #4cbba1;
}

.item-comments-widget .actions .with-input button.done {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  width: 100%;
  background-color: #3f9c86;
  border-radius: 8px;
  margin: 16px 0 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.item-comments-widget .actions .with-input button.done:focus {
  border: none;
  outline: none;
}

.item-comments-widget .actions button.done {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  width: 100%;
  background-color: #3f9c86;
  border-radius: 8px;
  margin: 16px 0 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.item-comments-widget .actions button.done:focus {
  border: none;
  outline: none;
}

.item-comments-widget .actions button.cancel {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  margin: 13px 0 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.item-comments-widget input::-webkit-outer-spin-button, .item-comments-widget input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.item-comments-widget input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes highlight {
  0%, 100% {
    background-color: #f59e0b00;
  }

  50% {
    background-color: #f59e0b80;
  }
}

.cart-item-widget {
  color: #fff;
  padding: 7px 12px;
  transition: all .2s ease-in-out;
  display: flex;
}

.cart-item-widget.highlight {
  animation-name: highlight;
  animation-duration: 2s;
}

.cart-item-widget > div:first-child {
  min-width: 40px;
}

.cart-item-widget > div:last-child {
  min-width: 24px;
}

.cart-item-widget > .quantity {
  width: 40px;
  height: 24px;
  text-align: center;
  cursor: pointer;
  background-color: #ffffff1a;
  border-radius: 4px;
  font-size: 15px;
  line-height: 24px;
}

.cart-item-widget > .more-actions-button {
  height: 24px;
  width: 24px;
  cursor: pointer;
  background-color: #ffffff1a;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cart-item-widget > .more-actions-button > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cart-item-widget > .more-actions-button > .icon > img {
  height: 100%;
}

.cart-item-widget > .cart-item {
  cursor: pointer;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 8px;
  display: flex;
}

.cart-item-widget > .cart-item > .header {
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.cart-item-widget > .cart-item > .header > .payment-status {
  color: #fff;
  background: #296a5bb3;
  border-radius: 4px;
  flex: 1;
  align-items: center;
  margin-bottom: 5px;
  padding: 3px 8px;
  font-size: 13px;
  line-height: 19px;
  display: flex;
}

.cart-item-widget > .cart-item > .header > .payment-status > .icon {
  height: 18px;
  width: 18px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.cart-item-widget > .cart-item > .header > .payment-status > .icon > img {
  height: 100%;
}

.cart-item-widget > .cart-item > .header > .price {
  color: #d97706;
  height: -moz-fit-content;
  height: fit-content;
  border-bottom: 1px solid #d9770640;
  margin-left: 8px;
}

.cart-item-widget > .cart-item > .header:not(:has(.hasPaidItem)) > .price {
  margin-left: 8px;
}

.cart-item-widget > .cart-item > .header:not(:has(.hasPaidItem)) > .name {
  color: #fff;
  top: 500px;
}

.cart-item-widget > .cart-item > .item-description-view-widget, .cart-item-widget > .cart-item > .name {
  color: #9597a7;
}

.cart-item-widget > .cart-item > .comments {
  color: #fff;
  white-space: pre-wrap;
  background-color: #4cbba140;
  border-radius: 4px;
  justify-content: space-between;
  margin-top: 8px;
  padding: 4px 8px;
  font-size: 13px;
  line-height: 19px;
  display: flex;
}

.cart-item-widget > .cart-item > .comments > .extra-charge {
  margin-left: 8px;
}

.pos-order-summary-widget {
  background-color: #f6f8fb;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
  padding: 30px;
  display: grid;
}

.pos-order-summary-widget .statistic {
  background-color: #fff;
  border-radius: 6px;
  padding: 16px 24px;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.pos-order-summary-widget .statistic .title {
  color: #7d7e92;
}

.pos-order-summary-widget .statistic .value {
  letter-spacing: 0;
  color: #1c1d3e;
  margin: 15px 0 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.pos-surcharge-presets {
  width: 500px;
  margin: 60px 160px;
  display: grid;
}

.pos-surcharge-presets .title {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 38px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
}

.pos-surcharge-presets .sub-title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin: 0 0 5px 5px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.pos-surcharge-presets .surcharges {
  max-height: 50vh;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  padding: 10px 5px 0;
  overflow-y: auto;
}

.pos-surcharge-presets .surcharges::-webkit-scrollbar {
  width: 1px;
}

.pos-surcharge-presets .surcharges::-webkit-scrollbar-track {
  background: none;
}

.pos-surcharge-presets .surcharges::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.pos-surcharge-presets .surcharges .public-holiday-surcharge {
  cursor: pointer;
  border-radius: 6px;
  flex-direction: column;
  padding: 16px 24px;
  display: flex;
  position: relative;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.pos-surcharge-presets .surcharges .public-holiday-surcharge.selected {
  background-color: #1c1d3e;
  position: relative;
}

.pos-surcharge-presets .surcharges .public-holiday-surcharge.selected .info {
  color: #93c5fd;
}

.pos-surcharge-presets .surcharges .public-holiday-surcharge.selected .value .amount, .pos-surcharge-presets .surcharges .public-holiday-surcharge.selected .value .label {
  color: #fff;
}

.pos-surcharge-presets .surcharges .public-holiday-surcharge.selected .check {
  height: 30px;
  width: 30px;
  background-image: url("icon-check.cf9e2e85.svg");
  background-repeat: no-repeat;
  background-size: 30px;
  position: absolute;
  top: -10px;
  right: -2px;
}

.pos-surcharge-presets .surcharges .public-holiday-surcharge .info {
  color: #2563eb;
  align-items: center;
  font-size: 10px;
  display: flex;
}

.pos-surcharge-presets .surcharges .public-holiday-surcharge .info .icon {
  width: 17px;
  height: 14px;
  margin-right: 4px;
}

.pos-surcharge-presets .surcharges .public-holiday-surcharge .info .label {
  line-height: 15px;
}

.pos-surcharge-presets .surcharges .public-holiday-surcharge .value {
  align-items: center;
  margin-top: 8px;
  font-size: 14px;
  display: flex;
}

.pos-surcharge-presets .surcharges .public-holiday-surcharge .value .amount {
  color: #4cbba1;
  margin-right: 12px;
  font-size: 14px;
  line-height: 21px;
}

.pos-surcharge-presets .surcharges .public-holiday-surcharge .value .label {
  color: #343553;
  font-weight: 600;
  line-height: 21px;
}

.pos-surcharge-presets .surcharges .list {
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  margin-bottom: 24px;
  display: grid;
}

.pos-surcharge-presets .surcharges .list .add-custom-surcharge {
  align-items: center;
  font-size: 30px;
}

.pos-surcharge-presets .surcharges .list .item {
  grid-gap: 8px;
  height: 100px;
  cursor: pointer;
  text-align: center;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  padding: 14px;
  display: grid;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.pos-surcharge-presets .surcharges .list .item .name {
  color: #343553;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}

.pos-surcharge-presets .surcharges .list .item .value {
  color: #4cbba1;
  font-size: 14px;
  line-height: 19px;
}

.pos-surcharge-presets .surcharges .list .item.selected {
  color: #fff;
  background: #1c1d3e;
  position: relative;
}

.pos-surcharge-presets .surcharges .list .item.selected .check {
  height: 30px;
  width: 30px;
  background-image: url("icon-check.cf9e2e85.svg");
  background-repeat: no-repeat;
  background-size: 30px;
  position: absolute;
  top: -10px;
  left: 130px;
}

.pos-surcharge-presets .surcharges .list .item.selected .name, .pos-surcharge-presets .surcharges .list .item.selected .value {
  color: #fff;
}

.pos-surcharge-presets .surcharges .divider {
  height: 1px;
  background-color: #dee0e6;
  margin: 20px 0;
}

.pos-surcharge-presets .actions {
  grid-template-columns: 1fr;
  column-gap: 16px;
  padding-top: 15px;
  display: grid;
}

.pos-surcharge-presets .actions.has-surcharge {
  grid-template-columns: 1fr 1fr;
}

.pos-surcharge-presets .actions button {
  border-radius: 4px;
  padding: 14px 32px;
}

.pos-surcharge-presets .actions > .clear-button {
  color: #4cbba1;
  color: #3f9c86;
  background-color: #0000;
  border: 1px solid #83d0be;
}

.pos-surcharge-presets .actions > .cancel-button {
  color: #7d7e92;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  grid-row-start: 1;
  grid-column-start: 1;
}

.pos-surcharge-user-defined {
  width: 430px;
  margin: 60px 160px;
}

.pos-surcharge-user-defined .title {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
}

.pos-surcharge-user-defined .form {
  margin-bottom: 24px;
}

.pos-surcharge-user-defined .form .toggle {
  box-sizing: border-box;
  background: #f6f8fb;
  border-radius: 7px;
  grid-template-columns: 1fr 1fr;
  margin: 24px 0;
  display: grid;
}

.pos-surcharge-user-defined .form .toggle .knob {
  letter-spacing: 0;
  cursor: pointer;
  text-align: center;
  color: #1c1d3e;
  align-items: center;
  padding: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  display: grid;
}

.pos-surcharge-user-defined .form .toggle .knob.selected {
  color: #fff;
  background: #1c1d3e;
  border-radius: 7px;
}

.pos-surcharge-user-defined .form .surcharge-input-box {
  border: 1px solid #ddd;
  border-radius: 8px;
  grid-template-columns: min-content 1fr;
  margin-bottom: 10px;
  padding: 12.75px 20px;
  display: grid;
}

.pos-surcharge-user-defined .form .surcharge-input-box .surcharge-type-icon {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  padding-top: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.pos-surcharge-user-defined .form .surcharge-input-box .surcharge-name, .pos-surcharge-user-defined .form .surcharge-input-box .surcharge-value {
  align-items: center;
  display: grid;
}

.pos-surcharge-user-defined .form .surcharge-input-box .surcharge-name input, .pos-surcharge-user-defined .form .surcharge-input-box .surcharge-value input {
  text-align: right;
  width: 100%;
  border: none;
  outline: none;
  font-size: 25px;
  overflow: hidden;
}

.pos-surcharge-user-defined .actions button {
  border-radius: 4px;
}

.pos-surcharge-user-defined .add-surcharge.actions {
  grid-gap: 10px;
  grid-template-areas: "confirm"
                       "back"
                       "cancel";
  display: grid;
}

.pos-surcharge-user-defined .edit-surcharge.actions {
  grid-gap: 10px;
  grid-template-areas: "clear confirm"
                       "back back"
                       "cancel cancel";
  display: grid;
}

.pos-surcharge-user-defined .confirm-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  grid-area: confirm;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.pos-surcharge-user-defined .confirm-button:focus {
  border: none;
  outline: none;
}

.pos-surcharge-user-defined .back-to-presets-button {
  color: #7d7e92;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  grid-area: back;
  padding: 14px 0;
  font-size: 17px;
}

.pos-surcharge-user-defined .clear-button {
  color: #4cbba1;
  color: #3f9c86;
  background-color: #0000;
  border: 1px solid #83d0be;
  grid-area: clear;
  font-size: 17px;
}

.pos-surcharge-user-defined .cancel-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  grid-area: cancel;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  text-decoration: underline;
  transition: all .2s ease-in-out;
}

.pos-surcharge-user-defined input::-webkit-outer-spin-button, .pos-surcharge-user-defined input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pos-surcharge-user-defined input[type="number"] {
  -moz-appearance: textfield;
}

.swal2-container .remove-surcharge-dialog .swal2-header .swal2-title {
  margin-top: 0 !important;
}

.swal2-container .remove-surcharge-dialog .swal2-content .swal2-html-container {
  color: #4c4e68;
}

.swal2-container .remove-surcharge-dialog .swal2-content .swal2-html-container .public-holiday-surcharge-text {
  font-weight: 600;
}

.swal2-container .remove-surcharge-dialog .swal2-actions .confirm-button {
  text-transform: inherit !important;
  background: #9f1239 !important;
  border: 1px solid #9f1239 !important;
  border-radius: 4px !important;
}

.swal2-container .remove-surcharge-dialog .swal2-actions .cancel-button {
  text-transform: inherit !important;
  border: 1px solid #7d7e92 !important;
  border-radius: 4px !important;
}

.pos-discount-presets {
  width: 500px;
  margin: 60px 160px;
  display: grid;
}

.pos-discount-presets .title {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 38px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
}

.pos-discount-presets .list {
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  margin-bottom: 24px;
  display: grid;
}

.pos-discount-presets .list .add-custom-discount {
  align-items: center;
  font-size: 30px;
}

.pos-discount-presets .list .item {
  grid-gap: 8px;
  height: 100px;
  cursor: pointer;
  text-align: center;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  padding: 14px;
  display: grid;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.pos-discount-presets .list .item .name {
  color: #343553;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}

.pos-discount-presets .list .item .value {
  color: #4cbba1;
  font-size: 14px;
  line-height: 19px;
}

.pos-discount-presets .list .item.preset-selected, .pos-discount-presets .list .item.loyalty-selected {
  color: #fff;
  background: #1c1d3e;
  position: relative;
}

.pos-discount-presets .list .item.preset-selected .check, .pos-discount-presets .list .item.loyalty-selected .check {
  height: 30px;
  width: 30px;
  background-image: url("icon-check.cf9e2e85.svg");
  background-repeat: no-repeat;
  background-size: 30px;
  position: absolute;
  top: -10px;
  left: 130px;
}

.pos-discount-presets .list .item.preset-selected .name, .pos-discount-presets .list .item.loyalty-selected .name, .pos-discount-presets .list .item.preset-selected .value, .pos-discount-presets .list .item.loyalty-selected .value {
  color: #fff;
}

.pos-discount-presets > .clear-button {
  color: #4cbba1;
  background-color: #0000;
  border: 1px solid #4cbba1;
  margin-bottom: 10px;
  padding: 15px;
  font-size: 17px;
}

.pos-discount-presets > .cancel-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.pos-discount-user-defined {
  width: 430px;
  margin: 60px 160px;
}

.pos-discount-user-defined .title {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
}

.pos-discount-user-defined .form {
  margin-bottom: 24px;
}

.pos-discount-user-defined .form .toggle {
  box-sizing: border-box;
  background: #f6f8fb;
  border-radius: 7px;
  grid-template-columns: 1fr 1fr;
  margin: 24px 0;
  display: grid;
}

.pos-discount-user-defined .form .toggle .knob {
  letter-spacing: 0;
  cursor: pointer;
  text-align: center;
  color: #1c1d3e;
  align-items: center;
  padding: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  display: grid;
}

.pos-discount-user-defined .form .toggle .knob.selected {
  color: #fff;
  background: #1c1d3e;
  border-radius: 7px;
}

.pos-discount-user-defined .form .discount-input-box {
  border: 1px solid #ddd;
  border-radius: 8px;
  grid-template-columns: min-content 1fr;
  margin-bottom: 10px;
  padding: 12.75px 20px;
  display: grid;
}

.pos-discount-user-defined .form .discount-input-box .discount-type-icon {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  padding-top: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.pos-discount-user-defined .form .discount-input-box .discount-name, .pos-discount-user-defined .form .discount-input-box .discount-value {
  align-items: center;
  display: grid;
}

.pos-discount-user-defined .form .discount-input-box .discount-name input, .pos-discount-user-defined .form .discount-input-box .discount-value input {
  text-align: right;
  width: 100%;
  border: none;
  outline: none;
  font-size: 25px;
  overflow: hidden;
}

.pos-discount-user-defined .form .valid.discount-input-box {
  border: 1px solid #4cbba1;
}

.pos-discount-user-defined .form .invalid.discount-input-box {
  border: 1px solid #da6565;
}

.pos-discount-user-defined .add-discount.actions {
  grid-gap: 10px;
  grid-template-areas: "confirm"
                       "back"
                       "cancel";
  display: grid;
}

.pos-discount-user-defined .edit-discount.actions {
  grid-gap: 10px;
  grid-template-areas: "clear confirm"
                       "back back"
                       "cancel cancel";
  display: grid;
}

.pos-discount-user-defined .confirm-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  grid-area: confirm;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.pos-discount-user-defined .confirm-button:focus {
  border: none;
  outline: none;
}

.pos-discount-user-defined .back-to-presets-button {
  color: #7d7e92;
  background-color: #0000;
  border: 1px solid #7d7e92;
  grid-area: back;
  padding: 14px 0;
  font-size: 17px;
}

.pos-discount-user-defined .clear-button {
  color: #4cbba1;
  background-color: #0000;
  border: 1px solid #4cbba1;
  grid-area: clear;
  font-size: 17px;
}

.pos-discount-user-defined .cancel-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  grid-area: cancel;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.pos-discount-user-defined input::-webkit-outer-spin-button, .pos-discount-user-defined input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pos-discount-user-defined input[type="number"] {
  -moz-appearance: textfield;
}

.menu-sidebar-widget {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  width: 17vw;
  background: #f6f8fb linear-gradient(141.27deg, #c3cee0 -6.33%, #f6f8fb 18.86%);
  grid-area: menu-sidebar-widget;
  padding: 12px 12px 12vh;
  overflow-y: scroll;
}

.menu-sidebar-widget::-webkit-scrollbar {
  width: 1px;
}

.menu-sidebar-widget::-webkit-scrollbar-track {
  background: none;
}

.menu-sidebar-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.menu-sidebar-widget .header {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-top: 12px;
  margin-bottom: 20px;
  padding-left: 4px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.menu-sidebar-widget .reorder-tab {
  color: #454545;
  letter-spacing: 0;
  color: #1c1d3e;
  width: 100%;
  cursor: pointer;
  letter-spacing: 0;
  text-align: left;
  color: #1c1d3e;
  background-color: #fff;
  background-image: url("icon-category.e1ace84b.svg");
  background-position: 94%;
  background-repeat: no-repeat;
  background-size: 8px;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 12px 14px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .3s ease-in-out;
  box-shadow: 0 0 8px -5px #0000004d;
}

.menu-sidebar-widget .reorder-tab.selected {
  margin-left: 10px;
}

.menu-sidebar-widget .reorder-tab .label {
  max-width: 93%;
}

.menu-sidebar-widget .reorder-tab.highlight {
  animation-name: highlight;
  animation-duration: 2s;
}

.menu-sidebar-widget .reorder-tab.hide {
  display: none;
}

.menu-sidebar-widget .category-tabs .category-tab {
  color: #454545;
  letter-spacing: 0;
  color: #1c1d3e;
  width: 100%;
  cursor: pointer;
  letter-spacing: 0;
  text-align: left;
  color: #1c1d3e;
  background-color: #fff;
  background-image: url("icon-category.e1ace84b.svg");
  background-position: 94%;
  background-repeat: no-repeat;
  background-size: 8px;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 12px 14px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .3s ease-in-out;
  box-shadow: 0 0 8px -5px #0000004d;
}

.menu-sidebar-widget .category-tabs .category-tab.selected {
  margin-left: 10px;
}

.menu-sidebar-widget .category-tabs .category-tab .label {
  max-width: 93%;
}

.menu-sidebar-widget .footer {
  text-align: center;
  color: #7d7e92;
  margin-top: 12px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

.menu-main-widget {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  -webkit-tap-highlight-color: transparent;
  overflow-y: auto;
}

.menu-main-widget::-webkit-scrollbar {
  width: 1px;
}

.menu-main-widget::-webkit-scrollbar-track {
  background: none;
}

.menu-main-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.menu-main-widget .spinner-container {
  width: inherit;
}

.requested-for-preset-selector-widget .presets {
  gap: 13px;
  display: grid;
}

.requested-for-preset-selector-widget .presets .preset {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #1c1d3e;
  cursor: pointer;
  background-color: #fff;
  background-image: url("icon-stopwatch.a076c2ad.svg");
  background-position: 92%;
  background-repeat: no-repeat;
  background-size: 22px;
  border-radius: 8px;
  justify-content: start;
  padding: 13px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  transition: all .2s ease-in-out;
}

.requested-for-preset-selector-widget .presets .preset.selected {
  color: #fff;
  background-color: #1c1d3e;
  background-image: url("icon-stopwatch-active.084ded0c.svg");
}

.requested-for-preset-selector-widget .presets .preset:first-child {
  background-image: url("icon-lightning.bbed1cbb.svg");
  background-size: 20px;
}

.requested-for-preset-selector-widget .presets .preset:first-child.selected {
  background-image: url("icon-lightning-active.d5564aca.svg");
}

.requested-for-preset-selector-widget .presets .preset:last-child {
  background-image: url("icon-datetime.8397a4a0.svg");
  background-position: 93%;
  background-size: 25px;
}

.requested-for-preset-selector-widget .presets .preset:last-child.selected {
  background-image: url("icon-datetime-active.7b20941a.svg");
}

@keyframes highlight-green {
  0%, 100% {
    background-color: #83d0be00;
  }

  50% {
    background-color: #83d0be80;
  }
}

.customer-info-edit-widget .pickup-form, .customer-info-edit-widget .delivery-form {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  overflow-y: scroll;
}

.customer-info-edit-widget .pickup-form::-webkit-scrollbar, .customer-info-edit-widget .delivery-form::-webkit-scrollbar {
  width: 1px;
}

.customer-info-edit-widget .pickup-form::-webkit-scrollbar-track, .customer-info-edit-widget .delivery-form::-webkit-scrollbar-track {
  background: none;
}

.customer-info-edit-widget .pickup-form::-webkit-scrollbar-thumb, .customer-info-edit-widget .delivery-form::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.customer-info-edit-widget .pickup-form .form-items, .customer-info-edit-widget .delivery-form .form-items {
  grid-template-columns: 300px 1fr 200px;
  gap: 0 16px;
  display: grid;
}

.customer-info-edit-widget .pickup-form .form-items .requested-for-selector, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector {
  grid-column: 1 / 4;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-top: 6px;
  margin-bottom: 16px;
  display: grid;
}

.customer-info-edit-widget .pickup-form .form-items .requested-for-selector .requested-for-asap, .customer-info-edit-widget .pickup-form .form-items .requested-for-selector .request-another-time, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .requested-for-asap, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .request-another-time {
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  box-shadow: 0 2px 3px #0003, 0 0 2px #0000001a;
}

.customer-info-edit-widget .pickup-form .form-items .requested-for-selector .requested-for-asap.selected, .customer-info-edit-widget .pickup-form .form-items .requested-for-selector .request-another-time.selected, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .requested-for-asap.selected, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .request-another-time.selected {
  background: #1c1d3e;
}

.customer-info-edit-widget .pickup-form .form-items .requested-for-selector .requested-for-asap.selected > .label, .customer-info-edit-widget .pickup-form .form-items .requested-for-selector .request-another-time.selected > .label, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .requested-for-asap.selected > .label, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .request-another-time.selected > .label {
  color: #fff;
}

.customer-info-edit-widget .pickup-form .form-items .requested-for-selector .requested-for-asap, .customer-info-edit-widget .pickup-form .form-items .requested-for-selector .request-another-time, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .requested-for-asap, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .request-another-time {
  position: relative;
}

.customer-info-edit-widget .pickup-form .form-items .requested-for-selector .requested-for-asap .selected-icon, .customer-info-edit-widget .pickup-form .form-items .requested-for-selector .request-another-time .selected-icon, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .requested-for-asap .selected-icon, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .request-another-time .selected-icon {
  z-index: 2;
  position: absolute;
  top: -6px;
  right: -8px;
}

.customer-info-edit-widget .pickup-form .form-items .requested-for-selector .request-another-time, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .request-another-time {
  cursor: pointer;
  z-index: 999;
  align-items: center;
  display: flex;
  position: relative;
}

.customer-info-edit-widget .pickup-form .form-items .requested-for-selector .request-another-time > .label, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .request-another-time > .label {
  text-align: center;
  flex-grow: 1;
}

.customer-info-edit-widget .pickup-form .form-items .requested-for-selector .request-another-time > .request-another-time-options, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .request-another-time > .request-another-time-options {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}

.customer-info-edit-widget .pickup-form .form-items .requested-for-selector .request-another-time > .request-another-time-options > .option, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .request-another-time > .request-another-time-options > .option {
  border-bottom: 1px solid #dee0e6;
  align-items: center;
  padding: 10px 16px;
  display: flex;
}

.customer-info-edit-widget .pickup-form .form-items .requested-for-selector .request-another-time > .request-another-time-options > .option img, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .request-another-time > .request-another-time-options > .option img {
  margin-right: 14px;
}

.customer-info-edit-widget .pickup-form .form-items .requested-for-selector .request-another-time > .request-another-time-options > .option:last-child, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .request-another-time > .request-another-time-options > .option:last-child {
  border-bottom: 0;
}

.customer-info-edit-widget .pickup-form .form-items .requested-for-selector .request-another-time > .request-another-time-options > .option.selected, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .request-another-time > .request-another-time-options > .option.selected {
  background-color: #f4f4f6;
  font-weight: 500;
}

.customer-info-edit-widget .pickup-form .form-items .requested-for-selector .request-another-time .drop-down-icon, .customer-info-edit-widget .delivery-form .form-items .requested-for-selector .request-another-time .drop-down-icon {
  position: absolute;
  right: 16px;
}

.customer-info-edit-widget .pickup-form .form-items .delivery-channel-selector, .customer-info-edit-widget .delivery-form .form-items .delivery-channel-selector {
  grid-column: 1 / 4;
  margin-bottom: 16px;
}

.customer-info-edit-widget .pickup-form .form-items .delivery-channel-selector > .label, .customer-info-edit-widget .delivery-form .form-items .delivery-channel-selector > .label {
  margin-bottom: 10px;
}

.customer-info-edit-widget .pickup-form .form-items .delivery-channel-selector .delivery-channels, .customer-info-edit-widget .delivery-form .form-items .delivery-channel-selector .delivery-channels {
  display: flex;
}

.customer-info-edit-widget .pickup-form .form-items .delivery-channel-selector .delivery-channels .delivery-channel, .customer-info-edit-widget .delivery-form .form-items .delivery-channel-selector .delivery-channels .delivery-channel {
  border: 2px solid #dee0e6;
  border-radius: 8px;
  align-items: center;
  margin-right: 18px;
  padding: 8px 14px;
  display: flex;
}

.customer-info-edit-widget .pickup-form .form-items .delivery-channel-selector .delivery-channels .delivery-channel img, .customer-info-edit-widget .delivery-form .form-items .delivery-channel-selector .delivery-channels .delivery-channel img {
  margin-right: 16px;
}

.customer-info-edit-widget .pickup-form .form-items .delivery-channel-selector .delivery-channels .delivery-channel > .label, .customer-info-edit-widget .delivery-form .form-items .delivery-channel-selector .delivery-channels .delivery-channel > .label {
  color: #1c1d3e;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.customer-info-edit-widget .pickup-form .form-items .delivery-channel-selector .delivery-channels .delivery-channel:last-child, .customer-info-edit-widget .delivery-form .form-items .delivery-channel-selector .delivery-channels .delivery-channel:last-child {
  margin-right: 0;
}

.customer-info-edit-widget .pickup-form .form-items .delivery-channel-selector .delivery-channels .delivery-channel.selected, .customer-info-edit-widget .delivery-form .form-items .delivery-channel-selector .delivery-channels .delivery-channel.selected {
  background: #ecf9f6;
  border: 2px solid #4cbba1;
  border-radius: 8px;
}

.customer-info-edit-widget .pickup-form .form-items .mobile-input, .customer-info-edit-widget .delivery-form .form-items .mobile-input {
  grid-column: 1;
  margin-bottom: 16px;
}

.customer-info-edit-widget .pickup-form .form-items .name-input, .customer-info-edit-widget .delivery-form .form-items .name-input {
  grid-column: 2 / 4;
  margin-bottom: 16px;
}

.customer-info-edit-widget .pickup-form .form-items > .customer-algolia-index-record-list-widget, .customer-info-edit-widget .delivery-form .form-items > .customer-algolia-index-record-list-widget {
  grid-area: unset;
  grid-column: 1 / 4;
  margin: 0 0 16px;
}

.customer-info-edit-widget .pickup-form .form-items > .customer-algolia-index-record-list-widget .spinner-container, .customer-info-edit-widget .delivery-form .form-items > .customer-algolia-index-record-list-widget .spinner-container {
  width: 100%;
  margin-top: 20px;
}

.customer-info-edit-widget .pickup-form .form-items > .customer-algolia-index-record-list-widget .suggested-customer-list, .customer-info-edit-widget .delivery-form .form-items > .customer-algolia-index-record-list-widget .suggested-customer-list {
  max-height: 18vh;
}

.customer-info-edit-widget .pickup-form .form-items .address-input, .customer-info-edit-widget .delivery-form .form-items .address-input {
  grid-column: 1 / 3;
  margin-bottom: 16px;
}

.customer-info-edit-widget .pickup-form .form-items .delivery-charge-input, .customer-info-edit-widget .delivery-form .form-items .delivery-charge-input {
  grid-column: 3 / 4;
  margin-bottom: 16px;
}

.customer-info-edit-widget .pickup-form .form-items .delivery-charge-input.uber-delivery-fee-applied, .customer-info-edit-widget .delivery-form .form-items .delivery-charge-input.uber-delivery-fee-applied {
  animation-name: highlight-green;
  animation-duration: 2s;
}

.customer-info-edit-widget .pickup-form .form-items .calculating-delivery-fee-label, .customer-info-edit-widget .delivery-form .form-items .calculating-delivery-fee-label {
  color: #7d7e92;
  grid-column: 3 / 4;
  font-size: 12px;
  position: relative;
  bottom: 14px;
}

.customer-info-edit-widget .pickup-form .form-items .dropoff-notes-input, .customer-info-edit-widget .delivery-form .form-items .dropoff-notes-input {
  grid-column: 1 / 4;
  margin-bottom: 16px;
}

.customer-info-edit-widget .pickup-form .marketing-campaign-widget, .customer-info-edit-widget .delivery-form .marketing-campaign-widget {
  grid-column: 1 / 4;
}

.customer-info-edit-widget .pickup-form .marketing-campaign-widget .description, .customer-info-edit-widget .delivery-form .marketing-campaign-widget .description {
  max-width: calc(75vw - 76px);
}

.customer-info-edit-widget .pickup-form .form-item, .customer-info-edit-widget .delivery-form .form-item {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 8px 12px;
}

.customer-info-edit-widget .pickup-form .form-item > label, .customer-info-edit-widget .pickup-form .form-item > .label, .customer-info-edit-widget .delivery-form .form-item > label, .customer-info-edit-widget .delivery-form .form-item > .label {
  color: #7d7e92;
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.customer-info-edit-widget .pickup-form .form-item > input, .customer-info-edit-widget .pickup-form .form-item > .value, .customer-info-edit-widget .pickup-form .form-item > .form-input > div > input, .customer-info-edit-widget .pickup-form .form-item textarea, .customer-info-edit-widget .delivery-form .form-item > input, .customer-info-edit-widget .delivery-form .form-item > .value, .customer-info-edit-widget .delivery-form .form-item > .form-input > div > input, .customer-info-edit-widget .delivery-form .form-item textarea {
  letter-spacing: .005em;
  color: #1c1d3e;
  border: none;
  border-radius: 0;
  padding: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.customer-info-edit-widget .pickup-form .form-item > input:focus, .customer-info-edit-widget .pickup-form .form-item > .value:focus, .customer-info-edit-widget .pickup-form .form-item > .form-input > div > input:focus, .customer-info-edit-widget .pickup-form .form-item textarea:focus, .customer-info-edit-widget .delivery-form .form-item > input:focus, .customer-info-edit-widget .delivery-form .form-item > .value:focus, .customer-info-edit-widget .delivery-form .form-item > .form-input > div > input:focus, .customer-info-edit-widget .delivery-form .form-item textarea:focus {
  outline: none;
}

.customer-info-edit-widget .pickup-form .form-item > input::placeholder, .customer-info-edit-widget .pickup-form .form-item > .value::placeholder, .customer-info-edit-widget .pickup-form .form-item > .form-input > div > input::placeholder, .customer-info-edit-widget .pickup-form .form-item textarea::placeholder, .customer-info-edit-widget .delivery-form .form-item > input::placeholder, .customer-info-edit-widget .delivery-form .form-item > .value::placeholder, .customer-info-edit-widget .delivery-form .form-item > .form-input > div > input::placeholder, .customer-info-edit-widget .delivery-form .form-item textarea::placeholder {
  color: #65667d;
}

.customer-info-edit-widget .pickup-form .form-item.delivery-charge-input, .customer-info-edit-widget .delivery-form .form-item.delivery-charge-input {
  grid-template-areas: "label spinner"
                       "value spinner";
  display: grid;
}

.customer-info-edit-widget .pickup-form .form-item.delivery-charge-input .label, .customer-info-edit-widget .delivery-form .form-item.delivery-charge-input .label {
  grid-area: label;
}

.customer-info-edit-widget .pickup-form .form-item.delivery-charge-input .value, .customer-info-edit-widget .delivery-form .form-item.delivery-charge-input .value {
  letter-spacing: .0025em;
  grid-area: value;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.customer-info-edit-widget .pickup-form .form-item.delivery-charge-input .spinner-container, .customer-info-edit-widget .delivery-form .form-item.delivery-charge-input .spinner-container {
  grid-area: spinner;
}

.customer-info-edit-widget .pickup-form .form-item.delivery-charge-input .spinner-container .spinner, .customer-info-edit-widget .pickup-form .form-item.delivery-charge-input .spinner-container .spinner > img, .customer-info-edit-widget .delivery-form .form-item.delivery-charge-input .spinner-container .spinner, .customer-info-edit-widget .delivery-form .form-item.delivery-charge-input .spinner-container .spinner > img {
  width: 15px;
  height: 15px;
  font-size: unset;
}

.dine-in-customer-info-edit-widget {
  width: 75vw;
  z-index: 100;
}

.dine-in-customer-info-edit-widget > .header {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 10px;
  padding: 22px 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.dine-in-customer-info-edit-widget > .form {
  padding: 0 24px 40px;
}

.dine-in-customer-info-edit-widget > .form .form-items {
  display: flex;
}

.dine-in-customer-info-edit-widget > .form .form-items .form-input {
  margin-top: 6px;
}

.dine-in-customer-info-edit-widget > .form .form-items > .mobile-number-input {
  width: 100%;
}

.dine-in-customer-info-edit-widget > .form .form-items > .name-input {
  width: 100%;
  margin-left: 16px;
}

.dine-in-customer-info-edit-widget > .form > .customer-algolia-index-record-list-widget {
  max-height: 39vh;
  margin-top: 18px;
}

.dine-in-customer-info-edit-widget > .form > .customer-algolia-index-record-list-widget .suggested-customer-list {
  max-height: 30vh;
}

.dine-in-customer-info-edit-widget > .actions {
  width: 100%;
  padding: 18px;
  display: flex;
}

.dine-in-customer-info-edit-widget > .actions > .cancel-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  width: 100%;
  background-color: #0000;
  border: 1px solid #7d7e92;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.dine-in-customer-info-edit-widget > .actions > .done-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  width: 100%;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0 0 0 18px;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.dine-in-customer-info-edit-widget > .actions > .done-button:focus {
  border: none;
  outline: none;
}

.collection-time-schedule-edit-widget > .heading {
  margin: 30px 0;
}

.collection-time-schedule-edit-widget .pos-date {
  color: #4c4e68;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  grid-template-columns: max-content 1fr;
  grid-auto-flow: column;
  gap: 10px;
  padding: 10px 14px;
  display: grid;
}

.collection-time-schedule-edit-widget .pos-date > i {
  opacity: .6;
  justify-self: end;
  font-size: 24px;
}

.collection-time-schedule-edit-widget .pos-date .name {
  color: #343553;
}

.collection-time-schedule-edit-widget .pos-time {
  color: #4c4e68;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  grid-template-columns: max-content 1fr;
  grid-auto-flow: column;
  gap: 10px;
  padding: 10px 14px;
  display: grid;
}

.collection-time-schedule-edit-widget .pos-time > i {
  opacity: .6;
  justify-self: end;
  font-size: 24px;
}

.collection-time-schedule-edit-widget .pos-time .name {
  color: #343553;
}

.incoming-online-orders-list-widget {
  flex-direction: column;
  display: flex;
  overflow-y: hidden;
}

.incoming-online-orders-list-widget .wrapper {
  align-items: center;
  padding: 20px 20px 0;
  display: flex;
}

.incoming-online-orders-list-widget .wrapper .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin: 0;
  padding: 0 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.incoming-online-orders-list-widget .wrapper .incoming-online-orders-count {
  color: #65667d;
  font-size: 14px;
}

.incoming-online-orders-list-widget .incoming-online-orders-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.incoming-online-orders-list-widget .incoming-online-orders-container {
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin: 20px 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.incoming-online-orders-list-widget .incoming-online-orders-container .incoming-online-orders {
  gap: 15px;
  margin: 0 20px;
  padding: 0 10px 10px;
  display: grid;
}

.incoming-online-orders-list-widget .incoming-online-orders-container .no-incoming-online-orders {
  text-align: center;
  margin-top: 30vh;
  font-size: 20px;
  line-height: 26px;
}

.incoming-uber-eats-orders-from-selected-store-list-widget {
  flex-direction: column;
  display: flex;
  overflow-y: hidden;
}

.incoming-uber-eats-orders-from-selected-store-list-widget .wrapper {
  align-items: center;
  padding: 20px 20px 0;
  display: flex;
}

.incoming-uber-eats-orders-from-selected-store-list-widget .wrapper .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin: 0;
  padding: 0 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.incoming-uber-eats-orders-from-selected-store-list-widget .wrapper .incoming-uber-eats-orders-count {
  color: #65667d;
  font-size: 14px;
}

.incoming-uber-eats-orders-from-selected-store-list-widget .incoming-uber-eats-orders-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.incoming-uber-eats-orders-from-selected-store-list-widget .incoming-uber-eats-orders-container {
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin: 20px 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.incoming-uber-eats-orders-from-selected-store-list-widget .incoming-uber-eats-orders-container .incoming-uber-eats-orders {
  gap: 15px;
  margin: 0 20px;
  padding: 0 10px 10px;
  display: grid;
}

.incoming-uber-eats-orders-from-selected-store-list-widget .incoming-uber-eats-orders-container .no-incoming-uber-eats-orders {
  text-align: center;
  margin-top: 30vh;
  font-size: 20px;
  line-height: 26px;
}

.incoming-online-orders-list-item-widget {
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  grid-template-columns: min-content 1fr;
  align-items: center;
  display: grid;
  position: relative;
  box-shadow: 0 3px 10px -3px #0000004d;
}

.incoming-online-orders-list-item-widget .order-data {
  cursor: pointer;
  grid-template-columns: min-content 1fr 1fr min-content;
  grid-auto-flow: column;
  align-items: center;
  gap: 1%;
  padding: 13px 25px 13px 0;
  display: grid;
}

.incoming-online-orders-list-item-widget .order-data .incoming-orders-list-item-logo {
  display: grid;
}

.incoming-online-orders-list-item-widget .order-data .incoming-orders-list-item-logo .uber-eats-logo {
  align-self: center;
}

.incoming-online-orders-list-item-widget .order-data .incoming-orders-list-item-logo > .optimized-image {
  align-self: center;
  display: grid;
}

.incoming-online-orders-list-item-widget .order-data .incoming-orders-list-item-logo > .optimized-image > img {
  border-radius: 50%;
  width: 40px !important;
}

.incoming-online-orders-list-item-widget .order-data .incoming-orders-list-item-customer-info .customer-name {
  color: #1c1d3e;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.incoming-online-orders-list-item-widget .order-data .incoming-orders-list-item-customer-info .customer-phone-number, .incoming-online-orders-list-item-widget .order-data .incoming-orders-list-item-customer-info .customer-delivery-address {
  color: #4c4e68;
  font-size: 14px;
  line-height: 21px;
}

.incoming-online-orders-list-item-widget .order-data .incoming-orders-list-item-collection-info .collection-type {
  color: #1c1d3e;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.incoming-online-orders-list-item-widget .order-data .incoming-orders-list-item-collection-info .collection-type > span {
  color: #7d7e92;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.incoming-online-orders-list-item-widget .order-data .incoming-orders-list-item-collection-info .estimated-pickup-time {
  color: #1e40af;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.incoming-online-orders-list-item-widget .order-data .incoming-orders-list-item-collection-info .requested-for {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.incoming-online-orders-list-item-widget .order-data .incoming-orders-list-item-collection-info .time-since-label-widget, .incoming-online-orders-list-item-widget .order-data .incoming-orders-list-item-collection-info .requested-for-label {
  color: #7d7e92;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.incoming-online-orders-list-item-widget .order-data .orders-list-item-grand-total {
  font-size: 18px;
}

.incoming-online-orders-list-item-widget .incoming-orders-list-item-store-name {
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 4px;
  margin-bottom: 3px;
  padding: 4px 12px;
}

.incoming-online-orders-list-item-widget .incoming-orders-list-item-store-name .store-info {
  color: #4c4e68;
  padding-left: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: inline;
}

.eta-selector-widget {
  grid-template-columns: 20px 1fr 20px;
  grid-template-areas: "title title title"
                       "etas etas etas"
                       "back-btn back-btn back-btn";
  padding: 50px;
  display: grid;
}

.eta-selector-widget .title {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  grid-area: title;
  margin-bottom: 40px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.eta-selector-widget .etas {
  grid-area: etas;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  display: grid;
}

.eta-selector-widget .etas .eta {
  letter-spacing: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 25px 35px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  box-shadow: 0 3px 10px -3px #0000004d;
}

.eta-selector-widget .cancel-btn {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  grid-area: back-btn;
  margin-top: 40px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.estimated-prep-complete-iso-date-time-selector-widget {
  width: 750px;
  height: 500px;
  grid-template-rows: min-content 1fr min-content;
  grid-template-areas: "header header header"
                       "body body body"
                       "actions actions actions";
  gap: 15px 0;
  padding: 24px 10px;
  display: grid;
}

.estimated-prep-complete-iso-date-time-selector-widget .header {
  grid-area: header;
  grid-template-columns: 1fr min-content;
  grid-auto-flow: column;
  align-items: center;
  margin: 0 15px;
  display: grid;
}

.estimated-prep-complete-iso-date-time-selector-widget .header .requested-for-and-date-time-container {
  flex-flow: column;
  align-items: center;
  margin-left: 36px;
  display: flex;
}

.estimated-prep-complete-iso-date-time-selector-widget .header .requested-for-and-date-time-container .requested-for-label {
  width: -moz-fit-content;
  width: fit-content;
  justify-self: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  display: grid;
}

.estimated-prep-complete-iso-date-time-selector-widget .header .requested-for-and-date-time-container .requested-for-label.asap, .estimated-prep-complete-iso-date-time-selector-widget .header .requested-for-and-date-time-container .requested-for-label.today {
  color: #9f1239;
  background-color: #ffe4e6;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.estimated-prep-complete-iso-date-time-selector-widget .header .requested-for-and-date-time-container .requested-for-label.future-order {
  color: #047857;
  background-color: #ecfdf5;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.estimated-prep-complete-iso-date-time-selector-widget .header .requested-for-and-date-time-container .date-and-time-label {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  grid-auto-flow: column;
  gap: 6px;
  margin: 10px 0 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  display: grid;
}

.estimated-prep-complete-iso-date-time-selector-widget .header .requested-for-and-date-time-container .date-and-time-label > span {
  font-weight: 100;
}

.estimated-prep-complete-iso-date-time-selector-widget .body {
  height: 100%;
  grid-area: body;
  align-self: center;
  padding: 0 15px;
  display: grid;
  overflow-x: hidden;
  overflow-y: auto;
}

.estimated-prep-complete-iso-date-time-selector-widget .subtitle {
  letter-spacing: 0;
  color: #1c1d3e;
  border-top: 1px solid #d9d9e6;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  display: grid;
}

.estimated-prep-complete-iso-date-time-selector-widget .empty-label {
  text-align: center;
  padding: 60px 0;
}

.estimated-prep-complete-iso-date-time-selector-widget .initial-options-for-non-asap-order {
  align-self: center;
  gap: 30px;
  display: grid;
}

.estimated-prep-complete-iso-date-time-selector-widget .initial-options-for-non-asap-order > .option {
  color: #1c1d3e;
  border: 1px solid #d9d9e6;
  border-radius: 10px;
  grid-template-columns: 1fr 100px;
  align-items: center;
  gap: 10px;
  padding: 25px 13px;
  display: grid;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.estimated-prep-complete-iso-date-time-selector-widget .initial-options-for-non-asap-order > .option .label {
  letter-spacing: 0;
  align-items: center;
  gap: 5px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  display: grid;
}

.estimated-prep-complete-iso-date-time-selector-widget .initial-options-for-non-asap-order > .option > button > span {
  font-size: 14px !important;
}

.estimated-prep-complete-iso-date-time-selector-widget .initial-options-for-non-asap-order .recommended-time-of-day {
  border: 1px solid #4cbba1;
}

.estimated-prep-complete-iso-date-time-selector-widget .initial-options-for-non-asap-order .recommended-time-of-day > .label {
  grid-template-columns: repeat(3, max-content) 1fr;
  grid-auto-flow: column;
}

.estimated-prep-complete-iso-date-time-selector-widget .initial-options-for-non-asap-order .recommended-time-of-day > .label .icon {
  margin-right: 3px;
}

.estimated-prep-complete-iso-date-time-selector-widget .initial-options-for-non-asap-order .recommended-time-of-day > .label .recommended-pick-up-time-label {
  font-weight: 700;
}

.estimated-prep-complete-iso-date-time-selector-widget .initial-options-for-non-asap-order .recommended-time-of-day > .label .recommended-label {
  text-align: end;
  color: #4cbba1;
  margin-left: 10px;
  font-size: 14px;
}

.estimated-prep-complete-iso-date-time-selector-widget .prep-time-list {
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  padding: 10px 5px;
  display: grid;
  overflow: auto;
}

.estimated-prep-complete-iso-date-time-selector-widget .prep-time-list .prep-time {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 25px 35px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  position: relative;
  box-shadow: 0 3px 10px -3px #0000004d;
}

.estimated-prep-complete-iso-date-time-selector-widget .prep-time-list .prep-time.selected {
  color: #fff;
  background: linear-gradient(#2c2d56 0%, #0c0d34 100%);
}

.estimated-prep-complete-iso-date-time-selector-widget .prep-time-list .prep-time.selected:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: #4cbba1;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
}

.estimated-prep-complete-iso-date-time-selector-widget .prep-time-list .prep-time.selected:after {
  content: "";
  width: 5px;
  height: 10px;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  display: block;
  position: absolute;
  top: -3px;
  right: 0;
  transform: rotate(45deg);
}

.estimated-prep-complete-iso-date-time-selector-widget .actions {
  height: 50px;
  grid-area: actions;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-auto-flow: column;
  gap: 20px;
  margin: 0 14px;
  display: grid;
}

.estimated-prep-complete-iso-date-time-selector-widget .actions .back-button {
  letter-spacing: 0;
  color: #7d7e92;
  background-color: #fff;
  border: 1px solid #adafbc;
  border-radius: 5px;
  flex: 1;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.estimated-prep-complete-iso-date-time-selector-widget .actions .confirm-button {
  letter-spacing: 0;
  background-color: #3f9c86;
  border-radius: 5px;
  flex: 1;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.pos-order-list-widget {
  margin-bottom: 10px;
}

.pos-order-list-widget .order-count {
  padding: 20px 30px 0;
}

.pos-order-list-widget .no-orders {
  text-align: center;
  margin-top: 20vh;
}

.pos-order-list-widget .orders-container {
  margin: 20px 0;
}

.pos-order-list-widget .orders-container .orders {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-auto-rows: min-content;
  gap: 15px;
  margin: 0 20px;
  padding: 0 10px 10px;
  display: grid;
}

.pos-order-list-widget .orders-container .orders::-webkit-scrollbar {
  width: 1px;
}

.pos-order-list-widget .orders-container .orders::-webkit-scrollbar-track {
  background: none;
}

.pos-order-list-widget .orders-container .orders::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.pos-customers.list-widget .pos-customers.list {
  margin: 20px 30px;
}

.pos-customers.list-widget .pos-customers.list .item {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 10px 0;
  box-shadow: 0 3px 10px -3px #0000004d;
}

.pos-customers.list-widget button.more {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #4cbba1;
  border-radius: 5px;
  margin: 20px auto;
  padding: 15px 70px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
}

.pos-customers.list-widget button.more:focus {
  border: 1px solid #4cbba1;
}

.pos-customer-list-item-widget {
  color: #1c1d3e;
  cursor: pointer;
  grid-template-columns: 1fr 1fr 1fr 3fr min-content;
  grid-auto-flow: column;
  gap: 15px;
  padding: 13px 20px;
  display: grid;
}

.pos-customer-list-item-widget .name {
  font-weight: 600;
}

.pos-customer-view-widget {
  color: #1c1d3e;
  grid-template-rows: min-content min-content min-content 1fr;
  padding: 24px 14px;
  display: grid;
  overflow: auto;
}

.pos-customer-view-widget .basic-info {
  border-radius: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "name name actions"
                       "other-info other-info other-info";
  align-items: center;
  gap: 6px 24px;
  margin: 0 10px;
  padding: 24px;
  display: grid;
  box-shadow: 0 0 3px #2e2f4d0d, 0 10px 25px #59587b26;
}

.pos-customer-view-widget .basic-info img {
  width: 17px;
}

.pos-customer-view-widget .basic-info .name {
  grid-area: name;
  font-size: 25px;
  font-weight: 600;
}

.pos-customer-view-widget .basic-info .other-info {
  grid-area: other-info;
}

.pos-customer-view-widget .basic-info .other-info .customer-phone-and-email {
  flex-wrap: wrap;
  display: flex;
}

.pos-customer-view-widget .basic-info .other-info .customer-phone-and-email .phone {
  padding-right: 50px;
}

.pos-customer-view-widget .basic-info .other-info .customer-phone-and-email .user-icon {
  margin-right: 8px;
}

.pos-customer-view-widget .basic-info .other-info .customer-notes {
  text-indent: -1.6em;
  text-align: justify;
  margin: 10px 0 0 25px;
  padding-right: 5rem;
}

.pos-customer-view-widget .basic-info .other-info .customer-notes .user-icon {
  vertical-align: top;
  margin-right: 8px;
}

.pos-customer-view-widget .basic-info .actions {
  grid-area: actions;
}

.pos-customer-view-widget .basic-info .actions button {
  color: #7d7e92;
  background-color: #0000;
  border: 1px solid #7d7e92;
  padding: 10px 70px;
}

.pos-customer-view-widget .order-history-text {
  margin: 38px 10px 0;
  font-size: 20px;
  font-weight: 600;
}

.pos-customer-view-widget .order-category-selector {
  border: 1px solid #1c1d3e;
  border-radius: 10px;
  grid-template-rows: min-content;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 24px 10px;
  display: grid;
}

.pos-customer-view-widget .order-category-selector .order-category {
  text-align: center;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 10px;
  list-style: none;
}

.pos-customer-view-widget .order-category-selector .order-category.active {
  color: #fff;
  background-color: #1c1d3e;
}

.pos-customer-view-widget .order-category-selector .order-category:nth-child(2) {
  border-radius: 0 10px 10px 0;
}

.dine-in-info-widget .dine-in-info, .dine-in-info-widget .no-dine-in-info {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-left: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.dine-in-info-widget {
  cursor: pointer;
  border-bottom: 1px solid #343553;
  align-items: center;
  padding-bottom: 8px;
  display: flex;
}

.dine-in-info-widget .icon {
  height: 16px;
  width: 16px;
  justify-content: center;
  align-items: center;
  padding: 0 7px 0 13px;
  display: flex;
}

.dine-in-info-widget .icon > img {
  height: 100%;
}

.dine-in-info-widget .no-dine-in-info {
  color: #ffffff80;
}

.dine-in-info-widget .dine-in-info {
  color: #fff;
}

.table-selector-widget {
  max-width: 1000px;
  width: 80vw;
  padding: 40px 60px;
}

.table-presets-widget .title {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 40px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.table-presets-widget .tables-container {
  max-height: 50vh;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #0000;
  background-image: linear-gradient(#fff 30%, #fff0), linear-gradient(#fff0, #fff 70%), radial-gradient(farthest-side at 50% 0, #0003, #0000), radial-gradient(farthest-side at 50% 100%, #0003, #0000);
  background-position: 0 0, 0 100%, 0 0, 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 10px, 100% 10px;
  background-attachment: local, local, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box, border-box;
  margin-bottom: 40px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.table-presets-widget .tables-container::-webkit-scrollbar {
  width: 1px;
}

.table-presets-widget .tables-container::-webkit-scrollbar-track {
  background: none;
}

.table-presets-widget .tables-container::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.table-presets-widget .tables-container .tables {
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-rows: 1fr;
  gap: 10px;
  padding: 4px;
  display: grid;
}

.table-presets-widget .tables-container .tables .table, .table-presets-widget .tables-container .tables .add-table {
  cursor: pointer;
  text-align: center;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  grid-auto-flow: row;
  place-items: center;
  padding: 20px 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  display: grid;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.table-presets-widget .tables-container .tables .table.taken, .table-presets-widget .tables-container .tables .add-table.taken {
  background: #fef3c7;
}

.table-presets-widget .tables-container .tables .table.taken .label, .table-presets-widget .tables-container .tables .add-table.taken .label {
  margin-top: 4px;
}

.table-presets-widget .tables-container .tables .add-table {
  justify-content: center;
  display: flex;
}

.table-presets-widget .tables-container .tables .add-table .icon {
  width: 21px;
  height: 21px;
}

.table-presets-widget .actions {
  justify-items: center;
  gap: 16px;
  display: grid;
}

.table-presets-widget .actions button {
  width: 430px;
}

.table-presets-widget .actions .skip-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.table-presets-widget .actions .skip-button:focus {
  border: none;
  outline: none;
}

.table-presets-widget .actions .cancel-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-size: 15px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.table-presets-widget .actions .continue-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.table-presets-widget .actions .continue-button:focus {
  border: none;
  outline: none;
}

.table-custom-edit-widget {
  padding: 60px 160px;
}

.table-custom-edit-widget .title {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 38px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.table-custom-edit-widget form {
  margin-bottom: 24px;
}

.table-custom-edit-widget .actions {
  gap: 10px;
  display: grid;
}

.table-custom-edit-widget .actions .confirm-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.table-custom-edit-widget .actions .confirm-button:focus {
  border: none;
  outline: none;
}

.table-custom-edit-widget .actions .back-button, .table-custom-edit-widget .actions .skip-button, .table-custom-edit-widget .actions .continue-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.table-custom-edit-widget .actions .cancel-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.online-order-list-widget {
  margin-bottom: 10px;
}

.online-order-list-widget .order-count {
  padding: 20px 30px 0;
}

.online-order-list-widget .no-orders {
  text-align: center;
  margin-top: 20vh;
}

.online-order-list-widget .orders-container {
  margin: 20px 0;
}

.online-order-list-widget .orders-container .orders {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-auto-rows: min-content;
  gap: 15px;
  margin: 0 20px;
  padding: 0 10px 10px;
  display: grid;
}

.online-order-list-widget .orders-container .orders::-webkit-scrollbar {
  width: 1px;
}

.online-order-list-widget .orders-container .orders::-webkit-scrollbar-track {
  background: none;
}

.online-order-list-widget .orders-container .orders::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.website-order-list-widget {
  margin-bottom: 10px;
}

.website-order-list-widget .order-count {
  padding: 20px 30px 0;
}

.website-order-list-widget .no-orders {
  text-align: center;
  margin-top: 20vh;
}

.website-order-list-widget .orders-container {
  margin: 20px 0;
}

.website-order-list-widget .orders-container .orders {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-auto-rows: min-content;
  gap: 15px;
  margin: 0 20px;
  padding: 0 10px 10px;
  display: grid;
}

.website-order-list-widget .orders-container .orders::-webkit-scrollbar {
  width: 1px;
}

.website-order-list-widget .orders-container .orders::-webkit-scrollbar-track {
  background: none;
}

.website-order-list-widget .orders-container .orders::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.google-order-list-item-widget {
  height: -moz-fit-content;
  height: fit-content;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  grid-template-columns: 1fr min-content;
  align-items: center;
  padding: 13px 20px;
  display: grid;
  box-shadow: 0 3px 10px -3px #0000004d;
}

.google-order-list-item-widget .order-data {
  grid-template-columns: 20% 15% 10% 20% 10% 20%;
  grid-auto-flow: column;
  align-items: center;
  gap: 1%;
  display: grid;
}

.google-order-list-item-widget .orders-list-item-customer-info {
  min-width: 0;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "order-logo customer-name"
                       "order-logo customer-phone-number";
  gap: 0 10px;
  display: grid;
}

.google-order-list-item-widget .orders-list-item-customer-info .order-logo {
  grid-area: order-logo;
  display: grid;
}

.google-order-list-item-widget .orders-list-item-customer-info .order-logo .google-logo {
  align-self: center;
  width: 28px !important;
}

.google-order-list-item-widget .orders-list-item-customer-info .order-logo > .optimized-image {
  align-self: center;
  display: grid;
}

.google-order-list-item-widget .orders-list-item-customer-info .order-logo > .optimized-image > img {
  border-radius: 50%;
  width: 28px !important;
}

.google-order-list-item-widget .orders-list-item-customer-info .customer-name {
  color: #1c1d3e;
  text-overflow: ellipsis;
  grid-area: customer-name;
  overflow: hidden;
}

.google-order-list-item-widget .orders-list-item-customer-info .customer-phone-number {
  color: #595976;
  grid-area: customer-phone-number;
  font-size: 14px;
}

.google-order-list-item-widget .orders-list-item-collection-info .requested-for {
  color: #e11d48;
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
}

.google-order-list-item-widget .orders-list-item-grand-total, .google-order-list-item-widget .orders-list-item-docket-number {
  text-align: left;
  margin: 0;
  font-size: 18px;
}

.google-order-list-item-widget .orders-list-item-order-status {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 30px;
  padding: 5px 15px;
  font-weight: 600;
}

.google-order-list-item-widget .orders-list-item-order-status.to-be-confirmed {
  color: #d97706;
  background-color: #fef3c7;
}

.google-order-list-item-widget .orders-list-item-order-status.accepted, .google-order-list-item-widget .orders-list-item-order-status.in-preparation, .google-order-list-item-widget .orders-list-item-order-status.ready-for-pickup, .google-order-list-item-widget .orders-list-item-order-status.in-transit, .google-order-list-item-widget .orders-list-item-order-status.completed {
  color: #065f46;
  background-color: #d1fae5;
}

.google-order-list-item-widget .orders-list-item-order-status.cancelled, .google-order-list-item-widget .orders-list-item-order-status.rejected {
  color: #9f1239;
  background-color: #ffe4e6;
}

.google-order-list-item-widget .actions {
  cursor: pointer;
  gap: 20px;
  display: flex;
}

.google-order-list-item-widget .orders-list-item-print-order-button {
  opacity: .8;
  justify-self: end;
  padding: 0 10px;
}

.google-order-list-item-widget .edit-icon {
  margin-left: auto;
}

.pos-order-list-item-widget {
  height: -moz-fit-content;
  height: fit-content;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  grid-template-columns: 1fr min-content;
  align-items: center;
  padding: 13px 20px;
  display: grid;
  position: relative;
  box-shadow: 0 3px 10px -3px #0000004d;
}

.pos-order-list-item-widget .order-data {
  grid-template-columns: 20% 15% 10% 20% 10% 20%;
  grid-auto-flow: column;
  align-items: center;
  gap: 1%;
  display: grid;
}

.pos-order-list-item-widget .order-data .customer-info {
  padding: 0 10px;
}

.pos-order-list-item-widget .order-data .customer-info .name {
  color: #1c1d3e;
}

.pos-order-list-item-widget .order-data .customer-info .mobile {
  color: #595976;
  font-size: 14px;
}

.pos-order-list-item-widget .order-data .collection-info .collection-type {
  color: #1c1d3e;
  margin: 0;
}

.pos-order-list-item-widget .order-data .collection-info .preset-label {
  color: #e11d48;
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
}

.pos-order-list-item-widget .order-data .grand-total {
  text-align: left;
  margin: 0;
  font-size: 18px;
}

.pos-order-list-item-widget .order-data .status {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 30px;
  padding: 5px 15px;
  font-weight: 600;
}

.pos-order-list-item-widget .order-data .status.processing {
  color: #d97706;
  background-color: #fef3c7;
}

.pos-order-list-item-widget .order-data .status.cancelled {
  color: #9f1239;
  background-color: #ffe4e6;
}

.pos-order-list-item-widget .order-data .status.success {
  color: #065f46;
  background-color: #d1fae5;
}

.pos-order-list-item-widget .order-data .docket-number {
  text-align: left;
  margin: 0;
  font-size: 18px;
}

.pos-order-list-item-widget .order-data .placed-iso-datetime {
  margin: 0 auto;
  font-size: 13px;
  line-height: 20px;
}

.pos-order-list-item-widget .actions {
  cursor: pointer;
  gap: 20px;
  display: flex;
}

.pos-order-list-item-widget .actions .print-icon {
  opacity: .8;
  justify-self: end;
  padding: 0 10px;
}

.pos-order-list-item-widget .actions .print-icon.disabled {
  opacity: .4;
  cursor: not-allowed;
}

.website-order-list-item-widget {
  height: -moz-fit-content;
  height: fit-content;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  grid-template-columns: 1fr min-content;
  align-items: center;
  padding: 13px 20px;
  display: grid;
  position: relative;
  box-shadow: 0 3px 10px -3px #0000004d;
}

.website-order-list-item-widget .order-data {
  grid-template-columns: 20% 15% 10% 20% 10% 20%;
  grid-auto-flow: column;
  align-items: center;
  gap: 1%;
  display: grid;
}

.website-order-list-item-widget .order-data .customer-info {
  padding: 0 10px;
}

.website-order-list-item-widget .order-data .customer-info .name {
  color: #1c1d3e;
}

.website-order-list-item-widget .order-data .customer-info .mobile {
  color: #595976;
  font-size: 14px;
}

.website-order-list-item-widget .order-data .collection-info .collection-type {
  color: #1c1d3e;
  margin: 0;
}

.website-order-list-item-widget .order-data .collection-info .preset-label {
  color: #e11d48;
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
}

.website-order-list-item-widget .order-data .grand-total {
  text-align: left;
  margin: 0;
  font-size: 18px;
}

.website-order-list-item-widget .order-data .status {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 30px;
  padding: 5px 15px;
  font-weight: 600;
}

.website-order-list-item-widget .order-data .status.processing {
  color: #d97706;
  background-color: #fef3c7;
}

.website-order-list-item-widget .order-data .status.cancelled {
  color: #9f1239;
  background-color: #ffe4e6;
}

.website-order-list-item-widget .order-data .status.success {
  color: #065f46;
  background-color: #d1fae5;
}

.website-order-list-item-widget .order-data .docket-number {
  text-align: left;
  margin: 0;
  font-size: 18px;
}

.website-order-list-item-widget .order-data .placed-iso-datetime {
  margin: 0 auto;
  font-size: 13px;
  line-height: 20px;
}

.website-order-list-item-widget .actions {
  cursor: pointer;
  gap: 20px;
  display: flex;
}

.website-order-list-item-widget .actions .print-icon {
  opacity: .8;
  justify-self: end;
  padding: 0 10px;
}

.website-order-list-item-widget .actions .print-icon.disabled {
  opacity: .4;
  cursor: not-allowed;
}

.reverse-last-transaction > .swal2-title, .cancel-uber-direct-order-prompt > .swal2-title {
  margin-top: 0 !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 26px !important;
}

.reverse-last-transaction > .swal2-content, .cancel-uber-direct-order-prompt > .swal2-content {
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
}

.reverse-last-transaction > .swal2-actions, .cancel-uber-direct-order-prompt > .swal2-actions {
  padding-top: 20px !important;
}

.reverse-last-transaction > .swal2-actions > button, .cancel-uber-direct-order-prompt > .swal2-actions > button {
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.reverse-last-transaction > .swal2-actions > .confirm-button, .cancel-uber-direct-order-prompt > .swal2-actions > .confirm-button {
  background-color: #9f1239 !important;
  border-radius: 5px !important;
}

.reverse-last-transaction > .swal2-actions > .cancel-button, .cancel-uber-direct-order-prompt > .swal2-actions > .cancel-button {
  border: 1px solid #7d7e92;
  color: #7d7e92 !important;
}

.pos.order-view-widget {
  height: 90vh;
  width: 90vw;
  max-width: 1200px;
  color: #1c1d3e;
  display: flex;
}

.pos.order-view-widget .order-view-main-panel-widget {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  flex-direction: column;
  flex: 1;
  padding: 24px 32px;
  display: flex;
  overflow-y: auto;
}

.pos.order-view-widget .order-view-main-panel-widget::-webkit-scrollbar {
  width: 1px;
}

.pos.order-view-widget .order-view-main-panel-widget::-webkit-scrollbar-track {
  background: none;
}

.pos.order-view-widget .order-view-main-panel-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.pos.order-view-widget .order-view-main-panel-widget > * {
  margin-top: 30px;
}

.pos.order-view-widget .order-view-main-panel-widget > :first-child {
  margin-top: 0;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget {
  grid-template-columns: max-content max-content 1fr;
  grid-template-areas: "collection-type order-source requested-for"
                       "order-id order-id requested-for";
  align-items: center;
  display: grid;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .collection-type {
  grid-area: collection-type;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .order-source {
  white-space: nowrap;
  height: -moz-fit-content;
  height: fit-content;
  color: #4c4e68;
  border: 1px solid #d3d3d9;
  border-radius: 99px;
  grid-area: order-source;
  margin-left: 10px;
  padding: 3px 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for {
  grid-area: requested-for;
  align-self: flex-start;
  margin-left: auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for .schedule > .label {
  margin: auto;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for .date-time.schedule > .value {
  color: #047857;
  background-color: #ecfdf5;
  border-radius: 100px;
  margin-left: 10px;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for .asap.schedule > .value, .pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for .eta.schedule > .value {
  color: #9f1239;
  background-color: #ffe4e6;
  border-radius: 100px;
  margin-left: 10px;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .time-since-label-widget {
  color: #4c4e68;
  text-align: right;
  margin-top: 4px;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .time-since-label-widget:first-letter {
  text-transform: uppercase;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .order-id {
  color: #4c4e68;
  letter-spacing: .035px;
  grid-area: order-id;
  margin-top: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .order-id span {
  text-transform: uppercase;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header {
  align-items: center;
  display: flex;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .icon > img {
  height: 100%;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .label {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .missing-items {
  color: #d97706;
  place-items: center;
  margin-left: 10px;
  display: flex;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .missing-items:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #d97706;
  border-radius: 9999px;
  margin-right: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: block;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > * {
  border-top: 1px solid #dee0e6;
  margin-top: 12px;
  padding-top: 12px;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > :first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget {
  color: #4c4e68;
  border-top: 1px solid #dee0e6;
  margin-top: 16px;
  padding-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget .amount {
  justify-content: space-between;
  margin-top: 2px;
  display: flex;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget .grand-total {
  border-top: 1px solid #dee0e6;
  margin: 12px 0;
  padding-top: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget .grand-total .value {
  color: #3f9c86;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget .gst-amount {
  font-weight: 500;
}

.pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .tyro-payment-summary-widget, .pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .linkly-payment-summary-widget, .pos.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .anz-worldline-payment-summary-widget {
  margin-top: 16px;
}

.pos.order-view-widget .order-view-info-panel-widget {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #f6f8fb;
  border-radius: 0 24px 24px 0;
  flex-direction: column;
  flex-basis: 30%;
  padding: 23px;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
}

.pos.order-view-widget .order-view-info-panel-widget::-webkit-scrollbar {
  width: 1px;
}

.pos.order-view-widget .order-view-info-panel-widget::-webkit-scrollbar-track {
  background: none;
}

.pos.order-view-widget .order-view-info-panel-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.pos.order-view-widget .order-view-info-panel-widget > * {
  margin-top: 24px;
}

.pos.order-view-widget .order-view-info-panel-widget > :first-child {
  margin-top: 0;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header {
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: 1fr auto;
  grid-template-areas: "title close-button";
  display: grid;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title {
  grid-area: title;
  grid-template-columns: repeat(2, max-content);
  grid-template-areas: "failed-status-tag failed-status-tag"
                       "currency-icon title-text";
  display: grid;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title .failed-status-tag {
  color: #f43f5e;
  grid-area: failed-status-tag;
  align-items: center;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title .failed-status-tag:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #f43f5e;
  border-radius: 100%;
  margin-right: 8px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title .currency-icon {
  width: 16px;
  height: 16px;
  color: #fff;
  text-align: center;
  background-color: #7d7e92;
  border-radius: 16px;
  grid-area: currency-icon;
  margin-top: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title .text {
  grid-area: title-text;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .close-button {
  grid-area: close-button;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .grand-total {
  margin-top: 8px;
  font-size: 31px;
  font-weight: 500;
  line-height: 38px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info {
  align-items: center;
  margin-top: 8px;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info .paid {
  color: #fff;
  background-color: #059669;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info .not-paid {
  color: #fff;
  background-color: #f43f5e;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info .refunded {
  color: #1c1d3e;
  background-color: #f59e0b;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info .bullet {
  margin: 0 4px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .tax-invoice-number {
  width: max-content;
  color: #4c4e68;
  border: 1px solid #d3d3d9;
  border-radius: 4px;
  margin-top: 16px;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.pos.order-view-widget .order-view-info-panel-widget .info-container {
  overflow-y: scroll;
}

.pos.order-view-widget .order-view-info-panel-widget .info-container .label {
  color: #7d7e92;
}

@media (max-height: 752px) {
  .pos.order-view-widget .order-view-info-panel-widget .info-container {
    scrollbar-width: thin;
    scrollbar-color: auto;
  }

  .pos.order-view-widget .order-view-info-panel-widget .info-container::-webkit-scrollbar {
    width: auto;
  }

  .pos.order-view-widget .order-view-info-panel-widget .info-container::-webkit-scrollbar-track {
    background: auto;
  }

  .pos.order-view-widget .order-view-info-panel-widget .info-container::-webkit-scrollbar-thumb {
    background-color: auto;
  }
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header {
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .icon > img {
  height: 100%;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .chevron > img {
  height: 100%;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info {
  display: none;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info.visible {
  display: block;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info > .key-value-pair {
  grid-template-columns: .5fr 1fr;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info > .key-value-pair > .text {
  color: #7d7e92;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header {
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .icon > img {
  height: 100%;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .chevron > img {
  height: 100%;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info {
  display: none;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info.visible {
  display: block;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair {
  gap: 12px;
  align-items: unset;
  grid-template-columns: .5fr 1fr;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair > .text {
  color: #7d7e92;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair.uber-eats .value {
  color: #7d7e92;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .dropoff-notes {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #343553;
  background: linear-gradient(#f7f9fc 0%, #fff 100%), #f6f8fb;
  border-radius: 8px;
  margin-top: 8px;
  padding: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  display: block;
  box-shadow: 0 2px 1px #0000000d, 0 0 1px #00000040;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .dropoff-notes > div {
  font-weight: 600;
  display: inline;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header {
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .icon > img {
  height: 100%;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .chevron > img {
  height: 100%;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info {
  display: none;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info.visible {
  display: block;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info > .key-value-pair {
  grid-template-columns: .5fr 1fr;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info > .key-value-pair > .text {
  color: #7d7e92;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header {
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .icon > img {
  height: 100%;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .chevron > img {
  height: 100%;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header) {
  grid-template-columns: .5fr 1fr;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: none;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header):nth-child(2) {
  margin-top: 10px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header).visible {
  display: grid;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header) > .label {
  color: #7d7e92;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header) > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header .icon {
  margin-top: 2px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-details {
  align-items: center;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-image {
  width: 100%;
  border-radius: 50%;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-name {
  color: #1c1d3e;
  font-size: 16px;
  font-weight: 500;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-vehicle {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 400;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-contact-number {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header {
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .icon > img {
  height: 100%;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .chevron > img {
  height: 100%;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .additional-info {
  letter-spacing: .048px;
  color: #059669;
  align-self: center;
  margin-left: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .additional-info.ARRIVED_AT_PICKUP {
  color: #3b82f6;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .additional-info.EN_ROUTE_TO_DROPOFF {
  color: #d97706;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .additional-info.FAILED {
  color: #f43f5e;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header .icon {
  margin-top: 2px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info {
  display: none;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info.visible {
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info {
  align-items: center;
  margin-top: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .icon {
  height: 74px;
  width: 74px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .icon > img {
  height: 100%;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .icon img {
  border-radius: 50%;
  margin-right: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .details .driver-name {
  color: #1c1d3e;
  font-size: 16px;
  font-weight: 500;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .details .vehicle-info {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 400;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .details .driver-contact-number {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status {
  display: none;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status.visible {
  display: block;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair {
  grid-template-columns: .5fr 1fr;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair:first-child {
  margin-top: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair > .text {
  color: #7d7e92;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair .text {
  color: #7d7e92;
  letter-spacing: .25px;
  font-size: 14px;
  line-height: 21px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair > .value {
  letter-spacing: .25px;
  color: #059669;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair.ARRIVED_AT_PICKUP > .value {
  color: #3b82f6;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair.EN_ROUTE_TO_DROPOFF > .value {
  color: #d97706;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair.FAILED > .value {
  color: #f43f5e;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header {
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .icon > img {
  height: 100%;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .chevron > img {
  height: 100%;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info {
  display: none;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info.visible {
  display: block;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info > .key-value-pair {
  gap: 12px;
  align-items: unset;
  grid-template-columns: .5fr 1fr;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info > .key-value-pair > .text {
  color: #7d7e92;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header {
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .icon > img {
  height: 100%;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .chevron > img {
  height: 100%;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info {
  display: none;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info.visible {
  display: block;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info > .key-value-pair {
  grid-template-columns: .5fr 1fr;
  align-items: flex-start;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info > .key-value-pair > .text {
  color: #7d7e92;
}

.pos.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.pos.order-view-widget .pos-order-view-actions-widget {
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  margin-top: auto;
  display: grid;
}

.pos.order-view-widget .pos-order-view-actions-widget.refundable:not(.cancelled) > .print-order-button {
  grid-column: 1 / 2;
}

.pos.order-view-widget .pos-order-view-actions-widget.refundable:not(.cancelled) > .refund-order-button {
  grid-column: 2 / 2;
}

.pos.order-view-widget .pos-order-view-actions-widget.cancelled > .refund-order-button {
  grid-row-start: 1;
  grid-column-start: 1;
}

.pos.order-view-widget .pos-order-view-actions-widget > * {
  width: 100%;
  border: 1px solid;
  border-radius: 4px;
  grid-column: 1 / -1;
  margin-top: 16px;
  padding: 14px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.pos.order-view-widget .pos-order-view-actions-widget > .accept-order-button, .pos.order-view-widget .pos-order-view-actions-widget > .make-payment-button {
  color: #fff;
  background-color: #3f9c86;
  border-color: #3f9c86;
}

.pos.order-view-widget .pos-order-view-actions-widget > .edit-order-button {
  color: #1c1d3e;
  background-color: #dee0e6;
  border-color: #dee0e6;
}

.pos.order-view-widget .pos-order-view-actions-widget > .print-order-button, .pos.order-view-widget .pos-order-view-actions-widget > .refund-order-button {
  color: #7d7e92;
  background-color: #0000;
  border-color: #7d7e92;
}

.pos.order-view-widget .pos-order-view-actions-widget > .reject-order-button, .pos.order-view-widget .pos-order-view-actions-widget > .cancel-order-button {
  color: #9f1239;
  background-color: #0000;
  border-color: #0000;
  text-decoration: underline;
}

.pos.order-view-widget .pos-order-view-actions-widget > .reverse-transaction-button {
  color: #1c1d3e;
  background-color: #dee0e6;
  border-color: #dee0e6;
}

.pos.order-view-widget.loading {
  display: block;
}

.website.order-view-widget {
  height: 90vh;
  width: 90vw;
  max-width: 1200px;
  color: #1c1d3e;
  display: flex;
}

.website.order-view-widget .order-view-main-panel-widget {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  flex-direction: column;
  flex: 1;
  padding: 24px 32px;
  display: flex;
  overflow-y: auto;
}

.website.order-view-widget .order-view-main-panel-widget::-webkit-scrollbar {
  width: 1px;
}

.website.order-view-widget .order-view-main-panel-widget::-webkit-scrollbar-track {
  background: none;
}

.website.order-view-widget .order-view-main-panel-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.website.order-view-widget .order-view-main-panel-widget > * {
  margin-top: 30px;
}

.website.order-view-widget .order-view-main-panel-widget > :first-child {
  margin-top: 0;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget {
  grid-template-columns: max-content max-content 1fr;
  grid-template-areas: "collection-type order-source requested-for"
                       "order-id order-id requested-for";
  align-items: center;
  display: grid;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .collection-type {
  grid-area: collection-type;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .order-source {
  white-space: nowrap;
  height: -moz-fit-content;
  height: fit-content;
  color: #4c4e68;
  border: 1px solid #d3d3d9;
  border-radius: 99px;
  grid-area: order-source;
  margin-left: 10px;
  padding: 3px 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for {
  grid-area: requested-for;
  align-self: flex-start;
  margin-left: auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for .schedule > .label {
  margin: auto;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for .date-time.schedule > .value {
  color: #047857;
  background-color: #ecfdf5;
  border-radius: 100px;
  margin-left: 10px;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for .asap.schedule > .value, .website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for .eta.schedule > .value {
  color: #9f1239;
  background-color: #ffe4e6;
  border-radius: 100px;
  margin-left: 10px;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .time-since-label-widget {
  color: #4c4e68;
  text-align: right;
  margin-top: 4px;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .time-since-label-widget:first-letter {
  text-transform: uppercase;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .order-id {
  color: #4c4e68;
  letter-spacing: .035px;
  grid-area: order-id;
  margin-top: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .order-id span {
  text-transform: uppercase;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header {
  align-items: center;
  display: flex;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .icon > img {
  height: 100%;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .label {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .missing-items {
  color: #d97706;
  place-items: center;
  margin-left: 10px;
  display: flex;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .missing-items:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #d97706;
  border-radius: 9999px;
  margin-right: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: block;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > * {
  border-top: 1px solid #dee0e6;
  margin-top: 12px;
  padding-top: 12px;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > :first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget {
  color: #4c4e68;
  border-top: 1px solid #dee0e6;
  margin-top: 16px;
  padding-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget .amount {
  justify-content: space-between;
  margin-top: 2px;
  display: flex;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget .grand-total {
  border-top: 1px solid #dee0e6;
  margin: 12px 0;
  padding-top: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget .grand-total .value {
  color: #3f9c86;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget .gst-amount {
  font-weight: 500;
}

.website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .tyro-payment-summary-widget, .website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .linkly-payment-summary-widget, .website.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .anz-worldline-payment-summary-widget {
  margin-top: 16px;
}

.website.order-view-widget .order-view-info-panel-widget {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #f6f8fb;
  border-radius: 0 24px 24px 0;
  flex-direction: column;
  flex-basis: 30%;
  padding: 23px;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
}

.website.order-view-widget .order-view-info-panel-widget::-webkit-scrollbar {
  width: 1px;
}

.website.order-view-widget .order-view-info-panel-widget::-webkit-scrollbar-track {
  background: none;
}

.website.order-view-widget .order-view-info-panel-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.website.order-view-widget .order-view-info-panel-widget > * {
  margin-top: 24px;
}

.website.order-view-widget .order-view-info-panel-widget > :first-child {
  margin-top: 0;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header {
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: 1fr auto;
  grid-template-areas: "title close-button";
  display: grid;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title {
  grid-area: title;
  grid-template-columns: repeat(2, max-content);
  grid-template-areas: "failed-status-tag failed-status-tag"
                       "currency-icon title-text";
  display: grid;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title .failed-status-tag {
  color: #f43f5e;
  grid-area: failed-status-tag;
  align-items: center;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title .failed-status-tag:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #f43f5e;
  border-radius: 100%;
  margin-right: 8px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title .currency-icon {
  width: 16px;
  height: 16px;
  color: #fff;
  text-align: center;
  background-color: #7d7e92;
  border-radius: 16px;
  grid-area: currency-icon;
  margin-top: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title .text {
  grid-area: title-text;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .close-button {
  grid-area: close-button;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .grand-total {
  margin-top: 8px;
  font-size: 31px;
  font-weight: 500;
  line-height: 38px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info {
  align-items: center;
  margin-top: 8px;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info .paid {
  color: #fff;
  background-color: #059669;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info .not-paid {
  color: #fff;
  background-color: #f43f5e;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info .refunded {
  color: #1c1d3e;
  background-color: #f59e0b;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info .bullet {
  margin: 0 4px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .tax-invoice-number {
  width: max-content;
  color: #4c4e68;
  border: 1px solid #d3d3d9;
  border-radius: 4px;
  margin-top: 16px;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.website.order-view-widget .order-view-info-panel-widget .info-container {
  overflow-y: scroll;
}

.website.order-view-widget .order-view-info-panel-widget .info-container .label {
  color: #7d7e92;
}

@media (max-height: 752px) {
  .website.order-view-widget .order-view-info-panel-widget .info-container {
    scrollbar-width: thin;
    scrollbar-color: auto;
  }

  .website.order-view-widget .order-view-info-panel-widget .info-container::-webkit-scrollbar {
    width: auto;
  }

  .website.order-view-widget .order-view-info-panel-widget .info-container::-webkit-scrollbar-track {
    background: auto;
  }

  .website.order-view-widget .order-view-info-panel-widget .info-container::-webkit-scrollbar-thumb {
    background-color: auto;
  }
}

.website.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header {
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .icon > img {
  height: 100%;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .chevron > img {
  height: 100%;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info {
  display: none;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info.visible {
  display: block;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info > .key-value-pair {
  grid-template-columns: .5fr 1fr;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info > .key-value-pair > .text {
  color: #7d7e92;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header {
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .icon > img {
  height: 100%;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .chevron > img {
  height: 100%;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info {
  display: none;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info.visible {
  display: block;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair {
  gap: 12px;
  align-items: unset;
  grid-template-columns: .5fr 1fr;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair > .text {
  color: #7d7e92;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair.uber-eats .value {
  color: #7d7e92;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .dropoff-notes {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #343553;
  background: linear-gradient(#f7f9fc 0%, #fff 100%), #f6f8fb;
  border-radius: 8px;
  margin-top: 8px;
  padding: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  display: block;
  box-shadow: 0 2px 1px #0000000d, 0 0 1px #00000040;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .dropoff-notes > div {
  font-weight: 600;
  display: inline;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header {
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .icon > img {
  height: 100%;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .chevron > img {
  height: 100%;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info {
  display: none;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info.visible {
  display: block;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info > .key-value-pair {
  grid-template-columns: .5fr 1fr;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info > .key-value-pair > .text {
  color: #7d7e92;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header {
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .icon > img {
  height: 100%;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .chevron > img {
  height: 100%;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header) {
  grid-template-columns: .5fr 1fr;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: none;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header):nth-child(2) {
  margin-top: 10px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header).visible {
  display: grid;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header) > .label {
  color: #7d7e92;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header) > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header .icon {
  margin-top: 2px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-details {
  align-items: center;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-image {
  width: 100%;
  border-radius: 50%;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-name {
  color: #1c1d3e;
  font-size: 16px;
  font-weight: 500;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-vehicle {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 400;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-contact-number {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header {
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .icon > img {
  height: 100%;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .chevron > img {
  height: 100%;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .additional-info {
  letter-spacing: .048px;
  color: #059669;
  align-self: center;
  margin-left: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .additional-info.ARRIVED_AT_PICKUP {
  color: #3b82f6;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .additional-info.EN_ROUTE_TO_DROPOFF {
  color: #d97706;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .additional-info.FAILED {
  color: #f43f5e;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header .icon {
  margin-top: 2px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info {
  display: none;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info.visible {
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info {
  align-items: center;
  margin-top: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .icon {
  height: 74px;
  width: 74px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .icon > img {
  height: 100%;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .icon img {
  border-radius: 50%;
  margin-right: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .details .driver-name {
  color: #1c1d3e;
  font-size: 16px;
  font-weight: 500;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .details .vehicle-info {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 400;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .details .driver-contact-number {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status {
  display: none;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status.visible {
  display: block;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair {
  grid-template-columns: .5fr 1fr;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair:first-child {
  margin-top: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair > .text {
  color: #7d7e92;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair .text {
  color: #7d7e92;
  letter-spacing: .25px;
  font-size: 14px;
  line-height: 21px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair > .value {
  letter-spacing: .25px;
  color: #059669;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair.ARRIVED_AT_PICKUP > .value {
  color: #3b82f6;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair.EN_ROUTE_TO_DROPOFF > .value {
  color: #d97706;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair.FAILED > .value {
  color: #f43f5e;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header {
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .icon > img {
  height: 100%;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .chevron > img {
  height: 100%;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info {
  display: none;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info.visible {
  display: block;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info > .key-value-pair {
  gap: 12px;
  align-items: unset;
  grid-template-columns: .5fr 1fr;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info > .key-value-pair > .text {
  color: #7d7e92;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header {
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .icon > img {
  height: 100%;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .chevron > img {
  height: 100%;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info {
  display: none;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info.visible {
  display: block;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info > .key-value-pair {
  grid-template-columns: .5fr 1fr;
  align-items: flex-start;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info > .key-value-pair > .text {
  color: #7d7e92;
}

.website.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.website.order-view-widget .website-order-view-actions-widget {
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  margin-top: auto;
  display: grid;
}

.website.order-view-widget .website-order-view-actions-widget.refundable:not(.cancelled) > .print-order-button {
  grid-column: 1 / 2;
}

.website.order-view-widget .website-order-view-actions-widget.refundable:not(.cancelled) > .refund-order-button {
  grid-column: 2 / 2;
}

.website.order-view-widget .website-order-view-actions-widget.cancelled > .refund-order-button {
  grid-row-start: 1;
  grid-column-start: 1;
}

.website.order-view-widget .website-order-view-actions-widget > * {
  width: 100%;
  border: 1px solid;
  border-radius: 4px;
  grid-column: 1 / -1;
  margin-top: 16px;
  padding: 14px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.website.order-view-widget .website-order-view-actions-widget > .accept-order-button, .website.order-view-widget .website-order-view-actions-widget > .make-payment-button {
  color: #fff;
  background-color: #3f9c86;
  border-color: #3f9c86;
}

.website.order-view-widget .website-order-view-actions-widget > .edit-order-button {
  color: #1c1d3e;
  background-color: #dee0e6;
  border-color: #dee0e6;
}

.website.order-view-widget .website-order-view-actions-widget > .print-order-button, .website.order-view-widget .website-order-view-actions-widget > .refund-order-button {
  color: #7d7e92;
  background-color: #0000;
  border-color: #7d7e92;
}

.website.order-view-widget .website-order-view-actions-widget > .reject-order-button, .website.order-view-widget .website-order-view-actions-widget > .cancel-order-button {
  color: #9f1239;
  background-color: #0000;
  border-color: #0000;
  text-decoration: underline;
}

.website.order-view-widget .website-order-view-actions-widget > .reverse-transaction-button {
  color: #1c1d3e;
  background-color: #dee0e6;
  border-color: #dee0e6;
}

.website.order-view-widget.loading {
  display: block;
}

.google.order-view-widget {
  height: 90vh;
  width: 90vw;
  max-width: 1200px;
  color: #1c1d3e;
  display: flex;
}

.google.order-view-widget .order-view-main-panel-widget {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  flex-direction: column;
  flex: 1;
  padding: 24px 32px;
  display: flex;
  overflow-y: auto;
}

.google.order-view-widget .order-view-main-panel-widget::-webkit-scrollbar {
  width: 1px;
}

.google.order-view-widget .order-view-main-panel-widget::-webkit-scrollbar-track {
  background: none;
}

.google.order-view-widget .order-view-main-panel-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.google.order-view-widget .order-view-main-panel-widget > * {
  margin-top: 30px;
}

.google.order-view-widget .order-view-main-panel-widget > :first-child {
  margin-top: 0;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget {
  grid-template-columns: max-content max-content 1fr;
  grid-template-areas: "collection-type order-source requested-for"
                       "order-id order-id requested-for";
  align-items: center;
  display: grid;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .collection-type {
  grid-area: collection-type;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .order-source {
  white-space: nowrap;
  height: -moz-fit-content;
  height: fit-content;
  color: #4c4e68;
  border: 1px solid #d3d3d9;
  border-radius: 99px;
  grid-area: order-source;
  margin-left: 10px;
  padding: 3px 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for {
  grid-area: requested-for;
  align-self: flex-start;
  margin-left: auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for .schedule > .label {
  margin: auto;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for .date-time.schedule > .value {
  color: #047857;
  background-color: #ecfdf5;
  border-radius: 100px;
  margin-left: 10px;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for .asap.schedule > .value, .google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for .eta.schedule > .value {
  color: #9f1239;
  background-color: #ffe4e6;
  border-radius: 100px;
  margin-left: 10px;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .time-since-label-widget {
  color: #4c4e68;
  text-align: right;
  margin-top: 4px;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .time-since-label-widget:first-letter {
  text-transform: uppercase;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .order-id {
  color: #4c4e68;
  letter-spacing: .035px;
  grid-area: order-id;
  margin-top: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .order-id span {
  text-transform: uppercase;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header {
  align-items: center;
  display: flex;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .icon > img {
  height: 100%;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .label {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .missing-items {
  color: #d97706;
  place-items: center;
  margin-left: 10px;
  display: flex;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .missing-items:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #d97706;
  border-radius: 9999px;
  margin-right: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: block;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > * {
  border-top: 1px solid #dee0e6;
  margin-top: 12px;
  padding-top: 12px;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > :first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget {
  color: #4c4e68;
  border-top: 1px solid #dee0e6;
  margin-top: 16px;
  padding-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget .amount {
  justify-content: space-between;
  margin-top: 2px;
  display: flex;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget .grand-total {
  border-top: 1px solid #dee0e6;
  margin: 12px 0;
  padding-top: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget .grand-total .value {
  color: #3f9c86;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget .gst-amount {
  font-weight: 500;
}

.google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .tyro-payment-summary-widget, .google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .linkly-payment-summary-widget, .google.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .anz-worldline-payment-summary-widget {
  margin-top: 16px;
}

.google.order-view-widget .order-view-info-panel-widget {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #f6f8fb;
  border-radius: 0 24px 24px 0;
  flex-direction: column;
  flex-basis: 30%;
  padding: 23px;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
}

.google.order-view-widget .order-view-info-panel-widget::-webkit-scrollbar {
  width: 1px;
}

.google.order-view-widget .order-view-info-panel-widget::-webkit-scrollbar-track {
  background: none;
}

.google.order-view-widget .order-view-info-panel-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.google.order-view-widget .order-view-info-panel-widget > * {
  margin-top: 24px;
}

.google.order-view-widget .order-view-info-panel-widget > :first-child {
  margin-top: 0;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header {
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: 1fr auto;
  grid-template-areas: "title close-button";
  display: grid;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title {
  grid-area: title;
  grid-template-columns: repeat(2, max-content);
  grid-template-areas: "failed-status-tag failed-status-tag"
                       "currency-icon title-text";
  display: grid;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title .failed-status-tag {
  color: #f43f5e;
  grid-area: failed-status-tag;
  align-items: center;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title .failed-status-tag:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #f43f5e;
  border-radius: 100%;
  margin-right: 8px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title .currency-icon {
  width: 16px;
  height: 16px;
  color: #fff;
  text-align: center;
  background-color: #7d7e92;
  border-radius: 16px;
  grid-area: currency-icon;
  margin-top: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title .text {
  grid-area: title-text;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .close-button {
  grid-area: close-button;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .grand-total {
  margin-top: 8px;
  font-size: 31px;
  font-weight: 500;
  line-height: 38px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info {
  align-items: center;
  margin-top: 8px;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info .paid {
  color: #fff;
  background-color: #059669;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info .not-paid {
  color: #fff;
  background-color: #f43f5e;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info .refunded {
  color: #1c1d3e;
  background-color: #f59e0b;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info .bullet {
  margin: 0 4px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .tax-invoice-number {
  width: max-content;
  color: #4c4e68;
  border: 1px solid #d3d3d9;
  border-radius: 4px;
  margin-top: 16px;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.google.order-view-widget .order-view-info-panel-widget .info-container {
  overflow-y: scroll;
}

.google.order-view-widget .order-view-info-panel-widget .info-container .label {
  color: #7d7e92;
}

@media (max-height: 752px) {
  .google.order-view-widget .order-view-info-panel-widget .info-container {
    scrollbar-width: thin;
    scrollbar-color: auto;
  }

  .google.order-view-widget .order-view-info-panel-widget .info-container::-webkit-scrollbar {
    width: auto;
  }

  .google.order-view-widget .order-view-info-panel-widget .info-container::-webkit-scrollbar-track {
    background: auto;
  }

  .google.order-view-widget .order-view-info-panel-widget .info-container::-webkit-scrollbar-thumb {
    background-color: auto;
  }
}

.google.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header {
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .icon > img {
  height: 100%;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .chevron > img {
  height: 100%;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info {
  display: none;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info.visible {
  display: block;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info > .key-value-pair {
  grid-template-columns: .5fr 1fr;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info > .key-value-pair > .text {
  color: #7d7e92;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header {
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .icon > img {
  height: 100%;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .chevron > img {
  height: 100%;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info {
  display: none;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info.visible {
  display: block;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair {
  gap: 12px;
  align-items: unset;
  grid-template-columns: .5fr 1fr;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair > .text {
  color: #7d7e92;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair.uber-eats .value {
  color: #7d7e92;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .dropoff-notes {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #343553;
  background: linear-gradient(#f7f9fc 0%, #fff 100%), #f6f8fb;
  border-radius: 8px;
  margin-top: 8px;
  padding: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  display: block;
  box-shadow: 0 2px 1px #0000000d, 0 0 1px #00000040;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .dropoff-notes > div {
  font-weight: 600;
  display: inline;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header {
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .icon > img {
  height: 100%;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .chevron > img {
  height: 100%;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info {
  display: none;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info.visible {
  display: block;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info > .key-value-pair {
  grid-template-columns: .5fr 1fr;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info > .key-value-pair > .text {
  color: #7d7e92;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header {
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .icon > img {
  height: 100%;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .chevron > img {
  height: 100%;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header) {
  grid-template-columns: .5fr 1fr;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: none;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header):nth-child(2) {
  margin-top: 10px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header).visible {
  display: grid;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header) > .label {
  color: #7d7e92;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header) > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header .icon {
  margin-top: 2px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-details {
  align-items: center;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-image {
  width: 100%;
  border-radius: 50%;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-name {
  color: #1c1d3e;
  font-size: 16px;
  font-weight: 500;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-vehicle {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 400;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-contact-number {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header {
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .icon > img {
  height: 100%;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .chevron > img {
  height: 100%;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .additional-info {
  letter-spacing: .048px;
  color: #059669;
  align-self: center;
  margin-left: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .additional-info.ARRIVED_AT_PICKUP {
  color: #3b82f6;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .additional-info.EN_ROUTE_TO_DROPOFF {
  color: #d97706;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .additional-info.FAILED {
  color: #f43f5e;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header .icon {
  margin-top: 2px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info {
  display: none;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info.visible {
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info {
  align-items: center;
  margin-top: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .icon {
  height: 74px;
  width: 74px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .icon > img {
  height: 100%;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .icon img {
  border-radius: 50%;
  margin-right: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .details .driver-name {
  color: #1c1d3e;
  font-size: 16px;
  font-weight: 500;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .details .vehicle-info {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 400;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .details .driver-contact-number {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status {
  display: none;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status.visible {
  display: block;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair {
  grid-template-columns: .5fr 1fr;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair:first-child {
  margin-top: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair > .text {
  color: #7d7e92;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair .text {
  color: #7d7e92;
  letter-spacing: .25px;
  font-size: 14px;
  line-height: 21px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair > .value {
  letter-spacing: .25px;
  color: #059669;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair.ARRIVED_AT_PICKUP > .value {
  color: #3b82f6;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair.EN_ROUTE_TO_DROPOFF > .value {
  color: #d97706;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair.FAILED > .value {
  color: #f43f5e;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header {
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .icon > img {
  height: 100%;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .chevron > img {
  height: 100%;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info {
  display: none;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info.visible {
  display: block;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info > .key-value-pair {
  gap: 12px;
  align-items: unset;
  grid-template-columns: .5fr 1fr;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info > .key-value-pair > .text {
  color: #7d7e92;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header {
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .icon > img {
  height: 100%;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .chevron > img {
  height: 100%;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info {
  display: none;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info.visible {
  display: block;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info > .key-value-pair {
  grid-template-columns: .5fr 1fr;
  align-items: flex-start;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info > .key-value-pair > .text {
  color: #7d7e92;
}

.google.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.google.order-view-widget .order-details-info .text {
  color: #7d7e92;
}

.google.order-view-widget .split-bill-payment-summary-widget, .google.order-view-widget .tyro-payment-summary-widget {
  margin-top: 16px;
}

.google.order-view-widget .google-order-view-actions-widget {
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  margin-top: auto;
  display: grid;
}

.google.order-view-widget .google-order-view-actions-widget > .print-order-button {
  color: #7d7e92;
  background-color: #0000;
  border-color: #7d7e92;
}

.pos-order-comment-widget {
  min-width: 748px;
  border-radius: 20px;
  padding: 60px 160px 20px;
}

.pos-order-comment-widget .title {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.pos-order-comment-widget .comments-form > form textarea {
  background-color: #f6f8fb;
  border: none;
  border-radius: 8px;
  padding-top: 14px;
  font-size: 16px;
}

.pos-order-comment-widget .add-comment.actions {
  grid-gap: 20px;
  grid-template-areas: "confirm"
                       "cancel";
  display: grid;
}

.pos-order-comment-widget .edit-comment.actions {
  grid-gap: 20px 10px;
  grid-template-areas: "clear confirm"
                       "cancel cancel";
  display: grid;
}

.pos-order-comment-widget button.confirm {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  grid-area: confirm;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.pos-order-comment-widget button.confirm:focus {
  border: none;
  outline: none;
}

.pos-order-comment-widget button.clear {
  color: #4cbba1;
  background-color: #0000;
  border: 1px solid #4cbba1;
  grid-area: clear;
  font-size: 17px;
  line-height: 23px;
}

.pos-order-comment-widget button.cancel {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  grid-area: cancel;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 22px;
  transition: all .2s ease-in-out;
}

.cash-or-card-refund-widget {
  min-width: 620px;
  border-radius: 20px;
  padding: 40px 32px 32px;
}

.cash-or-card-refund-widget .header {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
  display: flex;
}

.cash-or-card-refund-widget .header .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.cash-or-card-refund-widget .header .amount {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #3f9c86;
  margin: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.cash-or-card-refund-widget .header .amount span {
  color: #7d7e92;
  line-height: 24px;
}

.refund-type-select-widget {
  color: #1c1d3e;
  border-radius: 10px;
  flex-direction: column;
  margin: 0 auto;
  padding: 40px;
  display: flex;
}

.refund-type-select-widget > .label {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
}

.refund-type-select-widget > .refund-types {
  grid-auto-flow: column;
  gap: 24px;
  padding: 40px 0;
  display: grid;
}

.refund-type-select-widget > .refund-types > .refund-type {
  width: 22vw;
  text-align: center;
  letter-spacing: 0;
  color: #1c1d3e;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  padding: 40px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  box-shadow: 1px 0 18px #e4e4e4f5;
}

.refund-type-select-widget > .refund-types > .refund-type > .icon {
  height: 48px;
  width: 48px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.refund-type-select-widget > .refund-types > .refund-type > .icon > img {
  height: 100%;
}

.refund-type-select-widget > .back-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #1c1d3e;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0 24px;
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  transition: all .2s ease-in-out;
}

.order-versions-payment-refund-widget {
  width: 800px;
  padding: 24px 32px 32px;
}

.order-versions-payment-refund-widget .title {
  letter-spacing: .36px;
  text-align: center;
  margin: 12px 0 16px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
}

.order-versions-payment-refund-widget .description {
  margin: 16px 0 8px;
  font-size: 14px;
}

.order-versions-payment-refund-widget .description .refund-amount {
  color: #3f9c86;
}

.order-versions-payment-refund-widget .payments {
  letter-spacing: .08px;
  max-height: 50vh;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  overflow-y: auto;
}

.order-versions-payment-refund-widget .payments::-webkit-scrollbar {
  width: 1px;
}

.order-versions-payment-refund-widget .payments::-webkit-scrollbar-track {
  background: none;
}

.order-versions-payment-refund-widget .payments::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.order-versions-payment-refund-widget .payments .payment {
  background: #f6f8fb;
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: max-content 1fr max-content;
  grid-template-areas: "radio label amount"
                       ". reference-number reference-number"
                       ". tip tip"
                       ". surcharge surcharge";
  padding: 16px 20px;
  display: grid;
}

.order-versions-payment-refund-widget .payments .payment.disabled {
  color: #7d7e92;
  cursor: not-allowed;
}

.order-versions-payment-refund-widget .payments .payment > .radio-button {
  grid-area: radio;
  margin-right: 14px;
}

.order-versions-payment-refund-widget .payments .payment > .label {
  grid-area: label;
  align-items: center;
  display: flex;
}

.order-versions-payment-refund-widget .payments .payment > .label .payment-order {
  letter-spacing: .08px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.order-versions-payment-refund-widget .payments .payment > .label .bullet {
  margin: 0 8px 2px;
}

.order-versions-payment-refund-widget .payments .payment > .label .payment-type {
  letter-spacing: .035px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.order-versions-payment-refund-widget .payments .payment > .amount {
  text-align: right;
  letter-spacing: .08px;
  grid-area: amount;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.order-versions-payment-refund-widget .payments .payment > .amount.refund {
  color: #9f1239;
}

.order-versions-payment-refund-widget .payments .payment .reference-number {
  letter-spacing: .035px;
  color: #7d7e92;
  grid-area: reference-number;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.order-versions-payment-refund-widget .payments .payment .tip, .order-versions-payment-refund-widget .payments .payment .surcharge {
  letter-spacing: .035px;
  color: #7d7e92;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  display: flex;
}

.order-versions-payment-refund-widget .payments .payment .tip {
  grid-area: tip;
}

.order-versions-payment-refund-widget .payments .payment .surcharge {
  grid-area: surcharge;
}

.order-versions-payment-refund-widget .payments :first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.order-versions-payment-refund-widget .payments :last-child {
  border-bottom: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.order-versions-payment-refund-widget .actions {
  margin: 24px 24px 0;
  display: flex;
}

.order-versions-payment-refund-widget .actions button {
  width: 100%;
}

.order-versions-payment-refund-widget .actions button.back {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0 10px 0 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.order-versions-payment-refund-widget .actions button.refund {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.order-versions-payment-refund-widget .actions button.refund:focus {
  border: none;
  outline: none;
}

.partial-refund-widget {
  padding: 32px;
}

.partial-refund-widget .header {
  text-align: center;
  margin-bottom: 40px;
  padding-top: 10px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.partial-refund-widget .input-container {
  width: 780px;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  grid-template-columns: min-content 1fr;
  padding: 13px 20px;
  display: grid;
}

.partial-refund-widget .input-container.error {
  border-color: #9f1239;
}

.partial-refund-widget .input-container .sign {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  padding-top: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.partial-refund-widget .input-container .partial-refund-input {
  align-items: center;
  display: grid;
}

.partial-refund-widget .input-container .partial-refund-input input {
  text-align: right;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
  line-height: 35px;
  overflow: hidden;
}

.partial-refund-widget .invalid-amount-label {
  text-align: right;
  color: #9f1239;
  margin-top: 10px;
}

.partial-refund-widget .actions {
  grid-auto-flow: column;
  gap: 15px;
  margin-top: 40px;
  padding: 0 20px;
  display: grid;
}

.partial-refund-widget .actions .cancel-button {
  border: 1px solid var(--blue-grey-500-secondary, #7d7e92);
  background-color: #0000;
}

.start-pickup-order-widget, .start-dine-in-order-widget {
  color: #1c1d3e;
  background-color: #f6f8fb;
  place-items: center;
  display: grid;
}

.start-dine-in-order-widget .table-selector-widget {
  width: 80vw;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  padding: 48px;
  box-shadow: 0 0 3px #2e2f4d0d, 0 10px 25px #59587b26;
}

.start-dine-in-order-widget .table-selector-widget .table-presets-widget .actions {
  grid-auto-flow: column;
  gap: 14px;
  display: grid;
}

.start-dine-in-order-widget .table-selector-widget .table-presets-widget .actions button {
  width: 100%;
}

.start-delivery-order-widget, .start-saved-orders-widget {
  color: #1c1d3e;
  background-color: #f6f8fb;
  place-items: center;
  display: grid;
}

.start-saved-orders-widget .container {
  max-width: 90vw;
  min-width: 800px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  margin-bottom: 0;
  padding: 48px;
  box-shadow: 0 0 3px #2e2f4d0d, 0 10px 25px #59587b26;
}

.start-saved-orders-widget .container .header {
  text-align: center;
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 600;
  line-height: 45px;
}

.start-saved-orders-widget .container .saved-orders-widget {
  max-height: 60vh;
}

.start-saved-orders-widget .container .actions {
  margin-top: 10px;
}

.start-saved-orders-widget .container .actions button {
  width: 100%;
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #7d7e92;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.start-make-payment-widget {
  color: #1c1d3e;
  background-color: #f6f8fb;
  place-items: center;
  display: grid;
}

.start-make-payment-widget .container {
  width: 85vw;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  margin-bottom: 0;
  padding: 48px;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  box-shadow: 0 0 3px #2e2f4d0d, 0 10px 25px #59587b26;
}

.start-make-payment-widget .container .title {
  text-align: center;
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 600;
  line-height: 45px;
}

.start-make-payment-widget .container input {
  text-indent: 30px;
  width: 380px;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 15px;
  background-repeat: no-repeat;
  border: none;
  border-radius: 10px;
  outline: none;
  margin-inline: 10px;
  padding: 8px 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  box-shadow: 0 3px 15px -5px #0000004d;
}

.start-make-payment-widget .container .tabs {
  border: 1px solid #1c1d3e;
  border-radius: 10px;
  grid-template-rows: min-content;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 20px 0;
  display: grid;
}

.start-make-payment-widget .container .tabs li {
  text-align: center;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 10px;
  list-style: none;
}

.start-make-payment-widget .container .tabs li:nth-child(2) {
  border-radius: 0 10px 10px 0;
}

.start-make-payment-widget .container .tabs li.active {
  color: #fff;
  background-color: #1c1d3e;
}

.start-make-payment-widget .container .no-orders {
  margin: 10vh 0;
  font-size: 22px;
}

.start-make-payment-widget .container .pos-order-list-widget .orders, .start-make-payment-widget .container .online-order-list-widget .orders {
  max-height: 45vh;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.start-make-payment-widget .container .actions {
  margin-top: 10px;
}

.start-make-payment-widget .container .actions button {
  width: 100%;
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #7d7e92;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.payment-type-select-widget {
  width: 80vw;
  max-width: 1100px;
  color: #1c1d3e;
  gap: 40px;
  margin: 0 auto;
  padding: 40px 32px 32px;
  display: grid;
}

.payment-type-select-widget .title {
  text-align: center;
  letter-spacing: .36px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
}

.payment-type-select-widget .actions {
  padding: 0 24px;
}

.payment-type-select-widget .actions button.cancel, .payment-type-select-widget .actions button.back {
  width: 100%;
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.payment-type-select-widget .payment-types {
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  gap: 24px;
  display: grid;
}

.payment-type-select-widget .payment-type {
  letter-spacing: .09px;
  text-align: center;
  cursor: pointer;
  grid-gap: 24px;
  background: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 40px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  display: grid;
  box-shadow: 0 0 24px #00000014;
}

.payment-type-select-widget .payment-type:hover {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  outline: 2px solid #4f506a;
}

.payment-type-select-widget .payment-type > .icon {
  place-self: center;
}

#toasts-container .wrapper .linkly-retry-limit-reached .message {
  max-width: 700px !important;
}

.cancel-linkly-transaction-prompt > .swal2-title {
  margin-top: 0 !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 26px !important;
}

.cancel-linkly-transaction-prompt > .swal2-content {
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
}

.cancel-linkly-transaction-prompt > .swal2-actions {
  padding-top: 20px !important;
}

.cancel-linkly-transaction-prompt > .swal2-actions > button {
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.cancel-linkly-transaction-prompt > .swal2-actions > .confirm-button {
  background-color: #9f1239 !important;
  border-radius: 5px !important;
}

.cancel-linkly-transaction-prompt > .swal2-actions > .cancel-button {
  border: 1px solid #7d7e92;
  color: #7d7e92 !important;
}

.refund-payment-type-select-widget {
  width: 100%;
  margin: 0 auto;
}

.refund-payment-type-select-widget .title {
  text-align: center;
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.refund-payment-type-select-widget .payment-types {
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  gap: 20px;
  display: grid;
}

.refund-payment-type-select-widget .payment-types .payment-type {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  grid-gap: 30px;
  min-width: 265px;
  min-height: 177px;
  color: #1c1d3e;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  margin: auto;
  padding: 42px 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  display: grid;
  box-shadow: 0 0 24px #00000014;
}

.refund-payment-type-select-widget .payment-types .payment-type:hover {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border: 2px solid #4f506a;
}

.refund-payment-type-select-widget .payment-types .payment-type > .icon {
  max-width: 38px;
  place-self: center;
}

.refund-payment-type-select-widget .max-refund-amount {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #4c4e68;
  text-align: center;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  margin-top: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.refund-payment-type-select-widget .max-refund-amount span {
  font-weight: 600;
}

.refund-payment-type-select-widget .max-refund-amount span.invalid {
  color: #9f1239;
}

.refund-payment-type-select-widget .actions {
  gap: 30px;
  margin-top: 40px;
  padding: 0 24px;
  display: grid;
}

.refund-payment-type-select-widget .actions button.back-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.cash-payment-collect-widget {
  width: 40vw;
  min-width: 800px;
  gap: 40px;
  margin: 0 auto;
  padding: 40px 32px;
  display: grid;
}

.cash-payment-collect-widget .title {
  letter-spacing: .36px;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
}

.cash-payment-collect-widget .cash-tendered {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  grid-template-columns: max-content 1fr max-content;
  padding: 24px;
  display: grid;
}

.cash-payment-collect-widget .cash-tendered .icon {
  height: 40px;
  width: 40px;
  background: url("icon-cash.7a1024e9.svg") center no-repeat;
}

.cash-payment-collect-widget .cash-tendered .payment-value input {
  text-align: right;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  overflow: hidden;
}

.cash-payment-collect-widget .cash-tendered .payment-value input::placeholder {
  color: #7d7e92;
}

.cash-payment-collect-widget .payment-presets {
  grid-gap: 10px;
  grid-template-columns: repeat(5, 1fr);
  display: grid;
}

.cash-payment-collect-widget .payment-presets .preset {
  text-align: center;
  color: #1c1d3e;
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 8px;
  padding: 14px 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.cash-payment-collect-widget .payment-presets .preset.selected {
  color: #fff;
  background: #1c1d3e;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.cash-payment-collect-widget .payment-info {
  background: linear-gradient(90deg, #f6f8fb8c 0%, #e9e9e95f 100%);
  border-radius: 10px;
  flex-direction: column;
  padding: 0;
  display: flex;
}

.cash-payment-collect-widget .payment-info .total, .cash-payment-collect-widget .payment-info .change {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  display: flex;
}

.cash-payment-collect-widget .payment-info .total .label, .cash-payment-collect-widget .payment-info .total .amount, .cash-payment-collect-widget .payment-info .change .label, .cash-payment-collect-widget .payment-info .change .amount {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.cash-payment-collect-widget .payment-info .total {
  border-bottom: 1px solid #dee0e6;
}

.cash-payment-collect-widget .payment-info .change .amount, .cash-payment-collect-widget .payment-info .change .amount:first-letter {
  color: #f43f5e;
}

.cash-payment-collect-widget .actions {
  grid-template-columns: repeat(2, 1fr);
  gap: 14px;
  margin: 0 24px;
  display: grid;
}

.cash-payment-collect-widget .actions button {
  width: 100%;
}

.cash-payment-collect-widget .actions button.done {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.cash-payment-collect-widget .actions button.done:focus {
  border: none;
  outline: none;
}

.cash-payment-collect-widget .actions button.cancel {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.card-payment-collect-widget {
  width: 40vw;
  min-width: 844px;
  color: #1c1d3e;
  margin: 0 auto;
  padding: 40px 32px 32px;
}

.card-payment-collect-widget .title {
  letter-spacing: .36px;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
}

.card-payment-collect-widget .payment-amount {
  background: #fafafa;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  grid-auto-flow: column;
  align-items: center;
  margin-top: 40px;
  padding: 24px;
  display: grid;
}

.card-payment-collect-widget .payment-amount .amount {
  text-align: right;
  margin: 0;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
}

.card-payment-collect-widget .payment-amount .amount:first-letter {
  color: #7d7e92;
  margin-right: 5px;
}

.card-payment-collect-widget .payment-options {
  margin-top: 40px;
  display: flex;
}

.card-payment-collect-widget .payment-options > :not(:first-child) {
  margin-left: 24px;
}

.card-payment-collect-widget .payment-options .payment-collect-option-widget {
  flex-grow: 1;
  flex-basis: 100%;
}

.card-payment-collect-widget .payment-options .payment-collect-option-widget > .card {
  height: 100%;
  max-height: 174px;
  cursor: pointer;
  letter-spacing: .09px;
  text-align: center;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  display: flex;
  box-shadow: 0 0 24px #00000014;
}

.card-payment-collect-widget .payment-options .payment-collect-option-widget > .card > .icon {
  margin-bottom: 12px;
}

.card-payment-collect-widget .payment-options .payment-collect-option-widget > .disclaimer {
  justify-content: center;
  margin-top: 8px;
  display: flex;
}

.card-payment-collect-widget .payment-options .payment-collect-option-widget > .disclaimer > .icon {
  height: 18px;
  width: 18px;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  display: flex;
}

.card-payment-collect-widget .payment-options .payment-collect-option-widget > .disclaimer > .icon > img {
  height: 100%;
}

.card-payment-collect-widget .actions {
  margin-top: 40px;
  display: flex;
}

.card-payment-collect-widget .actions button {
  width: 100%;
}

.card-payment-collect-widget .actions button.confirm-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0 0 0 14px;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.card-payment-collect-widget .actions button.confirm-button:focus {
  border: none;
  outline: none;
}

.card-payment-collect-widget .actions button.back-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
}

.card-payment-collect-widget .actions button.back-button:focus {
  border: none;
  outline: none;
}

.card-payment-collect-widget .actions button.cancel {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  color: #4c4e68;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
}

.card-payment-collect-widget .actions button.cancel:focus {
  border: none;
  outline: none;
}

.card-payment-anz-worldline-moto-collect-widget .moto-icon {
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  display: flex;
}

.card-payment-anz-worldline-moto-collect-widget .moto-icon > img {
  height: 100%;
}

.card-refund-payment-widget {
  width: 40vw;
  min-width: 800px;
  color: #1c1d3e;
  margin: 0 auto;
  padding: 40px 32px 32px;
}

.card-refund-payment-widget .title {
  letter-spacing: .36px;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
}

.card-refund-payment-widget .refund-payment-amount {
  background: #fafafa;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  grid-auto-flow: column;
  align-items: center;
  margin-top: 40px;
  padding: 24px;
  display: grid;
}

.card-refund-payment-widget .refund-payment-amount .amount {
  text-align: right;
  margin: 0;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
}

.card-refund-payment-widget .refund-payment-amount .amount:first-letter {
  color: #7d7e92;
  margin-right: 5px;
}

.card-refund-payment-widget .refund-payment-options {
  grid-auto-flow: column;
  gap: 24px;
  margin-top: 40px;
  display: grid;
}

.card-refund-payment-widget .refund-payment-options .option {
  cursor: pointer;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  grid-auto-flow: row;
  justify-items: center;
  gap: 12px;
  padding: 40px;
  display: grid;
  box-shadow: 0 0 24px #00000014;
}

.card-refund-payment-widget .refund-payment-options .option .label {
  letter-spacing: .09px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.card-refund-payment-widget .max-refund-payment-amount {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #4c4e68;
  text-align: center;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  margin-top: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.card-refund-payment-widget .max-refund-payment-amount span {
  font-weight: 600;
}

.card-refund-payment-widget .max-refund-payment-amount span.invalid {
  color: #9f1239;
}

.card-refund-payment-widget .actions {
  margin-top: 40px;
  display: flex;
}

.card-refund-payment-widget .actions button {
  width: 100%;
}

.card-refund-payment-widget .actions button.done {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0 0 0 14px;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.card-refund-payment-widget .actions button.done:focus {
  border: none;
  outline: none;
}

.card-refund-payment-widget .actions button.cancel {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  color: #4c4e68;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
}

.card-refund-payment-widget .actions button.cancel:focus {
  border: none;
  outline: none;
}

.split-type-select-widget {
  color: #1c1d3e;
  gap: 40px;
  margin: 0 auto;
  padding: 40px 32px 32px;
  display: grid;
}

.split-type-select-widget .title {
  text-align: center;
  letter-spacing: .36px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
}

.split-type-select-widget .actions {
  padding: 0 24px;
}

.split-type-select-widget .actions button.cancel, .split-type-select-widget .actions button.back {
  width: 100%;
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.split-type-select-widget .split-types {
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  grid-auto-flow: column;
  gap: 24px;
  display: grid;
}

.split-type-select-widget .split-type {
  letter-spacing: .09px;
  text-align: center;
  cursor: pointer;
  grid-gap: 24px;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  justify-content: center;
  padding: 40px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  display: grid;
  box-shadow: 0 0 24px #00000014;
}

.split-type-select-widget .split-type:hover {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  outline: 2px solid #4f506a;
}

.split-type-select-widget .split-type > .icon {
  height: 48px;
  place-self: center;
}

.split-equally-count-select-widget {
  width: 60vw;
  max-width: 900px;
  grid-auto-flow: row;
  place-items: center;
  gap: 10px;
  padding: 60px 48px;
  display: grid;
}

.split-equally-count-select-widget .title {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.split-equally-count-select-widget .quantity {
  width: 100%;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: column;
  place-items: center;
  display: grid;
}

.split-equally-count-select-widget .quantity .button {
  width: 100%;
  height: 248px;
  cursor: pointer;
  place-items: center;
  display: grid;
}

.split-equally-count-select-widget .quantity .button .btn, .split-equally-count-select-widget .quantity .button .inc, .split-equally-count-select-widget .quantity .button .dec {
  width: 56px;
  height: 56px;
  text-align: center;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: grid;
}

.split-equally-count-select-widget .quantity .button .icon {
  color: #fff;
  box-shadow: 0 3px 10px -3px #0000004d;
}

.split-equally-count-select-widget .quantity .button .icon i {
  font-size: 30px;
}

.split-equally-count-select-widget .quantity .button .dec {
  background: #4c4e68;
  padding: 5px 8px;
}

.split-equally-count-select-widget .quantity .button .inc {
  background: #4cbba1;
  padding: 5px 8px;
}

.split-equally-count-select-widget .quantity .number {
  text-align: center;
  font-size: 120pt;
  font-weight: 500;
  line-height: 164px;
}

.split-equally-count-select-widget .actions {
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 10px;
  display: grid;
}

.split-equally-count-select-widget .actions button {
  width: 100%;
}

.split-equally-count-select-widget .actions button.confirm {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.split-equally-count-select-widget .actions button.confirm:focus {
  border: none;
  outline: none;
}

.split-equally-count-select-widget .actions button.back {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

h1 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

h2 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 22px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

h3 {
  letter-spacing: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

h4 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

h5 {
  letter-spacing: 0;
  color: #1c1d3e;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

p, pre {
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

form input[type="text"], form input[type="number"], form input[type="password"], form input[type="tel"], form textarea {
  font-size: 15px;
}

.cta {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.cta:focus {
  border: none;
  outline: none;
}

.secondary-cta {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #4cbba1;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
}

.tertiary-cta {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
}

.tertiary-cta:focus {
  border: none;
  outline: none;
}

.link-cta {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.cancel-split-payment .swal2-modal {
  width: 530px !important;
  padding: 32px 24px 0 !important;
}

.cancel-split-payment .swal2-header {
  margin-bottom: 16px;
  padding: 0 !important;
}

.cancel-split-payment .swal2-content {
  letter-spacing: .1px;
  padding: 0;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px !important;
  font-size: 20px !important;
}

.cancel-split-payment .swal2-actions {
  padding: 0 !important;
}

.cancel-split-payment .swal2-actions .confirm-button {
  text-transform: none !important;
}

.cancel-split-payment .swal2-actions .cancel-button {
  color: #7d7e92;
  border: 1px solid #7d7e92;
  text-transform: none !important;
}

.cancel-payment .confirm-button {
  text-transform: none !important;
  background: #9f1239 !important;
  border: 1px solid #9f1239 !important;
  border-radius: 4px !important;
}

.cancel-payment .cancel-button {
  text-transform: none !important;
  border: 1px solid #7d7e92 !important;
  border-radius: 4px !important;
}

.split-equally-payment-type-select-widget {
  width: 50vw;
  max-width: 700px;
  color: #1c1d3e;
  gap: 40px;
  margin: 0 auto;
  padding: 40px 32px 32px;
  display: grid;
}

.split-equally-payment-type-select-widget .title {
  text-align: center;
  letter-spacing: .36px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
}

.split-equally-payment-type-select-widget .actions {
  padding: 0 24px;
}

.split-equally-payment-type-select-widget .actions button.cancel, .split-equally-payment-type-select-widget .actions button.back {
  width: 100%;
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.split-equally-payment-type-select-widget .header {
  gap: 8px;
  display: grid;
}

.split-equally-payment-type-select-widget .subtitle {
  letter-spacing: .1px;
  text-align: center;
  color: #7d7e92;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.split-equally-payment-type-select-widget .subtitle span {
  color: #3f9c86;
}

.split-equally-payment-type-select-widget .payment-types {
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  gap: 24px;
  display: grid;
}

.split-equally-payment-type-select-widget .payment-type {
  letter-spacing: .09px;
  text-align: center;
  cursor: pointer;
  grid-gap: 24px;
  background: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 40px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  display: grid;
  box-shadow: 0 0 24px #00000014;
}

.split-equally-payment-type-select-widget .payment-type:hover {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  outline: 2px solid #4f506a;
}

.split-equally-payment-type-select-widget .payment-type > .icon {
  place-self: center;
}

.split-equally-payment-summary-widget {
  width: 65vw;
  max-width: 900px;
  padding: 40px 32px 32px;
}

.split-equally-payment-summary-widget > * {
  margin-top: 24px;
}

.split-equally-payment-summary-widget > :first-child {
  margin-top: 0;
}

.split-equally-payment-summary-widget > .header {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.split-equally-payment-summary-widget > .header > .icon {
  height: 64px;
  width: 64px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.split-equally-payment-summary-widget > .header > .icon > img {
  height: 100%;
}

.split-equally-payment-summary-widget > .header > .label {
  margin-top: 24px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.split-equally-payment-summary-widget > .actions {
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "cancel-button finalise-button"
                       "back-to-cart-button finalise-button"
                       "cancel-order-button cancel-order-button";
  display: grid;
}

.split-equally-payment-summary-widget > .actions > .cancel-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  grid-area: cancel-button;
  margin: 0 15px 0 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.split-equally-payment-summary-widget > .actions > .back-to-cart-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  grid-area: back-to-cart-button;
  margin: 0 15px 0 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.split-equally-payment-summary-widget > .actions > .cancel-order-button {
  color: #9f1239;
  font-weight: 500px;
  text-align: center;
  cursor: pointer;
  grid-area: cancel-order-button;
  margin-top: 30px;
  font-size: 16px;
  text-decoration: underline;
}

.split-equally-payment-summary-widget > .actions > .finalise-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 4px;
  grid-area: finalise-button;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.split-equally-payment-summary-widget > .actions > .finalise-button:focus {
  border: none;
  outline: none;
}

.split-equally-payment-summary-widget > .split-equally-payments > .label {
  color: #4c4e68;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  max-height: calc(60vh - 200px);
  background: #f6f8fb;
  border-radius: 8px;
  margin-top: 12px;
  padding: 16px;
  overflow-y: scroll;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items::-webkit-scrollbar {
  width: 1px;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items::-webkit-scrollbar-track {
  background: none;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > * {
  border-top: 1px solid #dee0e6;
  margin-top: 12px;
  padding-top: 12px;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > :first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > .split-equally-payment {
  color: #7d7e92;
  border-top: 1px solid #dee0e6;
  grid-template-columns: 1fr auto;
  grid-template-areas: "label amount"
                       "transaction-reference ."
                       "surcharge-label surcharge-amount"
                       "tip-label tip-amount"
                       "items items";
  place-items: center;
  gap: 0 8px;
  margin-top: 12px;
  padding-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > .split-equally-payment:first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > .split-equally-payment > .payment {
  color: #1c1d3e;
  display: contents;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > .split-equally-payment > .payment > .label {
  grid-area: label;
  justify-self: start;
  align-items: center;
  display: flex;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > .split-equally-payment > .payment > .label > .type {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > .split-equally-payment > .payment > .label > .bullet {
  color: #4c4e68;
  margin: 0 8px;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > .split-equally-payment > .payment > .amount {
  grid-area: amount;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > .split-equally-payment > .payment > .amount.refund {
  color: #9f1239;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > .split-equally-payment > .transaction-reference {
  color: #7d7e92;
  grid-area: transaction-reference;
  justify-self: start;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > .split-equally-payment > .surcharge {
  color: #7d7e92;
  display: contents;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > .split-equally-payment > .surcharge > .label {
  grid-area: surcharge-label;
  justify-self: start;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > .split-equally-payment > .surcharge > .amount {
  grid-area: surcharge-amount;
  justify-self: end;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > .split-equally-payment > .tip {
  color: #7d7e92;
  display: contents;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > .split-equally-payment > .tip > .label {
  grid-area: tip-label;
  justify-self: start;
}

.split-equally-payment-summary-widget > .split-equally-payments > .items > .split-equally-payment > .tip > .amount {
  grid-area: tip-amount;
  justify-self: end;
}

.split-by-item-select-widget {
  width: 60vw;
  max-width: 900px;
  color: #1c1d3e;
  padding: 48px;
}

.split-by-item-select-widget > .label {
  text-align: center;
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.split-by-item-select-widget > .unpaid-items {
  flex-direction: column;
  display: flex;
}

.split-by-item-select-widget > .unpaid-items > .label {
  color: #4c4e68;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.split-by-item-select-widget > .unpaid-items > .select-all-checkbox {
  cursor: pointer;
  grid-template-columns: min-content 1fr;
  align-items: center;
  padding: 12px 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  display: grid;
}

.split-by-item-select-widget > .unpaid-items > .items {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  max-height: 25vh;
  background: #f6f8fb;
  border-radius: 8px;
  margin-top: 10px;
  overflow-y: scroll;
}

.split-by-item-select-widget > .unpaid-items > .items::-webkit-scrollbar {
  width: 1px;
}

.split-by-item-select-widget > .unpaid-items > .items::-webkit-scrollbar-track {
  background: none;
}

.split-by-item-select-widget > .unpaid-items > .items::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.split-by-item-select-widget > .unpaid-items > .items > .unpaid-split-bill-item {
  cursor: pointer;
  border-top: 1px solid #dee0e6;
  grid-template-columns: auto 2fr 1fr .5fr;
  grid-template-areas: "checkbox name payment-status price"
                       ". description . ."
                       ". comments . .";
  column-gap: 4px;
  padding: 16px 20px;
  display: grid;
}

.split-by-item-select-widget > .unpaid-items > .items > .unpaid-split-bill-item:first-child {
  border-top: none;
}

.split-by-item-select-widget > .unpaid-items > .items > .unpaid-split-bill-item > .checkbox {
  grid-area: checkbox;
}

.split-by-item-select-widget > .unpaid-items > .items > .unpaid-split-bill-item > .name {
  grid-area: name;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.split-by-item-select-widget > .unpaid-items > .items > .unpaid-split-bill-item > .payment-status {
  text-align: right;
  grid-area: payment-status;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.split-by-item-select-widget > .unpaid-items > .items > .unpaid-split-bill-item > .price {
  text-align: end;
  grid-area: price;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.split-by-item-select-widget > .unpaid-items > .items > .unpaid-split-bill-item > .item-description-view-widget {
  color: #7d7e92;
  grid-area: description;
  margin-top: 8px;
}

.split-by-item-select-widget > .unpaid-items > .items > .unpaid-split-bill-item > .split-bill-item-comments {
  color: #7d7e92;
  grid-area: comments;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.split-by-item-select-widget > .paid-items {
  flex-direction: column;
  margin-top: 12px;
  display: flex;
}

.split-by-item-select-widget > .paid-items > .label {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.split-by-item-select-widget > .paid-items > .items {
  max-height: 20vh;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background: #f6f8fb;
  border-radius: 8px;
  margin-top: 10px;
  overflow-y: scroll;
}

.split-by-item-select-widget > .paid-items > .items::-webkit-scrollbar {
  width: 1px;
}

.split-by-item-select-widget > .paid-items > .items::-webkit-scrollbar-track {
  background: none;
}

.split-by-item-select-widget > .paid-items > .items::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item {
  border-top: 1px solid #dee0e6;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item:first-child {
  border-top: none;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .header {
  grid-template-columns: min-content 1fr auto;
  grid-template-areas: "icon label amount"
                       ". transaction-reference ."
                       ". surcharge-label surcharge-amount"
                       ". tip-label tip-amount";
  place-items: center;
  gap: 0 8px;
  padding: 16px 20px 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .header > .icon {
  height: 28px;
  width: 28px;
  grid-area: icon;
  justify-content: center;
  align-items: center;
  display: flex;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .header > .icon > img {
  height: 100%;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .header > .paid-split-bill-item-payment {
  display: contents;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .header > .paid-split-bill-item-payment > .label {
  grid-area: label;
  justify-self: start;
  align-items: center;
  display: flex;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .header > .paid-split-bill-item-payment > .label > .type {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .header > .paid-split-bill-item-payment > .label > .bullet {
  color: #4c4e68;
  margin: 0 8px;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .header > .paid-split-bill-item-payment > .amount {
  grid-area: amount;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .header > .paid-split-bill-item-transaction-reference {
  color: #7d7e92;
  grid-area: transaction-reference;
  justify-self: start;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .header > .paid-split-bill-item-surcharge {
  color: #7d7e92;
  display: contents;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .header > .paid-split-bill-item-surcharge > .label {
  grid-area: surcharge-label;
  justify-self: start;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .header > .paid-split-bill-item-surcharge > .amount {
  grid-area: surcharge-amount;
  justify-self: end;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .header > .paid-split-bill-item-tip {
  color: #7d7e92;
  display: contents;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .header > .paid-split-bill-item-tip > .label {
  grid-area: tip-label;
  justify-self: start;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .header > .paid-split-bill-item-tip > .amount {
  grid-area: tip-amount;
  justify-self: end;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .items {
  padding: 0 20px 16px 80px;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .items > .item {
  margin-top: 12px;
  display: list-item;
}

.split-by-item-select-widget > .paid-items > .items > .paid-split-bill-item > .items > .item:first-child {
  margin-top: 0;
}

.split-by-item-select-widget > .actions {
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 40px;
  display: grid;
}

.split-by-item-select-widget > .actions > .cancel-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #7d7e92;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.split-by-item-select-widget > .actions > .back-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #4cbba1;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
}

.split-by-item-select-widget > .actions > .back-to-cart-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.split-by-item-select-widget > .actions > .done-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.split-by-item-select-widget > .actions > .done-button:focus {
  border: none;
  outline: none;
}

.split-by-item-select-widget > .actions > .pay-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.split-by-item-select-widget > .actions > .pay-button:focus {
  border: none;
  outline: none;
}

.swal2-container .finalize-payment-confirmation-dialog .swal2-header .swal2-title {
  margin-top: 0 !important;
}

.swal2-container .finalize-payment-confirmation-dialog .swal2-content .swal2-html-container {
  color: #4c4e68;
}

.swal2-container .finalize-payment-confirmation-dialog .swal2-actions .confirm-button {
  text-transform: inherit !important;
}

.swal2-container .finalize-payment-confirmation-dialog .swal2-actions .cancel-button {
  color: #1c1d3e !important;
  text-transform: inherit !important;
  border: 1px solid #dee0e6 !important;
  border-radius: 4px !important;
}

h1 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

h2 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 22px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

h3 {
  letter-spacing: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

h4 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

h5 {
  letter-spacing: 0;
  color: #1c1d3e;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

p, pre {
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

form input[type="text"], form input[type="number"], form input[type="password"], form input[type="tel"], form textarea {
  font-size: 15px;
}

.cta {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.cta:focus {
  border: none;
  outline: none;
}

.secondary-cta {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #4cbba1;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
}

.tertiary-cta {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
}

.tertiary-cta:focus {
  border: none;
  outline: none;
}

.link-cta {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.cancel-split-payment .swal2-modal {
  width: 530px !important;
  padding: 32px 24px 0 !important;
}

.cancel-split-payment .swal2-header {
  margin-bottom: 16px;
  padding: 0 !important;
}

.cancel-split-payment .swal2-content {
  letter-spacing: .1px;
  padding: 0;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px !important;
  font-size: 20px !important;
}

.cancel-split-payment .swal2-actions {
  padding: 0 !important;
}

.cancel-split-payment .swal2-actions .confirm-button {
  text-transform: none !important;
}

.cancel-split-payment .swal2-actions .cancel-button {
  color: #7d7e92;
  border: 1px solid #7d7e92;
  text-transform: none !important;
}

.cancel-payment .confirm-button {
  text-transform: none !important;
  background: #9f1239 !important;
  border: 1px solid #9f1239 !important;
  border-radius: 4px !important;
}

.cancel-payment .cancel-button {
  text-transform: none !important;
  border: 1px solid #7d7e92 !important;
  border-radius: 4px !important;
}

.split-by-item-payment-type-select-widget {
  width: 50vw;
  max-width: 700px;
  color: #1c1d3e;
  gap: 40px;
  margin: 0 auto;
  padding: 40px 32px 32px;
  display: grid;
}

.split-by-item-payment-type-select-widget .title {
  text-align: center;
  letter-spacing: .36px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
}

.split-by-item-payment-type-select-widget .actions {
  padding: 0 24px;
}

.split-by-item-payment-type-select-widget .actions button.cancel, .split-by-item-payment-type-select-widget .actions button.back {
  width: 100%;
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.split-by-item-payment-type-select-widget .header {
  gap: 8px;
  display: grid;
}

.split-by-item-payment-type-select-widget .subtitle {
  letter-spacing: .1px;
  text-align: center;
  color: #7d7e92;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.split-by-item-payment-type-select-widget .subtitle span {
  color: #3f9c86;
}

.split-by-item-payment-type-select-widget .payment-types {
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  gap: 24px;
  display: grid;
}

.split-by-item-payment-type-select-widget .payment-type {
  letter-spacing: .09px;
  text-align: center;
  cursor: pointer;
  grid-gap: 24px;
  background: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 40px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  display: grid;
  box-shadow: 0 0 24px #00000014;
}

.split-by-item-payment-type-select-widget .payment-type:hover {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  outline: 2px solid #4f506a;
}

.split-by-item-payment-type-select-widget .payment-type > .icon {
  place-self: center;
}

.split-by-item-payment-summary-widget {
  width: 65vw;
  max-width: 900px;
  padding: 40px 32px 32px;
}

.split-by-item-payment-summary-widget > * {
  margin-top: 24px;
}

.split-by-item-payment-summary-widget > :first-child {
  margin-top: 0;
}

.split-by-item-payment-summary-widget > .header {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.split-by-item-payment-summary-widget > .header > .icon {
  height: 64px;
  width: 64px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.split-by-item-payment-summary-widget > .header > .icon > img {
  height: 100%;
}

.split-by-item-payment-summary-widget > .header > .label {
  margin-top: 24px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.split-by-item-payment-summary-widget > .actions {
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "cancel-button finalise-button"
                       "back-to-cart-button finalise-button"
                       "cancel-order-button cancel-order-button";
  display: grid;
}

.split-by-item-payment-summary-widget > .actions > .cancel-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  grid-area: cancel-button;
  margin: 0 15px 0 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.split-by-item-payment-summary-widget > .actions > .back-to-cart-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  grid-area: back-to-cart-button;
  margin: 0 15px 0 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.split-by-item-payment-summary-widget > .actions > .cancel-order-button {
  color: #9f1239;
  font-weight: 500px;
  text-align: center;
  cursor: pointer;
  grid-area: cancel-order-button;
  margin-top: 30px;
  font-size: 16px;
  text-decoration: underline;
}

.split-by-item-payment-summary-widget > .actions > .finalise-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 4px;
  grid-area: finalise-button;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.split-by-item-payment-summary-widget > .actions > .finalise-button:focus {
  border: none;
  outline: none;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .label {
  color: #4c4e68;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  max-height: calc(60vh - 200px);
  background: #f6f8fb;
  border-radius: 8px;
  margin-top: 12px;
  padding: 16px;
  overflow-y: scroll;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items::-webkit-scrollbar {
  width: 1px;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items::-webkit-scrollbar-track {
  background: none;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > * {
  border-top: 1px solid #dee0e6;
  margin-top: 12px;
  padding-top: 12px;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > :first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment {
  color: #7d7e92;
  border-top: 1px solid #dee0e6;
  grid-template-columns: 1fr auto;
  grid-template-areas: "label amount"
                       "transaction-reference ."
                       "surcharge-label surcharge-amount"
                       "tip-label tip-amount"
                       "items items";
  place-items: center;
  gap: 0 8px;
  margin-top: 12px;
  padding-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment:first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment > .payment {
  color: #1c1d3e;
  display: contents;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment > .payment > .label {
  grid-area: label;
  justify-self: start;
  align-items: center;
  display: flex;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment > .payment > .label > .type {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment > .payment > .label > .bullet {
  color: #4c4e68;
  margin: 0 8px;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment > .payment > .amount {
  grid-area: amount;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment > .payment > .amount.refund {
  color: #9f1239;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment > .transaction-reference {
  color: #7d7e92;
  grid-area: transaction-reference;
  justify-self: start;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment > .surcharge {
  color: #7d7e92;
  display: contents;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment > .surcharge > .label {
  grid-area: surcharge-label;
  justify-self: start;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment > .surcharge > .amount {
  grid-area: surcharge-amount;
  justify-self: end;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment > .tip {
  color: #7d7e92;
  display: contents;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment > .tip > .label {
  grid-area: tip-label;
  justify-self: start;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment > .tip > .amount {
  grid-area: tip-amount;
  justify-self: end;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment > .items {
  width: 100%;
  grid-area: items;
  margin-top: 8px;
  padding: 0 0 12px 32px;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment > .items > .item {
  margin-top: 12px;
  display: list-item;
}

.split-by-item-payment-summary-widget > .split-by-item-payments > .items > .split-by-item-payment > .items > .item:first-child {
  margin-top: 0;
}

.custom-delivery-charge-widget {
  padding: 80px 170px;
}

.custom-delivery-charge-widget .header {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 22px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.custom-delivery-charge-widget .custom-delivery-fee-box {
  border: 1px solid #7d7e92;
  border-radius: 8px;
  grid-template-columns: min-content 1fr;
  margin-bottom: 22px;
  padding: 13px 20px;
  display: grid;
}

.custom-delivery-charge-widget .custom-delivery-fee-box .sign {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  padding-top: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.custom-delivery-charge-widget .custom-delivery-fee-box .custom-delivery-charge-input {
  align-items: center;
  display: grid;
}

.custom-delivery-charge-widget .custom-delivery-fee-box .custom-delivery-charge-input input {
  text-align: right;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
  line-height: 35px;
  overflow: hidden;
}

.custom-delivery-charge-widget .actions {
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "skip-clear confirm"
                       "cancel cancel";
  gap: 10px;
  display: grid;
}

.custom-delivery-charge-widget .actions .skip-clear {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #7d7e92;
  grid-area: skip-clear;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.custom-delivery-charge-widget .actions .confirm {
  grid-area: confirm;
}

.custom-delivery-charge-widget .actions .cancel {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  grid-area: cancel;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.pac-container {
  border-radius: 8px;
  margin-top: 8px;
  box-shadow: 0 0 16px #31303e26 !important;
}

.pac-logo:after {
  display: none !important;
}

.pac-item {
  padding: 7px 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
}

.pac-item .pac-icon {
  background-size: 50px !important;
}

.pac-item .pac-icon-marker {
  background-position: -4px -268px !important;
}

.print-station-list-widget .print-stations {
  columns: 300px;
  column-gap: 24px;
}

.print-station-list-widget .empty-list {
  letter-spacing: 0;
  color: #1c1d3e;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 22px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
}

.print-station-list-widget .empty-list .message {
  flex-flow: column;
  align-items: center;
  display: flex;
}

.print-station-list-widget .empty-list .message .icon {
  width: 40px;
  margin-bottom: 10px;
}

.print-station-list-item-widget {
  break-inside: avoid;
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border: 1px solid #dee0e6;
  border-radius: 6px;
  flex-direction: column;
  margin-bottom: 24px;
  padding: 24px;
  display: flex;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.print-station-list-item-widget .print-station-name {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.print-station-list-item-widget .print-container {
  border: 1px solid #dee0e6;
  border-radius: 12px;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 16px;
  display: flex;
}

.print-station-list-item-widget .print-container .title {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.print-station-list-item-widget .print-container .info {
  color: #4c4e68;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  overflow: hidden;
}

.print-station-list-item-widget .print-container button {
  color: #7d7e92;
  background-color: unset;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 12px 16px 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.print-station-list-item-widget .actions {
  display: flex;
}

.print-station-list-item-widget .actions button {
  flex-grow: 1;
}

.print-station-list-item-widget .actions .delete-button {
  color: #7d7e92;
  background-color: unset;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-decoration: underline;
}

.print-station-edit-widget {
  width: auto;
  max-height: calc(100vh - 56px);
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  padding: 40px 32px;
  overflow: auto;
}

.print-station-edit-widget::-webkit-scrollbar {
  width: 1px;
}

.print-station-edit-widget::-webkit-scrollbar-track {
  background: none;
}

.print-station-edit-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.print-station-edit-widget .header {
  display: flex;
}

.print-station-edit-widget .header .title {
  text-align: center;
  flex-grow: 1;
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.print-station-edit-widget .header .close-button {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: -32px;
}

.print-station-edit-widget form .form-group-container {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin: 0;
  padding: 16px 24px;
}

.print-station-edit-widget form .form-group-container .form-group {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.print-station-edit-widget form .form-group-container .form-group .form-group-label {
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.print-station-edit-widget form .form-group-container .form-group .form-group-help-text {
  color: #4c4e68;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
}

.print-station-edit-widget form .form-group-container .form-group .form-group-help-text .info-icon {
  margin-right: 5px;
}

.print-station-edit-widget form .form-group-container .form-group .form-group-help-text .info-icon img {
  width: 18px;
  height: 18px;
}

.print-station-edit-widget form .form-group-container > .form-item-container:nth-child(2) {
  padding-top: 20px;
}

.print-station-edit-widget form .form-group-container > .form-item-container:not(:last-child) {
  padding-bottom: 15px;
}

.print-station-edit-widget form .form-item-container .form-item {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin: 0;
  padding: 8.5px 0;
}

.print-station-edit-widget form .form-item-container .form-item.invalid {
  border: 1px solid #f43f5e;
}

.print-station-edit-widget form .form-item-container .form-item.focus {
  border: 1px solid #4cbba1;
}

.print-station-edit-widget form .form-item-container .form-item.disabled {
  background-color: #7d7e9215;
  border: 1px solid #f4f4f6;
}

.print-station-edit-widget form .form-item-container .form-item .form-label {
  color: #7d7e92;
  margin-bottom: 2px;
  padding: 0 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.print-station-edit-widget form .form-item-container.switch > .form-item {
  align-items: center;
  display: flex;
}

.print-station-edit-widget form .form-item-container.switch > .form-item .form-label {
  flex-grow: 1;
}

.print-station-edit-widget form .form-item-container .form-meta {
  margin: 4px;
}

.print-station-edit-widget form .form-item-container .form-meta .error {
  color: #9f1239;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.print-station-edit-widget form .form-item-container .form-meta .help {
  color: #7d7e92;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.print-station-edit-widget form .text-input {
  display: flex;
}

.print-station-edit-widget form .text-input input {
  border-radius: unset;
  border: none;
  flex-grow: 1;
  margin: 0;
  padding: 0 12px;
}

.print-station-edit-widget form .text-input input:focus {
  outline: none;
}

.print-station-edit-widget form .text-input input:disabled {
  background-color: unset !important;
}

.print-station-edit-widget form .text-input input::placeholder {
  color: #9597a7;
}

.print-station-edit-widget form .text-input input:-ms-input-placeholder {
  color: #9597a7;
}

.print-station-edit-widget form .text-input input::-moz-placeholder {
  color: #9597a7;
}

.print-station-edit-widget form .text-input .clear-icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: -14px 12px 0 0;
  display: flex;
}

.print-station-edit-widget form .text-input .clear-icon > img {
  height: 100%;
}

.print-station-edit-widget form .drop-down-input {
  z-index: 999;
  cursor: pointer;
  display: flex;
  position: relative;
}

.print-station-edit-widget form .drop-down-input .value {
  flex-grow: 1;
  padding: 0 12px;
}

.print-station-edit-widget form .drop-down-input .down-arrow-icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin: -10px 12px 0 0;
  display: flex;
}

.print-station-edit-widget form .drop-down-input .down-arrow-icon > img {
  height: 100%;
}

.print-station-edit-widget form .drop-down-input .options {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-top: 16px;
  position: absolute;
  top: 100%;
  left: 0;
}

.print-station-edit-widget form .drop-down-input .options .option {
  border-bottom: 1px solid #dee0e6;
  justify-content: space-between;
  padding: 10px 12px;
  display: flex;
}

.print-station-edit-widget form .drop-down-input .options .option:last-child {
  border-bottom: 0;
}

.print-station-edit-widget form .drop-down-input .empty {
  color: #9597a7;
}

.print-station-edit-widget form .switch {
  cursor: pointer;
  margin: 0;
}

.print-station-edit-widget form .switch i {
  width: 46px;
  height: 26px;
  vertical-align: text-bottom;
  background-color: #c6c7d1;
  border-radius: 23px;
  margin-right: 4px;
  transition: all .3s linear;
  display: inline-block;
  position: relative;
}

.print-station-edit-widget form .switch i:before {
  content: "";
  width: 42px;
  height: 22px;
  background-color: #c6c7d1;
  border-radius: 11px;
  transition: all .25s linear;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0)scale3d(1, 1, 1);
}

.print-station-edit-widget form .switch i:after {
  content: "";
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transition: all .2s ease-in-out;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0);
  box-shadow: 0 2px 2px #0000003d;
}

.print-station-edit-widget form .switch:active i:after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.print-station-edit-widget form .switch:active input:checked + i:after {
  transform: translate3d(16px, 2px, 0);
}

.print-station-edit-widget form .switch input {
  display: none;
}

.print-station-edit-widget form .switch input:checked + i {
  background-color: #34c759;
}

.print-station-edit-widget form .switch input:checked + i:before {
  transform: translate3d(18px, 2px, 0)scale3d(0, 0, 0);
}

.print-station-edit-widget form .switch input:checked + i:after {
  transform: translate3d(22px, 2px, 0);
}

.print-station-edit-widget form .form-buttons {
  margin: 40px 24px 0;
  display: flex;
}

.print-station-edit-widget form .form-buttons button {
  flex-grow: 1;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
}

.print-station-edit-widget form .form-buttons .primary {
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
  border-radius: 4px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.print-station-edit-widget form .form-buttons .primary:disabled {
  opacity: unset;
  background-color: #c4eae1;
  border: 1px solid #c4eae1;
}

.category-colors-list-widget form .form-items {
  grid-template-columns: repeat(auto-fill, minmax(251px, 1fr));
  gap: 14px;
}

.category-colors-list-widget > .no-categories {
  letter-spacing: 0;
  text-align: center;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.item-colors-list-widget .category:not(:first-child) {
  margin-top: 24px;
}

.item-colors-list-widget .category > .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 22px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.item-colors-list-widget .category form .form-items {
  grid-template-columns: repeat(auto-fill, minmax(251px, 1fr));
  gap: 14px;
}

.item-colors-list-widget > .no-items {
  letter-spacing: 0;
  text-align: center;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.quantity-edit-widget {
  width: 96px;
  color: #65667d;
  grid-template-areas: "dec number inc";
  align-items: center;
  display: grid;
}

.quantity-edit-widget .icon {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.quantity-edit-widget .btn, .quantity-edit-widget .inc, .quantity-edit-widget .dec {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
}

.quantity-edit-widget .dec {
  background: #4c4e68;
}

.quantity-edit-widget .number {
  min-width: 34px;
  text-align: center;
  align-self: center;
  margin: 0 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
}

.quantity-edit-widget .inc {
  background: #4cbba1;
}

.quantity-edit-widget .icon {
  cursor: pointer;
  padding-top: 2px;
}

.quantity-edit-widget .dec {
  grid-area: dec;
}

.quantity-edit-widget .number {
  grid-area: number;
}

.quantity-edit-widget .inc {
  grid-area: inc;
}

.saved-orders-widget {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  max-height: calc(90vh - 24px);
  padding: 24px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.saved-orders-widget::-webkit-scrollbar {
  width: 1px;
}

.saved-orders-widget::-webkit-scrollbar-track {
  background: none;
}

.saved-orders-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.saved-orders-widget > .dine-in, .saved-orders-widget > .pickup-and-delivery {
  margin-bottom: 38px;
}

.saved-orders-widget > .dine-in > .label, .saved-orders-widget > .pickup-and-delivery > .label {
  color: #343553;
  font-size: 25px;
  font-weight: 500;
  line-height: 35px;
}

.saved-orders-widget > .dine-in .stashed-orders, .saved-orders-widget > .pickup-and-delivery .stashed-orders {
  grid-template-columns: repeat(5, 1fr);
  gap: 14px;
  margin-top: 24px;
  display: grid;
}

.saved-orders-widget > .dine-in .stashed-orders > .stashed-order, .saved-orders-widget > .pickup-and-delivery .stashed-orders > .stashed-order {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #1c1d3e;
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 14px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  display: flex;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.saved-orders-widget > .dine-in .stashed-orders > .stashed-order > .body, .saved-orders-widget > .pickup-and-delivery .stashed-orders > .stashed-order > .body {
  margin-bottom: 14px;
}

.saved-orders-widget > .dine-in .stashed-orders > .stashed-order > .body > .table-name, .saved-orders-widget > .pickup-and-delivery .stashed-orders > .stashed-order > .body > .table-name {
  font-size: 18px;
  line-height: 24px;
}

.saved-orders-widget > .dine-in .stashed-orders > .stashed-order > .body > .new-item-count, .saved-orders-widget > .pickup-and-delivery .stashed-orders > .stashed-order > .body > .new-item-count {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #f43f5e;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.saved-orders-widget > .dine-in .stashed-orders > .stashed-order > .body > .collection-type, .saved-orders-widget > .pickup-and-delivery .stashed-orders > .stashed-order > .body > .collection-type {
  font-weight: 700;
}

.saved-orders-widget > .dine-in .stashed-orders > .stashed-order > .body > .requested-for, .saved-orders-widget > .pickup-and-delivery .stashed-orders > .stashed-order > .body > .requested-for {
  color: #f43f5e;
}

.saved-orders-widget > .dine-in .stashed-orders > .stashed-order > .footer, .saved-orders-widget > .pickup-and-delivery .stashed-orders > .stashed-order > .footer {
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  display: flex;
}

.saved-orders-widget > .dine-in .stashed-orders > .stashed-order > .footer > .interval, .saved-orders-widget > .pickup-and-delivery .stashed-orders > .stashed-order > .footer > .interval {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #7d7e92;
  margin-right: 30px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.saved-orders-widget > .dine-in .stashed-orders > .stashed-order > .footer > .delete-button, .saved-orders-widget > .pickup-and-delivery .stashed-orders > .stashed-order > .footer > .delete-button {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.saved-orders-widget > .dine-in .stashed-orders > .stashed-order > .footer > .delete-button > img, .saved-orders-widget > .pickup-and-delivery .stashed-orders > .stashed-order > .footer > .delete-button > img {
  height: 100%;
}

.saved-orders-widget > .dine-in .stashed-orders .no-stashed-orders, .saved-orders-widget > .pickup-and-delivery .stashed-orders .no-stashed-orders {
  margin-top: 24px;
}

.swal2-container .order-view-cancel-reject-dialog .swal2-header .swal2-title {
  margin-top: 0 !important;
}

.swal2-container .order-view-cancel-reject-dialog .swal2-content .swal2-html-container {
  color: #4c4e68;
}

.swal2-container .order-view-cancel-reject-dialog .swal2-actions .confirm-button {
  text-transform: inherit !important;
  background: #9f1239 !important;
  border: 1px solid #9f1239 !important;
  border-radius: 4px !important;
}

.swal2-container .order-view-cancel-reject-dialog .swal2-actions .cancel-button {
  text-transform: inherit !important;
  border: 1px solid #7d7e92 !important;
  border-radius: 4px !important;
}

.sales-report-widget {
  height: 100vh;
  color: #1c1d3e;
  grid-template-columns: 23vw 1fr;
  display: grid;
}

.sales-report-widget .navigation-panel {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #f6f8fb;
  padding: 20px 25px 20px 10px;
  overflow-y: auto;
}

.sales-report-widget .navigation-panel::-webkit-scrollbar {
  width: 1px;
}

.sales-report-widget .navigation-panel::-webkit-scrollbar-track {
  background: none;
}

.sales-report-widget .navigation-panel::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.sales-report-widget .navigation-panel .header {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 20px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.sales-report-widget .navigation-panel .link {
  margin: unset;
  padding: unset;
  display: grid;
}

.sales-report-widget .navigation-panel .link .icon {
  display: none;
}

.sales-report-widget .navigation-panel .link .label {
  all: unset;
  letter-spacing: 0;
  color: #1c1d3e;
  cursor: pointer;
  color: #1c1d3e;
  background-color: #fff;
  background-image: url("icon-category.e1ace84b.svg");
  background-position: 94%;
  background-repeat: no-repeat;
  background-size: 8px;
  border: 1px solid #f6f8fb;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 12px 14px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .3s ease-in-out;
  box-shadow: 0 0 8px -5px #0000004d;
}

.sales-report-widget .navigation-panel .link .label.selected {
  color: #fff;
  background-color: #1c1d3e;
  background-image: url("icon-category-active.4841131b.svg");
}

.sales-report-widget .main-panel {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  overflow-y: auto;
}

.sales-report-widget .main-panel::-webkit-scrollbar {
  width: 1px;
}

.sales-report-widget .main-panel::-webkit-scrollbar-track {
  background: none;
}

.sales-report-widget .main-panel::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.sales-trend-multi-line-chart-filter-widget {
  height: 90vh;
  grid-template-rows: auto auto 1fr auto;
  padding: 40px 32px 32px;
  display: grid;
}

.sales-trend-multi-line-chart-filter-widget .header {
  grid-template-areas: "title title"
                       "message clear-selection-button";
  align-items: center;
  gap: 8px;
  padding-bottom: 24px;
  display: grid;
}

.sales-trend-multi-line-chart-filter-widget .header .title {
  color: #1c1d3e;
  grid-area: title;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.sales-trend-multi-line-chart-filter-widget .header .message {
  color: #7d7e92;
  grid-area: message;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.sales-trend-multi-line-chart-filter-widget .header .clear-selection-button {
  color: #1e40af;
  cursor: pointer;
  grid-area: clear-selection-button;
  justify-self: end;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-decoration: underline;
  position: relative;
}

.sales-trend-multi-line-chart-filter-widget .filter-container {
  border: 1px solid #dee0e6;
  border-radius: 12px;
  padding: 16px;
  position: relative;
  overflow: hidden;
}

.sales-trend-multi-line-chart-filter-widget .filter-container .search-box input {
  width: 100%;
  color: #4c4e68;
  text-indent: 30px;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 0;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.sales-trend-multi-line-chart-filter-widget .filter-container .search-box input::placeholder {
  color: #c6c7d1;
}

.sales-trend-multi-line-chart-filter-widget .filter-container .options {
  height: 90%;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  overflow-y: scroll;
}

.sales-trend-multi-line-chart-filter-widget .filter-container .options::-webkit-scrollbar {
  width: 1px;
}

.sales-trend-multi-line-chart-filter-widget .filter-container .options::-webkit-scrollbar-track {
  background: none;
}

.sales-trend-multi-line-chart-filter-widget .filter-container .options::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.sales-trend-multi-line-chart-filter-widget .filter-container .options .no-results {
  color: #7d7e92;
  width: max-content;
  margin: 0 auto;
  padding-top: 10%;
}

.sales-trend-multi-line-chart-filter-widget .actions {
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 40px 16px 0 24px;
  display: grid;
}

.sales-trend-multi-line-chart-filter-widget .actions .button {
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
}

.sales-trend-multi-line-chart-filter-widget .actions .cancel-button {
  color: #4c4e68;
  border: 1px solid #7d7e92;
  padding: 14px 101.5px;
}

.sales-trend-multi-line-chart-filter-widget .actions .confirm-button {
  color: #fff;
  background-color: #3f9c86;
  padding: 14px 50px;
}

.sales-trend-multi-line-chart-filter-widget .actions .confirm-button.disabled {
  pointer-events: none;
  background-color: #83d0be;
}

.sales-trend-multi-line-chart-filter-widget .error-message {
  color: #e11d48;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.swal2-container .cancel-edit-sales-trend-dialog .swal2-html-container {
  color: #4c4e68;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container .cancel-edit-sales-trend-dialog .swal2-actions button {
  text-transform: capitalize;
}

.swal2-container .cancel-edit-sales-trend-dialog .swal2-actions .confirm-button {
  background-color: #9f1239;
}

.swal2-container .cancel-edit-sales-trend-dialog .swal2-actions .cancel-button {
  color: #4c4e68;
  border: 1px solid #7d7e92;
}

.sales-trend-line-chart-view-widget .chart-title {
  color: #1c1d3e;
  padding: 24px 0 32px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.sales-trend-line-chart-view-widget .recharts-responsive-container {
  margin: 0 auto;
}

.sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-surface {
  overflow: visible;
}

.sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-surface .recharts-xAxis .x-axis-title {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-surface .recharts-brush .recharts-brush-slide {
  color: red;
}

.sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-surface .recharts-brush .recharts-brush-slide :active {
  fill-opacity: 1;
}

.sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-surface .recharts-brush .recharts-brush-traveller, .sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-surface .recharts-brush .recharts-brush-texts {
  display: none;
}

.sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-tooltip-wrapper .tooltip {
  text-align: center;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 12px 13.5px;
  transform: translate(-50%);
  box-shadow: 0 2px 16px #0000001f;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-tooltip-wrapper .tooltip .sales {
  color: #1c1d3e;
  padding-bottom: 4px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-tooltip-wrapper .tooltip .period {
  color: #7d7e92;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.sales-trend-table-view-toggle {
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  justify-content: left;
  align-items: center;
  display: flex;
}

.sales-trend-table-view-toggle .right-arrow-icon {
  margin-right: 12px;
}

.sales-trend-table-view-toggle .right-arrow-icon.hide > img {
  transform: rotate(-90deg);
}

.sales-trend-table-view-widget .sales-trend-table * {
  background: unset;
  white-space: normal;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

.sales-trend-table-view-widget .sales-trend-table ::-webkit-scrollbar {
  width: 1px;
}

.sales-trend-table-view-widget .sales-trend-table ::-webkit-scrollbar-track {
  background: none;
}

.sales-trend-table-view-widget .sales-trend-table ::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.sales-trend-table-view-widget .sales-trend-table * td:not(:first-child), .sales-trend-table-view-widget .sales-trend-table * td:not(:last-child), .sales-trend-table-view-widget .sales-trend-table * th:not(:first-child), .sales-trend-table-view-widget .sales-trend-table * th:not(:last-child) {
  border-left: none;
  border-right: none;
}

.sales-trend-table-view-widget .sales-trend-table * th {
  border-top: 1px solid #dee0e6;
  border-bottom: none;
}

.sales-trend-table-view-widget .sales-trend-table * td:first-child, .sales-trend-table-view-widget .sales-trend-table * th:first-child {
  border-left: 1px solid #dee0e6;
}

.sales-trend-table-view-widget .sales-trend-table * td:last-child, .sales-trend-table-view-widget .sales-trend-table * th:last-child {
  border-right: 1px solid #dee0e6;
}

.sales-trend-table-view-widget .sales-trend-table table {
  width: 100%;
  table-layout: fixed;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.sales-trend-table-view-widget .sales-trend-table table .header-row {
  color: #4c4e68;
}

.sales-trend-table-view-widget .sales-trend-table table .header-row .header-cell {
  text-align: right;
  background-color: #fff;
  border: 1px solid #fff;
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.sales-trend-table-view-widget .sales-trend-table table .header-row .header-cell:first-child {
  text-align: left;
}

.sales-trend-table-view-widget .sales-trend-table table tBody :first-child td {
  border-top: 1px solid #dee0e6;
}

.sales-trend-table-view-widget .sales-trend-table table tBody :first-child td:first-child {
  border-top-left-radius: 8px;
}

.sales-trend-table-view-widget .sales-trend-table table tBody :first-child td:last-child {
  border-top-right-radius: 8px;
}

.sales-trend-table-view-widget .sales-trend-table table tBody :last-child:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.sales-trend-table-view-widget .sales-trend-table table tBody :last-child:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.sales-trend-table-view-widget .sales-trend-table table tBody tr .table-cell {
  text-align: right;
  padding: 12px 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.sales-trend-table-view-widget .sales-trend-table table tBody tr .table-cell:first-of-type {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.docket-type-select-widget {
  width: 60vw;
  max-width: 900px;
  color: #1c1d3e;
  border-radius: 10px;
  flex-direction: column;
  margin: 0 auto;
  padding: 40px 32px 32px;
  display: flex;
}

.docket-type-select-widget > .label {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.docket-type-select-widget > .docket-types {
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding: 20px 0;
  display: grid;
}

.docket-type-select-widget > .docket-types > .docket-type {
  cursor: pointer;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  place-items: center;
  padding: 40px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  display: grid;
  box-shadow: 0 0 24px #00000014;
}

.docket-type-select-widget > .docket-types > .docket-type:last-child {
  margin-right: 0;
}

.docket-type-select-widget > .docket-types > .docket-type > .icon {
  height: 48px;
  width: 48px;
  justify-content: center;
  align-items: center;
  margin: auto auto 20px;
  display: flex;
}

.docket-type-select-widget > .docket-types > .docket-type > .icon > img {
  height: 100%;
}

.docket-type-select-widget > .actions {
  margin-top: 20px;
  padding: 0 24px;
}

.docket-type-select-widget > .actions > .back-button {
  width: 100%;
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  color: #4c4e68;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  transition: all .2s ease-in-out;
}

.docket-type-select-widget > .actions > .back-button:focus {
  border: none;
  outline: none;
}

.key-value-pair {
  grid-template-columns: auto 1fr;
  grid-template-areas: "label value";
  align-items: center;
  display: grid;
}

.key-value-pair .label {
  grid-area: label;
  display: flex;
}

.key-value-pair .label .info {
  margin-left: 4px;
  position: relative;
}

.key-value-pair .label .info .info-text {
  visibility: hidden;
  z-index: 2;
  width: 160px;
  color: #1c1d3e;
  text-align: center;
  letter-spacing: .03px;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  top: -45px;
  left: 120%;
  box-shadow: 0 8px 12px #091e4226, 0 0 1px #091e424f;
}

.key-value-pair .label .info .info-text:before {
  content: "";
  z-index: 1;
  background-color: #fff;
  padding: 5px;
  position: absolute;
  top: 47%;
  left: -2%;
  transform: rotate(45deg);
}

.key-value-pair .label .info:hover .info-text {
  visibility: visible;
}

.key-value-pair .value {
  grid-area: value;
}

.custom-sales-date-picker-widget {
  grid-gap: 32px;
  padding: 24px;
  display: grid;
}

.manage-item-routing-widget .empty, .connected-store-manage-item-routing-widget .empty {
  width: max-content;
  color: #7d7e92;
  margin: 40px auto;
}

.manage-item-routing-widget .form-items, .connected-store-manage-item-routing-widget .form-items {
  grid-row-gap: 0;
}

.manage-item-routing-widget .form-items .inline-title, .connected-store-manage-item-routing-widget .form-items .inline-title {
  background-color: #f6f8fb;
}

.manage-item-routing-widget .form-items .inline-title label, .connected-store-manage-item-routing-widget .form-items .inline-title label {
  color: #1c1d3e;
  margin-left: 2px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.manage-item-routing-widget .items, .connected-store-manage-item-routing-widget .items {
  margin-bottom: 10px;
}

.manage-item-routing-widget .items .item, .connected-store-manage-item-routing-widget .items .item {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 20px;
}

.terminal-settings-widget .switch {
  padding-right: 20px;
}

.terminal-settings-widget .switch label {
  color: #1c1d3e;
  margin-bottom: 0;
  margin-left: 20px;
  font-size: 15px;
  font-weight: 600;
}

.terminal-settings-widget .switch .help {
  margin: 0 0 5px 15px;
  font-size: 12px;
  font-weight: 400;
}

.terminal-settings-widget .empty {
  width: max-content;
  color: #7d7e92;
  margin: 40px auto;
}

.terminal-settings-widget .form-items {
  grid-row-gap: 0;
}

.terminal-settings-widget .form-items .inline-title {
  background-color: #f6f8fb;
}

.terminal-settings-widget .form-items .inline-title label {
  color: #1c1d3e;
  margin-left: 2px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.terminal-settings-widget .items {
  margin-bottom: 10px;
}

.terminal-settings-widget .items .item {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 20px;
}

.terminal-settings-widget .advanced-options {
  padding: 10px 20px 0;
  display: grid;
}

.terminal-settings-widget .advanced-options > .label {
  color: #4cbba1;
  cursor: pointer;
  width: min-content;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 900;
  line-height: 22px;
  display: flex;
}

.terminal-settings-widget .advanced-options > .label .icon-chevron {
  cursor: pointer;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  margin-left: 5px;
}

.terminal-settings-widget .advanced-options > .label .icon-chevron.open {
  background-image: url("icon-up-chevron-alt.7cb5c29c.svg");
}

.terminal-settings-widget .advanced-options > .label .icon-chevron.close {
  background-image: url("icon-down-chevron-alt.cabb9ff2.svg");
}

.terminal-settings-widget .advanced-options .content {
  grid-auto-flow: column;
  display: grid;
}

.terminal-settings-widget .advanced-options .content .number-of-copies-print-options {
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 15px;
  display: grid;
}

.date-range-picker-widget {
  grid-gap: 12px;
  grid-template-columns: max-content max-content;
  display: grid;
}

.date-range-picker-widget .start-date-picker, .date-range-picker-widget .end-date-picker {
  grid-gap: 12px;
  display: grid;
}

.date-range-picker-widget .start-date-picker .value, .date-range-picker-widget .end-date-picker .value {
  color: #65667d;
  cursor: pointer;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  padding: 10px 14px;
}

.date-range-picker-widget .start-date-picker {
  grid-area: start-date-picker;
}

.date-range-picker-widget .end-date-picker {
  grid-area: end-date-picker;
}

.date-range.end-date-picker, .date-range.start-date-picker {
  grid-gap: 32px;
  padding: 24px;
  display: grid;
}

.website-order-delivery-channel-selector-widget {
  width: 750px;
  height: 500px;
  grid-template-areas: "title title"
                       "delivery-channels delivery-channels"
                       "back-button back-button";
  padding: 65px 55px;
  display: grid;
}

.website-order-delivery-channel-selector-widget .title {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  grid-area: title;
  align-self: start;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.website-order-delivery-channel-selector-widget .delivery-channels {
  grid-area: delivery-channels;
  grid-auto-flow: column;
  gap: 20px;
  display: grid;
}

.website-order-delivery-channel-selector-widget .in-house-delivery-option, .website-order-delivery-channel-selector-widget .uber-direct-option {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  align-self: center;
  padding: 40px 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  box-shadow: 0 3px 10px -3px #0000004d;
}

.website-order-delivery-channel-selector-widget .in-house-delivery-option .icon, .website-order-delivery-channel-selector-widget .uber-direct-option .icon {
  width: 45px;
  margin: 0 130px 17px;
}

.website-order-delivery-channel-selector-widget .back-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  color: #1c1d3e;
  text-align: center;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 5px;
  grid-area: back-button;
  align-self: end;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
}

.website-order-delivery-channel-selector-widget .back-button:focus {
  border: none;
  outline: none;
}

.website-order-delivery-status-widget {
  margin-bottom: 15px;
}

.website-order-delivery-status-widget .cancelled-info {
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0 2px 6px #091e4226;
}

.website-order-delivery-status-widget .cancelled-info .courier-and-label-container {
  box-shadow: none;
  grid-auto-columns: unset;
  padding: 0;
}

.website-order-delivery-status-widget .cancelled-info .courier-and-label-container > .status-label {
  grid-row-start: 1;
  grid-column-start: 1;
  padding-right: 20px;
}

.website-order-delivery-status-widget .cancelled-info .courier-and-label-container > .courier-info {
  border-left: 1px solid #d9d9e6;
  padding-left: 20px;
}

.website-order-delivery-status-widget .delivered-info {
  border-radius: 10px;
  box-shadow: 0 2px 6px #091e4226;
}

.website-order-delivery-status-widget .delivered-info > .status-label {
  background-color: #10b981;
  border-radius: 10px 10px 0 0;
  padding: 24px;
}

.website-order-delivery-status-widget .delivered-info > .proof-of-delivery-label {
  padding: 24px;
}

.website-order-delivery-status-widget .delivered-info > .proof-of-delivery-label > .label {
  color: #1c1d3e;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.website-order-delivery-status-widget .delivered-info > .proof-of-delivery-label .view-link {
  color: #4c4e68;
  cursor: pointer;
  align-self: flex-end;
  text-decoration: underline;
}

.website-order-delivery-status-widget .courier-pickup-date-and-time {
  border-radius: 10px;
  place-items: center;
  padding: 16px;
  display: grid;
  box-shadow: 0 2px 6px #091e4226;
}

.website-order-delivery-status-widget .courier-pickup-date-and-time > img {
  width: 100px;
}

.website-order-delivery-status-widget .courier-pickup-date-and-time .title {
  color: #1c1d3e;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.website-order-delivery-status-widget .courier-pickup-date-and-time .subtitle {
  color: #1c1d3e;
  grid-auto-flow: column;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  display: grid;
}

.website-order-delivery-status-widget .courier-pickup-date-and-time .subtitle > .time-since-label-widget {
  color: #4cbba1 !important;
}

.website-order-delivery-status-widget .tracking-map {
  border-radius: 10px;
  margin-bottom: 16px;
  overflow: hidden;
  box-shadow: 0 2px 6px #091e4226;
}

.website-order-delivery-status-widget .tracking-map .map-container {
  width: 100%;
  height: 240px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.website-order-delivery-status-widget .tracking-map .map-container > iframe {
  width: 170%;
  height: 555px;
  transform-origin: 0 0;
  position: relative;
  top: -45px;
  left: -1px;
  overflow: hidden;
  transform: scale(.6);
}

.website-order-delivery-status-widget .tracking-map > .label {
  color: #343553;
  grid-auto-columns: min-content 1fr;
  grid-auto-flow: column;
  align-items: center;
  gap: 8px;
  padding: 12px 14px;
  font-size: 14px;
  display: grid;
}

.website-order-delivery-status-widget .courier-and-label-container {
  border-radius: 10px;
  grid-auto-columns: 1fr max-content;
  grid-auto-flow: column;
  align-items: center;
  padding: 16px;
  display: grid;
  box-shadow: 0 2px 6px #091e4226;
}

.website-order-delivery-status-widget .courier-info {
  grid-template-areas: "image name"
                       "image vehicle"
                       "image contact";
  grid-auto-columns: min-content 1fr;
  align-items: center;
  gap: 0 12px;
  display: grid;
}

.website-order-delivery-status-widget .courier-info .courier-image {
  width: 74px;
  border-radius: 50%;
  grid-area: image;
}

.website-order-delivery-status-widget .courier-info .courier-name {
  color: #1c1d3e;
  grid-area: name;
  font-size: 16px;
  font-weight: 500;
}

.website-order-delivery-status-widget .courier-info .courier-vehicle {
  color: #7d7e92;
  grid-area: vehicle;
  font-size: 14px;
  font-weight: 400;
}

.website-order-delivery-status-widget .courier-info .courier-contact-number {
  color: #7d7e92;
  grid-area: contact;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.website-order-delivery-status-widget .status-label {
  letter-spacing: 0;
  color: #1c1d3e;
  margin: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.website-order-delivery-status-widget .status-label.ongoing {
  color: #059669;
  color: #1e3a8a;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.website-order-delivery-status-widget .status-label.ongoing:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #059669;
  border-radius: 100%;
  margin-right: 8px;
  background-color: #1e3a8a !important;
}

.website-order-delivery-status-widget .status-label.failed {
  color: #9f1239;
  grid-template-columns: min-content 1fr;
  grid-auto-flow: column;
  gap: 12px;
  display: grid;
}

.website-order-delivery-status-widget .status-label.success {
  color: #fff;
  grid-template-columns: min-content 1fr;
  grid-auto-flow: column;
  gap: 12px;
  font-size: 18px;
  font-weight: 500;
  display: grid;
}

.order-source-filter-selector-widget {
  height: min-content;
  position: relative;
}

.order-source-filter-selector-widget .order-source-filter label {
  color: #7d7e92;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.order-source-filter-selector-widget .order-source-filter .form-input {
  border-radius: 4px;
  padding-top: 5px;
}

.order-source-filter-selector-widget .order-source-filter .form-input input {
  max-width: 180px;
  cursor: pointer;
  background-image: url("icon-chevron-down-filled.5bee599b.svg");
  background-position: 95%;
  background-repeat: no-repeat;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 12px 14px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.order-source-filter-selector-widget .order-source-filter .form-input input:focus {
  outline: none;
}

.order-source-filter-selector-widget .order-source-filter-options {
  width: 100%;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
}

.order-source-filter-selector-widget .order-source-filter-options > .option {
  cursor: pointer;
  border-bottom: 1px solid #dee0e6;
  padding: 12px;
}

.order-source-filter-selector-widget .order-source-filter-options > .option.selected {
  background-image: url("icon-check-alt.4ffb5dc4.svg");
  background-position: right 14px top 50%;
  background-repeat: no-repeat;
  background-size: 20px;
}

.order-source-filter-selector-widget .order-source-filter-options > .option:last-of-type {
  border-bottom: 0;
}

.pos-terminal-sales-report-widget .filters {
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 24px;
  display: flex;
}

.pos-terminal-sales-report-widget .filters .pos-terminal-selector .form-label {
  color: #7d7e92;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.pos-terminal-sales-report-widget .filters .pos-terminal-selector .form-input {
  padding-top: 8px;
}

.pos-terminal-sales-report-widget .filters .pos-terminal-selector .drop-down-input {
  z-index: 999;
  cursor: pointer;
  z-index: 0;
  min-width: 200px;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 12px 14px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  position: relative;
}

.pos-terminal-sales-report-widget .filters .pos-terminal-selector .drop-down-input .value {
  flex-grow: 1;
  padding: 0 12px;
}

.pos-terminal-sales-report-widget .filters .pos-terminal-selector .drop-down-input .down-arrow-icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin: -10px 12px 0 0;
  display: flex;
}

.pos-terminal-sales-report-widget .filters .pos-terminal-selector .drop-down-input .down-arrow-icon > img {
  height: 100%;
}

.pos-terminal-sales-report-widget .filters .pos-terminal-selector .drop-down-input .options {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-top: 16px;
  position: absolute;
  top: 100%;
  left: 0;
}

.pos-terminal-sales-report-widget .filters .pos-terminal-selector .drop-down-input .options .option {
  border-bottom: 1px solid #dee0e6;
  justify-content: space-between;
  padding: 10px 12px;
  display: flex;
}

.pos-terminal-sales-report-widget .filters .pos-terminal-selector .drop-down-input .options .option:last-child {
  border-bottom: 0;
}

.pos-terminal-sales-report-widget .filters .pos-terminal-selector .drop-down-input .value {
  padding: 0;
}

.pos-terminal-sales-report-widget .filters .pos-terminal-selector .drop-down-input .down-arrow-icon {
  margin: 0;
}

.pos-terminal-sales-report-widget .filters .pos-terminal-selector .drop-down-input .options {
  margin-top: 5px;
}

.notification-settings-widget {
  padding: 20px;
}

.notification-settings-widget .switch {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  padding: 24px;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.notification-settings-widget .switch label {
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}

.notification-settings-widget .switch .help {
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}

.breakdown-sales-trend-line-chart-view-widget {
  width: inherit;
  margin-top: 40px;
  padding-bottom: 12px;
  position: relative;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container {
  align-self: flex-end;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-surface {
  overflow: visible;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-surface .recharts-xAxis .x-axis-title {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-surface .recharts-brush .recharts-brush-traveller, .breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-surface .recharts-brush .recharts-brush-texts {
  display: none;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-tooltip-wrapper .tooltip {
  text-align: center;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 12px 13.5px;
  transform: translate(-50%);
  box-shadow: 0 2px 16px #0000001f;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-tooltip-wrapper .tooltip .period {
  color: #7d7e92;
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-tooltip-wrapper .tooltip .datum {
  grid-template-columns: minmax(auto, 130px) 1fr;
  gap: 8px;
  display: grid;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-tooltip-wrapper .tooltip .datum .datum-name {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  overflow: hidden;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-tooltip-wrapper .tooltip .datum .datum-sales {
  text-align: right;
  font-weight: 500;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-legend-wrapper {
  max-width: 240px;
  padding-left: 32px;
  height: 80% !important;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-legend-wrapper .chart-legend {
  height: 100%;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 24px;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-legend-wrapper .chart-legend .title {
  padding-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-legend-wrapper .chart-legend .data {
  height: 70%;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  overflow: scroll;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-legend-wrapper .chart-legend .data::-webkit-scrollbar {
  width: 1px;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-legend-wrapper .chart-legend .data::-webkit-scrollbar-track {
  background: none;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-legend-wrapper .chart-legend .data::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-legend-wrapper .chart-legend .data .datum {
  grid-template-columns: max-content 1fr;
  align-items: baseline;
  gap: 12px;
  display: grid;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-legend-wrapper .chart-legend .data .datum .datum-color {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-legend-wrapper .chart-legend .data .datum .datum-label {
  color: #4c4e68;
  min-width: 135px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-legend-wrapper .chart-legend .edit-button {
  width: -moz-fit-content;
  width: fit-content;
  color: #3f9c86;
  cursor: pointer;
  border-radius: 4px;
  padding: 9.5px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-decoration: underline;
  position: absolute;
  bottom: 24px;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-responsive-container .line-chart .recharts-legend-wrapper .chart-legend .edit-button:hover {
  background-color: #ecf9f6;
}

.breakdown-sales-trend-line-chart-view-widget .recharts-cartesian-axis-ticks {
  font-size: 12px;
}

.store-sales-report-breakdown-widget > .header {
  letter-spacing: 0;
  color: #1c1d3e;
  border-bottom: 1px solid #f4f4f6;
  grid-template-columns: 1fr max-content min-content;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 24px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  display: grid;
}

.store-sales-report-breakdown-widget > .header .print-button {
  color: #fff;
  background-color: #3f9c86;
  border-radius: 4px;
  padding: 9.5px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.store-sales-report-breakdown-widget .body {
  padding: 24px;
}

.store-sales-report-breakdown-widget .body .filters {
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 24px;
  display: flex;
}

.store-sales-report-breakdown-widget .body .no-report {
  text-align: center;
  color: #4c4e68;
  opacity: .5;
  margin: 56px auto;
}

.store-sales-report-breakdown-widget .body .report .sales-report-overall-widget {
  grid-template-areas: "total-sales total-sales total-sales total-sales"
                       "breakdown-by-channel breakdown-by-payment-type breakdown-by-collection-type breakdown-by-gst";
  gap: 12px;
  display: grid;
}

.store-sales-report-breakdown-widget .body .report .sales-report-pos-overview-widget, .store-sales-report-breakdown-widget .body .report .sales-report-website-overview-widget, .store-sales-report-breakdown-widget .body .report .sales-report-google-overview-widget, .store-sales-report-breakdown-widget .body .report .sales-report-uber-eats-overview-widget {
  grid-template-areas: "total-sales total-sales total-sales"
                       "breakdown-by-payment-type breakdown-by-collection-type breakdown-by-gst";
  gap: 12px;
  display: grid;
}

.store-sales-report-breakdown-widget .body .report .breakdown-by-channel {
  grid-area: breakdown-by-channel;
}

.store-sales-report-breakdown-widget .body .report .breakdown-by-payment-type {
  grid-area: breakdown-by-payment-type;
}

.store-sales-report-breakdown-widget .body .report .breakdown-by-collection-type {
  grid-area: breakdown-by-collection-type;
}

.store-sales-report-breakdown-widget .body .report .breakdown-by-gst {
  grid-area: breakdown-by-gst;
}

.store-sales-report-breakdown-widget .body .report .breakdown-by-gst .net-sales, .store-sales-report-breakdown-widget .body .report .breakdown-by-gst .gst {
  padding-left: 24px;
}

.store-sales-report-breakdown-widget .body .report .total-sales {
  color: #fff;
  background: linear-gradient(264deg, #3f9c86 0%, #296a5b 100%);
  border-radius: 8px;
  grid-area: total-sales;
  gap: 14px;
  padding: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: grid;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.store-sales-report-breakdown-widget .body .report .total-sales .value {
  font-size: 32px;
}

.store-sales-report-breakdown-widget .body .report .total-sales ~ div {
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  padding: 24px;
}

.store-sales-report-breakdown-widget .body .report .total-sales ~ div .header {
  letter-spacing: 0;
  padding-bottom: 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.store-sales-report-breakdown-widget .body .report .total-sales ~ div .key-value-pair {
  letter-spacing: 0;
  color: #1c1d3e;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.store-sales-report-breakdown-widget .body .report .total-sales ~ div .key-value-pair .label {
  color: #7d7e92;
  font-weight: 400;
}

.store-sales-report-breakdown-widget .body .report .total-sales ~ div .key-value-pair .value {
  text-align: end;
}

.store-sales-report-breakdown-widget .body .report .total-sales ~ div .key-value-pair:not(:last-of-type) {
  padding-bottom: 8px;
}

.swal2-container .invalid-time-range-warning {
  padding: 0 !important;
}

.requesting-uber-delivery-widget {
  width: 750px;
  height: 500px;
  place-items: center;
  padding: 80px 0;
  display: grid;
}

.requesting-uber-delivery-widget > img {
  width: 140px;
}

.requesting-uber-delivery-widget .title {
  color: #1c1d3e;
  margin-top: 45px;
  font-size: 25px;
  font-weight: 500;
}

.requesting-uber-delivery-widget .subtitle {
  color: #7d7e92;
  font-size: 17px;
  font-weight: 400;
}

.uber-delivery-request-for-website-order-success-widget {
  width: 750px;
  height: 500px;
  place-items: center;
  padding: 80px 36px;
  display: grid;
}

.uber-delivery-request-for-website-order-success-widget > img {
  width: 140px;
}

.uber-delivery-request-for-website-order-success-widget .title {
  color: #1c1d3e;
  margin-top: 40px;
  font-size: 25px;
  font-weight: 500;
}

.uber-delivery-request-for-website-order-success-widget .subtitle {
  color: #1c1d3e;
  grid-auto-flow: column;
  gap: 6px;
  margin-top: 16px;
  font-size: 17px;
  font-weight: 500;
  display: grid;
}

.uber-delivery-request-for-website-order-success-widget .subtitle .time-since-label-widget {
  color: #4cbba1 !important;
}

.uber-delivery-request-for-website-order-success-widget .actions {
  width: 100%;
  gap: 15px;
  margin-top: 18px;
  display: grid;
}

.uber-delivery-request-for-website-order-success-widget .actions > button {
  padding: 14px;
}

.uber-delivery-request-for-website-order-success-widget .actions > button.cancel-uber-delivery-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
}

.uber-delivery-request-for-website-order-success-widget .actions > button.cancel-uber-delivery-button:focus {
  border: none;
  outline: none;
}

.uber-delivery-request-for-pos-order-failed-widget {
  width: 750px;
  height: 500px;
  place-items: center;
  padding: 50px 36px;
  display: grid;
}

.uber-delivery-request-for-pos-order-failed-widget .warning-icon {
  width: 82px;
}

.uber-delivery-request-for-pos-order-failed-widget .title {
  color: #1c1d3e;
  margin-top: 40px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.uber-delivery-request-for-pos-order-failed-widget .message {
  color: #7d7e92;
  text-align: center;
  grid-auto-flow: column;
  gap: 6px;
  margin-top: 16px;
  font-size: 17px;
  font-weight: 400;
  display: grid;
}

.uber-delivery-request-for-pos-order-failed-widget .actions {
  width: 100%;
  gap: 15px;
  margin-top: 18px;
  display: grid;
}

.uber-delivery-request-for-pos-order-failed-widget .actions > button {
  border-radius: 5px !important;
  padding: 14px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.uber-delivery-request-for-pos-order-failed-widget .actions .retry-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.uber-delivery-request-for-pos-order-failed-widget .actions .retry-button:focus {
  border: none;
  outline: none;
}

.uber-delivery-request-for-pos-order-failed-widget .actions .use-in-house-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
}

.uber-delivery-request-for-pos-order-failed-widget .actions .use-in-house-button > .label {
  color: #3f9c86;
}

.uber-delivery-request-for-pos-order-failed-widget .actions .reject-order-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
  color: #f43f5e !important;
}

.uber-delivery-request-for-pos-order-failed-widget .actions .cancel-request-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #f43f5e;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
  color: #f43f5e !important;
}

.uber-delivery-request-for-website-order-failed-widget {
  width: 750px;
  height: 500px;
  place-items: center;
  padding: 50px 36px;
  display: grid;
}

.uber-delivery-request-for-website-order-failed-widget .warning-icon {
  width: 82px;
}

.uber-delivery-request-for-website-order-failed-widget .title {
  color: #1c1d3e;
  margin-top: 40px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.uber-delivery-request-for-website-order-failed-widget .message {
  color: #7d7e92;
  text-align: center;
  grid-auto-flow: column;
  gap: 6px;
  margin-top: 16px;
  font-size: 17px;
  font-weight: 400;
  display: grid;
}

.uber-delivery-request-for-website-order-failed-widget .actions {
  width: 100%;
  gap: 15px;
  margin-top: 18px;
  display: grid;
}

.uber-delivery-request-for-website-order-failed-widget .actions > button {
  border-radius: 5px !important;
  padding: 14px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.uber-delivery-request-for-website-order-failed-widget .actions .retry-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.uber-delivery-request-for-website-order-failed-widget .actions .retry-button:focus {
  border: none;
  outline: none;
}

.uber-delivery-request-for-website-order-failed-widget .actions .use-in-house-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
}

.uber-delivery-request-for-website-order-failed-widget .actions .use-in-house-button > .label {
  color: #3f9c86;
}

.uber-delivery-request-for-website-order-failed-widget .actions .reject-order-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
  color: #f43f5e !important;
}

.uber-delivery-cancel-payment-confirmation-widget {
  width: 750px;
  height: 500px;
  place-items: center;
  padding: 35px;
  display: grid;
}

.uber-delivery-cancel-payment-confirmation-widget > img {
  width: 82px;
  margin-top: 95px;
}

.uber-delivery-cancel-payment-confirmation-widget .title {
  color: #1c1d3e;
  margin-top: 40px;
  font-size: 24px;
  font-weight: 500;
}

.uber-delivery-cancel-payment-confirmation-widget .subtitle {
  color: #7d7e92;
  grid-auto-flow: column;
  gap: 6px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
  display: grid;
}

.uber-delivery-cancel-payment-confirmation-widget .actions {
  width: 100%;
  gap: 15px;
  margin-top: 20px;
  display: grid;
}

.uber-delivery-cancel-payment-confirmation-widget .actions > button {
  border-radius: 5px !important;
  padding: 14px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.uber-delivery-cancel-payment-confirmation-widget .actions > button.go-back-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.uber-delivery-cancel-payment-confirmation-widget .actions > button.go-back-button:focus {
  border: none;
  outline: none;
}

.uber-delivery-cancel-payment-confirmation-widget .actions > button.cancel-payment-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  color: #f43f5e;
  background-color: #fff;
  border: 1px solid #f43f5e;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
}

.uber-delivery-cancel-payment-confirmation-widget .actions > button.cancel-payment-button:focus {
  border: none;
  outline: none;
}

.uber-delivery-proof-of-delivery-picture-widget > img {
  height: 90vh;
  width: 90vw;
  max-width: 1200px;
  border-radius: 20px;
}

.time-picker .input-box {
  min-width: 165px;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  grid-template-columns: repeat(6, min-content) 1fr;
  align-items: center;
  padding: 14px 16px;
  display: grid;
}

.time-picker .input-box input {
  width: 30px;
  text-align: center;
  border: none;
  padding: 0;
}

.time-picker .input-box .la-clock {
  text-align: right;
  color: #535a64;
  cursor: pointer;
  font-size: 20px;
}

.time-picker .options-wrapper .options {
  z-index: 100;
  height: 260px;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 2px;
  padding: 16px 18px 0;
  display: grid;
  position: absolute;
}

.time-picker .options-wrapper .options .option-list {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  overflow-y: scroll;
}

.time-picker .options-wrapper .options .option-list::-webkit-scrollbar {
  width: 1px;
}

.time-picker .options-wrapper .options .option-list::-webkit-scrollbar-track {
  background: none;
}

.time-picker .options-wrapper .options .option-list::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.time-picker .options-wrapper .options .option {
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 10px;
}

.time-picker .options-wrapper .options .option.selected {
  color: #4cbba1;
}

.time-period-edit-widget {
  width: 100%;
  max-width: 555px;
}

.time-period-edit-widget > .form-input {
  display: flex;
}

.time-period-edit-widget .start-time {
  width: 100%;
  max-width: 200px;
  grid-area: start-time;
  margin-right: 16px;
}

.time-period-edit-widget .duration {
  width: 100%;
  min-width: 100px;
  max-width: 200px;
  grid-area: duration;
  margin-right: 16px;
}

.time-period-edit-widget .duration input {
  padding: 14px;
}

.time-period-edit-widget .end-time-of-day {
  width: 100%;
  min-width: max-content;
  max-width: 120px;
  text-align: center;
  grid-area: end-time;
  align-self: center;
}

.pos-trading-hours-widget {
  padding: 0 20px 50px;
}

.pos-trading-hours-widget form > .form-items {
  grid-gap: unset;
}

.pos-trading-hours-widget form > .form-items .input-titles {
  color: #7d7e92;
  max-width: 650px;
  text-align: center;
  grid-template-columns: 2fr 2fr 1.5fr;
  padding-left: 80px;
  font-size: 12px;
  display: grid;
}

.pos-trading-hours-widget form > .form-items .weekly-hours .daily-hours {
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: 80px 1fr;
  align-items: center;
  padding: 24px 0;
  display: grid;
}

.pos-trading-hours-widget form > .form-items .weekly-hours .daily-hours > .form-item.inline-title {
  background-color: unset;
  font-weight: 600;
}

.pos-trading-hours-widget form > .form-items .weekly-hours .daily-hours .time-period {
  align-items: center;
  display: flex;
}

.pos-trading-hours-widget form > .form-items .weekly-hours .daily-hours .time-period .time-period-edit-widget > .meta {
  display: none;
}

.pos-trading-hours-widget form > .form-items .weekly-hours .daily-hours .time-period .delete-trading-session.action {
  cursor: pointer;
  margin-left: 10px;
}

.pos-trading-hours-widget form > .form-items .weekly-hours .daily-hours .add-time-period {
  color: #4cbba1;
  cursor: pointer;
  display: flex;
}

.pos-trading-hours-widget form > .form-items .weekly-hours .daily-hours .add-time-period .plus-icon {
  margin-right: 5px;
  font-size: 25px;
}

.print-station-print-options-widget {
  min-height: 150px;
  background: #f6f8fb;
  border: 1px solid #dee0e6;
  border-radius: 4px;
  grid-auto-rows: min-content;
  padding: 16px;
  display: grid;
}

.print-station-print-options-widget .label {
  color: #1c1d3e;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 900;
}

.print-station-print-options-widget .number-of-copies {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.print-station-print-options-widget .number-of-copies .name {
  letter-spacing: 0;
  color: #595976;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.print-station-print-options-widget .number-of-copies .quantity {
  width: -moz-fit-content;
  width: fit-content;
  grid-auto-flow: column;
  display: grid;
}

.print-station-print-options-widget .number-of-copies .quantity .btn, .print-station-print-options-widget .number-of-copies .quantity .inc, .print-station-print-options-widget .number-of-copies .quantity .dec {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: grid;
}

.print-station-print-options-widget .number-of-copies .quantity .icon {
  box-shadow: 0 3px 10px -3px #0000004d;
}

.print-station-print-options-widget .number-of-copies .quantity .dec {
  height: 28px;
  width: 28px;
  background: #595976;
  padding: 5px 8px;
}

.print-station-print-options-widget .number-of-copies .quantity .number {
  min-width: 50px;
  text-align: center;
  color: #343553;
  margin: auto 0;
  font-size: 16px;
  font-weight: 500;
}

.print-station-print-options-widget .number-of-copies .quantity .inc {
  height: 28px;
  width: 28px;
  background: #4cbba1;
  padding: 5px 8px;
}

.manage-sold-out-items-widget .no-items {
  text-align: center;
  margin-top: 60px;
}

.manage-sold-out-items-widget .category-name {
  letter-spacing: 0;
  color: #1c1d3e;
  background: #f6f8fb;
  margin-bottom: 10px;
  padding: 24px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.manage-sold-out-items-widget .category-items .item {
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: 1fr max-content min-content;
  grid-template-areas: "item-name availability-label availability-toggle";
  display: grid;
}

.manage-sold-out-items-widget .category-items .item .item-name {
  letter-spacing: 0;
  grid-area: item-name;
  align-items: center;
  padding: 24px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.manage-sold-out-items-widget .category-items .item .item-availability-label {
  letter-spacing: 0;
  grid-area: availability-label;
  align-items: center;
  padding: 24px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.manage-sold-out-items-widget .category-items .item .item-availability-label.sold-out {
  color: #7d7e92;
}

.manage-sold-out-items-widget .category-items .item .item-availability-label.available {
  color: #10b981;
}

.manage-sold-out-items-widget .category-items .item .switch {
  grid-area: availability-toggle;
  align-items: center;
  padding: 5px 20px;
}

.item-print-priority-widget, .connected-store-item-print-priority-widget {
  grid-template-rows: auto;
  display: grid;
}

.item-print-priority-widget .no-priority-items, .connected-store-item-print-priority-widget .no-priority-items {
  padding-bottom: 20px;
}

.item-print-priority-widget .priority-items .title, .item-print-priority-widget .no-priority-items .title, .connected-store-item-print-priority-widget .priority-items .title, .connected-store-item-print-priority-widget .no-priority-items .title {
  color: #1c1d3e;
  margin-bottom: 15px;
  padding: 10px 24px;
  font-size: 25px;
  font-weight: 600;
  line-height: 35px;
  display: flex;
}

.item-print-priority-widget .priority-items .title .number-of-priority-items, .item-print-priority-widget .no-priority-items .title .number-of-priority-items, .connected-store-item-print-priority-widget .priority-items .title .number-of-priority-items, .connected-store-item-print-priority-widget .no-priority-items .title .number-of-priority-items {
  color: #7d7e92;
  padding-left: 20px;
  font-weight: 400;
}

.item-print-priority-widget .priority-items .priority-item, .item-print-priority-widget .no-priority-items .priority-item, .connected-store-item-print-priority-widget .priority-items .priority-item, .connected-store-item-print-priority-widget .no-priority-items .priority-item {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #1c1d3e;
  margin-bottom: 0;
  padding: 24px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.item-print-priority-widget .priority-items .items-per-category .category-name, .item-print-priority-widget .no-priority-items .items-per-category .category-name, .connected-store-item-print-priority-widget .priority-items .items-per-category .category-name, .connected-store-item-print-priority-widget .no-priority-items .items-per-category .category-name {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #1c1d3e;
  background-color: #f6f8fb;
  margin-bottom: 0;
  padding: 15px 24px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.item-print-priority-widget .priority-items .items .item, .item-print-priority-widget .no-priority-items .items .item, .connected-store-item-print-priority-widget .priority-items .items .item, .connected-store-item-print-priority-widget .no-priority-items .items .item {
  border-bottom: 1px solid #f0f0f0;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  display: flex;
}

.item-print-priority-widget .priority-items .items .item .set-priority, .item-print-priority-widget .no-priority-items .items .item .set-priority, .connected-store-item-print-priority-widget .priority-items .items .item .set-priority, .connected-store-item-print-priority-widget .no-priority-items .items .item .set-priority {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4cbba1;
  max-width: 120px;
  background-color: #0000;
  border: 1px solid #4cbba1;
  margin: 0;
  padding: 10px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.item-print-priority-widget .priority-items .items .item .set-priority .label, .item-print-priority-widget .no-priority-items .items .item .set-priority .label, .connected-store-item-print-priority-widget .priority-items .items .item .set-priority .label, .connected-store-item-print-priority-widget .no-priority-items .items .item .set-priority .label {
  font-size: 17px;
  font-weight: 600;
  line-height: 23px;
}

.item-print-priority-widget .priority-items .items .item .item-name, .item-print-priority-widget .no-priority-items .items .item .item-name, .connected-store-item-print-priority-widget .priority-items .items .item .item-name, .connected-store-item-print-priority-widget .no-priority-items .items .item .item-name {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #1c1d3e;
  margin-bottom: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.item-print-priority-widget .item-priority-drag-and-drop-widget, .connected-store-item-print-priority-widget .item-priority-drag-and-drop-widget {
  margin-bottom: 35px;
  padding: 0 24px;
}

.item-print-priority-widget .item-priority-drag-and-drop-widget .item, .connected-store-item-print-priority-widget .item-priority-drag-and-drop-widget .item {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  align-items: center;
  margin-bottom: 15px;
  padding: 18px;
  display: flex;
  box-shadow: 0 3px 10px -3px #0000004d;
}

.item-print-priority-widget .item-priority-drag-and-drop-widget .item .label, .connected-store-item-print-priority-widget .item-priority-drag-and-drop-widget .item .label {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #1c1d3e;
  flex: auto;
  margin-bottom: 0;
  padding-left: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.item-print-priority-widget .item-priority-drag-and-drop-widget .item .drag-handle, .connected-store-item-print-priority-widget .item-priority-drag-and-drop-widget .item .drag-handle {
  cursor: move;
  touch-action: none;
  flex: 0;
  align-items: center;
  padding: 4px;
  display: flex;
}

.item-print-priority-widget .item-priority-drag-and-drop-widget .item .remove, .connected-store-item-print-priority-widget .item-priority-drag-and-drop-widget .item .remove {
  flex: 0;
  align-items: center;
  display: flex;
}

.login-widget {
  height: 100vh;
}

.login-widget > .header {
  width: 0;
  margin: 0 !important;
}

.login-widget > .body {
  height: 100%;
}

.login-widget .login {
  height: 100%;
  grid-template-columns: 30% 70%;
  grid-auto-flow: column;
  display: grid;
}

.login-widget .login .illustration {
  text-align: center;
  background-image: url("login-hero-image.493f222c.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 0;
}

.login-widget .login .illustration .text {
  letter-spacing: 0;
  color: #1c1d3e;
  width: 90%;
  margin: 10px auto;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
}

.login-widget .login .form {
  background-color: #2d2e4c;
  grid-template-rows: min-content 1fr min-content;
  align-items: center;
  display: grid;
}

.login-widget .login .form .trouble-logging-in {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #7d7e92;
  text-align: end;
  cursor: pointer;
  margin: 50px 60px 0 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.login-widget .login .form > form.default {
  width: 60%;
  max-width: 450px;
  margin: 0 auto;
}

.login-widget .login .form > form.default .form-input {
  position: relative;
}

.login-widget .login .form > form.default .form-input input {
  color: #fff;
  background-color: #ffffff21;
  border: none;
  outline: none;
}

.login-widget .login .form > form.default .form-input input::placeholder {
  color: #ffffff90;
}

.login-widget .login .form > form.default .form-input.invalid input {
  background-color: #da656510;
  border: 1px solid #da6565;
}

.login-widget .login .form > form.default .form-input.invalid input::placeholder {
  color: #ffffff90;
}

.login-widget .login .form > form.default .form-input .show-password {
  color: #ffffff90;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  font-size: 12px;
  position: absolute;
  top: 12px;
  right: 15px;
}

.login-widget .login .form > form.default .login-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  width: 100%;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 20px 0 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.login-widget .login .form > form.default .login-button:focus {
  border: none;
  outline: none;
}

.login-widget .login .form .footer {
  color: #7d7e92;
  text-indent: 22px;
  margin: 20px auto;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  position: relative;
}

.login-widget .login .form .footer > span {
  height: 15px;
  font-size: 22px;
  position: absolute;
  top: 4px;
  left: -15px;
}

.employee-time-clock-actions-widget {
  max-height: 95vh;
  background-color: #f6f8fb;
  border-radius: 20px;
  grid-template-rows: min-content min-content 1fr;
  grid-template-columns: minmax(100%, 414px);
  gap: 4px;
  padding: 24px;
  display: grid;
}

.employee-time-clock-actions-widget .employee-name {
  justify-self: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.employee-time-clock-actions-widget .current-date-and-time-info-widget {
  flex-direction: column-reverse;
  align-items: center;
  margin: 10px 0 20px;
  display: flex;
}

.employee-time-clock-actions-widget .current-date-and-time-info-widget .date {
  color: #7d7e92;
  padding-bottom: 4px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.employee-time-clock-actions-widget .current-date-and-time-info-widget .time {
  color: #1c1d3e;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.employee-time-clock-actions-widget .options {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  gap: 10px;
  display: grid;
  overflow-y: scroll;
}

.employee-time-clock-actions-widget .options::-webkit-scrollbar {
  width: 1px;
}

.employee-time-clock-actions-widget .options::-webkit-scrollbar-track {
  background: none;
}

.employee-time-clock-actions-widget .options::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.employee-time-clock-actions-widget .options .clock-in-option {
  min-height: 125px;
  cursor: pointer;
  color: #3f9c86;
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.employee-time-clock-actions-widget .options .clock-in-option .label {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.employee-time-clock-actions-widget .options .clock-out-option {
  min-height: 125px;
  cursor: pointer;
  color: #9f1239;
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.employee-time-clock-actions-widget .options .clock-out-option .label {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.employee-time-clock-actions-widget .options .break-option {
  min-height: 125px;
  cursor: pointer;
  color: #d97706;
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.employee-time-clock-actions-widget .options .break-option .label {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.employee-time-clock-actions-widget .options .timesheets-option {
  min-height: 125px;
  cursor: pointer;
  color: #2563eb;
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.employee-time-clock-actions-widget .options .timesheets-option .label {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.employee-time-clock-actions-widget .options .staff-records-option {
  min-height: 125px;
  cursor: pointer;
  color: #a601f4;
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.employee-time-clock-actions-widget .options .staff-records-option .label {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.cancelled-order-notification-widget, .failed-order-notification-widget, .new-order-notification-widget, .new-table-booking-notification-widget {
  z-index: 999;
  -webkit-animation: .5s 1.5s forwards slide;
  cursor: pointer;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  align-items: center;
  animation: .5s 1.5s forwards slide;
  display: flex;
  position: fixed;
  bottom: 4vh;
}

@keyframes slide {
  100% {
    left: 70px;
  }
}

@keyframes lockScreenSlide {
  100% {
    left: 0;
  }
}

.lock-screen-widget .employee-time-clock-actions .shifts-button, .lock-screen-widget .employee-time-clock-pin .shifts-button, .lock-screen-widget .login-screen .shifts-button {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border-radius: 4px;
  justify-self: end;
  margin: 25px 40px 10px;
  padding: 14px 28px;
  font-weight: 500;
  display: flex;
}

.lock-screen-widget .employee-time-clock-actions .shifts-button img, .lock-screen-widget .employee-time-clock-pin .shifts-button img, .lock-screen-widget .login-screen .shifts-button img {
  margin-right: 10px;
}

.lock-screen-widget .employee-time-clock-actions .disabled.shifts-button, .lock-screen-widget .employee-time-clock-pin .disabled.shifts-button, .lock-screen-widget .login-screen .disabled.shifts-button {
  color: #1c1d3e;
  pointer-events: none;
  background-color: #dee0e6;
  border: 1px solid #dee0e6;
}

.lock-screen-widget .employee-time-clock-actions .disabled.shifts-button .shifts-image, .lock-screen-widget .employee-time-clock-pin .disabled.shifts-button .shifts-image, .lock-screen-widget .login-screen .disabled.shifts-button .shifts-image {
  filter: invert(.86) sepia() saturate(5) hue-rotate(175deg);
}

.lock-screen-widget .employee-time-clock-actions .back-to-lock-screen-link, .lock-screen-widget .employee-time-clock-pin .back-to-lock-screen-link, .lock-screen-widget .notification .back-to-lock-screen-link {
  min-width: 250px;
  height: min-content;
  color: #dee0e6;
  cursor: pointer;
  align-items: center;
  padding: 40px 30px;
  font-size: 16px;
  display: flex;
}

.lock-screen-widget .employee-time-clock-actions .back-to-lock-screen-link img, .lock-screen-widget .employee-time-clock-pin .back-to-lock-screen-link img, .lock-screen-widget .notification .back-to-lock-screen-link img {
  height: 24px;
  margin-right: 8px;
}

.lock-screen-widget .employee-time-clock-staff-records-widget, .lock-screen-widget .employee-time-clock-timesheet-widget {
  padding-bottom: 20px;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .labels, .lock-screen-widget .employee-time-clock-timesheet-widget .labels {
  color: #65667d;
  background-color: #fff;
  padding: 22px 40px 8px;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-header, .lock-screen-widget .employee-time-clock-timesheet-widget .employee-time-clock-header {
  padding-bottom: 7.5px;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-header .header-title, .lock-screen-widget .employee-time-clock-timesheet-widget .employee-time-clock-header .header-title {
  color: #fff;
  margin-top: 4px;
  margin-left: 16px;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-header .date-today, .lock-screen-widget .employee-time-clock-timesheet-widget .employee-time-clock-header .date-today {
  color: #fff;
  margin-right: 17px;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-header .date-today span, .lock-screen-widget .employee-time-clock-timesheet-widget .employee-time-clock-header .date-today span {
  color: #fff;
  margin-bottom: 4px;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-header .go-back-link, .lock-screen-widget .employee-time-clock-timesheet-widget .employee-time-clock-header .go-back-link {
  margin-left: 15px;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-header .go-back-link span, .lock-screen-widget .employee-time-clock-timesheet-widget .employee-time-clock-header .go-back-link span {
  color: #fff;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-header .go-back-link > .arrow-left, .lock-screen-widget .employee-time-clock-timesheet-widget .employee-time-clock-header .go-back-link > .arrow-left {
  background-image: url("icon-chevron-left-filled.ddf1b2b2.svg");
}

.lock-screen-widget {
  height: 100vh;
  z-index: 2000;
  background-image: url("lock-screen-bg.e5aeaac2.png"), url("lock-screen-bg.e5aeaac2.png"), url("lock-screen-bg.e5aeaac2.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.lock-screen-widget .login-screen {
  box-sizing: border-box;
  height: inherit;
  grid-template-columns: 48% 1fr;
  grid-template-areas: "date actions"
                       "weather actions";
  display: grid;
}

.lock-screen-widget .login-screen .login-actions {
  grid-area: actions;
  grid-template-rows: min-content 1fr min-content;
  grid-template-areas: "shifts"
                       "pin"
                       "logout";
  display: grid;
}

.lock-screen-widget .login-screen .current-date-and-time-info-widget {
  text-align: start;
  flex-direction: column-reverse;
  grid-area: date;
  justify-content: flex-end;
  padding-top: 96px;
  padding-left: 48px;
  display: flex;
}

.lock-screen-widget .login-screen .current-date-and-time-info-widget .time {
  letter-spacing: .015em;
  color: #fff;
  font-size: 72px;
  font-weight: 400;
  line-height: 115px;
}

.lock-screen-widget .login-screen .current-date-and-time-info-widget .date {
  color: #dee0e6;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
}

.lock-screen-widget .login-screen .employee-pin-widget {
  grid-area: pin;
}

.lock-screen-widget .login-screen .weather-info-widget {
  width: -moz-fit-content;
  width: fit-content;
  grid-area: weather;
  align-self: end;
  padding: 0 0 156px 48px;
}

.lock-screen-widget .login-screen .weather-info-widget .temperature {
  color: #fff;
  letter-spacing: .005em;
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
}

.lock-screen-widget .login-screen .weather-info-widget .location {
  color: #dee0e6;
  letter-spacing: .015em;
  font-size: 32px;
  font-weight: 400;
  line-height: 29px;
}

.lock-screen-widget .login-screen .shifts-button {
  color: #fff;
  border: 1px solid #fff;
  grid-area: shifts;
}

.lock-screen-widget .login-screen .logout-link {
  letter-spacing: .005em;
  color: #fff;
  cursor: pointer;
  grid-area: logout;
  justify-content: end;
  align-items: center;
  padding-bottom: 20px;
  padding-right: 28px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: underline;
  display: flex;
}

.lock-screen-widget .new-order-notification-widget, .lock-screen-widget .new-table-booking-notification-widget, .lock-screen-widget .failed-order-notification-widget, .lock-screen-widget .cancelled-order-notification-widget {
  left: 0 !important;
}

.lock-screen-widget .notification {
  height: inherit;
  background-image: url("lock-screen-bg-blurred.c2593856.png");
  background-size: cover;
  grid-template-columns: min-content 1fr;
  display: grid;
}

.lock-screen-widget .notification .employee-pin-widget {
  grid-column: 2;
  place-self: center;
}

.lock-screen-widget .employee-time-clock-pin, .lock-screen-widget .employee-time-clock-actions {
  height: inherit;
  grid-template-columns: min-content 1fr min-content;
  justify-items: center;
  display: grid;
}

.lock-screen-widget .employee-time-clock-actions .employee-time-clock-actions-widget {
  align-self: center;
}

.lock-screen-widget .employee-time-clock-timesheet-widget .employee-time-clock-date-range-picker {
  background-color: #fff;
  border-top-left-radius: 8px;
  padding-left: 24px;
}

.lock-screen-widget .employee-time-clock-timesheet-widget .actions {
  background-color: #fff;
  border-top-right-radius: 8px;
  padding: 22px 28px 22px 0;
}

.lock-screen-widget .employee-time-clock-timesheet-widget .employee-time-clock-shift-list-widget {
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  padding: 0 16px 32px;
}

.lock-screen-widget .employee-time-clock-timesheet-widget .employee-time-clock-shift-list-widget > .spinner-container {
  color: #1c1d3e;
}

.lock-screen-widget .employee-time-clock-timesheet-widget .employee-time-clock-shift-list-widget > .no-records {
  border-color: #65667d;
  margin-bottom: 0;
}

.lock-screen-widget .employee-time-clock-timesheet-widget .employee-time-clock-shift-list-widget > .employee-time-clock-shift-list-item:not(:first-child.active) {
  border-color: #65667d;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-date-range-picker {
  margin-bottom: unset;
  background-color: #fff;
  border-radius: 12px 12px 0 0;
  padding: 30px 28px 16px;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .staff-records-list-widget {
  background-color: #fff;
  border-radius: 0 0 12px 12px;
  flex: auto;
  padding: 0 16px;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .staff-records-list-widget .staff-records-list-item {
  border-color: #65667d;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .staff-records-list-widget .staff-records-list-item:nth-last-child(2) {
  border-bottom: 1px solid #65667d;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-shift-list-edit-widget {
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  padding: 0 16px 32px;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-shift-list-edit-widget > .spinner-container {
  color: #1c1d3e;
  border: 1px solid #65667d;
  border-radius: 8px;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-shift-list-edit-widget > .no-records {
  border-color: #65667d;
  margin-bottom: 0;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-shift-list-edit-widget > .employee-time-clock-shift-list-item-edit-widget {
  border-color: #65667d;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-shift-list-edit-widget > .employee-time-clock-shift-list-item-edit-widget.invalid {
  border-color: #f43f5e;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-shift-list-edit-widget > .employee-time-clock-shift-list-item-edit-widget.invalid:last-child:not(:only-child) {
  border-bottom: 1px solid #f43f5e;
  border-radius: 0 0 8px 8px;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-shift-list-edit-widget > .employee-time-clock-shift-list-item-edit-widget.invalid + div:has(.edit) {
  border-top: none;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-shift-list-edit-widget > .employee-time-clock-shift-list-item-edit-widget.edit:not(.invalid) {
  border-color: #3b82f6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-shift-list-edit-widget > .employee-time-clock-shift-list-item-edit-widget.edit:not(.invalid):last-child:not(:only-child) {
  border-bottom: 1px solid #3b82f6;
  border-radius: 0 0 8px 8px;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-shift-list-edit-widget > .employee-time-clock-shift-list-item-edit-widget.edit + div:not(.invalid) {
  border-top: none;
}

.lock-screen-widget .employee-time-clock-staff-records-widget .employee-time-clock-shift-list-edit-widget > .employee-time-clock-shift-list-item-edit-widget.edit + div:has(.edit):not(.invalid) {
  border-top: none;
}

.swal2-container.show-on-lockscreen, .swal2-container.invalid-warning, .swal2-container.confirm-clock-out-question, .swal2-container.confirm-quit-editing-question {
  z-index: 2001 !important;
}

#toasts-container:has(.employee-time-clock-disabled-warning) {
  z-index: 2001 !important;
}

#modal-container:has(.iso-date-range-picker-widget.employee-time-clock-date-range-picker-modal) {
  z-index: 2001;
  position: absolute;
}

#modal-container:has(.iso-date-picker-widget.shift-date-picker) {
  z-index: 2001;
  position: absolute;
}

@media only screen and (max-height: 700px) {
  .lock-screen-widget .login-screen {
    grid-template-columns: unset;
  }

  .lock-screen-widget .login-screen .current-date-and-time-info-widget {
    padding-top: 56px;
  }

  .lock-screen-widget .login-screen .login-actions {
    grid-template: "pin shifts" min-content
                   "pin ." 1fr
                   "pin logout" max-content
                   / 1fr max-content;
    grid-area: actions;
    display: grid;
  }

  .lock-screen-widget .login-screen .login-actions .shifts-button {
    margin: 25px 28px 10px;
  }

  .lock-screen-widget .login-screen .login-actions .employee-pin-widget {
    place-self: center;
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .lock-screen-widget .login-screen .current-date-and-time-info-widget .time {
    text-wrap: nowrap;
    font-size: 45px;
    line-height: 60px;
  }

  .lock-screen-widget .login-screen .current-date-and-time-info-widget .date {
    font-size: 30px;
    line-height: 35px;
  }
}

.employee-discount-pin-widget .employee-pin-widget {
  padding: 25px 70px;
}

.employee-discount-pin-widget .employee-pin-widget .amount {
  padding-bottom: 6px;
  font-weight: 500;
}

.employee-discount-pin-widget .employee-pin-widget .message {
  font-size: 16px;
}

.employee-discount-pin-widget .employee-pin-widget .number-pad {
  margin-bottom: 0;
}

.employee-discount-pin-widget .employee-pin-widget .cancel-button, .employee-discount-pin-widget .employee-pin-widget .delete-button {
  letter-spacing: .5px;
  font-weight: 500;
  color: #4c4e68 !important;
}

.employee-pin-widget {
  text-align: center;
  width: 470px;
  border-radius: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  padding: 25px 85px;
  display: flex;
  box-shadow: 0 0 24px 8px #0000001f;
}

.employee-pin-widget button {
  -webkit-tap-highlight-color: transparent;
}

.employee-pin-widget.dark {
  background: linear-gradient(#f7f9fce6 0%, #ffffffe6 100%);
}

.employee-pin-widget.dark .pin-input {
  border: 1px solid #9597a7;
}

.employee-pin-widget.dark .number-pad .number-button {
  color: #fff;
  background: linear-gradient(#343553 0%, #343553cc 100%);
  box-shadow: 0 2px 3px #0003, 0 0 2px #0000001a;
}

.employee-pin-widget.light {
  background: #fff;
}

.employee-pin-widget.light .pin-input {
  border: 1px solid #9597a7;
}

.employee-pin-widget.light .number-pad .number-button {
  color: #1c1d3e;
  box-shadow: none;
  background: #dee0e6;
  font-weight: bold;
}

.employee-pin-widget.order-view-pin-modal {
  background: #fff;
  padding: 25px 65px;
}

.employee-pin-widget.order-view-pin-modal .pin-input {
  border: 1px solid #9597a7;
}

.employee-pin-widget.order-view-pin-modal .number-pad .number-button {
  color: #1c1d3e;
  box-shadow: none;
  background: #dee0e6;
  font-weight: bold;
}

.employee-pin-widget.order-view-pin-modal .title {
  font-size: 16px;
}

.employee-pin-widget.order-view-pin-modal .number-pad {
  grid-gap: 21px;
  margin: 20px 0 0;
}

.employee-pin-widget.order-view-pin-modal .number-pad .number-button {
  padding: 20px 27px;
  font-size: 27px;
}

.employee-pin-widget .title {
  color: #1c1d3e;
  white-space: pre-wrap;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}

.employee-pin-widget .pin-inputs {
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.employee-pin-widget .pin-inputs :nth-child(1), .employee-pin-widget .pin-inputs :nth-child(2), .employee-pin-widget .pin-inputs :nth-child(3) {
  margin-right: 24px;
}

.employee-pin-widget .pin-input {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.employee-pin-widget .pin-input-filled {
  background-color: #1c1d3e;
}

.employee-pin-widget .pin-input-error {
  background-color: #0000;
  border-color: #9f1239 !important;
}

.employee-pin-widget .error-message {
  color: #9f1239;
  margin-bottom: 15px;
  font-size: 13px;
}

.employee-pin-widget .number-pad {
  grid-gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

.employee-pin-widget .number-pad .number-button {
  cursor: pointer;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  padding: 23px 32px;
  font-size: 32px;
  line-height: 38px;
  display: flex;
}

.employee-pin-widget .number-pad .number-button:nth-child(10) {
  grid-area: 4 / 2;
}

.employee-pin-widget .number-pad .delete-button, .employee-pin-widget .number-pad .cancel-button {
  max-width: 82px;
  background-color: #0000;
  grid-area: 4 / 3;
  font-size: 16px;
  font-weight: bold;
}

.employee-pin-widget .number-pad .delete-button {
  color: #4c4e68;
}

.employee-pin-widget .number-pad .cancel-button {
  color: #7d7e92;
}

.employee-pin-widget .restoplus-icon {
  justify-content: center;
  align-items: center;
  margin-top: 23px;
  display: flex;
}

.employee-pin-widget .restoplus-icon .powered-by {
  margin-right: 8px;
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
}

.employee-pin-widget .restoplus-icon .icon {
  width: 74px;
}

@media only screen and (max-height: 754px) {
  .employee-pin-widget {
    width: 430px;
    padding: 25px 85px;
  }

  .employee-pin-widget .pin-inputs {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .employee-pin-widget .pin-inputs .pin-input {
    width: 32px;
    height: 32px;
  }

  .employee-pin-widget .error-message {
    margin-bottom: 10px;
  }

  .employee-pin-widget .number-pad {
    grid-gap: 16px;
  }

  .employee-pin-widget .number-pad .number-button {
    padding: 19px 28px;
  }

  .employee-pin-widget .number-pad .delete-button, .employee-pin-widget .number-pad .cancel-button {
    max-width: 76px;
  }
}

.tag-along-print-stations-widget .empty, .connected-store-tag-along-print-stations-widget .empty {
  width: max-content;
  color: #7d7e92;
  margin: 40px auto;
}

.tag-along-print-stations-widget .form-items, .connected-store-tag-along-print-stations-widget .form-items {
  grid-row-gap: 0;
}

.tag-along-print-stations-widget .form-items .inline-title, .connected-store-tag-along-print-stations-widget .form-items .inline-title {
  background-color: #f6f8fb;
}

.tag-along-print-stations-widget .form-items .inline-title label, .connected-store-tag-along-print-stations-widget .form-items .inline-title label {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #1c1d3e;
  margin-bottom: 22px;
  margin-left: 2px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.tag-along-print-stations-widget .items, .connected-store-tag-along-print-stations-widget .items {
  margin-bottom: 10px;
}

.tag-along-print-stations-widget .items .item, .connected-store-tag-along-print-stations-widget .items .item {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 20px;
}

.permission-manager .modal {
  cursor: "pointer";
}

.permission-manager .modal .link {
  pointer-events: none;
}

.permission-manager .page {
  margin: 20px;
}

.permission-manager.loading {
  height: 100%;
  padding-left: 70px;
}

.permission-manager.no-employee {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 70px;
  display: flex;
}

.weather-info-widget {
  height: -moz-fit-content;
  height: fit-content;
  grid-template-areas: "icon temperature"
                       "icon location";
  gap: 4px 24px;
  display: grid;
}

.weather-info-widget img {
  grid-area: icon;
}

.weather-info-widget .temperature {
  letter-spacing: .05em;
  grid-area: temperature;
  align-self: end;
  font-size: 25px;
  font-weight: 500;
  line-height: 35px;
}

.weather-info-widget .temperature span {
  font-weight: 600;
}

.weather-info-widget .location {
  color: #7d7e92;
  grid-area: location;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.current-date-and-time-info-widget {
  text-align: right;
}

.current-date-and-time-info-widget .time {
  margin-bottom: 4px;
  font-size: 25px;
  font-weight: 600;
  line-height: 35px;
}

.current-date-and-time-info-widget .date {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #4c4e68;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.pos-more-widget .store-pos-info {
  height: min-content;
  color: #1c1d3e;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  grid-template-columns: min-content 1fr 1fr;
  grid-template-areas: "logo store-name actions"
                       "logo name actions"
                       "logo id actions";
  padding: 20px;
  display: grid;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.pos-more-widget .store-pos-info .store-name {
  letter-spacing: 0;
  color: #1c1d3e;
  grid-area: store-name;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.pos-more-widget .store-pos-info .pos-terminal-name {
  grid-area: name;
}

.pos-more-widget .store-pos-info .pos-terminal-name span {
  font-weight: bolder;
}

.pos-more-widget .store-pos-info .pos-terminal-id {
  grid-area: id;
}

.pos-more-widget .store-pos-info .pos-terminal-id span {
  font-weight: bolder;
}

.pos-more-widget .store-pos-info .image {
  grid-area: logo;
  margin-right: 15px;
}

.pos-more-widget .store-pos-info .image img {
  width: 80px;
  border-radius: 10px;
}

.pos-more-widget .store-pos-info .actions {
  grid-area: actions;
  justify-self: flex-end;
  margin-top: 20px;
}

.pos-more-widget .store-pos-info .actions .logout {
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #f43f5e;
  margin: 0;
  padding: 10px 15px;
  font-size: 15px;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #f43f5e !important;
}

.pos-more-widget .quick-actions .title, .pos-more-widget .reports .title, .pos-more-widget .miscellaneous .title, .pos-more-widget .settings .title, .pos-more-widget .color-settings .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin: 30px 0 10px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
}

.pos-more-widget .quick-actions .card, .pos-more-widget .reports .card, .pos-more-widget .miscellaneous .card, .pos-more-widget .settings .card, .pos-more-widget .color-settings .card {
  width: 100%;
  grid-template-columns: repeat(auto-fit, 260px);
  gap: 24px;
  display: grid;
}

.pos-more-widget .quick-actions .card .link, .pos-more-widget .reports .card .link, .pos-more-widget .miscellaneous .card .link, .pos-more-widget .settings .card .link, .pos-more-widget .color-settings .card .link {
  width: 260px;
  height: 170px;
  text-align: center;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  margin: 0;
  padding: 40px 0;
  display: grid;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.pos-more-widget .quick-actions .card .link *, .pos-more-widget .reports .card .link *, .pos-more-widget .miscellaneous .card .link *, .pos-more-widget .settings .card .link *, .pos-more-widget .color-settings .card .link * {
  all: unset;
}

.pos-more-widget .quick-actions .card .link .icon img, .pos-more-widget .reports .card .link .icon img, .pos-more-widget .miscellaneous .card .link .icon img, .pos-more-widget .settings .card .link .icon img, .pos-more-widget .color-settings .card .link .icon img {
  width: 42px;
}

.pos-more-widget .quick-actions .card .link .label, .pos-more-widget .reports .card .link .label, .pos-more-widget .miscellaneous .card .link .label, .pos-more-widget .settings .card .link .label, .pos-more-widget .color-settings .card .link .label {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-top: 16px;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.pos-grand-total-edit-widget {
  padding: 24px;
}

.pos-grand-total-edit-widget .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 36px;
  padding: 0 146px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.pos-grand-total-edit-widget .custom-grand-total-input-box {
  border: 1px solid #ddd;
  border-radius: 8px;
  grid-template-columns: min-content 1fr;
  padding: 12.75px 20px;
  display: grid;
}

.pos-grand-total-edit-widget .custom-grand-total-input-box .icon {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  padding-top: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.pos-grand-total-edit-widget .custom-grand-total-input-box .custom-grand-total-input {
  align-items: center;
  display: grid;
}

.pos-grand-total-edit-widget .custom-grand-total-input-box .custom-grand-total-input input {
  text-align: right;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
  line-height: 35px;
  overflow: hidden;
}

.pos-grand-total-edit-widget .custom-grand-total-input-box .custom-grand-total-input input::-webkit-outer-spin-button, .pos-grand-total-edit-widget .custom-grand-total-input-box .custom-grand-total-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pos-grand-total-edit-widget .custom-grand-total-input-box .custom-grand-total-input input[type="number"] {
  -moz-appearance: textfield;
}

.pos-grand-total-edit-widget .summary {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #7d7e92;
  background: #f6f8fb;
  border-radius: 8px;
  margin-top: 24px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.pos-grand-total-edit-widget .summary .info, .pos-grand-total-edit-widget .summary .new-grand-total {
  padding: 16px;
}

.pos-grand-total-edit-widget .summary .info > div, .pos-grand-total-edit-widget .summary .new-grand-total {
  justify-content: space-between;
  display: flex;
}

.pos-grand-total-edit-widget .summary .discount, .pos-grand-total-edit-widget .summary .surcharge {
  margin-top: 8px;
}

.pos-grand-total-edit-widget .summary .discount .amount {
  color: #da6565;
}

.pos-grand-total-edit-widget .summary .surcharge .amount {
  color: #4cbba1;
}

.pos-grand-total-edit-widget .summary .new-grand-total {
  letter-spacing: 0;
  color: #1c1d3e;
  border-top: 1px solid #d9d9e6;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.pos-grand-total-edit-widget .actions {
  grid-auto-flow: column;
  gap: 12px;
  margin-top: 24px;
  display: grid;
}

.pos-grand-total-edit-widget .actions button {
  width: 100%;
}

.pos-grand-total-edit-widget .actions .confirm-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.pos-grand-total-edit-widget .actions .confirm-button:focus {
  border: none;
  outline: none;
}

.pos-grand-total-edit-widget .actions .cancel-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #7d7e92;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.split-by-amount-input-widget {
  width: 60vw;
  max-width: 900px;
  place-items: center;
  padding: 60px 48px;
  display: grid;
}

.split-by-amount-input-widget .title {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.split-by-amount-input-widget .amount-to-pay-input-box {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  grid-template-columns: min-content 1fr;
  padding: 12.75px 20px;
  display: grid;
}

.split-by-amount-input-widget .amount-to-pay-input-box .dollar-sign-icon {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #7d7e92;
  margin-bottom: 10px;
  padding-top: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.split-by-amount-input-widget .amount-to-pay-input-box .amount-to-pay-value {
  align-items: center;
  display: grid;
}

.split-by-amount-input-widget .amount-to-pay-input-box .amount-to-pay-value input {
  text-align: right;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
  line-height: 35px;
  overflow: hidden;
}

.split-by-amount-input-widget .payment-info {
  letter-spacing: 0;
  color: #1c1d3e;
  width: 100%;
  background: linear-gradient(90deg, #f6f8fb8c 0%, #e9e9e95f 100%);
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 22px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.split-by-amount-input-widget .payment-info .total, .split-by-amount-input-widget .payment-info .amount-paid, .split-by-amount-input-widget .payment-info .balance {
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  display: flex;
}

.split-by-amount-input-widget .payment-info .total, .split-by-amount-input-widget .payment-info .amount-paid {
  border-bottom: 1px solid #dee0e6;
}

.split-by-amount-input-widget .payment-info .balance .amount, .split-by-amount-input-widget .payment-info .balance .amount:first-letter {
  color: #f43f5e;
}

.split-by-amount-input-widget .actions {
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 8px;
  display: grid;
}

.split-by-amount-input-widget .actions.first-payment {
  grid-template-columns: repeat(2, 1fr);
}

.split-by-amount-input-widget .actions button {
  width: 100%;
}

.split-by-amount-input-widget .actions button.confirm-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.split-by-amount-input-widget .actions button.confirm-button:focus {
  border: none;
  outline: none;
}

.split-by-amount-input-widget .actions button.back-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.split-by-amount-input-widget .actions button.pay-balance-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #1c1d3e;
  background-color: #dee0e6;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.split-by-amount-input-widget input::-webkit-outer-spin-button, .split-by-amount-input-widget input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.split-by-amount-input-widget input[type="number"] {
  -moz-appearance: textfield;
}

h1 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

h2 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 22px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

h3 {
  letter-spacing: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

h4 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

h5 {
  letter-spacing: 0;
  color: #1c1d3e;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

p, pre {
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

form input[type="text"], form input[type="number"], form input[type="password"], form input[type="tel"], form textarea {
  font-size: 15px;
}

.cta {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.cta:focus {
  border: none;
  outline: none;
}

.secondary-cta {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #4cbba1;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
}

.tertiary-cta {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
}

.tertiary-cta:focus {
  border: none;
  outline: none;
}

.link-cta {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.cancel-split-payment .swal2-modal {
  width: 530px !important;
  padding: 32px 24px 0 !important;
}

.cancel-split-payment .swal2-header {
  margin-bottom: 16px;
  padding: 0 !important;
}

.cancel-split-payment .swal2-content {
  letter-spacing: .1px;
  padding: 0;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px !important;
  font-size: 20px !important;
}

.cancel-split-payment .swal2-actions {
  padding: 0 !important;
}

.cancel-split-payment .swal2-actions .confirm-button {
  text-transform: none !important;
}

.cancel-split-payment .swal2-actions .cancel-button {
  color: #7d7e92;
  border: 1px solid #7d7e92;
  text-transform: none !important;
}

.cancel-payment .confirm-button {
  text-transform: none !important;
  background: #9f1239 !important;
  border: 1px solid #9f1239 !important;
  border-radius: 4px !important;
}

.cancel-payment .cancel-button {
  text-transform: none !important;
  border: 1px solid #7d7e92 !important;
  border-radius: 4px !important;
}

.split-by-amount-payment-type-select-widget {
  width: 50vw;
  max-width: 700px;
  color: #1c1d3e;
  gap: 40px;
  margin: 0 auto;
  padding: 40px 32px 32px;
  display: grid;
}

.split-by-amount-payment-type-select-widget .title {
  text-align: center;
  letter-spacing: .36px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
}

.split-by-amount-payment-type-select-widget .actions {
  padding: 0 24px;
}

.split-by-amount-payment-type-select-widget .actions button.cancel, .split-by-amount-payment-type-select-widget .actions button.back {
  width: 100%;
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.split-by-amount-payment-type-select-widget .header {
  gap: 8px;
  display: grid;
}

.split-by-amount-payment-type-select-widget .subtitle {
  letter-spacing: .1px;
  text-align: center;
  color: #7d7e92;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.split-by-amount-payment-type-select-widget .subtitle span {
  color: #3f9c86;
}

.split-by-amount-payment-type-select-widget .payment-types {
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  gap: 24px;
  display: grid;
}

.split-by-amount-payment-type-select-widget .payment-type {
  letter-spacing: .09px;
  text-align: center;
  cursor: pointer;
  grid-gap: 24px;
  background: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 40px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  display: grid;
  box-shadow: 0 0 24px #00000014;
}

.split-by-amount-payment-type-select-widget .payment-type:hover {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  outline: 2px solid #4f506a;
}

.split-by-amount-payment-type-select-widget .payment-type > .icon {
  place-self: center;
}

.split-by-amount-payment-summary-widget {
  width: 65vw;
  max-width: 900px;
  padding: 40px 32px 32px;
}

.split-by-amount-payment-summary-widget > * {
  margin-top: 24px;
}

.split-by-amount-payment-summary-widget > :first-child {
  margin-top: 0;
}

.split-by-amount-payment-summary-widget > .header {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.split-by-amount-payment-summary-widget > .header > .icon {
  height: 64px;
  width: 64px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.split-by-amount-payment-summary-widget > .header > .icon > img {
  height: 100%;
}

.split-by-amount-payment-summary-widget > .header > .label {
  margin-top: 24px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.split-by-amount-payment-summary-widget > .actions {
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "cancel-button finalise-button"
                       "back-to-cart-button finalise-button"
                       "cancel-order-button cancel-order-button";
  display: grid;
}

.split-by-amount-payment-summary-widget > .actions > .cancel-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  grid-area: cancel-button;
  margin: 0 15px 0 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.split-by-amount-payment-summary-widget > .actions > .back-to-cart-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  grid-area: back-to-cart-button;
  margin: 0 15px 0 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.split-by-amount-payment-summary-widget > .actions > .cancel-order-button {
  color: #9f1239;
  font-weight: 500px;
  text-align: center;
  cursor: pointer;
  grid-area: cancel-order-button;
  margin-top: 30px;
  font-size: 16px;
  text-decoration: underline;
}

.split-by-amount-payment-summary-widget > .actions > .finalise-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 4px;
  grid-area: finalise-button;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.split-by-amount-payment-summary-widget > .actions > .finalise-button:focus {
  border: none;
  outline: none;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .label {
  color: #4c4e68;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  max-height: calc(60vh - 200px);
  background: #f6f8fb;
  border-radius: 8px;
  margin-top: 12px;
  padding: 16px;
  overflow-y: scroll;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items::-webkit-scrollbar {
  width: 1px;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items::-webkit-scrollbar-track {
  background: none;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > * {
  border-top: 1px solid #dee0e6;
  margin-top: 12px;
  padding-top: 12px;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > :first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > .split-by-amount-payment {
  color: #7d7e92;
  border-top: 1px solid #dee0e6;
  grid-template-columns: 1fr auto;
  grid-template-areas: "label amount"
                       "transaction-reference ."
                       "surcharge-label surcharge-amount"
                       "tip-label tip-amount"
                       "items items";
  place-items: center;
  gap: 0 8px;
  margin-top: 12px;
  padding-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > .split-by-amount-payment:first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > .split-by-amount-payment > .payment {
  color: #1c1d3e;
  display: contents;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > .split-by-amount-payment > .payment > .label {
  grid-area: label;
  justify-self: start;
  align-items: center;
  display: flex;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > .split-by-amount-payment > .payment > .label > .type {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > .split-by-amount-payment > .payment > .label > .bullet {
  color: #4c4e68;
  margin: 0 8px;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > .split-by-amount-payment > .payment > .amount {
  grid-area: amount;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > .split-by-amount-payment > .payment > .amount.refund {
  color: #9f1239;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > .split-by-amount-payment > .transaction-reference {
  color: #7d7e92;
  grid-area: transaction-reference;
  justify-self: start;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > .split-by-amount-payment > .surcharge {
  color: #7d7e92;
  display: contents;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > .split-by-amount-payment > .surcharge > .label {
  grid-area: surcharge-label;
  justify-self: start;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > .split-by-amount-payment > .surcharge > .amount {
  grid-area: surcharge-amount;
  justify-self: end;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > .split-by-amount-payment > .tip {
  color: #7d7e92;
  display: contents;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > .split-by-amount-payment > .tip > .label {
  grid-area: tip-label;
  justify-self: start;
}

.split-by-amount-payment-summary-widget > .split-by-amount-payments > .items > .split-by-amount-payment > .tip > .amount {
  grid-area: tip-amount;
  justify-self: end;
}

.item-summary-description-view-widget .modifiers {
  border-bottom: 1px solid #dee0e6;
  margin-left: 35px;
}

.item-summary-description-view-widget .modifiers .title {
  font-weight: 600;
}

.item-summary-description-view-widget .modifiers:last-child {
  border-bottom: none;
}

.item-summary-description-view-widget .modifier {
  grid-template-columns: 150px 1fr;
  display: grid;
}

.item-summary-description-view-widget .modifier .name {
  color: #7d7e92;
}

.deal-suggestions-widget {
  height: 90vh;
  width: 75vw;
  max-width: 1500px;
  flex-direction: column;
  padding: 22px 44px 36px;
  display: flex;
}

.deal-suggestions-widget > .header {
  grid-template-columns: 1fr min-content;
  align-items: center;
  margin-bottom: 12px;
  display: grid;
}

.deal-suggestions-widget > .header .title {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 10px;
  margin-bottom: unset;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.deal-suggestions-widget > .header .close-button {
  cursor: pointer;
  position: absolute;
  top: 19px;
  right: 24px;
}

.deal-suggestions-widget .deal-suggestions {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  gap: 20px;
  padding: 20px 0 30px;
  display: grid;
  position: relative;
  overflow: auto;
}

.deal-suggestions-widget .deal-suggestions::-webkit-scrollbar {
  width: 1px;
}

.deal-suggestions-widget .deal-suggestions::-webkit-scrollbar-track {
  background: none;
}

.deal-suggestions-widget .deal-suggestions::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.deal-suggestions-widget .deal-suggestions .header {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 22px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.deal-suggestion-card-widget {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.deal-suggestion-card-widget .pizzas-or-half-and-halves {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  gap: 8px;
  padding: 24px 20px;
  display: grid;
}

.deal-suggestion-card-widget .pizzas-or-half-and-halves > .pizza-or-half-and-half > .name {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.deal-suggestion-card-widget .pizzas-or-half-and-halves > .pizza-or-half-and-half > .item-description-view-widget {
  color: #7d7e92;
  margin-left: 20px;
}

.deal-suggestion-card-widget .suggestion {
  background-color: #f6f8fb;
  border-left: 1px solid #dee0e6;
  flex-direction: column;
  padding: 24px 20px;
  display: flex;
}

.deal-suggestion-card-widget .suggestion > .name {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.deal-suggestion-card-widget .suggestion > .description {
  color: #7d7e92;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.deal-suggestion-card-widget .suggestion .convert-button {
  color: #4cbba1;
  height: unset;
  background-color: #0000;
  border: 2px solid #4cbba1;
  margin-top: auto;
  padding: 10px;
}

.print-docket-settings-widget .docket-prefix-section, .print-docket-settings-widget .form-item {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  padding: 24px;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.print-docket-settings-widget {
  padding: 20px;
}

.print-docket-settings-widget .form-item label {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.print-docket-settings-widget .form-item .help {
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

.print-docket-settings-widget .docket-prefix-section {
  grid-template-columns: 4fr 1fr;
  grid-template-areas: "label prefix"
                       "help prefix";
  display: grid;
}

.print-docket-settings-widget .docket-prefix-section .label {
  letter-spacing: 0;
  color: #1c1d3e;
  grid-area: label;
  margin-bottom: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.print-docket-settings-widget .docket-prefix-section .help {
  color: #6c6c6c;
  grid-area: help;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

.print-docket-settings-widget .docket-prefix-section .prefix {
  color: #368875;
  grid-area: prefix;
  align-content: center;
  justify-self: end;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.print-docket-settings-widget .reset-docket-numeric-counter {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  display: flex;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.print-docket-settings-widget .reset-docket-numeric-counter .label {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}

.new-table-booking-notification-widget {
  color: #fff;
  background-color: #fbbf24;
  left: -227px;
}

.new-table-booking-notification-widget img {
  padding-left: 24px;
}

.new-table-booking-notification-widget .count {
  padding: 12px 24px 12px 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}

.new-table-booking-list-widget .table-bookings {
  max-height: calc(100vh - 126px);
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-auto-rows: min-content;
  gap: 15px;
  padding: 24px;
  display: grid;
  overflow-x: hidden;
  overflow-y: scroll;
}

.new-table-booking-list-widget .table-bookings::-webkit-scrollbar {
  width: 1px;
}

.new-table-booking-list-widget .table-bookings::-webkit-scrollbar-track {
  background: none;
}

.new-table-booking-list-widget .table-bookings::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.new-table-booking-list-widget .table-bookings-page-link {
  text-align: center;
}

.new-table-booking-list-widget .table-bookings-page-link span {
  cursor: pointer;
  color: #3f9c86;
}

.new-table-booking-list-widget .table-bookings-page-link.no-table-bookings {
  margin-top: 20vh;
}

.new-table-booking-list-widget .table-bookings-page-link.end-list {
  margin-top: 20px;
}

.new-table-booking-list-item-widget {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  grid-template-columns: 1.25fr repeat(3, 1fr) 2fr max-content;
  align-items: center;
  gap: 28px;
  padding: 24px;
  display: grid;
  position: relative;
  box-shadow: 0 3px 10px -3px #0000004d;
}

.new-table-booking-list-item-widget .booked-for .time, .new-table-booking-list-item-widget .customer-info .name {
  font-weight: 700;
}

.new-table-booking-list-item-widget .actions {
  display: flex;
}

.new-table-booking-list-item-widget .actions .accept-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0 16px 0 0;
  padding: 12.5px 32.5px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.new-table-booking-list-item-widget .actions .accept-button:focus {
  border: none;
  outline: none;
}

.new-table-booking-list-item-widget .actions .reject-button {
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #f43f5e;
  margin: 0 16px 0 0;
  padding: 12.5px 32.5px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #f43f5e !important;
}

.add-new-table-booking-widget {
  padding: 24px;
}

.add-new-table-booking-widget .header {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .sub-title, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .sub-title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 22px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget form .form-items .DayPicker, .add-new-table-booking-widget .table-booking-contact-details-edit-widget form .form-items .DayPicker {
  justify-content: unset;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget form .actions, .add-new-table-booking-widget .table-booking-contact-details-edit-widget form .actions {
  grid-area: actions;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 14px;
  margin-top: 20px;
  display: grid;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget form .actions button, .add-new-table-booking-widget .table-booking-contact-details-edit-widget form .actions button {
  width: 100%;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget form .actions .cancel-button, .add-new-table-booking-widget .table-booking-booking-details-edit-widget form .actions .go-back-button, .add-new-table-booking-widget .table-booking-contact-details-edit-widget form .actions .cancel-button, .add-new-table-booking-widget .table-booking-contact-details-edit-widget form .actions .go-back-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #1c1d3e;
  background-color: #fff;
  border: 2px solid #adafbc;
  margin: 0;
  padding: 15px;
  font-size: 15px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget form .actions .continue-button, .add-new-table-booking-widget .table-booking-booking-details-edit-widget form .actions .submit-button, .add-new-table-booking-widget .table-booking-contact-details-edit-widget form .actions .continue-button, .add-new-table-booking-widget .table-booking-contact-details-edit-widget form .actions .submit-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget form .actions .continue-button:focus, .add-new-table-booking-widget .table-booking-booking-details-edit-widget form .actions .submit-button:focus, .add-new-table-booking-widget .table-booking-contact-details-edit-widget form .actions .continue-button:focus, .add-new-table-booking-widget .table-booking-contact-details-edit-widget form .actions .submit-button:focus {
  border: none;
  outline: none;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget form .actions .disabled-continue-button, .add-new-table-booking-widget .table-booking-booking-details-edit-widget form .actions .disabled-submit-button, .add-new-table-booking-widget .table-booking-contact-details-edit-widget form .actions .disabled-continue-button, .add-new-table-booking-widget .table-booking-contact-details-edit-widget form .actions .disabled-submit-button {
  opacity: .6;
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget form .actions .disabled-continue-button:focus, .add-new-table-booking-widget .table-booking-booking-details-edit-widget form .actions .disabled-submit-button:focus, .add-new-table-booking-widget .table-booking-contact-details-edit-widget form .actions .disabled-continue-button:focus, .add-new-table-booking-widget .table-booking-contact-details-edit-widget form .actions .disabled-submit-button:focus {
  border: none;
  outline: none;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details {
  grid-template-columns: minmax(400px, 720px);
  display: grid;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div {
  width: inherit;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "iso-date time-of-day"
                       "pax-and-seating-area pax-and-seating-area"
                       "special-request special-request"
                       "actions actions";
  display: grid;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div.disabled-seating-area-and-notes, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div.disabled-seating-area-and-notes {
  grid-template-columns: 1fr 20%;
  grid-template-areas: "iso-date-date-picker iso-date-date-picker"
                       "time-of-day pax-and-seating-area"
                       "actions actions";
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div.disabled-seating-area-and-notes .pax-and-seating-areas, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div.disabled-seating-area-and-notes .pax-and-seating-areas {
  grid-area: pax-and-seating-area;
  grid-template-columns: 1fr;
  margin-top: 12px;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div.disabled-seating-area-and-notes .iso-date-date-picker, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div.disabled-seating-area-and-notes .iso-date-date-picker {
  grid-area: iso-date-date-picker;
  margin-bottom: 15px;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div.disabled-seating-area-and-notes .time-of-day, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div.disabled-seating-area-and-notes .time-of-day {
  grid-area: time-of-day;
  margin: 6px 10px 0 0;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div.disabled-seating-area-and-notes .actions, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div.disabled-seating-area-and-notes .actions {
  margin-top: 30px;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div.disabled-seating-area, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div.disabled-seating-area {
  grid-template-columns: repeat(2, 1fr) 20%;
  grid-template-areas: "iso-date time-of-day pax-and-seating-area"
                       "special-request special-request special-request"
                       "actions actions actions";
  gap: 10px;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div.disabled-seating-area .pax-and-seating-areas, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div.disabled-seating-area .pax-and-seating-areas {
  grid-area: pax-and-seating-area;
  grid-template-columns: 1fr;
  gap: 15px;
  margin-top: 0;
  display: grid;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div.disabled-seating-area .pax-and-seating-areas .pax, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div.disabled-seating-area .pax-and-seating-areas .pax {
  max-height: 82px;
  padding-bottom: 0;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div.disabled-seating-area .special-request, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div.disabled-seating-area .special-request {
  margin-top: 16px;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div.disabled-seating-area .time-of-day, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div.disabled-seating-area .time-of-day {
  grid-area: time-of-day;
  margin-left: 0;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div.disabled-seating-area .actions, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div.disabled-seating-area .actions {
  margin-top: 10px;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div .iso-date, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div .iso-date {
  grid-area: iso-date;
  margin-bottom: 8px;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div .time-of-day, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div .time-of-day {
  grid-area: time-of-day;
  margin-left: 10px;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div .form-select-icon input, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div .form-select-icon input {
  background-image: url("icon-chevron-down-grey.b17677ff.svg");
  background-position: right 20px top 60%;
  background-repeat: no-repeat;
  background-size: 20px !important;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div .pax-and-seating-areas, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div .pax-and-seating-areas {
  grid-area: pax-and-seating-area;
  grid-template-columns: 18% 80%;
  gap: 15px;
  margin-top: 25px;
  display: grid;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div .pax-and-seating-areas .seating-areas, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div .pax-and-seating-areas .seating-areas {
  position: relative;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div .pax-and-seating-areas .seating-areas .seating-area-options, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div .pax-and-seating-areas .seating-areas .seating-area-options {
  max-height: 230%;
  width: 100%;
  float: right;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 4px;
  position: absolute;
  top: 105%;
  left: 0;
  overflow: auto;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div .pax-and-seating-areas .seating-areas .seating-area-options > .option, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div .pax-and-seating-areas .seating-areas .seating-area-options > .option {
  cursor: pointer;
  border-bottom: 1px solid #dee0e6;
  align-items: center;
  padding: 16px;
  display: flex;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div .pax-and-seating-areas .seating-areas .seating-area-options > .option:last-child, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div .pax-and-seating-areas .seating-areas .seating-area-options > .option:last-child {
  border-bottom: 0;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div .pax-and-seating-areas .seating-areas .seating-area-options > .option.selected, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div .pax-and-seating-areas .seating-areas .seating-area-options > .option.selected {
  background-color: #f4f4f6;
  font-weight: 500;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div .special-request, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div .special-request {
  grid-area: special-request;
  margin-top: 35px;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .booking-details form .form-items > div .special-request .notes-character-limit, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .booking-details form .form-items > div .special-request .notes-character-limit {
  float: right;
  color: #9597a7;
  margin-top: 10px;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .contact-details form .form-items, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .contact-details form .form-items {
  grid-template-columns: repeat(2, 348px);
  grid-template-areas: "contact-number customer-name"
                       "customer-email customer-email"
                       "actions actions";
  gap: 17px;
  display: grid;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .contact-details form .form-items .contact-number, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .contact-details form .form-items .contact-number {
  grid-area: contact-number;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .contact-details form .form-items .customer-name, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .contact-details form .form-items .customer-name {
  grid-area: customer-name;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .contact-details form .form-items .customer-email, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .contact-details form .form-items .customer-email {
  grid-area: customer-email;
  margin: 20px 0 10px;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .form-items .form-item, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .form-items .form-item {
  position: relative;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .form-items .form-item label, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .form-items .form-item label {
  color: #7d7e92;
  padding: 5px 0 10px;
  font-size: 15px;
  line-height: 30px;
  position: absolute;
  top: 5px;
  left: 6px;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .form-items .form-item .meta, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .form-items .form-item .meta {
  position: absolute;
  top: 78px;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .form-items .form-item .meta .error, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .form-items .form-item .meta .error {
  color: #f43f5e;
  font-size: 14px;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .form-items .form-item > .form-input > div > input, .add-new-table-booking-widget .table-booking-booking-details-edit-widget .form-items .form-item textarea, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .form-items .form-item > .form-input > div > input, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .form-items .form-item textarea {
  letter-spacing: .005em;
  color: #1c1d3e;
  border-radius: 8px;
  padding: 40px 10px 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5em;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .form-items .form-item > .form-input > div > input.invalid, .add-new-table-booking-widget .table-booking-booking-details-edit-widget .form-items .form-item textarea.invalid, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .form-items .form-item > .form-input > div > input.invalid, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .form-items .form-item textarea.invalid {
  border: 1px solid #f43f5e !important;
}

.add-new-table-booking-widget .table-booking-booking-details-edit-widget .form-items .form-item ::placeholder, .add-new-table-booking-widget .table-booking-contact-details-edit-widget .form-items .form-item ::placeholder {
  color: #65667d;
}

.table-bookings-list-widget {
  color: #1c1d3e;
  padding: 25px 24px;
}

.table-bookings-list-widget .header {
  grid-template-columns: 3fr max-content 1fr;
  align-items: center;
  margin-bottom: 30px;
  display: grid;
}

.table-bookings-list-widget .header .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.table-bookings-list-widget .header .add-new-table-booking-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: 0.5%;
  background-color: #0000;
  border: 1px solid #3f9c86;
  border-radius: 5px;
  margin: 0 26px 0 29.75px;
  padding: 13px 32.5px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
  color: #3f9c86 !important;
}

.table-bookings-list-widget .header input {
  text-indent: 30px;
  width: 400px;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 15px;
  background-repeat: no-repeat;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 10px 12px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  box-shadow: 0 3px 15px -5px #0000004d;
}

.table-bookings-list-widget .no-table-bookings {
  text-align: center;
  margin-top: 20vh;
}

.table-bookings-list-widget .table-bookings-tabs {
  border-radius: 10px;
  grid-template-rows: min-content;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  column-gap: 10px;
  display: grid;
}

.table-bookings-list-widget .table-bookings-tabs li {
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400px;
  color: #7d7e92;
  background-color: #f6f8fb;
  border-radius: 10px;
  padding: 14px;
  list-style: none;
}

.table-bookings-list-widget .table-bookings-tabs li:nth-child(2) {
  border-radius: 10px;
}

.table-bookings-list-widget .table-bookings-tabs li.active {
  color: #fff;
  background-color: #343553;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.table-bookings-list-widget .table-bookings {
  grid-auto-rows: min-content;
  display: grid;
}

.table-bookings-list-widget .table-bookings .wrapper {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  grid-auto-rows: min-content;
  display: grid;
}

.table-bookings-list-widget .table-bookings .date-group > .date {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-top: 30px;
  margin-bottom: 22px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.table-bookings-list-widget .table-bookings .end-list {
  text-align: center;
  color: #adafbc;
  margin-top: 20px;
}

.table-bookings-list-widget .back-to-top-button {
  background-color: #fff;
  border-radius: 30px;
  padding: 13px;
  position: fixed;
  top: 90vh;
  right: 50px;
  box-shadow: 0 0 3px #687a9b40;
}

.table-bookings-list-widget .load-more-button {
  color: #fff;
  background-color: #4c4e68;
  border-radius: 5px;
  margin: 20px auto auto;
  padding: 15px 110px;
}

.table-bookings-list-widget .end-of-the-list {
  text-align: center;
  color: #7d7e92;
  margin-top: 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

.table-booking-list-item-widget {
  color: #1c1d3e;
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: 1fr 1.5fr 1fr 1.5fr repeat(2, 1fr) 2fr max-content;
  align-items: center;
  padding: 16px 38px 16px 24px;
  font-size: 14px;
  display: grid;
}

.table-booking-list-item-widget .booked-for-time-of-day {
  font-weight: 700;
}

.table-booking-list-item-widget:last-child {
  border-bottom: none;
}

.table-booking-list-item-widget .customer-info .name {
  font-weight: 500;
}

.table-booking-list-item-widget .customer-info .mobile {
  color: #1c1d3e;
}

.table-booking-list-item-widget .status {
  font-size: 14.5px !important;
}

.table-booking-list-item-widget .status.accepted {
  color: #059669;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.table-booking-list-item-widget .status.accepted:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #059669;
  border-radius: 100%;
  margin-right: 8px;
}

.table-booking-list-item-widget .status.new {
  color: #d97706;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.table-booking-list-item-widget .status.new:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #d97706;
  border-radius: 100%;
  margin-right: 8px;
}

.table-booking-list-item-widget .status.cancelled {
  color: #f43f5e;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.table-booking-list-item-widget .status.cancelled:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #f43f5e;
  border-radius: 100%;
  margin-right: 8px;
}

.table-booking-list-item-widget .actions {
  justify-content: center;
  align-items: center;
  display: flex;
}

.table-booking-list-item-widget .actions .print-table-booking-stub {
  margin-right: 20px;
}

.table-booking-list-item-widget .actions .edit-table-booking-button {
  color: #1e40af;
  max-width: 55px;
  background-color: #0000;
  border: 1px solid #60a5fa;
  border-radius: 4px;
  padding: 7px 16px;
  font-size: 12px;
  line-height: 18px;
}

.table-booking-list-item-widget .actions .edit-table-booking-button button {
  font-size: 15px;
  font-weight: 700px;
  background-color: #0000;
  border-radius: 3px;
  padding: 7px 16px;
}

.table-booking-list-item-widget .actions .edit-table-booking-button button[disabled] {
  color: #adafbc;
  border: 1px solid #dee0e6;
}

.table-booking-list-item-widget .actions .cancel-table-booking-button {
  color: #9f1239;
  max-width: 74px;
  background-color: #0000;
  border: 1px solid #f43f5e;
  border-radius: 4px;
  margin-left: 20px;
  padding: 7px 16px;
  font-size: 12px;
  line-height: 18px;
}

.table-booking-list-item-widget .actions .cancel-table-booking-button button {
  font-size: 15px;
  font-weight: 700px;
  background-color: #0000;
  border-radius: 3px;
  padding: 7px 16px;
}

.table-booking-list-item-widget .actions .cancel-table-booking-button button[disabled] {
  color: #adafbc;
  border: 1px solid #dee0e6;
}

.table-booking-list-item-widget .actions .cancel-table-booking-button.hidden {
  display: none;
}

.table-booking-reject-reason-selector-widget {
  max-width: 650px;
  width: 70vw;
  padding: 80px 120px;
}

.table-booking-reject-reason-selector-widget .header {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 40px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.table-booking-reject-reason-selector-widget .reject-reasons-presets .reject-reasons {
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-rows: 1fr;
  gap: 12px;
  padding: 4px;
  display: grid;
}

.table-booking-reject-reason-selector-widget .reject-reasons-presets .reject-reasons .reject-reason {
  cursor: pointer;
  text-align: center;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  grid-auto-flow: row;
  place-items: center;
  padding: 30px 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  display: grid;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.table-booking-reject-reason-selector-widget .reject-reasons-presets .actions {
  place-items: center;
  margin-top: 24px;
  display: grid;
}

.table-booking-reject-reason-selector-widget .reject-reasons-presets .actions button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.table-booking-reject-reason-selector-widget .custom-reject-reason .form-buttons {
  width: 100%;
  gap: 10px;
  margin-top: 24px;
  display: grid;
}

.table-booking-reject-reason-selector-widget .custom-reject-reason .form-buttons .submit-btn {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.table-booking-reject-reason-selector-widget .custom-reject-reason .form-buttons .submit-btn:focus {
  border: none;
  outline: none;
}

.table-booking-reject-reason-selector-widget .custom-reject-reason .form-buttons .cancel-btn {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.table-booking-view-details-widget {
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  margin: 0 26px 33.5px;
  display: flex;
  box-shadow: 0 10px 25px #59637b26;
}

.table-booking-view-details-widget .table-booking-details {
  grid-template-columns: minmax(100px, 193px) minmax(100px, 193px) minmax(100px, 321px);
  gap: 24px 32px;
  padding: 24px;
  display: grid;
}

.table-booking-view-details-widget .detail {
  color: #1c1d3e;
}

.table-booking-view-details-widget .detail .label {
  color: #7d7e92;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.table-booking-view-details-widget .detail .value {
  color: #1c1d3e;
  overflow-wrap: break-word;
  max-width: 220px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.table-booking-view-details-widget .detail .value.seating {
  font-weight: 400;
}

.table-booking-view-details-widget .detail.status .accepted {
  color: #059669;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.table-booking-view-details-widget .detail.status .accepted:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #059669;
  border-radius: 100%;
  margin-right: 8px;
}

.table-booking-view-details-widget .detail.status .new {
  color: #d97706;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.table-booking-view-details-widget .detail.status .new:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #d97706;
  border-radius: 100%;
  margin-right: 8px;
}

.table-booking-view-details-widget .detail.status .cancelled {
  color: #f43f5e;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.table-booking-view-details-widget .detail.status .cancelled:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #f43f5e;
  border-radius: 100%;
  margin-right: 8px;
}

.table-booking-view-details-widget .actions {
  align-items: flex-start;
  margin: 24px 24px 24px auto;
  display: flex;
}

.table-booking-view-details-widget .actions button {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.table-booking-view-details-widget .actions button .label {
  font-weight: 500;
}

.table-booking-view-details-widget .actions .edit-button {
  white-space: nowrap;
  border: 1px solid #60a5fa;
  padding: 14px 32.25px;
}

.table-booking-view-details-widget .actions .edit-button .label {
  color: #1e40af;
}

.table-booking-view-details-widget .actions .cancel-button {
  white-space: nowrap;
  border: 1px solid #f43f5e;
  margin-left: 24px;
  padding: 14px 32.5px;
}

.table-booking-view-details-widget .actions .cancel-button .label {
  color: #9f1239;
}

.table-booking-back-link-header-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  margin-top: 22px;
  margin-bottom: 16px;
  display: grid;
}

.table-booking-back-link-header-widget .go-back-link {
  cursor: pointer;
  color: #7d7e92;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 30px;
  padding: 0 0 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.table-booking-back-link-header-widget .go-back-link > .arrow-left {
  width: 10px;
  height: 12px;
  background-image: url("icon-chevron-left.88d05a8c.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 3px;
}

.table-booking-back-link-header-widget .go-back-link span {
  margin-left: 12px;
}

.table-booking-back-link-header-widget .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin: 0 26px;
  padding: 0 0 12px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
}

.popout-cart-item-quantity-widget > .counter > .decrement, .popout-cart-item-quantity-widget > .counter > .increment {
  cursor: pointer;
  border-radius: 50%;
  padding: 4px;
  box-shadow: 0 2px 4px #2223334d;
}

.popout-cart-item-quantity-widget > .counter > .decrement .icon, .popout-cart-item-quantity-widget > .counter > .increment .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.popout-cart-item-quantity-widget > .counter > .decrement .icon > img, .popout-cart-item-quantity-widget > .counter > .increment .icon > img {
  height: 100%;
}

.popout-cart-item-quantity-widget {
  text-align: center;
}

.popout-cart-item-quantity-widget > .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 0;
  padding: 10px 48px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.popout-cart-item-quantity-widget > .counter {
  display: flex;
}

.popout-cart-item-quantity-widget > .counter > .quantity {
  letter-spacing: 0;
  color: #1c1d3e;
  flex-grow: 1;
  margin-bottom: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.popout-cart-item-quantity-widget > .counter > .increment {
  background-color: #4cbba1;
}

.popout-cart-item-quantity-widget > .counter > .decrement {
  background-color: #595976;
}

.popout-cart-item-more-actions-widget > .remove-item-button, .popout-cart-item-more-actions-widget > .add-item-comment-button, .popout-cart-item-more-actions-widget .pay-for-item-button {
  cursor: pointer;
  align-items: center;
  padding: 14px 16px;
  display: flex;
}

.popout-cart-item-more-actions-widget {
  letter-spacing: 0;
  white-space: nowrap;
  flex-direction: column;
  padding: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.popout-cart-item-more-actions-widget > .add-item-comment-button, .popout-cart-item-more-actions-widget .pay-for-item-button {
  border-bottom: 1px solid #dee0e6;
}

.popout-cart-item-more-actions-widget > .add-item-comment-button > .icon, .popout-cart-item-more-actions-widget .pay-for-item-button > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  display: flex;
}

.popout-cart-item-more-actions-widget > .add-item-comment-button > .icon > img, .popout-cart-item-more-actions-widget .pay-for-item-button > .icon > img {
  height: 18px;
}

.popout-cart-item-more-actions-widget > .remove-item-button {
  color: #f43f5e;
}

.popout-cart-item-more-actions-widget > .remove-item-button > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  display: flex;
}

.popout-cart-item-more-actions-widget > .remove-item-button > .icon > img {
  height: 18px;
}

.popout-collection-type-selector-widget > .button {
  cursor: pointer;
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

.popout-collection-type-selector-widget {
  flex-direction: column;
  padding: 0;
  display: flex;
}

.popout-collection-type-selector-widget > .button {
  width: 200px;
  position: relative;
}

.popout-collection-type-selector-widget > .button:not(:last-child) {
  border-bottom: 1px solid #dee0e6;
}

.popout-collection-type-selector-widget > .button:only-child {
  border-bottom: 0;
}

.popout-collection-type-selector-widget > .button > .check {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.popout-collection-type-selector-widget > .button > .check > img {
  height: 100%;
}

.popout-collection-type-selector-widget > .button > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.popout-collection-type-selector-widget > .button > .icon > img {
  height: 100%;
}

.popout-collection-type-selector-widget > .button > .title {
  letter-spacing: 0;
  margin-left: 12px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.popout-cart-header-more-actions-widget > .reset-order-button, .popout-cart-header-more-actions-widget > .save-order-button, .popout-cart-header-more-actions-widget .print-invoice-button, .popout-cart-header-more-actions-widget .pay-for-items-button {
  cursor: pointer;
  align-items: center;
  padding: 14px 16px;
  display: flex;
}

.popout-cart-header-more-actions-widget {
  letter-spacing: 0;
  white-space: nowrap;
  flex-direction: column;
  padding: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.popout-cart-header-more-actions-widget > .save-order-button, .popout-cart-header-more-actions-widget .print-invoice-button, .popout-cart-header-more-actions-widget .pay-for-items-button {
  border-bottom: 1px solid #dee0e6;
}

.popout-cart-header-more-actions-widget > .save-order-button > .icon, .popout-cart-header-more-actions-widget .print-invoice-button > .icon, .popout-cart-header-more-actions-widget .pay-for-items-button > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  display: flex;
}

.popout-cart-header-more-actions-widget > .save-order-button > .icon > img, .popout-cart-header-more-actions-widget .print-invoice-button > .icon > img, .popout-cart-header-more-actions-widget .pay-for-items-button > .icon > img {
  height: 18px;
}

.popout-cart-header-more-actions-widget > .reset-order-button {
  color: #f43f5e;
}

.popout-cart-header-more-actions-widget > .reset-order-button > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  display: flex;
}

.popout-cart-header-more-actions-widget > .reset-order-button > .icon > img {
  height: 18px;
}

.customer-algolia-index-record-list-widget {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  grid-area: suggestions;
  grid-template-areas: "label show-suggestions-button"
                       "list list"
                       "no-results no-results";
  margin: 5px 0;
  padding: 12px;
  display: grid;
}

.customer-algolia-index-record-list-widget .label {
  color: #7d7e92;
  grid-area: label;
  margin-bottom: 0;
  margin-left: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.customer-algolia-index-record-list-widget .show-suggestions-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: end;
  cursor: pointer;
  color: #4cbba1;
  -webkit-tap-highlight-color: transparent;
  grid-area: show-suggestions-button;
  margin-right: 4px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.customer-algolia-index-record-list-widget .list {
  grid-area: list;
}

.customer-algolia-index-record-list-widget .list .ais-SearchBox, .customer-algolia-index-record-list-widget .list .ais-Pagination {
  display: none;
}

.customer-algolia-index-record-list-widget .list .ais-Hits-list {
  list-style-type: none;
}

.customer-algolia-index-record-list-widget .list .spinner-container {
  height: unset;
}

.customer-algolia-index-record-list-widget .list .no-results {
  text-align: center;
  padding: 20px 0;
}

.customer-algolia-index-record-list-widget .suggested-customer-list {
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-top: 10px;
  padding: 4px;
  display: grid;
  overflow-y: scroll;
}

.customer-algolia-index-record-list-widget .suggested-customer-list .suggested-customer {
  height: 100%;
  cursor: pointer;
  text-align: center;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  align-items: center;
  padding: 12px;
  display: grid;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.customer-algolia-index-record-list-widget .suggested-customer-list .suggested-customer .name {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #1c1d3e;
  align-self: end;
  margin: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.customer-algolia-index-record-list-widget .suggested-customer-list .suggested-customer .mobile {
  letter-spacing: 0;
  color: #1c1d3e;
  align-self: center;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.customer-algolia-index-record-list-widget .suggested-customer-list .suggested-customer .address {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #7d7e92;
  align-self: start;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.cart-widget {
  width: 26vw;
  min-width: 330px;
  height: 100vh;
  max-height: 100vh;
  background-color: #1c1d3e;
  flex-direction: column;
  grid-area: cart-widget;
  display: flex;
}

.swal2-container .leave-cart-confirmation-dialog .swal2-header .swal2-title {
  margin-top: 0 !important;
}

.swal2-container .leave-cart-confirmation-dialog .swal2-content .swal2-html-container {
  color: #4c4e68;
}

.swal2-container .leave-cart-confirmation-dialog .swal2-actions .confirm-button {
  text-transform: inherit !important;
  background: #9f1239 !important;
  border: 1px solid #9f1239 !important;
  border-radius: 4px !important;
}

.swal2-container .leave-cart-confirmation-dialog .swal2-actions .cancel-button {
  text-transform: inherit !important;
  border: 1px solid #7d7e92 !important;
  border-radius: 4px !important;
}

.cart-header-widget {
  background: linear-gradient(#1c1d3e 0%, #ffffff06 100%);
}

.cart-header-widget .spinner-container {
  place-content: unset;
  flex-flow: row;
  display: flex;
}

.cart-header-widget .spinner-container > .message {
  color: #ffffff80;
  align-self: center;
  margin-left: 7px;
}

.cart-header-widget .editing-strip {
  color: #fff;
  background-color: #f43f5e;
  justify-content: space-between;
  padding: 12px;
  display: flex;
}

.cart-header-widget .editing-strip .edit-text {
  letter-spacing: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.cart-header-widget .editing-strip .edit-cancel {
  cursor: pointer;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

.cart-header-widget .header-actions {
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  display: flex;
}

.cart-header-widget .header-actions > div:first-child {
  align-items: center;
  display: flex;
}

.cart-header-widget .header-actions > div:first-child > .collection-type-selector {
  cursor: pointer;
  background-color: #ffffff1a;
  border-radius: 8px;
  padding: 10px 12px;
  display: flex;
}

.cart-header-widget .header-actions > div:first-child > .collection-type-selector > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cart-header-widget .header-actions > div:first-child > .collection-type-selector > .icon > img {
  height: 100%;
}

.cart-header-widget .header-actions > div:first-child > .collection-type-selector > .title {
  letter-spacing: 0;
  color: #fff;
  margin-left: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.cart-header-widget .header-actions > div:first-child > .count {
  color: #fff;
  height: 28px;
  width: 28px;
  text-align: center;
  background-color: #3b82f6;
  border-radius: 50%;
  margin-left: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
}

.cart-header-widget .header-actions > .actions {
  align-items: center;
  display: flex;
}

.cart-header-widget .header-actions > .actions > div {
  cursor: pointer;
  background-color: #ffffff1a;
  border-radius: 8px;
  margin-left: 8px;
  padding: 10px 12px;
}

.cart-header-widget .header-actions > .actions > div:first-child {
  margin-left: 0;
}

.cart-header-widget .header-actions > .actions > div > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cart-header-widget .header-actions > .actions > div > .icon > img {
  height: 100%;
}

.cart-header-info-widget {
  align-items: center;
  display: flex;
}

.cart-header-info-widget > .icon {
  height: 16px;
  width: 16px;
  justify-content: center;
  align-items: center;
  padding: 0 7px 0 13px;
  display: flex;
}

.cart-header-info-widget > .icon > img {
  height: 100%;
}

.cart-header-info-widget > .label {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #fff;
  word-break: break-word;
  margin-left: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.cart-requested-for-widget, .cart-dine-in-info-widget {
  cursor: pointer;
  border-bottom: 1px solid #343553;
  margin: 0 12px;
  padding-bottom: 8px;
}

.cart-customer-info-widget {
  cursor: pointer;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "customer-name customer-number"
                       "delivery-address delivery-address"
                       "delivery-drop-notes delivery-drop-notes"
                       "customer-notes customer-notes";
  margin: 8px 12px 12px;
  display: grid;
}

.cart-customer-info-widget > .cart-header-info-widget.add > .label {
  color: #ffffff80;
}

.cart-customer-info-widget > .cart-header-info-widget.span {
  grid-column: 1 / -1;
}

.cart-customer-info-widget .customer-name {
  grid-area: customer-name;
}

.cart-customer-info-widget .customer-number {
  grid-area: customer-number;
}

.cart-customer-info-widget .delivery-address {
  grid-area: delivery-address;
  margin-top: 8px;
}

.cart-customer-info-widget .delivery-dropoff-notes {
  grid-area: delivery-drop-notes;
  margin-top: 8px;
}

.cart-customer-info-widget .customer-notes {
  grid-area: customer-notes;
  margin-top: 5px;
}

.cart-customer-info-widget .customer-notes > .cart-header-info-widget.with-customer-notes {
  background-color: #4cbba150;
  border-radius: 3px;
  padding: 3px 7px;
}

.cart-customer-info-widget .customer-notes > .cart-header-info-widget.with-customer-notes .label {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
  overflow: hidden;
}

.cart-customer-info-widget .customer-notes > .cart-header-info-widget.without-customer-notes {
  padding-left: 6px;
}

.cart-customer-info-widget .customer-notes > .cart-header-info-widget.without-customer-notes > .label {
  color: #ffffff80;
}

.cart-customer-info-widget .customer-notes > .cart-header-info-widget > .icon {
  padding: 0 7px;
}

.cart-main-widget {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
}

.cart-main-widget::-webkit-scrollbar {
  width: 1px;
}

.cart-main-widget::-webkit-scrollbar-track {
  background: none;
}

.cart-main-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.cart-main-widget .spinner-container {
  place-content: unset;
  flex-flow: row;
  display: flex;
}

.cart-main-widget .spinner-container > .message {
  color: #ffffff80;
  align-self: center;
  margin-left: 7px;
}

.cart-main-widget .order-comments {
  border-top: 1px solid #343553;
  padding: 12px;
}

.cart-main-widget .order-comments > div {
  color: #fff;
  cursor: pointer;
  white-space: pre-wrap;
  background-color: #4cbba140;
  border-radius: 4px;
  padding: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
}

.cart-main-widget .convert-to-deal-button {
  color: #83d0be;
  cursor: pointer;
  background-color: #ffffff10;
  border-radius: 8px;
  justify-content: center;
  margin: 7px 12px 14px;
  padding: 9px;
  font-size: 14px;
  display: flex;
}

.cart-main-widget .partial-payments-amount {
  flex-direction: column;
  flex-grow: 1;
  justify-content: end;
  display: flex;
}

.cart-main-widget .partial-payments-amount > .container {
  border-top: 1px solid #343553;
  margin-bottom: 0;
  padding: 12px 12px 0;
}

.cart-main-widget .partial-payments-amount > .container > .amount {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #fff9;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
}

.cart-main-widget .partial-payments-amount > .container > .amount > .value {
  color: #fff;
}

.cart-actions-widget .customizations {
  border-top: 1px solid #343553;
  padding: 12px;
  display: flex;
}

.cart-actions-widget .customizations > div {
  margin-left: 8px;
}

.cart-actions-widget .customizations > div:first-child {
  margin-left: 0;
}

.cart-actions-widget .customizations .add-order-comments-button, .cart-actions-widget .customizations .add-discount-button, .cart-actions-widget .customizations .add-surcharge-button {
  width: 100%;
  cursor: pointer;
  background-color: #ffffff1a;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  display: flex;
}

.cart-actions-widget .customizations .add-order-comments-button .icon, .cart-actions-widget .customizations .add-discount-button .icon, .cart-actions-widget .customizations .add-surcharge-button .icon {
  height: 28px;
  width: 28px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cart-actions-widget .customizations .add-order-comments-button .icon > img, .cart-actions-widget .customizations .add-discount-button .icon > img, .cart-actions-widget .customizations .add-surcharge-button .icon > img {
  height: 100%;
}

.cart-actions-widget .customizations .add-surcharge-button.has-value {
  background-color: #f43f5e40;
}

.cart-actions-widget .customizations .add-discount-button.has-value {
  background-color: #d9770640;
}

.cart-actions-widget .customizations .add-discount-button.is-loyalty {
  background-color: #2563eb40;
}

.cart-actions-widget .customizations .add-order-comments-button.has-value {
  background-color: #4cbba140;
}

.cart-actions-widget .checkout-actions {
  padding: 0 12px 12px;
  display: flex;
}

.cart-actions-widget .checkout-actions .send-button {
  cursor: pointer;
  background: #d97706;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  padding: 0 25px;
  display: flex;
}

.cart-actions-widget .checkout-actions .send-button .icon {
  height: 28px;
  width: 28px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cart-actions-widget .checkout-actions .send-button .icon > img {
  height: 100%;
}

.cart-actions-widget .checkout-actions .update-button, .cart-actions-widget .checkout-actions .checkout-button, .cart-actions-widget .checkout-actions .checkout-uber-delivery-button, .cart-actions-widget .checkout-actions .refund-button {
  letter-spacing: 0;
  flex-grow: 1;
  font-size: 17px;
}

.confirm-surcharge .swal2-modal {
  width: 530px !important;
  padding: 32px 24px !important;
}

.confirm-surcharge .swal2-modal .swal2-header {
  padding-top: 12px;
  padding-bottom: 16px;
}

.confirm-surcharge .swal2-modal .swal2-header .swal2-title {
  letter-spacing: .36px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
}

.confirm-surcharge .swal2-modal .swal2-html-container {
  letter-spacing: .08px;
  color: #4c4e68;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.confirm-surcharge .swal2-modal .swal2-actions {
  flex-direction: row-reverse;
  flex-wrap: unset;
}

.confirm-surcharge .swal2-modal .swal2-actions .cancel-button {
  color: #7d7e92;
  text-transform: none;
  border: 1px solid #7d7e92;
}

.service-worker-update-banner-widget {
  z-index: 998;
  width: 100vw;
  z-index: 995;
  background: #fef3c7;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px 32px 7.5px 102px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 4px 24px #00000026;
}

.service-worker-update-banner-widget > .text {
  display: inherit;
  align-items: inherit;
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.service-worker-update-banner-widget > .text > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.service-worker-update-banner-widget > .text > .icon > img {
  height: 100%;
}

.service-worker-update-banner-widget > .text > .highlight {
  display: inherit;
  align-items: inherit;
  margin-right: 8px;
  font-weight: 500;
}

.service-worker-update-banner-widget > .actions {
  justify-content: center;
  align-items: center;
  display: flex;
}

.service-worker-update-banner-widget > .actions > * {
  margin-left: 12px;
}

.service-worker-update-banner-widget > .actions > :first-child {
  margin-left: 0;
}

.service-worker-update-banner-widget > .actions .refresh-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0;
  color: #1c1d3e;
  color: #fff;
  background-color: #3f9c86;
  border-radius: 4px;
  margin: 0;
  padding: 7px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.service-worker-update-banner-widget > .actions .refresh-button:focus {
  border: none;
  outline: none;
}

.service-worker-update-banner-widget > .actions .later-button {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #9f1239;
  background-color: #0000;
  border: 1px solid #d97706;
  border-radius: 4px;
  padding: 7px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.service-worker-update-banner-widget > .actions > .continue-transaction-button, .service-worker-update-banner-widget > .actions .void-transaction-button {
  cursor: pointer;
  border-radius: 4px;
  padding: 7px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.service-worker-update-banner-widget > .actions > .continue-transaction-button {
  color: #fff;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.service-worker-update-banner-widget > .actions > .void-transaction-button {
  color: #9f1239;
  background-color: #0000;
  border: 1px solid #f43f5e;
}

.service-worker-update-banner-widget > .actions > .void-transaction-button.transaction-failed {
  color: #fff;
  background-color: #9f1239;
  border: none;
}

.service-worker-update-banner-widget > .actions > .go-online-button {
  border: 1px solid #0000;
}

.service-worker-update-banner-widget > .actions > .dismiss-button {
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
}

.service-worker-update-banner-widget > .actions > .enable-audio-button {
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.service-worker-update-banner-widget > .text > .highlight {
  color: #9f1239;
}

.audio-playback-banner-widget {
  z-index: 998;
  width: 100vw;
  z-index: 996;
  background: #ffe4e6;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px 32px 7.5px 102px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 4px 24px #00000026;
}

.audio-playback-banner-widget > .text {
  display: inherit;
  align-items: inherit;
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.audio-playback-banner-widget > .text > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.audio-playback-banner-widget > .text > .icon > img {
  height: 100%;
}

.audio-playback-banner-widget > .text > .highlight {
  display: inherit;
  align-items: inherit;
  margin-right: 8px;
  font-weight: 500;
}

.audio-playback-banner-widget > .actions {
  justify-content: center;
  align-items: center;
  display: flex;
}

.audio-playback-banner-widget > .actions > * {
  margin-left: 12px;
}

.audio-playback-banner-widget > .actions > :first-child {
  margin-left: 0;
}

.audio-playback-banner-widget > .actions .refresh-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0;
  color: #1c1d3e;
  color: #fff;
  background-color: #3f9c86;
  border-radius: 4px;
  margin: 0;
  padding: 7px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.audio-playback-banner-widget > .actions .refresh-button:focus {
  border: none;
  outline: none;
}

.audio-playback-banner-widget > .actions .later-button {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #9f1239;
  background-color: #0000;
  border: 1px solid #d97706;
  border-radius: 4px;
  padding: 7px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.audio-playback-banner-widget > .actions > .continue-transaction-button, .audio-playback-banner-widget > .actions .void-transaction-button {
  cursor: pointer;
  border-radius: 4px;
  padding: 7px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.audio-playback-banner-widget > .actions > .continue-transaction-button {
  color: #fff;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.audio-playback-banner-widget > .actions > .void-transaction-button {
  color: #9f1239;
  background-color: #0000;
  border: 1px solid #f43f5e;
}

.audio-playback-banner-widget > .actions > .void-transaction-button.transaction-failed {
  color: #fff;
  background-color: #9f1239;
  border: none;
}

.audio-playback-banner-widget > .actions > .go-online-button {
  border: 1px solid #0000;
}

.audio-playback-banner-widget > .actions > .dismiss-button {
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
}

.audio-playback-banner-widget > .actions > .enable-audio-button {
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.audio-playback-banner-widget > .text > .highlight {
  color: #9f1239;
}

.audio-playback-banner-widget button {
  cursor: pointer;
  white-space: nowrap;
  border-radius: 4px;
  padding: 7px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.offline-widget {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.offline-widget .icon {
  height: 96px;
  aspect-ratio: 1 / 1;
}

.offline-widget .title {
  color: #1c1d3e;
  padding-top: 30px;
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
}

.offline-widget .body {
  color: #7d7e92;
  width: 60vh;
  text-align: center;
  padding-top: 16px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.offline-widget button {
  color: #fff;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
  border-radius: 4px;
  margin: 30px 0 60px;
  padding: 14px 32px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.offline-widget button:disabled {
  opacity: unset;
  background-color: #c4eae1;
  border: 1px solid #c4eae1;
}

.offline-widget button.logout-button {
  background-color: #9f1239;
  border: 1px solid #9f1239;
}

.offline-widget a {
  color: #1e40af;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.error-widget {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.error-widget .icon {
  height: 96px;
  aspect-ratio: 1 / 1;
}

.error-widget .title {
  color: #1c1d3e;
  padding-top: 30px;
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
}

.error-widget .body {
  color: #7d7e92;
  width: 60vh;
  text-align: center;
  padding-top: 16px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.error-widget button {
  color: #fff;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
  border-radius: 4px;
  margin: 30px 0 60px;
  padding: 14px 32px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.error-widget button:disabled {
  opacity: unset;
  background-color: #c4eae1;
  border: 1px solid #c4eae1;
}

.error-widget button.logout-button {
  background-color: #9f1239;
  border: 1px solid #9f1239;
}

.error-widget a {
  color: #1e40af;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.order-history-widget {
  flex-direction: column;
  display: flex;
  overflow: auto;
}

.order-history-widget .order-history-list-info {
  z-index: 2;
  background-color: #fff;
  border-top: 1px solid #dee0e6;
  transition: transform .3s ease-out;
  position: relative;
  transform: translateY(-122px);
}

.order-history-widget .order-history-list-info.show-date-time-filter {
  transform: translateY(0);
}

.order-history-widget .order-history-list-info .order-sources {
  color: #7d7e92;
  margin-bottom: 24px;
  padding: 24px 24px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.order-history-widget .order-history-list-info .order-sources > .order-source {
  text-align: center;
  cursor: pointer;
  background-color: #f6f8fb;
  border-radius: 8px;
  flex: 1;
  margin: 0 8px;
  padding: 12px 14px;
}

.order-history-widget .order-history-list-info .order-sources > .order-source:first-child {
  margin-left: 0;
}

.order-history-widget .order-history-list-info .order-sources > .order-source:last-child {
  margin-right: 0;
}

.order-history-widget .order-history-list-info .order-sources > .order-source.active {
  color: #fff;
  background-color: #343553;
}

.order-history-widget .order-history-list-info .labels {
  white-space: nowrap;
  color: #7d7e92;
  grid-template-columns: 1.25fr 1.25fr .5fr 1.25fr 1fr .5fr 1fr .35fr;
  grid-auto-flow: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 10px;
  padding: 12px 48px;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  display: grid;
}

.swal2-container .invalid-date-and-time-range-warning, .swal2-container .invalid-date-and-time-range-warning .swal2-content {
  padding: 0 !important;
}

.swal2-container .invalid-date-and-time-range-warning .swal2-html-container {
  color: #4c4e68;
}

.period-coverage-filter-widget .date-time-range-label {
  width: max-content;
  align-items: center;
  padding: 0 24px;
  display: flex;
}

.period-coverage-filter-widget .date-time-range-label .label {
  color: #4c4e68;
  border-bottom: 1px solid #4c4e68;
  font-size: 14px;
  line-height: .9;
}

.period-coverage-filter-widget .date-time-range-label .chevron {
  height: 24px;
  width: 24px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  display: flex;
}

.period-coverage-filter-widget .date-time-range-label .chevron > img {
  height: 100%;
}

.period-coverage-filter-widget .time-range-form {
  z-index: 5;
  transform-origin: top;
  opacity: 0;
  margin-top: 16px;
  padding: 0 24px;
  transition: transform .3s ease-out, opacity .3s ease-out;
  position: relative;
  overflow: hidden;
  transform: scaleY(0);
}

.period-coverage-filter-widget .time-range-form.date-time-range .form-items {
  background-color: #f6f8fb;
  border-radius: 8px;
  padding: 12px;
}

.period-coverage-filter-widget .time-range-form.date-time-range .time-range-selector {
  background-color: unset;
  border-radius: unset;
  padding: unset;
}

.period-coverage-filter-widget .time-range-form.date-time-range .time-range-selector .form-input {
  padding: 8px 24px 0 0;
}

.period-coverage-filter-widget .time-range-form.show {
  opacity: 1;
  overflow: visible;
  transform: scaleY(1);
}

.period-coverage-filter-widget .time-range-form .form-items {
  grid-gap: 10px;
  grid-template-columns: 1.2fr repeat(2, 1fr) auto repeat(2, 1fr);
  margin-bottom: 24px;
  display: grid;
}

.period-coverage-filter-widget .time-range-form .time-range-selector {
  background-color: #f6f8fb;
  border-radius: 8px;
  align-self: end;
  padding: 12px;
}

.period-coverage-filter-widget .time-range-form .time-range-selector .form-label {
  color: #7d7e92;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.period-coverage-filter-widget .time-range-form .time-range-selector .form-input {
  padding: 8px 0 0;
}

.period-coverage-filter-widget .time-range-form .time-range-selector .drop-down-input {
  z-index: 999;
  cursor: pointer;
  min-width: 200px;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 12px 14px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  position: relative;
}

.period-coverage-filter-widget .time-range-form .time-range-selector .drop-down-input .value {
  flex-grow: 1;
  padding: 0 12px;
}

.period-coverage-filter-widget .time-range-form .time-range-selector .drop-down-input .down-arrow-icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin: -10px 12px 0 0;
  display: flex;
}

.period-coverage-filter-widget .time-range-form .time-range-selector .drop-down-input .down-arrow-icon > img {
  height: 100%;
}

.period-coverage-filter-widget .time-range-form .time-range-selector .drop-down-input .options {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-top: 16px;
  position: absolute;
  top: 100%;
  left: 0;
}

.period-coverage-filter-widget .time-range-form .time-range-selector .drop-down-input .options .option {
  border-bottom: 1px solid #dee0e6;
  justify-content: space-between;
  padding: 10px 12px;
  display: flex;
}

.period-coverage-filter-widget .time-range-form .time-range-selector .drop-down-input .options .option:last-child {
  border-bottom: 0;
}

.period-coverage-filter-widget .time-range-form .time-range-selector .drop-down-input .value {
  padding: 0;
}

.period-coverage-filter-widget .time-range-form .time-range-selector .drop-down-input .down-arrow-icon {
  margin: 0;
}

.period-coverage-filter-widget .time-range-form .time-range-selector .drop-down-input .options {
  margin-top: 5px;
}

.period-coverage-filter-widget .time-range-form .time-range-selector .drop-down-input .options .option {
  border-bottom: none;
}

.period-coverage-filter-widget .time-range-form .start-date label, .period-coverage-filter-widget .time-range-form .end-date label {
  color: #7d7e92;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.period-coverage-filter-widget .time-range-form .start-time, .period-coverage-filter-widget .time-range-form .end-time {
  align-self: end;
}

.period-coverage-filter-widget .time-range-form .start-time .time-input, .period-coverage-filter-widget .time-range-form .end-time .time-input {
  background-color: #fff;
}

.period-coverage-filter-widget .time-range-form .date-range-separator {
  align-self: end;
  padding-bottom: 15px;
}

@media screen and (max-width: 1160px) {
  .period-coverage-filter-widget .time-range-form.show {
    max-height: 210px;
  }

  .period-coverage-filter-widget .time-range-form .form-items {
    grid-template-columns: 1.2fr repeat(2, 1fr) auto;
  }
}

.loyalty-credits-widget {
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  grid-template-areas: "header header"
                       "amount button"
                       "info info";
  align-items: center;
  margin: 12px 12px 36px;
  padding: 12px 22px 12px 12px;
  display: grid;
  position: relative;
}

.loyalty-credits-widget.redeemable {
  background-color: #eff6ff;
  border: 1px solid #60a5fa;
}

.loyalty-credits-widget > .header {
  letter-spacing: 0;
  color: #1c1d3e;
  letter-spacing: .005em;
  grid-area: header;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}

.loyalty-credits-widget > .amount {
  letter-spacing: .015em;
  grid-area: amount;
  margin-bottom: 12px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
}

.loyalty-credits-widget > .action {
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  grid-area: button;
  margin-bottom: 12px;
  margin-left: auto;
  padding: 9.5px 24px;
}

.loyalty-credits-widget > .action.revert-button {
  color: #1e40af;
  background-color: #fff;
  border: 1px solid #3b82f6;
}

.loyalty-credits-widget > .action.redeem-button {
  color: #7d7e92;
  background-color: #dee0e6;
}

.loyalty-credits-widget > .action.redeem-button.redeemable {
  color: #fff;
  background-color: #3b82f6;
}

.loyalty-credits-widget > .progress-info {
  grid-area: info;
  align-items: center;
  padding: 2px 0;
  display: flex;
}

.loyalty-credits-widget > .progress-info .label {
  color: #1c1d3e;
  margin-right: 6px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.loyalty-credits-widget > .progress-info .bar {
  height: 6px;
  width: 100%;
  background: #bfdbfe;
  border-radius: 25px;
  position: relative;
}

.loyalty-credits-widget > .progress-info .bar .fill {
  height: 100%;
  width: 0;
  background: #3b82f6;
  border-radius: 15px;
  transition: width .75s ease-out;
  position: absolute;
}

.loyalty-credits-widget.loading {
  justify-content: center;
  align-items: center;
  display: flex;
}

.loyalty-credits-widget:after {
  content: "";
  width: 100%;
  border: none;
  border-top: 1px solid #9597a7;
  position: absolute;
  bottom: -26px;
}

.pos-order-delivery-status-widget {
  margin-bottom: 15px;
}

.pos-order-delivery-status-widget .delivery-failed-info {
  border-radius: 10px;
  box-shadow: 0 2px 6px #091e4226;
}

.pos-order-delivery-status-widget .delivery-failed-info .header {
  background-color: #f43f5e;
  border-radius: 10px 10px 0 0;
  align-items: center;
  padding: 16px;
  display: flex;
}

.pos-order-delivery-status-widget .delivery-failed-info .header .title {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #fff;
  margin-bottom: 10px;
  padding: 10px 0 0 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.pos-order-delivery-status-widget .delivery-failed-info .sub-title {
  letter-spacing: 0;
  border-bottom: 1px solid #dee0e6;
  padding: 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.pos-order-delivery-status-widget .delivery-failed-info .actions {
  display: flex;
}

.pos-order-delivery-status-widget .delivery-failed-info .actions .label-actions {
  margin-bottom: 10px;
}

.pos-order-delivery-status-widget .delivery-failed-info .actions .refund-customer {
  border-right: 1px solid #dee0e6;
  padding: 16px 50px 16px 16px;
}

.pos-order-delivery-status-widget .delivery-failed-info .actions .refund-customer .refund-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: #9f1239;
  border: 1px solid #9f1239;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
}

.pos-order-delivery-status-widget .delivery-failed-info .actions .mark-completed-order {
  padding: 16px;
}

.pos-order-delivery-status-widget .delivery-failed-info .actions .mark-completed-order .completed-order-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #4cbba1;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
}

.pos-order-delivery-status-widget .cancelled-info {
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0 2px 6px #091e4226;
}

.pos-order-delivery-status-widget .cancelled-info .courier-and-label-container {
  box-shadow: none;
  grid-auto-columns: unset;
  padding: 0;
}

.pos-order-delivery-status-widget .cancelled-info .courier-and-label-container > .status-label {
  grid-row-start: 1;
  grid-column-start: 1;
  padding-right: 20px;
}

.pos-order-delivery-status-widget .cancelled-info .courier-and-label-container > .courier-info {
  border-left: 1px solid #dee0e6;
  padding-left: 20px;
}

.pos-order-delivery-status-widget .delivered-info {
  border-radius: 10px;
  grid-template-areas: "status-label status-label"
                       "proof-of-delivery-label courier-info";
  display: grid;
  box-shadow: 0 2px 6px #091e4226;
}

.pos-order-delivery-status-widget .delivered-info > .status-label {
  background-color: #10b981;
  border-radius: 10px 10px 0 0;
  grid-area: status-label;
  padding: 24px;
}

.pos-order-delivery-status-widget .delivered-info > .proof-of-delivery-label {
  grid-area: proof-of-delivery-label;
  grid-template-rows: min-content min-content;
  align-self: center;
  gap: 10px;
  margin: 24px 0 24px 24px;
  padding-right: 20px;
  display: grid;
}

.pos-order-delivery-status-widget .delivered-info > .proof-of-delivery-label > .label {
  color: #1c1d3e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.pos-order-delivery-status-widget .delivered-info > .proof-of-delivery-label .view-link {
  color: #4c4e68;
  cursor: pointer;
  align-self: flex-end;
  text-decoration: underline;
}

.pos-order-delivery-status-widget .delivered-info > .courier-info {
  border-left: 1px solid #dee0e6;
  grid-area: courier-info;
  margin: 24px 24px 24px 0;
  padding-left: 20px;
}

.pos-order-delivery-status-widget .courier-pickup-date-and-time {
  border-radius: 10px;
  place-items: center;
  padding: 16px;
  display: grid;
  box-shadow: 0 2px 6px #091e4226;
}

.pos-order-delivery-status-widget .courier-pickup-date-and-time > img {
  width: 100px;
}

.pos-order-delivery-status-widget .courier-pickup-date-and-time .title {
  color: #1c1d3e;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.pos-order-delivery-status-widget .courier-pickup-date-and-time .subtitle {
  color: #1c1d3e;
  grid-auto-flow: column;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  display: grid;
}

.pos-order-delivery-status-widget .courier-pickup-date-and-time .subtitle > .time-since-label-widget {
  color: #4cbba1 !important;
}

.pos-order-delivery-status-widget .tracking-map {
  border-radius: 10px;
  margin-bottom: 16px;
  overflow: hidden;
  box-shadow: 0 2px 6px #091e4226;
}

.pos-order-delivery-status-widget .tracking-map .map-container {
  width: 100%;
  height: 240px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.pos-order-delivery-status-widget .tracking-map .map-container > iframe {
  width: 170%;
  height: 555px;
  transform-origin: 0 0;
  position: relative;
  top: -45px;
  left: -1px;
  overflow: hidden;
  transform: scale(.6);
}

.pos-order-delivery-status-widget .tracking-map > .label {
  color: #343553;
  grid-auto-columns: min-content 1fr;
  grid-auto-flow: column;
  align-items: center;
  gap: 8px;
  padding: 12px 14px;
  font-size: 14px;
  display: grid;
}

.pos-order-delivery-status-widget .courier-and-label-container {
  border-radius: 10px;
  grid-auto-columns: 1fr max-content;
  grid-auto-flow: column;
  align-items: center;
  padding: 16px;
  display: grid;
  box-shadow: 0 2px 6px #091e4226;
}

.pos-order-delivery-status-widget .courier-info {
  grid-template-areas: "image name"
                       "image vehicle"
                       "image contact";
  grid-auto-columns: min-content 1fr;
  align-items: center;
  gap: 0 12px;
  display: grid;
}

.pos-order-delivery-status-widget .courier-info .courier-image {
  width: 74px;
  border-radius: 50%;
  grid-area: image;
}

.pos-order-delivery-status-widget .courier-info .courier-name {
  color: #1c1d3e;
  grid-area: name;
  font-size: 16px;
  font-weight: 500;
}

.pos-order-delivery-status-widget .courier-info .courier-vehicle {
  color: #7d7e92;
  grid-area: vehicle;
  font-size: 14px;
  font-weight: 400;
}

.pos-order-delivery-status-widget .courier-info .courier-contact-number {
  color: #7d7e92;
  grid-area: contact;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.pos-order-delivery-status-widget .status-label {
  letter-spacing: 0;
  color: #1c1d3e;
  margin: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.pos-order-delivery-status-widget .status-label.ongoing {
  color: #059669;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.pos-order-delivery-status-widget .status-label.ongoing:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #059669;
  border-radius: 100%;
  margin-right: 8px;
  background-color: #1e3a8a !important;
}

.pos-order-delivery-status-widget .status-label.failed {
  color: #9f1239;
  grid-template-columns: min-content 1fr;
  grid-auto-flow: column;
  gap: 12px;
  display: grid;
}

.pos-order-delivery-status-widget .status-label.success {
  color: #fff;
  grid-template-columns: min-content 1fr;
  grid-auto-flow: column;
  gap: 12px;
  font-size: 18px;
  font-weight: 500;
  display: grid;
}

.uber-delivery-cancel-order-confirmation-widget {
  width: 23vw;
  text-align: center;
  padding: 25px;
}

.uber-delivery-cancel-order-confirmation-widget .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.uber-delivery-cancel-order-confirmation-widget .sub-title {
  color: #4c4e68;
}

.uber-delivery-cancel-order-confirmation-widget .actions {
  margin-top: 10px;
}

.uber-delivery-cancel-order-confirmation-widget .actions .cancel-button {
  width: 100%;
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background: #9f1239;
  border: 2px solid #9f1239;
  border-radius: 10px;
  margin: 0 0 10px;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
}

.uber-delivery-cancel-order-confirmation-widget .actions .cancel-button:focus {
  border: none;
  outline: none;
}

.uber-delivery-cancel-order-confirmation-widget .actions .go-back-button {
  width: 100%;
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  color: #1c1d3e;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
}

.uber-delivery-cancel-order-confirmation-widget .actions .go-back-button:focus {
  border: none;
  outline: none;
}

.uber-delivery-payment-over-phone-confirmation-widget {
  padding: 0 15px;
}

.uber-delivery-payment-over-phone-confirmation-widget .header {
  text-align: center;
  padding: 30px 70px;
}

.uber-delivery-payment-over-phone-confirmation-widget .header .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 25px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.uber-delivery-payment-over-phone-confirmation-widget .actions {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  display: flex;
}

.uber-delivery-payment-over-phone-confirmation-widget .actions .cancel-button {
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  background: none;
  border: 1px solid #7d7e92;
  border-radius: 5px;
  flex: 1;
  margin-right: 15px;
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  transition: all .2s ease-in-out;
}

.uber-delivery-payment-over-phone-confirmation-widget .actions .cancel-button:focus {
  border: none;
  outline: none;
}

.uber-delivery-payment-over-phone-confirmation-widget .actions .confirm-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  flex: 1;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.uber-delivery-payment-over-phone-confirmation-widget .actions .confirm-button:focus {
  border: none;
  outline: none;
}

.uber-delivery-request-for-pos-order-success-widget {
  width: 750px;
  height: 500px;
  place-items: center;
  padding: 80px 36px;
  display: grid;
}

.uber-delivery-request-for-pos-order-success-widget > img {
  width: 140px;
}

.uber-delivery-request-for-pos-order-success-widget .title {
  color: #1c1d3e;
  margin-top: 40px;
  font-size: 25px;
  font-weight: 500;
}

.uber-delivery-request-for-pos-order-success-widget .subtitle {
  color: #1c1d3e;
  grid-auto-flow: column;
  gap: 6px;
  margin-top: 16px;
  font-size: 17px;
  font-weight: 500;
  display: grid;
}

.uber-delivery-request-for-pos-order-success-widget .subtitle .time-since-label-widget {
  color: #4cbba1 !important;
}

.uber-delivery-request-for-pos-order-success-widget .actions {
  width: 100%;
  gap: 15px;
  margin-top: 18px;
  display: grid;
}

.uber-delivery-request-for-pos-order-success-widget .actions .done-button {
  padding: 14px;
}

.uber-delivery-request-for-pos-order-success-widget .actions .cancel-uber-delivery-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 5px;
  margin: 0;
  padding: 14px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
}

.uber-delivery-request-for-pos-order-success-widget .actions .cancel-uber-delivery-button:focus {
  border: none;
  outline: none;
}

.uber-delivery-collect-payment-for-pos-order-widget {
  width: 40vw;
  padding: 0 20px;
}

.uber-delivery-collect-payment-for-pos-order-widget .header {
  text-align: center;
  padding: 30px 10px;
}

.uber-delivery-collect-payment-for-pos-order-widget .header .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 25px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
}

.uber-delivery-collect-payment-for-pos-order-widget .header .label {
  color: #1c1d3e;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.uber-delivery-collect-payment-for-pos-order-widget .header .label .bold {
  font-family: Sailec-Bold, Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-style: normal;
  font-weight: bold;
}

.uber-delivery-collect-payment-for-pos-order-widget .payment-amount {
  background: linear-gradient(90deg, #f6f8fbb3 0%, #e9e9e9b3 100%);
  border-radius: 10px;
  grid-auto-flow: column;
  margin-bottom: 15px;
  padding: 30px;
  display: grid;
}

.uber-delivery-collect-payment-for-pos-order-widget .payment-amount .amount {
  color: #000;
  text-align: right;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.uber-delivery-collect-payment-for-pos-order-widget .payment-amount .amount:first-letter {
  margin-right: 10px;
}

.uber-delivery-collect-payment-for-pos-order-widget .actions {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  display: flex;
}

.uber-delivery-collect-payment-for-pos-order-widget .actions > button {
  border-radius: 5px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.uber-delivery-collect-payment-for-pos-order-widget .actions .cancel-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background: none;
  border: 1px solid #7d7e92;
  flex: 1;
  margin-right: 15px;
  padding: 15px;
  transition: all .2s ease-in-out;
}

.uber-delivery-collect-payment-for-pos-order-widget .actions .cancel-button:focus {
  border: none;
  outline: none;
}

.uber-delivery-collect-payment-for-pos-order-widget .actions .payment-success-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  flex: 1;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.uber-delivery-collect-payment-for-pos-order-widget .actions .payment-success-button:focus {
  border: none;
  outline: none;
}

.table-booking-edit-widget {
  grid-template-rows: max-content max-content min-content;
  display: grid;
}

.table-booking-edit-form-widget {
  display: grid;
}

.table-booking-edit-form-widget.extend {
  padding-bottom: 50vh;
}

.table-booking-edit-form-widget .items {
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "customer-number customer-name"
                       "customer-email date-time"
                       "pax-seating-area pax-seating-area"
                       "special-request special-request";
  gap: 24px 16px;
  display: grid;
}

.table-booking-edit-form-widget .items .customer-number {
  grid-area: customer-number;
}

.table-booking-edit-form-widget .items .customer-name {
  grid-area: customer-name;
}

.table-booking-edit-form-widget .items .customer-email {
  grid-area: customer-email;
}

.table-booking-edit-form-widget .items .date-time {
  grid-area: date-time;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  display: grid;
}

.table-booking-edit-form-widget .items .pax-seating-area {
  grid-area: pax-seating-area;
  grid-template-columns: 141px 1fr;
  column-gap: 16px;
  display: grid;
}

.table-booking-edit-form-widget .items .pax-seating-area .seating-areas {
  position: relative;
}

.table-booking-edit-form-widget .items .pax-seating-area .seating-areas .seating-area-options {
  max-height: 230%;
  width: 100%;
  float: right;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 4px;
  position: absolute;
  top: 105%;
  left: 0;
  overflow: auto;
}

.table-booking-edit-form-widget .items .pax-seating-area .seating-areas .seating-area-options > .option {
  cursor: pointer;
  border-bottom: 1px solid #dee0e6;
  align-items: center;
  padding: 16px;
  display: flex;
}

.table-booking-edit-form-widget .items .pax-seating-area .seating-areas .seating-area-options > .option:last-child {
  border-bottom: 0;
}

.table-booking-edit-form-widget .items .pax-seating-area .seating-areas .seating-area-options > .option.selected {
  background-color: #f4f4f6;
  font-weight: 500;
}

.table-booking-edit-form-widget .items .special-request {
  grid-area: special-request;
}

.table-booking-edit-form-widget .items .special-request .notes-character-limit {
  float: right;
  color: #9597a7;
  margin-top: 8px;
}

.table-booking-edit-form-widget .form-items:has(.invalid) > div {
  padding: 12px 0 0;
}

.table-booking-edit-form-widget .form-items:has(.invalid) > .actions {
  padding: unset;
}

.table-booking-edit-form-widget .form-items {
  gap: 0;
  margin: 0 26px;
}

.table-booking-edit-form-widget .form-items .meta {
  margin-top: 5px;
  margin-bottom: 4px;
  position: unset !important;
}

.table-booking-edit-form-widget .form-items .form-select-pax {
  padding: 12px;
}

.table-booking-edit-form-widget .form-items .form-select-icon input {
  background-image: url("icon-chevron-down-grey.b17677ff.svg");
  background-position: right 20px top 60%;
  background-repeat: no-repeat;
  background-size: 20px !important;
}

.table-booking-edit-form-widget .form-items .form-item {
  position: relative;
}

.table-booking-edit-form-widget .form-items .form-item label {
  color: #7d7e92;
  padding: 5px 0 10px;
  font-size: 15px;
  line-height: 30px;
  position: absolute;
  top: 5px;
  left: 6px;
}

.table-booking-edit-form-widget .form-items .form-item .meta {
  position: absolute;
  top: 78px;
}

.table-booking-edit-form-widget .form-items .form-item .meta .error {
  color: #f43f5e;
  font-size: 14px;
}

.table-booking-edit-form-widget .form-items .form-item > .form-input > div > input, .table-booking-edit-form-widget .form-items .form-item textarea {
  letter-spacing: .005em;
  color: #1c1d3e;
  border-radius: 8px;
  padding: 40px 10px 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5em;
}

.table-booking-edit-form-widget .form-items .form-item > .form-input > div > input.invalid, .table-booking-edit-form-widget .form-items .form-item textarea.invalid {
  border: 1px solid #f43f5e !important;
}

.table-booking-edit-form-widget .form-items .form-item ::placeholder {
  color: #65667d;
}

.table-booking-edit-form-widget .form-items .actions {
  padding: unset;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
}

.table-booking-edit-form-widget .form-items .actions .label {
  padding: 13px 33px;
}

.table-booking-edit-form-widget .form-items .actions .cancel-button {
  max-width: 120px;
  color: #7d7e92;
  padding: unset;
  max-height: 50px;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin-left: auto;
  margin-right: 16px;
}

.table-booking-edit-form-widget .form-items .actions .save-button {
  max-width: 102px;
  color: #fff;
  padding: unset;
  max-height: 50px;
  background-color: #3f9c86;
  border-radius: 4px;
}

.table-booking-edit-form-widget .form-items .actions .disabled-save-button {
  max-width: 102px;
  padding: unset;
  max-height: 50px;
  background-color: #83d0be;
  border-radius: 4px;
}

.table-booking-edit-form-confirmation .swal2-html-container {
  max-width: 210px;
}

.table-booking-iso-date-picker, .table-booking-time-of-day-picker {
  padding: 20px;
}

.table-booking-activity-logs-widget {
  display: grid;
}

.table-booking-activity-logs-widget .activity-logs-button {
  color: #1c1d3e;
  align-items: center;
  margin-left: 26px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.table-booking-activity-logs-widget .activity-logs-button svg {
  transform: rotate(-90deg);
}

.table-booking-activity-logs-widget .activity-logs-button.close svg {
  transform: rotate(0);
}

.table-booking-activity-logs-widget .activity-logs-button span {
  margin-left: 16px;
}

.table-booking-activity-logs-list-item-widget .field {
  border-bottom: 1px solid #dee0e6;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.table-booking-activity-logs-list-item-widget .date {
  font-family: Sailec-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.table-booking-activity-logs-list-item-widget .employee {
  text-transform: capitalize;
}

.table-booking-activity-logs-list-item-widget .old-value, .table-booking-activity-logs-list-item-widget .new-value {
  font-family: Sailec-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.table-booking-activity-logs-list-widget {
  margin: 0 24px;
}

.table-booking-activity-logs-list-widget .layout {
  grid-template-columns: minmax(50px, 201px) minmax(50px, 128px) minmax(50px, 271px) minmax(50px, 539px);
  padding: 16px 24px;
  display: grid;
}

.table-booking-activity-logs-list-widget .layout.label {
  color: #7d7e92;
  text-transform: uppercase;
  padding: 12px 24px;
  font-size: 11px;
  line-height: 24px;
}

.table-booking-activity-logs-list-widget .table-booking-activity-logs {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-bottom: 50px;
  display: grid;
  box-shadow: 0 0 10px #00000026;
}

.table-booking-activity-logs-list-widget .table-booking-activity-logs .cancelled {
  border-bottom: 1px solid #dee0e6;
}

.table-booking-activity-logs-list-widget .table-booking-activity-logs .information {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-bottom: 50px;
  display: grid;
  box-shadow: 0 0 10px #00000026;
}

.table-booking-activity-logs-list-widget .table-booking-activity-logs .information .field {
  border-bottom: 1px solid #dee0e6;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.table-booking-activity-logs-list-widget .table-booking-activity-logs .information .date {
  font-family: Sailec-Medium;
}

.table-booking-activity-logs-list-widget .table-booking-activity-logs .information .employee {
  text-transform: capitalize;
}

.table-booking-activity-logs-list-widget .table-booking-activity-logs .information .new-value {
  font-family: Sailec-Medium;
}

.table-booking-iso-date-picker-widget .header {
  padding: 7px 24px 13px;
}

.table-booking-iso-date-picker-widget .header .title {
  text-align: center;
  letter-spacing: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.table-booking-iso-date-picker-widget .current-date .form-iso-date-picker .DayPicker-Day--today {
  color: #1c1d3e;
  text-align: center;
  outline-offset: -10px;
  border-radius: 25%;
  outline: 2px solid #4cbba1;
}

.table-booking-iso-date-picker-widget .selected-date .form-iso-date-picker .DayPicker-Day--today {
  color: #1c1d3e;
}

.table-booking-iso-date-picker-widget .selected-date .form-iso-date-picker .DayPicker-Day--highlighted {
  color: #1c1d3e;
  text-align: center;
  outline-offset: -10px;
  border-radius: 25%;
  outline: 2px solid #4cbba1;
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-wrapper {
  -webkit-user-select: none;
  user-select: none;
  border: 2px solid #dee0e6;
  border-radius: 8px;
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-Month {
  border-spacing: 30px 0;
  border-collapse: separate;
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-NavBar {
  all: unset;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  display: flex;
  position: relative;
  top: 35px;
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-NavButton {
  all: unset;
  width: 1.25em;
  height: 1.25em;
  color: #8b9898;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 20px;
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-NavButton--prev {
  background-image: url("icon-chevron-down-grey.b17677ff.svg");
  transform: rotate(90deg);
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-NavButton--next {
  background-image: url("icon-chevron-down-grey.b17677ff.svg");
  transform: rotate(270deg);
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-Caption {
  text-align: center;
  margin-bottom: 34px;
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-Caption > div {
  font-size: 20px;
  font-weight: 700;
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-Weekday {
  color: #8b9898;
  font-size: 16px;
  line-height: 22px;
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-WeekdaysRow {
  text-transform: uppercase;
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-WeekdaysRow .DayPicker-Weekday:first-child:after, .table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-WeekdaysRow .DayPicker-Weekday:nth-child(2):after {
  content: "N";
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-WeekdaysRow .DayPicker-Weekday:nth-child(3):after {
  content: "E";
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-WeekdaysRow .DayPicker-Weekday:nth-child(4):after {
  content: "D";
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-WeekdaysRow .DayPicker-Weekday:nth-child(5):after {
  content: "U";
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-WeekdaysRow .DayPicker-Weekday:nth-child(6):after {
  content: "I";
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-WeekdaysRow .DayPicker-Weekday:nth-child(7):after {
  content: "T";
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-Day {
  padding: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-Day--disabled.DayPicker-Day--today {
  cursor: default;
  color: #dee0e6;
  outline: none;
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-Day--disabled {
  cursor: default;
  color: #dee0e6;
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPicker-Day--unavailable {
  color: #dee0e6;
  outline-offset: -10px;
  border-radius: 25%;
  outline: 2px solid #dee0e6;
}

.table-booking-iso-date-picker-widget .form-iso-date-picker .DayPickerInput > input {
  box-sizing: border-box;
  color: #44444f;
  width: 100%;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-weight: 400;
  transition: all .3s;
}

.table-booking-iso-date-picker-widget .action {
  margin: 29px 0 0;
}

.table-booking-iso-date-picker-widget .action .back-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #1c1d3e;
  width: 100%;
  background-color: #fff;
  border: 2px solid #adafbc;
  margin: 0;
  padding: 15px;
  font-size: 15px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.table-booking-time-of-day-list-widget {
  width: 70vw;
  height: 85vh;
  grid-template-columns: min-content minmax(6fr, max-content);
  display: grid;
  position: relative;
  overflow: hidden;
}

.table-booking-time-of-day-list-widget .header {
  grid-template-columns: 1fr max-content min-content;
  align-items: center;
  padding: 10px 24px;
  display: grid;
}

.table-booking-time-of-day-list-widget .header .title {
  text-align: center;
  letter-spacing: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.table-booking-time-of-day-list-widget .header > button {
  min-width: 20%;
  letter-spacing: .5px;
  border-radius: 8px;
  padding: 10px 46px;
  font-size: 17px;
}

.table-booking-time-of-day-list-widget .empty-list {
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  font-size: 21px;
  display: flex;
}

.table-booking-time-of-day-list-widget .empty-list > button {
  min-width: 20%;
  letter-spacing: .5px;
  border-radius: 8px;
  margin-top: 27px;
  padding: 13px;
  font-size: 17px;
}

.table-booking-time-of-day-list-widget .times-of-day-list {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(136px, 1fr));
  gap: 12px;
  padding: 10px 12px 24px;
  display: grid;
  overflow-x: hidden;
  overflow-y: scroll;
}

.table-booking-time-of-day-list-widget .times-of-day-list::-webkit-scrollbar {
  display: none;
}

.table-booking-time-of-day-list-widget .times-of-day-list .option {
  text-align: center;
  color: #1c1d3e;
  cursor: pointer;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  flex-flow: column;
  justify-content: center;
  margin: 0;
  padding: 20px 10px;
  transition: unset;
  display: flex;
  position: relative;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.table-booking-time-of-day-list-widget .times-of-day-list .option.selected {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(#2c2d56 0%, #0c0d34 100%);
}

.table-booking-time-of-day-list-widget .times-of-day-list .option.selected:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: #4cbba1;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
}

.table-booking-time-of-day-list-widget .times-of-day-list .option.selected:after {
  content: "";
  width: 5px;
  height: 10px;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  display: block;
  position: absolute;
  top: -3px;
  right: 0;
  transform: rotate(45deg);
}

.table-booking-time-of-day-list-widget .times-of-day-list .option.selected .charge {
  color: #fff;
}

.table-booking-time-of-day-list-widget .times-of-day-list .option .charge {
  color: #1c1d3e90;
  margin: 7px 0 0;
}

.table-booking-time-of-day-list-widget .times-of-day-list .option {
  letter-spacing: .5px;
  padding: 22px;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

.table-booking-time-of-day-list-widget .times-of-day-list .option.unavailable {
  color: #adafbc;
  background: #e9e9ec;
}

.table-booking-time-of-day-list-widget .action {
  margin: 30px 0;
}

.table-booking-time-of-day-list-widget .action .back-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #1c1d3e;
  width: 100%;
  background-color: #fff;
  border: 2px solid #adafbc;
  margin: 0;
  padding: 15px;
  font-size: 15px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.uber-delivery-failed-view-widget {
  border-radius: 10px;
  box-shadow: 0 2px 6px #091e4226;
}

.uber-delivery-failed-view-widget .header {
  background-color: #f43f5e;
  border-radius: 10px 10px 0 0;
  align-items: center;
  padding: 28px 24px;
  display: flex;
}

.uber-delivery-failed-view-widget .header .title {
  color: #fff;
  padding-left: 12px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.uber-delivery-failed-view-widget .sub-title {
  letter-spacing: .005em;
  border-bottom: 1px solid #dee0e6;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.uber-delivery-failed-view-widget .actions {
  display: flex;
}

.uber-delivery-failed-view-widget .actions .label-actions {
  letter-spacing: .0025em;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.uber-delivery-failed-view-widget .actions .refund-order {
  border-right: 1px solid #dee0e6;
  padding: 16px 50px 16px 16px;
}

.uber-delivery-failed-view-widget .actions .refund-order .refund-order-button {
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: #9f1239;
  border: 1px solid #9f1239;
  border-radius: 4px;
  padding: 9.5px 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  transition: all .2s ease-in-out;
}

.uber-delivery-failed-view-widget .actions .complete-order {
  padding: 16px;
}

.uber-delivery-failed-view-widget .actions .complete-order .complete-order-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #4cbba1;
  border-radius: 4px;
  margin: 0;
  padding: 9.5px 24px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
}

.confirm-refund .swal2-header {
  padding: 9px 24px 12px !important;
}

.confirm-refund .swal2-content {
  padding: 0 24px !important;
}

.confirm-refund .swal2-title {
  color: #1c1d3e !important;
  margin: 0 !important;
}

.confirm-refund .swal2-html-container {
  color: #4c4e68 !important;
}

.confirm-refund .swal2-footer {
  padding: 24px 24px 9px !important;
}

.confirm-refund .info {
  grid-template-columns: min-content 1fr;
  gap: 6px;
  display: grid;
  color: #343553 !important;
}

.confirm-refund .info .text {
  text-align: center;
}

.confirm-refund .swal2-actions {
  padding: 24px 24px 0 !important;
}

.confirm-refund .swal2-actions button {
  text-transform: none !important;
}

.confirm-refund .swal2-actions .confirm-button {
  color: #fff;
  background: #9f1239 !important;
  border-radius: 4px !important;
}

.confirm-refund .swal2-actions .cancel-button {
  border-radius: 4px;
  color: #7d7e92 !important;
  border: 1px solid #7d7e92 !important;
}

.mark-completed .swal2-title {
  color: #1c1d3e !important;
  margin: 0 !important;
}

.mark-completed .swal2-header {
  padding: 9px 24px 24px !important;
}

.mark-completed .swal2-actions {
  padding: 0 24px 9px !important;
}

.mark-completed .swal2-actions button {
  text-transform: none !important;
  border-radius: 4px !important;
}

.mark-completed .swal2-actions .confirm-button {
  background-color: #3f9c86 !important;
}

.mark-completed .swal2-actions .cancel-button {
  color: #7d7e92 !important;
  border: 1px solid #7d7e92 !important;
}

.split-bill-payment-summary-widget {
  margin-top: 16px;
}

.split-bill-payment-summary-widget > .header {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.split-bill-payment-summary-widget > .header > .label {
  color: #1c1d3e;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

.split-bill-payment-summary-widget > .header > .icon {
  height: 16px;
  width: 16px;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  display: flex;
}

.split-bill-payment-summary-widget > .header > .icon > img {
  height: 100%;
}

.split-bill-payment-summary-widget > .header > .split-type {
  text-transform: capitalize;
  margin-left: auto;
  font-weight: 700;
}

.split-bill-payment-summary-widget > .split-payments {
  margin-top: 12px;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment {
  color: #7d7e92;
  border-top: 1px solid #dee0e6;
  grid-template-columns: 1fr auto;
  grid-template-areas: "label amount"
                       "transaction-reference ."
                       "surcharge-label surcharge-amount"
                       "tip-label tip-amount"
                       "items items";
  place-items: center;
  gap: 0 8px;
  margin-top: 12px;
  padding-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment:first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment > .payment {
  color: #1c1d3e;
  display: contents;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment > .payment > .label {
  grid-area: label;
  justify-self: start;
  align-items: center;
  display: flex;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment > .payment > .label > .type {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment > .payment > .label > .bullet {
  color: #4c4e68;
  margin: 0 8px;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment > .payment > .amount {
  grid-area: amount;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment > .payment > .amount.refund {
  color: #9f1239;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment > .transaction-reference {
  color: #7d7e92;
  grid-area: transaction-reference;
  justify-self: start;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment > .surcharge {
  color: #7d7e92;
  display: contents;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment > .surcharge > .label {
  grid-area: surcharge-label;
  justify-self: start;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment > .surcharge > .amount {
  grid-area: surcharge-amount;
  justify-self: end;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment > .tip {
  color: #7d7e92;
  display: contents;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment > .tip > .label {
  grid-area: tip-label;
  justify-self: start;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment > .tip > .amount {
  grid-area: tip-amount;
  justify-self: end;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment > .split-bill-items {
  width: 100%;
  grid-area: items;
  margin-top: 8px;
  padding: 0 0 12px 32px;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment > .split-bill-items > .item {
  margin-top: 12px;
  display: list-item;
}

.split-bill-payment-summary-widget > .split-payments > .split-payment > .split-bill-items > .item:first-child {
  margin-top: 0;
}

.pos-order-version-payment-summary-widget {
  margin-top: 16px;
}

.pos-order-version-payment-summary-widget > .header {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.pos-order-version-payment-summary-widget > .header > .label {
  color: #1c1d3e;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

.pos-order-version-payment-summary-widget > .header > .icon {
  height: 16px;
  width: 16px;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  display: flex;
}

.pos-order-version-payment-summary-widget > .header > .icon > img {
  height: 100%;
}

.pos-order-version-payment-summary-widget > .versions {
  margin-top: 12px;
}

.pos-order-version-payment-summary-widget > .versions > .version-payment-info {
  color: #7d7e92;
  border-top: 1px solid #dee0e6;
  grid-template-columns: 1fr auto;
  grid-template-areas: "label amount"
                       "transaction-reference ."
                       "surcharge-label surcharge-amount"
                       "tip-label tip-amount"
                       "items items";
  place-items: center;
  gap: 0 8px;
  margin-top: 12px;
  padding-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.pos-order-version-payment-summary-widget > .versions > .version-payment-info:first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.pos-order-version-payment-summary-widget > .versions > .version-payment-info > .payment {
  color: #1c1d3e;
  display: contents;
}

.pos-order-version-payment-summary-widget > .versions > .version-payment-info > .payment > .label {
  grid-area: label;
  justify-self: start;
  align-items: center;
  display: flex;
}

.pos-order-version-payment-summary-widget > .versions > .version-payment-info > .payment > .label > .type {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.pos-order-version-payment-summary-widget > .versions > .version-payment-info > .payment > .label > .bullet {
  color: #4c4e68;
  margin: 0 8px;
}

.pos-order-version-payment-summary-widget > .versions > .version-payment-info > .payment > .amount {
  grid-area: amount;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.pos-order-version-payment-summary-widget > .versions > .version-payment-info > .payment > .amount.refund {
  color: #9f1239;
}

.pos-order-version-payment-summary-widget > .versions > .version-payment-info > .transaction-reference {
  color: #7d7e92;
  grid-area: transaction-reference;
  justify-self: start;
}

.pos-order-version-payment-summary-widget > .versions > .version-payment-info > .surcharge {
  color: #7d7e92;
  display: contents;
}

.pos-order-version-payment-summary-widget > .versions > .version-payment-info > .surcharge > .label {
  grid-area: surcharge-label;
  justify-self: start;
}

.pos-order-version-payment-summary-widget > .versions > .version-payment-info > .surcharge > .amount {
  grid-area: surcharge-amount;
  justify-self: end;
}

.pos-order-version-payment-summary-widget > .versions > .version-payment-info > .tip {
  color: #7d7e92;
  display: contents;
}

.pos-order-version-payment-summary-widget > .versions > .version-payment-info > .tip > .label {
  grid-area: tip-label;
  justify-self: start;
}

.pos-order-version-payment-summary-widget > .versions > .version-payment-info > .tip > .amount {
  grid-area: tip-amount;
  justify-self: end;
}

.order-history-list-widget {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  flex-direction: column;
  flex-grow: 1;
  margin-top: -122px;
  padding: 0 24px;
  display: flex;
  position: relative;
  overflow: auto;
}

.order-history-list-widget::-webkit-scrollbar {
  width: 1px;
}

.order-history-list-widget::-webkit-scrollbar-track {
  background: none;
}

.order-history-list-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.order-history-list-widget.show-date-time-filter > .orders {
  transform: translateY(122px);
}

.order-history-list-widget.show-date-time-filter > .no-orders, .order-history-list-widget.show-date-time-filter > .no-order-search-results {
  margin-top: 122px;
}

.order-history-list-widget.transform-none > .orders {
  margin-top: 122px;
  transition: none;
  transform: none;
}

.order-history-list-widget > .orders {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  flex-direction: column;
  transition: transform .3s ease-out;
  display: flex;
  overflow: auto;
}

.order-history-list-widget > .orders::-webkit-scrollbar {
  width: 1px;
}

.order-history-list-widget > .orders::-webkit-scrollbar-track {
  background: none;
}

.order-history-list-widget > .orders::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.order-history-list-widget > .orders > .order-history-list-item-widget {
  padding: 24px;
}

.order-history-list-widget > .orders > .order-history-list-item-widget.connected-stores {
  padding: 16px 24px;
}

.order-history-list-widget > .orders > .end-of-list {
  color: #7d7e92;
  text-align: center;
  padding: 24px 0;
  font-size: 14px;
}

.order-history-list-widget > .orders > .pos.order-history-list-item-widget {
  border: 1px solid #dee0e6;
  margin-top: -1px;
}

.order-history-list-widget > .orders > .pos.order-history-list-item-widget.highlighted {
  background: #fffbeb80;
  border: 1px solid #ff7c00 !important;
}

.order-history-list-widget > .orders > .pos.order-history-list-item-widget.highlighted + div {
  border-top: none;
}

.order-history-list-widget > .orders > .pos.order-history-list-item-widget:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 0;
}

.order-history-list-widget > .orders > .pos.order-history-list-item-widget:nth-last-child(2) {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.order-history-list-widget > .orders > .website.order-history-list-item-widget {
  border: 1px solid #dee0e6;
  margin-top: -1px;
}

.order-history-list-widget > .orders > .website.order-history-list-item-widget.highlighted {
  background: #fffbeb80;
  border: 1px solid #ff7c00 !important;
}

.order-history-list-widget > .orders > .website.order-history-list-item-widget.highlighted + div {
  border-top: none;
}

.order-history-list-widget > .orders > .website.order-history-list-item-widget:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 0;
}

.order-history-list-widget > .orders > .website.order-history-list-item-widget:nth-last-child(2) {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.order-history-list-widget > .orders > .google.order-history-list-item-widget {
  border: 1px solid #dee0e6;
  margin-top: -1px;
}

.order-history-list-widget > .orders > .google.order-history-list-item-widget.highlighted {
  background: #fffbeb80;
  border: 1px solid #ff7c00 !important;
}

.order-history-list-widget > .orders > .google.order-history-list-item-widget.highlighted + div {
  border-top: none;
}

.order-history-list-widget > .orders > .google.order-history-list-item-widget:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 0;
}

.order-history-list-widget > .orders > .google.order-history-list-item-widget:nth-last-child(2) {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.order-history-list-widget > .orders > .uber-eats.order-history-list-item-widget {
  border: 1px solid #dee0e6;
  margin-top: -1px;
}

.order-history-list-widget > .orders > .uber-eats.order-history-list-item-widget.highlighted {
  background: #fffbeb80;
  border: 1px solid #ff7c00 !important;
}

.order-history-list-widget > .orders > .uber-eats.order-history-list-item-widget.highlighted + div {
  border-top: none;
}

.order-history-list-widget > .orders > .uber-eats.order-history-list-item-widget:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 0;
}

.order-history-list-widget > .orders > .uber-eats.order-history-list-item-widget:nth-last-child(2) {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.order-history-list-widget > .no-orders {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 69px;
  transition: margin .3s ease-out;
  display: flex;
}

.order-history-list-widget > .no-orders > .label {
  color: #4c4e68;
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.order-history-list-widget > .no-order-search-results {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 69px;
  transition: margin .3s ease-out;
  display: flex;
}

.order-history-list-widget > .no-order-search-results > .label {
  color: #4c4e68;
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.order-history-list-widget > .no-order-search-results > .text {
  text-align: center;
  color: #1c1d3e;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.order-history-list-widget > .no-order-search-results > .text > .subtext {
  font-weight: 500;
}

.order-history-list-widget > .no-order-search-results > .text > .customer-page-link {
  color: #4cbba1;
  cursor: pointer;
}

.order-history-list-widget > .back-to-top-button {
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 30px;
  padding: 13px;
  position: fixed;
  top: 82vh;
  right: 20px;
  box-shadow: 0 2px 8px #687a9b40;
}

.pos.order-history-list-item-widget .order-details-info {
  white-space: nowrap;
  color: #1c1d3e;
  cursor: pointer;
  grid-template-columns: 1.25fr 1.25fr .5fr 1.25fr 1fr .5fr 1fr .35fr;
  grid-auto-flow: column;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-customer-info {
  min-width: 0;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "order-logo customer-name"
                       "order-logo customer-phone-number";
  gap: 0 10px;
  display: grid;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo {
  grid-area: order-logo;
  display: grid;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo .google-logo, .pos.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo .uber-eats-logo {
  align-self: center;
  width: 28px !important;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo > .optimized-image {
  align-self: center;
  display: grid;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo > .optimized-image > img {
  border-radius: 50%;
  width: 28px !important;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .customer-name {
  text-overflow: ellipsis;
  color: #1c1d3e;
  grid-area: customer-name;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  overflow: hidden;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .customer-phone-number {
  color: #7d7e92;
  grid-area: customer-phone-number;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-collection-info {
  max-width: 100%;
  overflow: hidden;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .collection-type {
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .collection-type > span {
  color: #7d7e92;
  margin-top: 2px;
  margin-left: 3px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .pickup-in-label {
  color: #1e40af;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .requested-for {
  color: #7d7e92;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .table-info {
  color: #7d7e92;
  max-width: 100%;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .table-info img {
  margin-right: 4px;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .table-info .table-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-grand-total {
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-order-status {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-payment-status {
  grid-template-columns: min-content min-content 1fr;
  align-items: center;
  display: grid;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .payment-status {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .payment-status.paid {
  color: #fff;
  background-color: #059669;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .payment-status.not-paid {
  color: #fff;
  background-color: #f43f5e;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .payment-status.refunded {
  color: #1c1d3e;
  background-color: #f59e0b;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .bullet {
  margin: 0 4px;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-payment-status .payment-type {
  color: #1c1d3e;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  overflow: hidden;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-docket-number {
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-placed-date-time {
  color: #1c1d3e;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-print-order-button {
  height: 22px;
  width: 22px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pos.order-history-list-item-widget .order-details-info .orders-list-item-print-order-button > img {
  height: 100%;
}

.pos.order-history-list-item-widget .orders-list-item-order-status.accepted, .pos.order-history-list-item-widget .orders-list-item-order-status.completed {
  color: #059669;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.pos.order-history-list-item-widget .orders-list-item-order-status.accepted:before, .pos.order-history-list-item-widget .orders-list-item-order-status.completed:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #059669;
  border-radius: 100%;
  margin-right: 8px;
}

.pos.order-history-list-item-widget .orders-list-item-order-status.cancelled, .pos.order-history-list-item-widget .orders-list-item-order-status.rejected {
  color: #f43f5e;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.pos.order-history-list-item-widget .orders-list-item-order-status.cancelled:before, .pos.order-history-list-item-widget .orders-list-item-order-status.rejected:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #f43f5e;
  border-radius: 100%;
  margin-right: 8px;
}

.website.order-history-list-item-widget .order-details-info {
  white-space: nowrap;
  color: #1c1d3e;
  cursor: pointer;
  grid-template-columns: 1.25fr 1.25fr .5fr 1.25fr 1fr .5fr 1fr .35fr;
  grid-auto-flow: column;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-customer-info {
  min-width: 0;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "order-logo customer-name"
                       "order-logo customer-phone-number";
  gap: 0 10px;
  display: grid;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo {
  grid-area: order-logo;
  display: grid;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo .google-logo, .website.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo .uber-eats-logo {
  align-self: center;
  width: 28px !important;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo > .optimized-image {
  align-self: center;
  display: grid;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo > .optimized-image > img {
  border-radius: 50%;
  width: 28px !important;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .customer-name {
  text-overflow: ellipsis;
  color: #1c1d3e;
  grid-area: customer-name;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  overflow: hidden;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .customer-phone-number {
  color: #7d7e92;
  grid-area: customer-phone-number;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-collection-info {
  max-width: 100%;
  overflow: hidden;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .collection-type {
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .collection-type > span {
  color: #7d7e92;
  margin-top: 2px;
  margin-left: 3px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .pickup-in-label {
  color: #1e40af;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .requested-for {
  color: #7d7e92;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .table-info {
  color: #7d7e92;
  max-width: 100%;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .table-info img {
  margin-right: 4px;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .table-info .table-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-grand-total {
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-order-status {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-payment-status {
  grid-template-columns: min-content min-content 1fr;
  align-items: center;
  display: grid;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .payment-status {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .payment-status.paid {
  color: #fff;
  background-color: #059669;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .payment-status.not-paid {
  color: #fff;
  background-color: #f43f5e;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .payment-status.refunded {
  color: #1c1d3e;
  background-color: #f59e0b;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .bullet {
  margin: 0 4px;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-payment-status .payment-type {
  color: #1c1d3e;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  overflow: hidden;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-docket-number {
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-placed-date-time {
  color: #1c1d3e;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-print-order-button {
  height: 22px;
  width: 22px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.website.order-history-list-item-widget .order-details-info .orders-list-item-print-order-button > img {
  height: 100%;
}

.website.order-history-list-item-widget .orders-list-item-order-status.processing-payment, .website.order-history-list-item-widget .orders-list-item-order-status.to-be-confirmed {
  color: #d97706;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.website.order-history-list-item-widget .orders-list-item-order-status.processing-payment:before, .website.order-history-list-item-widget .orders-list-item-order-status.to-be-confirmed:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #d97706;
  border-radius: 100%;
  margin-right: 8px;
}

.website.order-history-list-item-widget .orders-list-item-order-status.accepted, .website.order-history-list-item-widget .orders-list-item-order-status.completed {
  color: #059669;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.website.order-history-list-item-widget .orders-list-item-order-status.accepted:before, .website.order-history-list-item-widget .orders-list-item-order-status.completed:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #059669;
  border-radius: 100%;
  margin-right: 8px;
}

.website.order-history-list-item-widget .orders-list-item-order-status.failure, .website.order-history-list-item-widget .orders-list-item-order-status.cancelled, .website.order-history-list-item-widget .orders-list-item-order-status.rejected {
  color: #f43f5e;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.website.order-history-list-item-widget .orders-list-item-order-status.failure:before, .website.order-history-list-item-widget .orders-list-item-order-status.cancelled:before, .website.order-history-list-item-widget .orders-list-item-order-status.rejected:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #f43f5e;
  border-radius: 100%;
  margin-right: 8px;
}

.google.order-history-list-item-widget .order-details-info {
  white-space: nowrap;
  color: #1c1d3e;
  cursor: pointer;
  grid-template-columns: 1.25fr 1.25fr .5fr 1.25fr 1fr .5fr 1fr .35fr;
  grid-auto-flow: column;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-customer-info {
  min-width: 0;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "order-logo customer-name"
                       "order-logo customer-phone-number";
  gap: 0 10px;
  display: grid;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo {
  grid-area: order-logo;
  display: grid;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo .google-logo, .google.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo .uber-eats-logo {
  align-self: center;
  width: 28px !important;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo > .optimized-image {
  align-self: center;
  display: grid;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo > .optimized-image > img {
  border-radius: 50%;
  width: 28px !important;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .customer-name {
  text-overflow: ellipsis;
  color: #1c1d3e;
  grid-area: customer-name;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  overflow: hidden;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .customer-phone-number {
  color: #7d7e92;
  grid-area: customer-phone-number;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-collection-info {
  max-width: 100%;
  overflow: hidden;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .collection-type {
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .collection-type > span {
  color: #7d7e92;
  margin-top: 2px;
  margin-left: 3px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .pickup-in-label {
  color: #1e40af;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .requested-for {
  color: #7d7e92;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .table-info {
  color: #7d7e92;
  max-width: 100%;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .table-info img {
  margin-right: 4px;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .table-info .table-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-grand-total {
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-order-status {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-payment-status {
  grid-template-columns: min-content min-content 1fr;
  align-items: center;
  display: grid;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .payment-status {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .payment-status.paid {
  color: #fff;
  background-color: #059669;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .payment-status.not-paid {
  color: #fff;
  background-color: #f43f5e;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .payment-status.refunded {
  color: #1c1d3e;
  background-color: #f59e0b;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .bullet {
  margin: 0 4px;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-payment-status .payment-type {
  color: #1c1d3e;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  overflow: hidden;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-docket-number {
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-placed-date-time {
  color: #1c1d3e;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-print-order-button {
  height: 22px;
  width: 22px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.google.order-history-list-item-widget .order-details-info .orders-list-item-print-order-button > img {
  height: 100%;
}

.google.order-history-list-item-widget .orders-list-item-order-status.to-be-confirmed {
  color: #d97706;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.google.order-history-list-item-widget .orders-list-item-order-status.to-be-confirmed:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #d97706;
  border-radius: 100%;
  margin-right: 8px;
}

.google.order-history-list-item-widget .orders-list-item-order-status.accepted, .google.order-history-list-item-widget .orders-list-item-order-status.in-preparation, .google.order-history-list-item-widget .orders-list-item-order-status.ready-for-pickup, .google.order-history-list-item-widget .orders-list-item-order-status.in-transit, .google.order-history-list-item-widget .orders-list-item-order-status.completed {
  color: #059669;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.google.order-history-list-item-widget .orders-list-item-order-status.accepted:before, .google.order-history-list-item-widget .orders-list-item-order-status.in-preparation:before, .google.order-history-list-item-widget .orders-list-item-order-status.ready-for-pickup:before, .google.order-history-list-item-widget .orders-list-item-order-status.in-transit:before, .google.order-history-list-item-widget .orders-list-item-order-status.completed:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #059669;
  border-radius: 100%;
  margin-right: 8px;
}

.google.order-history-list-item-widget .orders-list-item-order-status.cancelled, .google.order-history-list-item-widget .orders-list-item-order-status.rejected {
  color: #f43f5e;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.google.order-history-list-item-widget .orders-list-item-order-status.cancelled:before, .google.order-history-list-item-widget .orders-list-item-order-status.rejected:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #f43f5e;
  border-radius: 100%;
  margin-right: 8px;
}

.integrations-widget.vertical {
  height: 100vh;
  grid-template-columns: 23vw 1fr;
  display: grid;
}

.integrations-widget.vertical .navigation-panel {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #f6f8fb;
  overflow-y: auto;
}

.integrations-widget.vertical .navigation-panel::-webkit-scrollbar {
  width: 1px;
}

.integrations-widget.vertical .navigation-panel::-webkit-scrollbar-track {
  background: none;
}

.integrations-widget.vertical .navigation-panel::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.integrations-widget.vertical .navigation-panel > .title {
  letter-spacing: 0;
  color: #1c1d3e;
  height: 72px;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 24px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  display: flex;
}

.integrations-widget.vertical .navigation-panel .tabbed-navigation-tabs-widget {
  gap: 10px;
  padding: 10px;
  display: grid;
}

.integrations-widget.vertical .navigation-panel .tabbed-navigation-tabs-widget .navigation-tab {
  letter-spacing: 0;
  color: #1c1d3e;
  cursor: pointer;
  color: #1c1d3e;
  background-color: #fff;
  background-image: url("icon-category.e1ace84b.svg");
  background-position: 94%;
  background-repeat: no-repeat;
  background-size: 8px;
  border: 1px solid #f6f8fb;
  border-radius: 8px;
  margin: 0;
  padding: 12px 14px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .3s ease-in-out;
  box-shadow: 0 0 8px -5px #0000004d;
}

.integrations-widget.vertical .navigation-panel .tabbed-navigation-tabs-widget .navigation-tab.selected {
  color: #fff;
  background-color: #1c1d3e;
  background-image: url("icon-category-active.4841131b.svg");
}

.integrations-widget.vertical .tabbed-navigation-view-panel-widget {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  overflow-y: auto;
}

.integrations-widget.vertical .tabbed-navigation-view-panel-widget::-webkit-scrollbar {
  width: 1px;
}

.integrations-widget.vertical .tabbed-navigation-view-panel-widget::-webkit-scrollbar-track {
  background: none;
}

.integrations-widget.vertical .tabbed-navigation-view-panel-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.integrations-widget.vertical .tabbed-navigation-view-panel-widget > .header {
  letter-spacing: 0;
  color: #1c1d3e;
  border-bottom: 1px solid #dee0e6;
  margin-bottom: 0;
  padding: 20px 24px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.integrations-widget.vertical .tabbed-navigation-view-panel-widget > .content {
  padding: 24px;
}

.uber-proof-of-delivery-widget > img {
  height: 90vh;
  width: 90vw;
  max-width: 1200px;
  border-radius: 20px;
}

.tyro-settings-widget form .form-item-container .form-item {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin: 0;
  padding: 8.5px 0;
}

.tyro-settings-widget form .form-item-container .form-item.invalid {
  border: 1px solid #f43f5e;
}

.tyro-settings-widget form .form-item-container .form-item.focus {
  border: 1px solid #4cbba1;
}

.tyro-settings-widget form .form-item-container .form-item.disabled {
  background-color: #7d7e9215;
  border: 1px solid #f4f4f6;
}

.tyro-settings-widget form .form-item-container .form-item .form-label {
  color: #7d7e92;
  margin-bottom: 2px;
  padding: 0 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.tyro-settings-widget form .form-item-container.switch > .form-item {
  align-items: center;
  display: flex;
}

.tyro-settings-widget form .form-item-container.switch > .form-item .form-label {
  flex-grow: 1;
}

.tyro-settings-widget form .form-item-container .form-meta {
  margin: 4px;
}

.tyro-settings-widget form .form-item-container .form-meta .error {
  color: #9f1239;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.tyro-settings-widget form .form-item-container .form-meta .help {
  color: #7d7e92;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.tyro-settings-widget form .text-input {
  display: flex;
}

.tyro-settings-widget form .text-input input {
  border-radius: unset;
  border: none;
  flex-grow: 1;
  margin: 0;
  padding: 0 12px;
}

.tyro-settings-widget form .text-input input:focus {
  outline: none;
}

.tyro-settings-widget form .text-input input:disabled {
  background-color: unset !important;
}

.tyro-settings-widget form .text-input input::placeholder {
  color: #9597a7;
}

.tyro-settings-widget form .text-input input:-ms-input-placeholder {
  color: #9597a7;
}

.tyro-settings-widget form .text-input input::-moz-placeholder {
  color: #9597a7;
}

.tyro-settings-widget form .text-input .clear-icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: -14px 12px 0 0;
  display: flex;
}

.tyro-settings-widget form .text-input .clear-icon > img {
  height: 100%;
}

.tyro-settings-widget > .terminal-pairing > .title {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.tyro-settings-widget > .terminal-pairing > .description {
  color: #4c4e68;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.tyro-settings-widget > .terminal-pairing > .form {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  gap: 16px 64px;
  margin-top: 40px;
  display: grid;
}

.tyro-settings-widget > .terminal-pairing > .form > .actions {
  display: flex;
}

.tyro-settings-widget > .terminal-pairing > .form > .actions > * {
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 16px;
}

.tyro-settings-widget > .terminal-pairing > .form > .actions > :first-child {
  margin-left: 0;
}

.tyro-settings-widget > .terminal-pairing > .form > .actions > .test-button {
  width: -moz-fit-content;
  width: fit-content;
  color: #1c1d3e;
  white-space: nowrap;
  cursor: pointer;
  background-color: #dee0e6;
  border-radius: 4px;
  padding: 10px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.tyro-settings-widget > .terminal-pairing > .form > .actions > .authorise-button {
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 4px;
  padding: 10px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.tyro-settings-widget > .terminal-pairing > .form > .help {
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fafafa;
  border-radius: 12px;
  grid-row: 1 / -1;
  padding: 24px;
}

.tyro-settings-widget > .terminal-pairing > .form > .help > .label {
  color: #4c4e68;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
}

.tyro-settings-widget > .terminal-pairing > .form > .help > .label > .icon {
  height: 16px;
  width: 16px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.tyro-settings-widget > .terminal-pairing > .form > .help > .label > .icon > img {
  height: 100%;
}

.tyro-settings-widget > .terminal-pairing > .form > .help > .instruction {
  margin-top: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.tyro-settings-widget > .terminal-pairing > .form > .help > .instruction > .label {
  font-weight: 500;
}

.tyro-settings-widget > .terminal-pairing > .form > .help > .instruction > .steps {
  margin-top: 16px;
  list-style-position: inside;
}

.tyro-settings-widget > .permissions {
  margin-top: 40px;
}

.tyro-settings-widget > .permissions > .label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.tyro-settings-widget > .permissions > * {
  margin-top: 12px;
}

.tyro-settings-widget > .permissions .integrated-receipts-permission {
  border: 1px solid #dee0e6;
  border-radius: 12px;
  padding: 20px 24px;
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > * {
  border: 0;
  border-top: 1px solid #dee0e6;
  border-radius: 0;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr auto;
  grid-auto-flow: column;
  align-items: center;
  gap: 4px 0;
  margin-top: 16px;
  padding: 16px 0 0;
  display: grid;
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > * > .label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > * > .description {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > * > .switch {
  cursor: pointer;
  grid-row: 1 / -1;
  justify-self: end;
  margin: 0;
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > * > .switch i {
  width: 46px;
  height: 26px;
  vertical-align: text-bottom;
  background-color: #c6c7d1;
  border-radius: 23px;
  margin-right: 4px;
  transition: all .3s linear;
  display: inline-block;
  position: relative;
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > * > .switch i:before {
  content: "";
  width: 42px;
  height: 22px;
  background-color: #c6c7d1;
  border-radius: 11px;
  transition: all .25s linear;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0)scale3d(1, 1, 1);
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > * > .switch i:after {
  content: "";
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transition: all .2s ease-in-out;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0);
  box-shadow: 0 2px 2px #0000003d;
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > * > .switch:active i:after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > * > .switch:active input:checked + i:after {
  transform: translate3d(16px, 2px, 0);
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > * > .switch input {
  display: none;
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > * > .switch input:checked + i {
  background-color: #34c759;
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > * > .switch input:checked + i:before {
  transform: translate3d(18px, 2px, 0)scale3d(0, 0, 0);
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > * > .switch input:checked + i:after {
  transform: translate3d(22px, 2px, 0);
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > :first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > .merchant-permission {
  grid-template-areas: "label switch"
                       "description description";
  display: grid;
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > .merchant-permission > .label {
  grid-area: label;
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > .merchant-permission > .switch {
  grid-area: switch;
}

.tyro-settings-widget > .permissions .integrated-receipts-permission > .merchant-permission > .description {
  grid-area: description;
  margin-right: 56px;
}

.tyro-settings-widget > .retrieve-iclient-logs-button {
  width: -moz-fit-content;
  width: fit-content;
  color: #1e40af;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #60a5fa;
  border-radius: 4px;
  margin-top: 56px;
  padding: 10px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.tyro-iclient-logs-widget {
  width: 50vw;
  height: 55vh;
}

.tyro-iclient-logs-widget > .spinner-container {
  position: absolute;
  inset: 0;
}

.tyro-iclient-logs-widget > iframe {
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 20px;
  position: absolute;
  inset: 0;
}

.split-bill-refund-widget {
  width: 800px;
  padding: 24px 32px 32px;
}

.split-bill-refund-widget .title {
  letter-spacing: .36px;
  text-align: center;
  margin: 12px 0 16px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
}

.split-bill-refund-widget .split-payments {
  letter-spacing: .08px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.split-bill-refund-widget .split-payments .split-payment {
  background: #f6f8fb;
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: max-content 1fr max-content;
  grid-template-areas: "radio label amount"
                       ". reference-number reference-number"
                       ". tip tip"
                       ". surcharge surcharge"
                       ". items items";
  padding: 16px 20px;
  display: grid;
}

.split-bill-refund-widget .split-payments .split-payment > .radio-button {
  grid-area: radio;
  margin-right: 14px;
}

.split-bill-refund-widget .split-payments .split-payment > .label {
  grid-area: label;
  align-items: center;
  display: flex;
}

.split-bill-refund-widget .split-payments .split-payment > .label .payment {
  letter-spacing: .08px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.split-bill-refund-widget .split-payments .split-payment > .label .bullet {
  margin: 0 8px 2px;
}

.split-bill-refund-widget .split-payments .split-payment > .label .payment-type {
  letter-spacing: .035px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.split-bill-refund-widget .split-payments .split-payment > .amount {
  text-align: right;
  letter-spacing: .08px;
  grid-area: amount;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.split-bill-refund-widget .split-payments .split-payment > .amount.refund {
  color: #9f1239;
}

.split-bill-refund-widget .split-payments .split-payment .reference-number {
  letter-spacing: .035px;
  color: #7d7e92;
  grid-area: reference-number;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.split-bill-refund-widget .split-payments .split-payment .tip, .split-bill-refund-widget .split-payments .split-payment .surcharge {
  letter-spacing: .035px;
  color: #7d7e92;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  display: flex;
}

.split-bill-refund-widget .split-payments .split-payment .tip {
  grid-area: tip;
}

.split-bill-refund-widget .split-payments .split-payment .surcharge {
  grid-area: surcharge;
}

.split-bill-refund-widget .split-payments .split-payment > .split-bill-items {
  color: #7d7e92;
  grid-area: items;
  margin-top: 8px;
}

.split-bill-refund-widget .split-payments .split-payment > .split-bill-items > .split-bill-item {
  margin-left: 40px;
  display: list-item;
}

.split-bill-refund-widget .split-payments .split-payment > .split-bill-items > .split-bill-item > .header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.split-bill-refund-widget .split-payments .split-payment > .split-bill-items > .split-bill-item > .header > .name {
  color: #1c1d3e;
  letter-spacing: .08px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.split-bill-refund-widget .split-payments .split-payment > .split-bill-items > .split-bill-item > .header > .amount {
  letter-spacing: .035px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.split-bill-refund-widget .split-payments .split-payment > .split-bill-items > .split-bill-item > .split-bill-item-comments {
  grid-template-columns: 150px 1fr;
  margin-left: 35px;
  display: grid;
}

.split-bill-refund-widget .split-payments :first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.split-bill-refund-widget .split-payments :last-child {
  border-bottom: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.split-bill-refund-widget .actions {
  margin: 24px 24px 0;
  display: flex;
}

.split-bill-refund-widget .actions button {
  width: 100%;
}

.split-bill-refund-widget .actions button.back {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0 10px 0 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.split-bill-refund-widget .actions button.refund {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.split-bill-refund-widget .actions button.refund:focus {
  border: none;
  outline: none;
}

.tyro-terminal-info-widget {
  width: 60vw;
  flex-direction: column;
  padding: 40px 32px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  display: flex;
}

.tyro-terminal-info-widget > :not(.actions) {
  border-top: 1px solid #dee0e6;
  grid-template-columns: 1fr 1fr;
  margin-top: 12px;
  padding-top: 12px;
  display: grid;
}

.tyro-terminal-info-widget > :not(.actions):first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.tyro-terminal-info-widget > :not(.actions) > .label {
  font-weight: 500;
}

.tyro-terminal-info-widget > .actions {
  padding: 0 24px;
}

.tyro-terminal-info-widget > .actions > .ok-button {
  width: 100%;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 4px;
  margin-top: 40px;
  padding: 14px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.employee-time-clock-staff-records-widget {
  height: 100vh;
  grid-template-rows: repeat(3, min-content) 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: "header header"
                       "date-picker actions"
                       "labels labels"
                       "list list";
  padding: 15.5px 24px 0;
  display: grid;
  overflow: hidden;
}

.employee-time-clock-staff-records-widget > .labels {
  color: #7d7e92;
  letter-spacing: .05em;
  grid-column-gap: 2px;
  grid-area: labels;
  justify-items: center;
  padding: 22px 24px 8px;
  font-size: 11px;
  font-weight: 700;
  line-height: 24px;
  display: grid;
}

.employee-time-clock-staff-records-widget > .labels > :first-child {
  justify-self: start;
}

.employee-time-clock-staff-records-widget > .labels > :last-child {
  justify-self: center;
}

.employee-time-clock-staff-records-widget > .labels.staff-records {
  grid-template-columns: 4fr repeat(3, 1fr);
  display: grid;
}

.employee-time-clock-staff-records-widget > .labels.timesheet {
  grid-template-columns: .9fr .7fr .2fr .7fr 1.2fr repeat(4, 1fr);
}

.employee-time-clock-staff-records-widget > .staff-records-list-widget {
  color: #1c1d3e;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  flex-direction: column;
  grid-area: list;
  padding: 0 3px;
  display: flex;
  overflow: scroll;
}

.employee-time-clock-staff-records-widget > .staff-records-list-widget::-webkit-scrollbar {
  width: 1px;
}

.employee-time-clock-staff-records-widget > .staff-records-list-widget::-webkit-scrollbar-track {
  background: none;
}

.employee-time-clock-staff-records-widget > .staff-records-list-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.employee-time-clock-staff-records-widget > .staff-records-list-widget::-webkit-scrollbar {
  width: 0;
}

.employee-time-clock-staff-records-widget > .staff-records-list-widget > .no-records {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 69px;
  display: flex;
}

.employee-time-clock-staff-records-widget > .staff-records-list-widget > .no-records > .label {
  color: #4c4e68;
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.employee-time-clock-staff-records-widget > .staff-records-list-widget .footer {
  text-align: center;
  letter-spacing: .0025em;
  color: #65667d;
  padding: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.employee-time-clock-staff-records-widget > .staff-records-list-widget .staff-records-list-item {
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #dee0e6;
  border-bottom: none;
  grid-template-columns: 4fr repeat(3, 1fr);
  justify-items: center;
  padding: 24px 12px 24px 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: grid;
}

.employee-time-clock-staff-records-widget > .staff-records-list-widget .staff-records-list-item:first-of-type {
  border-radius: 8px 8px 0 0;
  margin-top: 0;
}

.employee-time-clock-staff-records-widget > .staff-records-list-widget .staff-records-list-item > :first-child {
  justify-self: start;
}

.employee-time-clock-staff-records-widget > .staff-records-list-widget .staff-records-list-item > :last-child {
  justify-self: center;
  padding-right: 30px;
}

.employee-time-clock-staff-records-widget > .staff-records-list-widget .staff-records-list-item:first-child:nth-last-child(2) {
  border-bottom: 1px solid #dee0e6;
  border-radius: 8px;
}

.employee-time-clock-staff-records-widget > .staff-records-list-widget .staff-records-list-item:nth-last-child(2) {
  border-bottom: 1px solid #dee0e6;
  border-radius: 0 0 8px 8px;
}

.employee-time-clock-staff-records-widget > .staff-records-list-widget .staff-records-list-item.loading {
  grid-template-columns: 4fr 3fr;
}

.employee-time-clock-staff-records-widget > .staff-records-list-widget .staff-records-list-item.loading .spinner-container {
  grid-column: unset;
}

.employee-time-clock-shift-list-edit-widget {
  color: #1c1d3e;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  flex-direction: column;
  grid-area: list;
  padding: 0 3px;
  display: flex;
  overflow: scroll;
}

.employee-time-clock-shift-list-edit-widget::-webkit-scrollbar {
  width: 1px;
}

.employee-time-clock-shift-list-edit-widget::-webkit-scrollbar-track {
  background: none;
}

.employee-time-clock-shift-list-edit-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.employee-time-clock-shift-list-edit-widget::-webkit-scrollbar {
  width: 0;
}

.employee-time-clock-shift-list-edit-widget > .no-records {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 69px;
  display: flex;
}

.employee-time-clock-shift-list-edit-widget > .no-records > .label {
  color: #4c4e68;
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.employee-time-clock-shift-list-edit-widget input[type="text"] {
  max-width: 100px;
  border: 1px solid #c6c7d1;
  font-size: 16px;
}

.employee-time-clock-shift-list-edit-widget input[type="text"]:disabled {
  color: #1c1d3e;
  border: none;
  background: none !important;
}

.employee-time-clock-shift-list-edit-widget .form-item .time-picker .time-input .input-box {
  min-width: min-content;
  border: none;
  border-radius: 0;
  padding: 4px;
}

.employee-time-clock-shift-list-edit-widget .form-item .time-picker .time-input .input-box input[type="text"] {
  pointer-events: none;
  border: none;
  outline: none;
}

.employee-time-clock-shift-list-edit-widget .form-item .time-picker .time-input .input-box i {
  display: none;
}

.employee-time-clock-shift-list-edit-widget .form-item.edit {
  border: 1px solid #c6c7d1;
}

.employee-time-clock-shift-list-edit-widget .form-item.edit .time-picker .time-input .input-box input[type="text"] {
  pointer-events: auto;
}

.employee-time-clock-shift-list-edit-widget .form-item.edit.invalid {
  border-color: #f43f5e;
}

.employee-time-clock-shift-list-item-edit-widget {
  white-space: nowrap;
  border: 1px solid #dee0e6;
  border-bottom: none;
  grid-template-columns: .9fr .7fr .2fr .7fr 1.2fr repeat(4, 1fr);
  place-items: center;
  padding: 24px 12px 24px 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: grid;
}

.employee-time-clock-shift-list-item-edit-widget:first-of-type {
  border-radius: 8px 8px 0 0;
  margin-top: 0;
}

.employee-time-clock-shift-list-item-edit-widget > :first-child {
  justify-self: start;
}

.employee-time-clock-shift-list-item-edit-widget > :last-child {
  justify-self: end;
  padding-right: 30px;
}

.employee-time-clock-shift-list-item-edit-widget.invalid {
  border-color: #f43f5e;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.employee-time-clock-shift-list-item-edit-widget.invalid:last-child:not(:only-child) {
  border-bottom: 1px solid #f43f5e;
  border-radius: 0 0 8px 8px;
}

.employee-time-clock-shift-list-item-edit-widget.invalid + div:has(.edit) {
  border-top: none;
}

.employee-time-clock-shift-list-item-edit-widget.edit {
  grid-column-gap: 2px;
}

.employee-time-clock-shift-list-item-edit-widget.edit:not(.invalid) {
  border-color: #3b82f6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.employee-time-clock-shift-list-item-edit-widget.edit:not(.invalid):last-child:not(:only-child) {
  border-bottom: 1px solid #3b82f6;
  border-radius: 0 0 8px 8px;
}

.employee-time-clock-shift-list-item-edit-widget.edit + div:has(.edit):not(.invalid) {
  border-top: none;
}

.employee-time-clock-shift-list-item-edit-widget.edit + div:has(.invalid) {
  border-top: none;
}

.employee-time-clock-shift-list-item-edit-widget > .date-end {
  justify-content: center;
  display: flex;
}

.employee-time-clock-shift-list-item-edit-widget > .date-end, .employee-time-clock-shift-list-item-edit-widget > .date-start {
  min-width: 112px;
  pointer-events: none;
}

.employee-time-clock-shift-list-item-edit-widget > .date-end.edit, .employee-time-clock-shift-list-item-edit-widget > .date-start.edit {
  pointer-events: all;
  border: 1px solid #c6c7d1;
  justify-content: center;
  display: flex;
}

.employee-time-clock-shift-list-item-edit-widget > .date-end.invalid, .employee-time-clock-shift-list-item-edit-widget > .date-start.invalid {
  border-color: #f43f5e;
}

.employee-time-clock-shift-list-item-edit-widget:only-child {
  border-bottom: 1px solid #dee0e6;
  border-radius: 8px;
}

.employee-time-clock-shift-list-item-edit-widget:only-child.invalid {
  border-bottom: 1px solid #f43f5e;
}

.employee-time-clock-shift-list-item-edit-widget:last-child:not(:only-child) {
  border-bottom: 1px solid #dee0e6;
  border-radius: 0 0 8px 8px;
}

.employee-time-clock-shift-list-item-edit-widget > .actions {
  grid-auto-flow: column;
  justify-self: center;
  gap: 8px;
  padding-right: 0;
  display: grid;
}

.employee-time-clock-shift-list-item-edit-widget > .actions .edit-button {
  color: #3b82f6;
  height: 30px;
  background-color: #0000;
  border: 1px solid #3b82f6;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px 15px !important;
}

.employee-time-clock-shift-list-item-edit-widget > .actions .save-button {
  color: #fff;
  height: 30px;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px 15px !important;
}

.employee-time-clock-shift-list-item-edit-widget > .actions .cancel-button {
  color: #1c1d3e;
  height: 30px;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px 15px !important;
}

.employee-time-clock-shift-list-item-edit-widget > .actions .cancel-button .label {
  color: #7d7e92;
}

.employee-time-clock-timesheet-widget {
  height: 100vh;
  grid-template-rows: repeat(3, min-content) 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: "header header"
                       "date-picker actions"
                       "labels labels"
                       "list list";
  padding: 15.5px 24px 0;
  display: grid;
  overflow: hidden;
}

.employee-time-clock-timesheet-widget > .labels {
  color: #7d7e92;
  letter-spacing: .05em;
  grid-area: labels;
  grid-template-columns: 1fr .9fr .7fr .2fr .7fr .9fr repeat(3, 1fr);
  justify-items: center;
  padding: 22px 24px 8px;
  font-size: 11px;
  font-weight: 700;
  line-height: 24px;
  display: grid;
}

.employee-time-clock-timesheet-widget > .labels > :first-child {
  justify-self: start;
}

.employee-time-clock-timesheet-widget > .labels > :last-child {
  justify-self: end;
}

.employee-time-clock-timesheet-widget > .actions {
  grid-area: actions;
  grid-auto-flow: column;
  gap: 25px;
  display: grid;
}

.employee-time-clock-timesheet-widget > .actions > .start-end-break-button {
  color: #d97706;
  background-color: #0000;
  border: 1px solid #d97706;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px 24px !important;
}

.employee-time-clock-timesheet-widget > .actions .clock-in-button {
  color: #fff;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px 24px !important;
}

.employee-time-clock-timesheet-widget > .actions .clock-out-button {
  color: #fff;
  background-color: #9f1239;
  border: 1px solid #9f1239;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px 24px !important;
}

.employee-time-clock-shift-list-widget {
  color: #1c1d3e;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  flex-direction: column;
  grid-area: list;
  padding: 0 3px;
  display: flex;
  overflow: scroll;
}

.employee-time-clock-shift-list-widget::-webkit-scrollbar {
  width: 1px;
}

.employee-time-clock-shift-list-widget::-webkit-scrollbar-track {
  background: none;
}

.employee-time-clock-shift-list-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.employee-time-clock-shift-list-widget::-webkit-scrollbar {
  width: 0;
}

.employee-time-clock-shift-list-widget > .no-records {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 69px;
  display: flex;
}

.employee-time-clock-shift-list-widget > .no-records > .label {
  color: #4c4e68;
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.employee-time-clock-shift-list-widget > .employee-time-clock-shift-list-item {
  white-space: nowrap;
  border: 1px solid #dee0e6;
  border-bottom: none;
  grid-template-columns: 1fr .9fr .7fr .2fr .7fr .9fr repeat(3, 1fr);
  justify-items: center;
  padding: 24px 12px 24px 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: grid;
}

.employee-time-clock-shift-list-widget > .employee-time-clock-shift-list-item:first-of-type {
  border-radius: 8px 8px 0 0;
  margin-top: 0;
}

.employee-time-clock-shift-list-widget > .employee-time-clock-shift-list-item > :first-child {
  justify-self: start;
}

.employee-time-clock-shift-list-widget > .employee-time-clock-shift-list-item > :last-child {
  justify-self: end;
  padding-right: 30px;
}

.employee-time-clock-shift-list-widget > .employee-time-clock-shift-list-item:first-child.active {
  border: 1px solid #4cbba1;
}

.employee-time-clock-shift-list-widget > .employee-time-clock-shift-list-item:first-child.active + div {
  border-top: none;
}

.employee-time-clock-shift-list-widget > .employee-time-clock-shift-list-item:only-child {
  border-bottom: 1px solid #dee0e6;
  border-radius: 8px;
}

.employee-time-clock-shift-list-widget > .employee-time-clock-shift-list-item:last-child:not(:only-child) {
  border-bottom: 1px solid #dee0e6;
  border-radius: 0 0 8px 8px;
}

.employee-time-clock-shift-break-presets-widget {
  color: #1c1d3e;
  height: min-content;
  max-width: 72vw;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  align-self: center;
  margin: 0 auto;
  padding: 40px;
  display: flex;
}

.employee-time-clock-shift-break-presets-widget > .label {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 20px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
}

.employee-time-clock-shift-break-presets-widget > .shift-break-presets {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  max-width: 45vw;
  max-height: 60vh;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0;
  display: flex;
  overflow: scroll;
}

.employee-time-clock-shift-break-presets-widget > .shift-break-presets::-webkit-scrollbar {
  width: 1px;
}

.employee-time-clock-shift-break-presets-widget > .shift-break-presets::-webkit-scrollbar-track {
  background: none;
}

.employee-time-clock-shift-break-presets-widget > .shift-break-presets::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.employee-time-clock-shift-break-presets-widget > .shift-break-presets > .shift-break-preset {
  text-align: center;
  letter-spacing: 0;
  color: #1c1d3e;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  margin: 24px 12px 0;
  padding: 60px 30px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  box-shadow: 1px 0 18px #e4e4e4f5;
}

.employee-time-clock-shift-break-presets-widget > .shift-break-presets > .shift-break-preset > .name {
  min-width: 200px;
  font-size: 18px;
  line-height: 27px;
}

.employee-time-clock-shift-break-presets-widget > .shift-break-presets > .shift-break-preset > .duration {
  font-size: 14px;
  line-height: 21px;
}

.employee-time-clock-shift-break-presets-widget > .back-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #1c1d3e;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 15px 24px 0;
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  transition: all .2s ease-in-out;
}

#modal-container:has(.employee-time-clock-shift-break-presets-widget) {
  z-index: 2001;
  position: absolute;
}

.loyalty-card-widget {
  width: 312px;
  height: 196px;
  background-color: #9191a1;
  border-radius: 10px;
  position: relative;
  box-shadow: 1px 3px 4px 1px #00000040;
}

.loyalty-card-widget .background img, .loyalty-card-widget .background .optimized-image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: absolute;
  top: 0;
}

.loyalty-card-widget .details {
  width: 100%;
  height: 100%;
  grid-template-areas: "balance emv-chip"
                       "count-progress count-progress"
                       "customer-info customer-info";
  padding: 0 27px;
  display: grid;
  position: absolute;
  top: 0;
}

.loyalty-card-widget .details .balance {
  height: inherit;
  color: #fff;
  text-shadow: 0 1px 1px #000c;
  letter-spacing: 1.3px;
  grid-area: balance;
  align-items: end;
  font-family: larabie;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  display: flex;
}

.loyalty-card-widget .details .mock-emv-chip {
  grid-area: emv-chip;
  justify-content: end;
  align-items: end;
  display: flex;
}

.loyalty-card-widget .details .mock-emv-chip img {
  width: 32px;
}

.loyalty-card-widget .details .count-progress {
  grid-area: count-progress;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 5px;
  margin-top: 10px;
  display: grid;
}

.loyalty-card-widget .details .count-progress .count {
  height: 10px;
  background-color: #e9e9ec;
  border-radius: 10px;
  box-shadow: inset 0 2px 4px #0000004a;
}

.loyalty-card-widget .details .count-progress .count.active {
  background-color: #80de1e;
  border: 1px solid #00000036;
  box-shadow: inset 0 -2px 4px #0003, inset 0 -3px 3px #00000080;
}

.loyalty-card-widget .details .customer-info {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  text-transform: uppercase;
  letter-spacing: 2px;
  grid-area: customer-info;
  font-family: kredit;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.loyalty-card-widget .details .customer-info .name {
  -webkit-line-clamp: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.loyalty-credits-redeemable-widget {
  flex-direction: column;
  align-items: center;
  padding: 32px;
  display: flex;
}

.loyalty-credits-redeemable-widget .close-button {
  position: absolute;
  top: 16px;
  right: 16px;
}

.loyalty-credits-redeemable-widget .header {
  justify-items: center;
  gap: 8px;
  margin-top: 24px;
  line-height: 24px;
  display: grid;
}

.loyalty-credits-redeemable-widget .header .title {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 500;
}

.loyalty-credits-redeemable-widget .header .subtitle {
  color: #7d7e92;
  font-size: 16px;
  font-weight: 400;
}

.loyalty-credits-redeemable-widget .card {
  margin: 24px 60px 40px;
}

.loyalty-credits-redeemable-widget .card .loyalty-card-widget {
  box-shadow: 0 0 12px 3px #00000052;
}

.loyalty-credits-redeemable-widget .card .loyalty-card-widget:before {
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  animation: 3s ease-in-out infinite shine;
  position: absolute;
}

@keyframes shine {
  0% {
    opacity: .05;
    background-image: linear-gradient(60deg, #fff0 50%, #ffffffd1, #ffffff5e 150%);
  }

  10% {
    opacity: .3;
    background-image: linear-gradient(60deg, #fff0 50%, #ffffffd1, #ffffff5e 150%);
  }

  20% {
    opacity: .05;
    background-image: linear-gradient(60deg, #fff0 50%, #ffffffd1, #ffffff5e 150%);
  }

  30% {
    opacity: .05;
    background-image: linear-gradient(60deg, #fff0 30%, #fffc, #fff0 70%);
  }

  40% {
    opacity: .3;
    background-image: linear-gradient(60deg, #fff0 30%, #fffc, #fff0 70%);
  }

  50% {
    opacity: .05;
    background-image: linear-gradient(60deg, #fff0 30%, #fffc, #fff0 70%);
  }

  60% {
    opacity: .05;
    background-image: linear-gradient(60deg, #fffffff2 10%, #fff0, #fff0 80%);
  }

  70% {
    opacity: .3;
    background-image: linear-gradient(60deg, #fffffff2 10%, #fff0, #fff0 80%);
  }

  80% {
    opacity: .05;
    background-image: linear-gradient(60deg, #fffffff2 10%, #fff0, #fff0 80%);
  }

  100% {
    opacity: .05;
    background-image: linear-gradient(60deg, #fffffff2 10%, #fff0, #fff0 80%);
  }
}

.loyalty-credits-redeemable-widget .actions {
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  padding: 0 24px;
  display: grid;
}

.loyalty-credits-redeemable-widget .actions .redeem-now-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 4px;
  margin: 0;
  padding: 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.loyalty-credits-redeemable-widget .actions .redeem-now-button:focus {
  border: none;
  outline: none;
}

.loyalty-credits-redeemable-widget .actions .maybe-later-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #1c1d3e;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  transition: all .2s ease-in-out;
}

.loyalty-card-rewards-count-widget {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 !important;
}

.loyalty-card-rewards-count-widget .count {
  height: inherit;
  z-index: 99;
  background-color: #3b82f6;
  border-radius: 8px 0 0 8px;
  transition: width .75s linear;
  position: absolute;
}

.loyalty-card-rewards-count-widget .count.full {
  border-radius: 8px;
  animation: 2s ease-in-out infinite emphasize;
}

@keyframes emphasize {
  0% {
    background-color: #3b82f6;
  }

  50% {
    background-color: #3f9c86;
    border: 2px solid #fff;
  }

  0% {
    background-color: #3b82f6;
  }
}

.loyalty-card-rewards-count-widget .info {
  color: #fff;
  z-index: 100;
  letter-spacing: 1px;
  align-items: center;
  padding: 10px 15px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  position: relative;
}

.loyalty-card-rewards-count-widget .info .label {
  margin-left: 10px;
}

.item-summary-view-widget {
  color: #7d7e92;
  grid-template-columns: auto auto 1fr auto;
  grid-template-areas: "quantity name invalid-reason price"
                       ". discount discount discount"
                       ". extra-charge extra-charge extra-charge"
                       ". description description ."
                       ". comments comments comments";
  column-gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.item-summary-view-widget > .quantity {
  color: #1c1d3e;
  grid-area: quantity;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.item-summary-view-widget > .name {
  color: #1c1d3e;
  grid-area: name;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.item-summary-view-widget > .invalid-reason {
  white-space: nowrap;
  height: -moz-fit-content;
  height: fit-content;
  color: #9f1239;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #fecdd3;
  border-radius: 99px;
  padding: 3px 11px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.item-summary-view-widget > .price {
  color: #1c1d3e;
  grid-area: price;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.item-summary-view-widget > .discount {
  grid-area: discount;
  justify-content: space-between;
  margin-top: 8px;
  display: flex;
}

.item-summary-view-widget > .extra-charge {
  grid-area: extra-charge;
  justify-content: space-between;
  margin-top: 8px;
  display: flex;
}

.item-summary-view-widget > .comments {
  grid-area: comments;
  justify-content: space-between;
  margin-top: 8px;
  display: flex;
}

.item-summary-view-widget > .item-description-view-widget {
  grid-area: description;
  margin-top: 8px;
}

.split-bill-item-summary-view-widget {
  color: #7d7e92;
  grid-template-columns: 1fr auto;
  grid-template-areas: "name price"
                       "description ."
                       "comments comments";
  column-gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.split-bill-item-summary-view-widget > .name {
  color: #1c1d3e;
  grid-area: name;
  font-weight: 500;
}

.split-bill-item-summary-view-widget > .price {
  grid-area: price;
  font-weight: 500;
}

.split-bill-item-summary-view-widget > .comments {
  grid-area: comments;
  justify-content: space-between;
  margin-top: 8px;
  display: flex;
}

.split-bill-item-summary-view-widget > .item-description-view-widget {
  grid-area: description;
  margin-top: 8px;
}

.item-description-view-widget {
  white-space: pre-wrap;
  flex-direction: column;
  display: flex;
}

.item-description-view-widget > .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.item-description-view-widget > .title + * {
  margin-top: 8px;
}

.item-description-view-widget > .modifier, .item-description-view-widget > .simple {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.item-description-view-widget > .simple:first-child + .title {
  margin-top: 8px;
}

.item-description-view-widget > .break {
  height: 8px;
}

.item-description-view-widget > .break + * {
  margin-top: 0;
}

.custom-payment-collect-widget {
  max-width: 72vw;
  flex-direction: column;
  padding: 40px;
  display: flex;
}

.custom-payment-collect-widget .header {
  text-align: center;
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.custom-payment-collect-widget .custom-payments {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  max-width: 40vw;
  max-height: 55vh;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  gap: 15px;
  margin-top: 20px;
  padding: 10px;
  display: grid;
  overflow: scroll;
}

.custom-payment-collect-widget .custom-payments::-webkit-scrollbar {
  width: 1px;
}

.custom-payment-collect-widget .custom-payments::-webkit-scrollbar-track {
  background: none;
}

.custom-payment-collect-widget .custom-payments::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.custom-payment-collect-widget .custom-payments .custom-payment {
  text-align: center;
  cursor: pointer;
  grid-gap: 15px;
  background: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 70px 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  display: grid;
  box-shadow: 0 0 15px #0000000f;
}

.custom-payment-collect-widget .custom-payments .custom-payment:hover {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  outline: 2px solid #4f506a;
}

.custom-payment-collect-widget .action {
  margin-top: 20px;
}

.custom-payment-collect-widget .action > .back-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  width: 100%;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  transition: all .2s ease-in-out;
}

.tyro-payment-summary-widget > .header {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.tyro-payment-summary-widget > .header > .label {
  color: #1c1d3e;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

.tyro-payment-summary-widget > .header > .icon {
  height: 16px;
  width: 16px;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  display: flex;
}

.tyro-payment-summary-widget > .header > .icon > img {
  height: 100%;
}

.tyro-payment-summary-widget > .transaction-infos:not(:first-child) {
  margin-top: 12px;
}

.tyro-payment-summary-widget > .transaction-infos > .payment-transaction-info {
  color: #7d7e92;
  border-top: 1px solid #dee0e6;
  grid-template-columns: 1fr auto;
  grid-template-areas: "label amount"
                       "transaction-reference ."
                       "surcharge-label surcharge-amount"
                       "tip-label tip-amount"
                       "items items";
  place-items: center;
  gap: 0 8px;
  margin-top: 12px;
  padding-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.tyro-payment-summary-widget > .transaction-infos > .payment-transaction-info:first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.tyro-payment-summary-widget > .transaction-infos > .payment-transaction-info > .payment {
  color: #1c1d3e;
  display: contents;
}

.tyro-payment-summary-widget > .transaction-infos > .payment-transaction-info > .payment > .label {
  grid-area: label;
  justify-self: start;
  align-items: center;
  display: flex;
}

.tyro-payment-summary-widget > .transaction-infos > .payment-transaction-info > .payment > .label > .type {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.tyro-payment-summary-widget > .transaction-infos > .payment-transaction-info > .payment > .label > .bullet {
  color: #4c4e68;
  margin: 0 8px;
}

.tyro-payment-summary-widget > .transaction-infos > .payment-transaction-info > .payment > .amount {
  grid-area: amount;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.tyro-payment-summary-widget > .transaction-infos > .payment-transaction-info > .payment > .amount.refund {
  color: #9f1239;
}

.tyro-payment-summary-widget > .transaction-infos > .payment-transaction-info > .transaction-reference {
  color: #7d7e92;
  grid-area: transaction-reference;
  justify-self: start;
}

.tyro-payment-summary-widget > .transaction-infos > .payment-transaction-info > .surcharge {
  color: #7d7e92;
  display: contents;
}

.tyro-payment-summary-widget > .transaction-infos > .payment-transaction-info > .surcharge > .label {
  grid-area: surcharge-label;
  justify-self: start;
}

.tyro-payment-summary-widget > .transaction-infos > .payment-transaction-info > .surcharge > .amount {
  grid-area: surcharge-amount;
  justify-self: end;
}

.tyro-payment-summary-widget > .transaction-infos > .payment-transaction-info > .tip {
  color: #7d7e92;
  display: contents;
}

.tyro-payment-summary-widget > .transaction-infos > .payment-transaction-info > .tip > .label {
  grid-area: tip-label;
  justify-self: start;
}

.tyro-payment-summary-widget > .transaction-infos > .payment-transaction-info > .tip > .amount {
  grid-area: tip-amount;
  justify-self: end;
}

.tyro-payment-summary-widget > .transaction-infos > .refund-transaction-info {
  color: #7d7e92;
  border-top: 1px solid #dee0e6;
  grid-template-columns: 1fr auto;
  grid-template-areas: "label amount"
                       "transaction-reference ."
                       "surcharge-label surcharge-amount"
                       "tip-label tip-amount"
                       "items items";
  place-items: center;
  gap: 0 8px;
  margin-top: 12px;
  padding-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.tyro-payment-summary-widget > .transaction-infos > .refund-transaction-info:first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.tyro-payment-summary-widget > .transaction-infos > .refund-transaction-info > .payment {
  color: #1c1d3e;
  display: contents;
}

.tyro-payment-summary-widget > .transaction-infos > .refund-transaction-info > .payment > .label {
  grid-area: label;
  justify-self: start;
  align-items: center;
  display: flex;
}

.tyro-payment-summary-widget > .transaction-infos > .refund-transaction-info > .payment > .label > .type {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.tyro-payment-summary-widget > .transaction-infos > .refund-transaction-info > .payment > .label > .bullet {
  color: #4c4e68;
  margin: 0 8px;
}

.tyro-payment-summary-widget > .transaction-infos > .refund-transaction-info > .payment > .amount {
  grid-area: amount;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.tyro-payment-summary-widget > .transaction-infos > .refund-transaction-info > .payment > .amount.refund {
  color: #9f1239;
}

.tyro-payment-summary-widget > .transaction-infos > .refund-transaction-info > .transaction-reference {
  color: #7d7e92;
  grid-area: transaction-reference;
  justify-self: start;
}

.tyro-payment-summary-widget > .transaction-infos > .refund-transaction-info > .surcharge {
  color: #7d7e92;
  display: contents;
}

.tyro-payment-summary-widget > .transaction-infos > .refund-transaction-info > .surcharge > .label {
  grid-area: surcharge-label;
  justify-self: start;
}

.tyro-payment-summary-widget > .transaction-infos > .refund-transaction-info > .surcharge > .amount {
  grid-area: surcharge-amount;
  justify-self: end;
}

.tyro-payment-summary-widget > .transaction-infos > .refund-transaction-info > .tip {
  color: #7d7e92;
  display: contents;
}

.tyro-payment-summary-widget > .transaction-infos > .refund-transaction-info > .tip > .label {
  grid-area: tip-label;
  justify-self: start;
}

.tyro-payment-summary-widget > .transaction-infos > .refund-transaction-info > .tip > .amount {
  grid-area: tip-amount;
  justify-self: end;
}

.tyro-sales-report-widget > .header {
  border-bottom: 1px solid #dee0e6;
  justify-content: space-between;
  align-items: center;
  padding: 19px 24px;
  display: flex;
}

.tyro-sales-report-widget > .header > .label {
  font-size: 24px;
  font-weight: 500;
  line-height: 33.77px;
}

.tyro-sales-report-widget > .header > .print-button {
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 4px;
  padding: 10px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.tyro-sales-report-widget > .header > .print-button.disabled {
  background-color: #83d0be;
}

.tyro-sales-report-widget > .main {
  padding: 24px;
}

.tyro-sales-report-widget > .main > .select-date {
  display: flex;
}

.tyro-sales-report-widget > .main > .select-date > .context > .label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.tyro-sales-report-widget > .main > .select-date > .context > .description {
  max-width: 174px;
  color: #7d7e92;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.tyro-sales-report-widget > .main > .select-date > .date-picker-input {
  height: -moz-fit-content;
  height: fit-content;
  min-width: 300px;
  cursor: pointer;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  justify-content: space-between;
  margin-left: 40px;
  padding: 20px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.tyro-sales-report-widget > .main > .select-date > .date-picker-input.empty {
  color: #9597a7;
}

.tyro-sales-report-widget > .main > .select-date > .date-picker-input.error {
  border-color: #f43f5e;
}

.tyro-sales-report-widget > .main > .select-date > .date-picker-input > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  display: flex;
}

.tyro-sales-report-widget > .main > .select-date > .date-picker-input > .icon > img {
  height: 100%;
}

.tyro-sales-report-widget > .main > .report-type {
  margin-top: 32px;
  display: flex;
}

.tyro-sales-report-widget > .main > .report-type > .label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.tyro-sales-report-widget > .main > .report-type > .radio-group-input-widget {
  margin-left: 32px;
}

.tyro-sales-report-widget > .main > .actions {
  margin-top: 40px;
  display: flex;
}

.tyro-sales-report-widget > .main > .actions > .run-report-button {
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 4px;
  padding: 10px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.tyro-sales-report-widget > .main > .actions > .clear-button {
  width: -moz-fit-content;
  width: fit-content;
  color: #1c1d3e;
  cursor: pointer;
  background-color: #dee0e6;
  border-radius: 4px;
  margin-left: 24px;
  padding: 10px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.tyro-sales-report-widget > .main > .report {
  margin-top: 24px;
}

.tyro-sales-report-widget > .main > .report > .label {
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 16px;
  margin-left: auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.tyro-sales-report-widget > .main > .report > .label > .date {
  color: #3f9c86;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.tyro-reconciliation-report-summary-widget {
  overflow: auto;
}

.tyro-reconciliation-report-summary-widget > .labels {
  color: #7d7e92;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px;
  padding: 14px 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: grid;
}

.tyro-reconciliation-report-summary-widget > .list {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.tyro-reconciliation-report-summary-widget > .list > .card {
  border-top: 1px solid #dee0e6;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px;
  padding: 14px 24px;
  display: grid;
}

.tyro-reconciliation-report-summary-widget > .list > .card:first-child {
  border-top: none;
}

.tyro-reconciliation-report-summary-widget > .list > .card .card-type {
  font-weight: 500;
}

.tyro-reconciliation-report-summary-widget > .list > .total {
  border-top: 1px solid #dee0e6;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px;
  padding: 14px 24px;
  display: grid;
}

.tyro-reconciliation-report-summary-widget > .list > .total > .amount {
  grid-column-end: -1;
}

.tyro-reconciliation-report-detail-widget {
  overflow: auto;
}

.tyro-reconciliation-report-detail-widget > .labels {
  color: #7d7e92;
  grid-template-columns: .5fr 1fr 1.5fr repeat(4, 1fr) 1.5fr;
  gap: 8px;
  padding: 14px 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: grid;
}

.tyro-reconciliation-report-detail-widget > .list {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.tyro-reconciliation-report-detail-widget > .list > .transaction {
  border-top: 1px solid #dee0e6;
  grid-template-columns: .5fr 1fr 1.5fr repeat(4, 1fr) 1.5fr;
  gap: 8px;
  padding: 14px 24px;
  display: grid;
}

.tyro-reconciliation-report-detail-widget > .list > .transaction:first-child {
  border-top: none;
}

.tyro-reconciliation-report-detail-widget > .list > .transaction > .transaction-time {
  color: #7d7e92;
}

.tyro-reconciliation-report-detail-widget > .list > .transaction > .transaction-card-type {
  font-weight: 500;
}

.tyro-reconciliation-report-detail-widget > .list > .total {
  border-top: 1px solid #dee0e6;
  grid-template-columns: .5fr 1fr 1.5fr repeat(4, 1fr) 1.5fr;
  gap: 8px;
  padding: 14px 24px;
  display: grid;
}

.tyro-reconciliation-report-detail-widget > .list > .total > .amount {
  grid-column-end: -1;
}

.radio-group-input-widget {
  display: flex;
}

.radio-group-input-widget > .option {
  cursor: pointer;
  align-items: center;
  margin-left: 32px;
  display: flex;
}

.radio-group-input-widget > .option:first-child {
  margin-left: 0;
}

.radio-group-input-widget > .option > input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: 1.2px solid #dee0e6;
  border-radius: 50%;
}

.radio-group-input-widget > .option > input[type="radio"]:checked {
  border: 5px solid #4cbba1;
}

.radio-group-input-widget > .option > .label {
  margin-left: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.date-picker-widget {
  padding: 24px;
}

.date-picker-widget .DayPicker-wrapper {
  -webkit-user-select: none;
  user-select: none;
  padding: 0;
}

.date-picker-widget .DayPicker-NavBar {
  display: contents;
}

.date-picker-widget .DayPicker-NavButton {
  all: unset;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  position: absolute;
  top: 12px;
}

.date-picker-widget .DayPicker-NavButton--prev {
  background-image: url("icon-chevron-down-grey.b17677ff.svg");
  left: 4vw;
  transform: rotate(90deg);
}

.date-picker-widget .DayPicker-NavButton--next {
  background-image: url("icon-chevron-down-grey.b17677ff.svg");
  right: 4vw;
  transform: rotate(270deg);
}

.date-picker-widget .DayPicker-Month {
  table-layout: fixed;
  width: 60.18vw;
  height: 59.841vh;
  border-spacing: 14px 12px;
  border-collapse: separate;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.date-picker-widget .DayPicker-Caption {
  text-align: center;
  margin-bottom: 12px;
  padding: 12px 0;
}

.date-picker-widget .DayPicker-Caption > div {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.date-picker-widget .DayPicker-WeekdaysRow {
  text-transform: uppercase;
}

.date-picker-widget .DayPicker-Weekday {
  color: #7d7e92;
  font-size: 16px;
  line-height: 22px;
}

.date-picker-widget .DayPicker-Weekday:first-child:after, .date-picker-widget .DayPicker-Weekday:nth-child(2):after {
  content: "N";
}

.date-picker-widget .DayPicker-Weekday:nth-child(3):after {
  content: "E";
}

.date-picker-widget .DayPicker-Weekday:nth-child(4):after {
  content: "D";
}

.date-picker-widget .DayPicker-Weekday:nth-child(5):after {
  content: "U";
}

.date-picker-widget .DayPicker-Weekday:nth-child(6):after {
  content: "I";
}

.date-picker-widget .DayPicker-Weekday:nth-child(7):after {
  content: "T";
}

.date-picker-widget .DayPicker-Day {
  color: #1c1d3e;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.date-picker-widget .DayPicker-Day:hover {
  background-color: unset !important;
}

.date-picker-widget .DayPicker-Day--today {
  color: #1c1d3e;
  text-align: center;
  position: relative;
}

.date-picker-widget .DayPicker-Day--today:before {
  content: "";
  width: 2em;
  height: 2em;
  border: 2px solid #4cbba1;
  border-radius: 25%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.date-picker-widget .DayPicker-Day--disabled {
  color: #dee0e6;
}

.date-picker-widget.has-selected-date .DayPicker-Day--today:before {
  content: unset;
}

.date-picker-widget.has-selected-date .DayPicker-Day--highlighted {
  color: #1c1d3e;
  text-align: center;
  position: relative;
}

.date-picker-widget.has-selected-date .DayPicker-Day--highlighted:before {
  content: "";
  width: 2em;
  height: 2em;
  border: 2px solid #4cbba1;
  border-radius: 25%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tyro-incomplete-transaction-banner-widget {
  z-index: 998;
  width: 100vw;
  background-color: #ffe4e6;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px 32px 7.5px 102px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 4px 24px #00000026;
}

.tyro-incomplete-transaction-banner-widget > .text {
  display: inherit;
  align-items: inherit;
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.tyro-incomplete-transaction-banner-widget > .text > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.tyro-incomplete-transaction-banner-widget > .text > .icon > img {
  height: 100%;
}

.tyro-incomplete-transaction-banner-widget > .text > .highlight {
  display: inherit;
  align-items: inherit;
  margin-right: 8px;
  font-weight: 500;
}

.tyro-incomplete-transaction-banner-widget > .actions {
  justify-content: center;
  align-items: center;
  display: flex;
}

.tyro-incomplete-transaction-banner-widget > .actions > * {
  margin-left: 12px;
}

.tyro-incomplete-transaction-banner-widget > .actions > :first-child {
  margin-left: 0;
}

.tyro-incomplete-transaction-banner-widget > .actions .refresh-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0;
  color: #1c1d3e;
  color: #fff;
  background-color: #3f9c86;
  border-radius: 4px;
  margin: 0;
  padding: 7px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.tyro-incomplete-transaction-banner-widget > .actions .refresh-button:focus {
  border: none;
  outline: none;
}

.tyro-incomplete-transaction-banner-widget > .actions .later-button {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #9f1239;
  background-color: #0000;
  border: 1px solid #d97706;
  border-radius: 4px;
  padding: 7px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.tyro-incomplete-transaction-banner-widget > .actions > .continue-transaction-button, .tyro-incomplete-transaction-banner-widget > .actions .void-transaction-button {
  cursor: pointer;
  border-radius: 4px;
  padding: 7px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.tyro-incomplete-transaction-banner-widget > .actions > .continue-transaction-button {
  color: #fff;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.tyro-incomplete-transaction-banner-widget > .actions > .void-transaction-button {
  color: #9f1239;
  background-color: #0000;
  border: 1px solid #f43f5e;
}

.tyro-incomplete-transaction-banner-widget > .actions > .void-transaction-button.transaction-failed {
  color: #fff;
  background-color: #9f1239;
  border: none;
}

.tyro-incomplete-transaction-banner-widget > .actions > .go-online-button {
  border: 1px solid #0000;
}

.tyro-incomplete-transaction-banner-widget > .actions > .dismiss-button {
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
}

.tyro-incomplete-transaction-banner-widget > .actions > .enable-audio-button {
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.tyro-incomplete-transaction-banner-widget > .text > .highlight {
  color: #9f1239;
}

.swal2-container.void-transaction-modal .swal2-confirm {
  background-color: #9f1239 !important;
}

.refresh-menu-banner {
  z-index: 998;
  width: 100vw;
  z-index: 994;
  background: #fef3c7;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px 32px 7.5px 102px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 4px 24px #00000026;
}

.refresh-menu-banner > .text {
  display: inherit;
  align-items: inherit;
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.refresh-menu-banner > .text > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.refresh-menu-banner > .text > .icon > img {
  height: 100%;
}

.refresh-menu-banner > .text > .highlight {
  display: inherit;
  align-items: inherit;
  margin-right: 8px;
  font-weight: 500;
}

.refresh-menu-banner > .actions {
  justify-content: center;
  align-items: center;
  display: flex;
}

.refresh-menu-banner > .actions > * {
  margin-left: 12px;
}

.refresh-menu-banner > .actions > :first-child {
  margin-left: 0;
}

.refresh-menu-banner > .actions .refresh-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0;
  color: #1c1d3e;
  color: #fff;
  background-color: #3f9c86;
  border-radius: 4px;
  margin: 0;
  padding: 7px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.refresh-menu-banner > .actions .refresh-button:focus {
  border: none;
  outline: none;
}

.refresh-menu-banner > .actions .later-button {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #9f1239;
  background-color: #0000;
  border: 1px solid #d97706;
  border-radius: 4px;
  padding: 7px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.refresh-menu-banner > .actions > .continue-transaction-button, .refresh-menu-banner > .actions .void-transaction-button {
  cursor: pointer;
  border-radius: 4px;
  padding: 7px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.refresh-menu-banner > .actions > .continue-transaction-button {
  color: #fff;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.refresh-menu-banner > .actions > .void-transaction-button {
  color: #9f1239;
  background-color: #0000;
  border: 1px solid #f43f5e;
}

.refresh-menu-banner > .actions > .void-transaction-button.transaction-failed {
  color: #fff;
  background-color: #9f1239;
  border: none;
}

.refresh-menu-banner > .actions > .go-online-button {
  border: 1px solid #0000;
}

.refresh-menu-banner > .actions > .dismiss-button {
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
}

.refresh-menu-banner > .actions > .enable-audio-button {
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.refresh-menu-banner > .text > .highlight {
  color: #9f1239;
}

.sales-report-charts-template > .header {
  letter-spacing: 0;
  color: #1c1d3e;
  border-bottom: 1px solid #f4f4f6;
  grid-template-columns: 1fr min-content;
  align-items: center;
  margin-bottom: 0;
  padding: 22px 24px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  display: grid;
}

.sales-report-charts-template .body {
  padding: 24px 32px;
}

.sales-report-charts-template .body .filters {
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 24px;
  display: flex;
}

.sales-report-charts-template .body .report > .sales-charts {
  gap: 24px;
  display: grid;
}

.sales-report-charts-template .body .no-report {
  text-align: center;
  color: #4c4e68;
  opacity: .5;
  margin: 56px auto;
}

.sales-charts-view-widget {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 36px 24px;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.sales-charts-view-widget .view-selectors {
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: repeat(3, max-content);
  gap: 20px;
  display: grid;
}

.sales-charts-view-widget .view-selectors .option {
  cursor: pointer;
  opacity: .5;
  padding-bottom: 8px;
}

.sales-charts-view-widget .view-selectors .option.selected {
  opacity: 1;
  border-bottom: 2px solid #3b82f6;
  font-weight: 600;
}

.sales-charts-view-widget .bar-chart {
  padding-top: 12px;
}

.sales-trend-tabular-view-widget .table-container {
  display: none;
}

.sales-trend-tabular-view-widget .table-container.visible {
  display: block;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table * {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table ::-webkit-scrollbar {
  width: 1px;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table ::-webkit-scrollbar-track {
  background: none;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table ::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table table thead, .sales-trend-tabular-view-widget .table-container .sales-trend-table table tr {
  width: 100%;
  table-layout: fixed;
  display: table;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table table tbody, .sales-trend-tabular-view-widget .table-container .sales-trend-table table th, .sales-trend-tabular-view-widget .table-container .sales-trend-table table td {
  overflow: scroll;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table table thead {
  background-color: #fbfdff;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table table thead tr > th {
  color: #4c4e68;
  border: none;
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table table thead tr > th:first-child {
  text-align: left;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table table tbody {
  table-layout: fixed;
  max-height: 400px;
  background-color: #fff;
  display: block;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table table tbody tr > td {
  text-align: center;
  border: none;
  border-top: 1px solid #dee0e6;
  padding: 13.5px 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table table tbody tr > td:first-child {
  text-align: left;
  border-left: 1px solid #dee0e6;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table table tbody tr > td:last-child {
  border-right: 1px solid #dee0e6;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table table tbody :first-child td:first-child {
  border-top-left-radius: 8px;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table table tbody :first-child td:last-child {
  border-top-right-radius: 8px;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table table tbody :last-child td {
  border-bottom: 1px solid #dee0e6;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table table tbody :last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table table tbody :last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table.daily table th:first-of-type, .sales-trend-tabular-view-widget .table-container .sales-trend-table.daily table td:first-of-type {
  width: 25%;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table.monthly table th:first-of-type, .sales-trend-tabular-view-widget .table-container .sales-trend-table.monthly table td:first-of-type {
  width: 30%;
}

.sales-trend-tabular-view-widget .table-container .sales-trend-table.weekly table th:first-of-type, .sales-trend-tabular-view-widget .table-container .sales-trend-table.weekly table td:first-of-type, .sales-trend-tabular-view-widget .table-container .sales-trend-table.hourly table th:first-of-type, .sales-trend-tabular-view-widget .table-container .sales-trend-table.hourly table td:first-of-type {
  width: 35%;
}

.sales-report-time-interval-filter-widget {
  flex-wrap: wrap;
  display: flex;
  position: relative;
}

.sales-report-time-interval-filter-widget .time-interval-selector {
  margin-right: 24px;
}

.sales-report-time-interval-filter-widget .time-interval-selector .form-label {
  color: #7d7e92;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.sales-report-time-interval-filter-widget .time-interval-selector .form-input {
  padding-top: 8px;
}

.sales-report-time-interval-filter-widget .time-interval-selector .drop-down-input {
  z-index: 999;
  cursor: pointer;
  min-width: 200px;
  z-index: 1;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 12px 14px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  position: relative;
}

.sales-report-time-interval-filter-widget .time-interval-selector .drop-down-input .value {
  flex-grow: 1;
  padding: 0 12px;
}

.sales-report-time-interval-filter-widget .time-interval-selector .drop-down-input .down-arrow-icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin: -10px 12px 0 0;
  display: flex;
}

.sales-report-time-interval-filter-widget .time-interval-selector .drop-down-input .down-arrow-icon > img {
  height: 100%;
}

.sales-report-time-interval-filter-widget .time-interval-selector .drop-down-input .options {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-top: 16px;
  position: absolute;
  top: 100%;
  left: 0;
}

.sales-report-time-interval-filter-widget .time-interval-selector .drop-down-input .options .option {
  border-bottom: 1px solid #dee0e6;
  justify-content: space-between;
  padding: 10px 12px;
  display: flex;
}

.sales-report-time-interval-filter-widget .time-interval-selector .drop-down-input .options .option:last-child {
  border-bottom: 0;
}

.sales-report-time-interval-filter-widget .time-interval-selector .drop-down-input .value {
  padding: 0;
}

.sales-report-time-interval-filter-widget .time-interval-selector .drop-down-input .down-arrow-icon {
  margin: 0;
}

.sales-report-time-interval-filter-widget .time-interval-selector .drop-down-input .options {
  margin-top: 5px;
}

.sales-report-date-range-filter-widget {
  background-color: #f6f8fb;
  border-radius: 8px;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  gap: 8px;
  margin-top: 18px;
  padding: 8px;
  display: grid;
}

.sales-report-date-range-filter-widget .selector {
  position: relative;
}

.sales-report-date-range-filter-widget .form-input input {
  cursor: pointer;
  min-width: 200px;
  background-image: url("icon-calendar.4ddcc08d.svg");
  background-position: 95%;
  background-repeat: no-repeat;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  outline: none;
  padding: 13.5px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.sales-report-date-range-filter-widget .options {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  width: 100%;
  max-height: 280px;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
  overflow-y: scroll;
}

.sales-report-date-range-filter-widget .options::-webkit-scrollbar {
  width: 1px;
}

.sales-report-date-range-filter-widget .options::-webkit-scrollbar-track {
  background: none;
}

.sales-report-date-range-filter-widget .options::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.sales-report-date-range-filter-widget .options .option {
  cursor: pointer;
  text-align: left;
  border-bottom: 1px solid #dee0e6;
  padding: 12px;
}

.sales-report-date-range-filter-widget .options .option.selected {
  background-image: url("icon-check-alt.4ffb5dc4.svg");
  background-position: right 14px top 50%;
  background-repeat: no-repeat;
  background-size: 20px;
}

.sales-report-date-range-filter-widget .options .option.disabled {
  color: #dee0e6;
}

.sales-report-date-range-filter-widget .options .option:last-of-type {
  border-bottom: 0;
}

.sales-report-date-range-filter-widget .sales-report-year-quarter-selector .year-filter, .sales-report-date-range-filter-widget .sales-report-year-month-selector .year-filter {
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: repeat(3, 1fr);
  padding: 12px;
  display: grid;
}

.sales-report-date-range-filter-widget .sales-report-year-quarter-selector .year-filter .year, .sales-report-date-range-filter-widget .sales-report-year-month-selector .year-filter .year {
  cursor: pointer;
  background-color: #f4f4f6;
  border-radius: 8px;
  padding: 6px 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.sales-report-date-range-filter-widget .sales-report-year-quarter-selector .year-filter .previous-button, .sales-report-date-range-filter-widget .sales-report-year-month-selector .year-filter .previous-button {
  cursor: pointer;
  width: 100%;
  background-image: url("icon-chevron-down-filled.5bee599b.svg");
  background-position: 50% 23px;
  background-repeat: no-repeat;
  transform: rotate(90deg);
}

.sales-report-date-range-filter-widget .sales-report-year-quarter-selector .year-filter .previous-button.hide, .sales-report-date-range-filter-widget .sales-report-year-month-selector .year-filter .previous-button.hide {
  visibility: hidden;
}

.sales-report-date-range-filter-widget .sales-report-year-quarter-selector .year-filter .next-button, .sales-report-date-range-filter-widget .sales-report-year-month-selector .year-filter .next-button {
  cursor: pointer;
  width: 100%;
  background-image: url("icon-chevron-down-filled.5bee599b.svg");
  background-position: 50% 23px;
  background-repeat: no-repeat;
  transform: rotate(270deg);
}

.sales-report-date-range-filter-widget .sales-report-year-quarter-selector .year-filter .next-button.hide, .sales-report-date-range-filter-widget .sales-report-year-month-selector .year-filter .next-button.hide {
  visibility: hidden;
}

.sales-report-date-range-filter-widget .sales-report-year-month-selector .year-month-filter-options {
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.sales-report-date-range-filter-widget .sales-report-year-month-selector .year-month-filter-options .month-filter-options {
  max-height: 400px;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  overflow-y: scroll;
}

.sales-report-date-range-filter-widget .sales-report-year-month-selector .year-month-filter-options .month-filter-options::-webkit-scrollbar {
  width: 1px;
}

.sales-report-date-range-filter-widget .sales-report-year-month-selector .year-month-filter-options .month-filter-options::-webkit-scrollbar-track {
  background: none;
}

.sales-report-date-range-filter-widget .sales-report-year-month-selector .year-month-filter-options .month-filter-options::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.sales-report-date-range-filter-widget .report-form-iso-date-picker-input .DayPickerInput > input {
  min-width: 200px;
  padding: 13.5px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.sales-report-time-range-filter-widget {
  text-align: center;
  background-color: #f6f8fb;
  border-radius: 8px;
  align-items: center;
  margin-top: 20px;
  padding: 8px;
  display: grid;
}

.sales-report-time-range-filter-widget .report-form-iso-date-picker-input .DayPickerInput > input {
  padding: 13.5px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.sales-report-time-range-filter-widget .day-interval-filter {
  grid-template-columns: repeat(3, auto);
  align-items: center;
  gap: 8px;
  padding-top: 8px;
  display: grid;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .form-label {
  color: #7d7e92;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .form-item.invalid .drop-down-input {
  border: 1px solid #f43f5e;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .form-input {
  padding-top: 8px;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .drop-down-input {
  z-index: 999;
  cursor: pointer;
  min-width: 200px;
  text-align: left;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  align-items: center;
  padding: 12px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
  position: relative;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .drop-down-input .value {
  flex-grow: 1;
  padding: 0 12px;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .drop-down-input .down-arrow-icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin: -10px 12px 0 0;
  display: flex;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .drop-down-input .down-arrow-icon > img {
  height: 100%;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .drop-down-input .options {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-top: 16px;
  position: absolute;
  top: 100%;
  left: 0;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .drop-down-input .options .option {
  border-bottom: 1px solid #dee0e6;
  justify-content: space-between;
  padding: 10px 12px;
  display: flex;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .drop-down-input .options .option:last-child {
  border-bottom: 0;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .drop-down-input .value {
  padding: 0;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .drop-down-input .down-arrow-icon {
  margin: 0;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .drop-down-input .down-arrow-icon img {
  display: none;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .drop-down-input .down-arrow-icon:after {
  content: url("icon-clock.815b2511.svg");
  align-self: baseline;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .drop-down-input .options {
  max-height: 265px;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  margin-top: 5px;
  overflow-y: scroll;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .drop-down-input .options::-webkit-scrollbar {
  width: 1px;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .drop-down-input .options::-webkit-scrollbar-track {
  background: none;
}

.sales-report-time-range-filter-widget .day-interval-filter .day-interval-selector .drop-down-input .options::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.sales-report-table-overview-widget {
  padding: 12px;
}

.sales-report-table-overview-widget .header {
  letter-spacing: 0;
  color: #1c1d3e;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.sales-report-table-overview-widget table {
  width: 100%;
  table-layout: fixed;
}

.sales-report-table-overview-widget .sales-table {
  border-radius: 8px;
}

.sales-report-table-overview-widget .sales-table * {
  background: unset;
  white-space: normal;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

.sales-report-table-overview-widget .sales-table ::-webkit-scrollbar {
  width: 1px;
}

.sales-report-table-overview-widget .sales-table ::-webkit-scrollbar-track {
  background: none;
}

.sales-report-table-overview-widget .sales-table ::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.sales-report-table-overview-widget .sales-table td:not(:first-child), .sales-report-table-overview-widget .sales-table td:not(:last-child), .sales-report-table-overview-widget .sales-table th:not(:first-child), .sales-report-table-overview-widget .sales-table th:not(:last-child) {
  border-left: none;
  border-right: none;
}

.sales-report-table-overview-widget .sales-table td:first-child {
  border-left: 1px solid #dee0e6;
}

.sales-report-table-overview-widget .sales-table td:last-child {
  border-right: 1px solid #dee0e6;
}

.sales-report-table-overview-widget .sales-table .sales-header {
  color: #7d7e92;
  font-weight: 500;
}

.sales-report-table-overview-widget .sales-table .sales-header > th {
  padding: 12px;
}

.sales-report-table-overview-widget .sales-table .sales-header .sales-header-cell {
  border-bottom: none;
  padding: 14px 12px;
}

.sales-report-table-overview-widget .sales-table .sales-header .sales-header-cell.unsorted:after {
  content: "▲▼";
  color: #c6c7d1;
  writing-mode: vertical-rl;
  font-size: 10px;
  position: absolute;
}

.sales-report-table-overview-widget .sales-table .sales-header .sales-header-cell.sort-ascending > span:after, .sales-report-table-overview-widget .sales-table .sales-header .sales-header-cell.sort-descending > span:after {
  color: #3b82f6;
}

.sales-report-table-overview-widget .sales-table .sales-header .sales-header-cell:first-child {
  text-align: left;
  width: 30%;
}

.sales-report-table-overview-widget .sales-table .sales-row > td {
  padding: 12px;
}

.sales-report-table-overview-widget .sales-table .sales-row:first-child td {
  border-top: 1px solid #dee0e6;
}

.sales-report-table-overview-widget .sales-table .sales-row:first-child td:first-child {
  border-top-left-radius: 8px;
}

.sales-report-table-overview-widget .sales-table .sales-row:first-child td:last-child {
  border-top-right-radius: 8px;
}

.sales-report-table-overview-widget .sales-table .sales-row:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.sales-report-table-overview-widget .sales-table .sales-row:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.sales-report-table-overview-widget .sales-table .sales-cell {
  text-align: center;
  padding: 14px 12px;
  font-weight: 400;
}

.sales-report-table-overview-widget .sales-table .sales-cell:first-child {
  text-align: left;
}

.sales-report-table-overview-widget .sales-table .sales-cell:nth-child(3) {
  font-weight: 500;
}

.sales-report-table-overview-widget .sales-table .sales-cell:last-child {
  color: #3b82f6;
}

.sales-report-table-overview-widget .title {
  letter-spacing: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.store-filter-widget {
  z-index: 6;
  max-width: 500px;
  width: 35vw;
  position: absolute;
  top: 20px;
  right: 456px;
}

.store-filter-widget .form-item {
  display: flex;
}

.store-filter-widget .form-label {
  color: #7d7e92;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 9.5px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.store-filter-widget .form-input {
  box-sizing: border-box;
  color: #44444f;
  width: 100%;
  color: #1c1d3e;
  min-width: 123px;
  max-width: 400px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  transition: all .3s;
}

.store-filter-widget .drop-down-input {
  z-index: 999;
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.store-filter-widget .drop-down-input .value {
  flex-grow: 1;
  padding: 0 12px;
}

.store-filter-widget .drop-down-input .down-arrow-icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin: -10px 12px 0 0;
  display: flex;
}

.store-filter-widget .drop-down-input .down-arrow-icon > img {
  height: 100%;
}

.store-filter-widget .drop-down-input .options {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-top: 16px;
  position: absolute;
  top: 100%;
  left: 0;
}

.store-filter-widget .drop-down-input .options .option {
  border-bottom: 1px solid #dee0e6;
  justify-content: space-between;
  padding: 10px 12px;
  display: flex;
}

.store-filter-widget .drop-down-input .options .option:last-child {
  border-bottom: 0;
}

.store-filter-widget .drop-down-input .value {
  color: #1c1d3e;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
}

.store-filter-widget .drop-down-input .options {
  width: auto;
  margin-top: 15px;
  position: absolute;
  top: 100%;
  left: -15px;
  right: -15px;
}

.store-filter-widget .drop-down-input .option {
  border-bottom: 1px solid #dee0e6;
  padding: 10px 12px;
}

.store-filter-widget .drop-down-input .option > .option-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 89%;
  position: relative;
  overflow: hidden;
  display: block !important;
}

.store-filter-widget .drop-down-input .option > img {
  position: absolute;
  right: 12px;
}

.store-filter-widget .drop-down-input .down-arrow-icon {
  margin: 0;
}

.connected-store-manage-sold-out-items-widget .no-items {
  text-align: center;
  margin-top: 60px;
}

.connected-store-manage-sold-out-items-widget .category-name {
  letter-spacing: 0;
  color: #1c1d3e;
  background: #f6f8fb;
  margin-bottom: 10px;
  padding: 24px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.connected-store-manage-sold-out-items-widget .category-items .item {
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: 1fr max-content min-content;
  grid-template-areas: "item-name availability-label availability-toggle";
  display: grid;
}

.connected-store-manage-sold-out-items-widget .category-items .item .item-name {
  letter-spacing: 0;
  grid-area: item-name;
  align-items: center;
  padding: 24px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.connected-store-manage-sold-out-items-widget .category-items .item .item-availability-label {
  letter-spacing: 0;
  grid-area: availability-label;
  align-items: center;
  padding: 24px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.connected-store-manage-sold-out-items-widget .category-items .item .item-availability-label.sold-out {
  color: #7d7e92;
}

.connected-store-manage-sold-out-items-widget .category-items .item .item-availability-label.available {
  color: #10b981;
}

.connected-store-manage-sold-out-items-widget .category-items .item .switch {
  grid-area: availability-toggle;
  align-items: center;
  padding: 5px 20px;
}

.sales-report-store-multi-select-widget {
  width: 100%;
  padding-bottom: 16px;
  position: relative;
}

.sales-report-store-multi-select-widget > .form-item-container.close > .form-item > .form-input, .sales-report-store-multi-select-widget > .form-item-container.open.empty > .form-item > .form-input {
  padding: 12px 14px;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-meta > .error {
  color: #9f1239;
  padding-top: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-label {
  color: #7d7e92;
  padding-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 8px 14px;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-input {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-input.open:not(.empty) {
  margin: -6px;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-input.close {
  flex-grow: 1;
  justify-content: space-between;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-input > .removable-tag-list {
  flex-wrap: wrap;
  flex-grow: 1;
  display: flex;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-input > .removable-tag-list > .removable-tag {
  color: #1c1d3e;
  cursor: default;
  background-color: #f4f4f6;
  border-radius: 8px;
  margin: 6px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-input > .input-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 95%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-input > .hidden-count {
  margin: 0 8px 0 24px;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-input > .down-arrow-icon {
  display: inline-flex;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-list {
  width: 100%;
  z-index: 2;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-top: 13px;
  position: absolute;
  left: 0;
  box-shadow: 0 2px 8px #00000014;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-list > .options {
  max-height: 310px;
  overflow-y: scroll;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-list > .options > .option {
  border-bottom: 1px solid #dee0e6;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-list > .options > .option.store-option {
  cursor: pointer;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-list > .options > .option.select-all-option {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-list > .options > .option.select-all-option > .select-all {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-list > .options > .option.select-all-option > .select-all > .label {
  padding-right: 8px;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-list > .options > .option:last-of-type {
  border-bottom: none;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-list > .actions {
  border-top: 1px solid #dee0e6;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-list > .actions > .clear-selection-button {
  color: #1e40af;
  cursor: pointer;
  text-decoration: underline;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-list > .actions > .clear-selection-button.hide {
  visibility: hidden;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-list > .actions .confirm-button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 4px;
  padding: 9.5px 24px;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item > .form-input > .drop-down-list > .actions .confirm-button.disabled {
  pointer-events: none;
  background-color: #83d0be;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item.invalid > .form-input {
  border: 1px solid #f43f5e;
}

.sales-report-store-multi-select-widget > .form-item-container > .form-item.invalid > .form-input > .drop-down-list {
  margin-top: 42px;
}

.pizza-size-filter-selector-widget {
  height: min-content;
  position: relative;
}

.pizza-size-filter-selector-widget .pizza-size-filter label {
  color: #7d7e92;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.pizza-size-filter-selector-widget .pizza-size-filter .form-input {
  border-radius: 4px;
  padding-top: 5px;
}

.pizza-size-filter-selector-widget .pizza-size-filter .form-input input {
  max-width: 180px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-image: url("icon-chevron-down-filled.5bee599b.svg");
  background-position: right 10px center;
  background-repeat: no-repeat;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 12px 34px 12px 14px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
  overflow: hidden;
  display: block !important;
}

.pizza-size-filter-selector-widget .pizza-size-filter .form-input input:focus {
  outline: none;
}

.pizza-size-filter-selector-widget .pizza-size-filter-options {
  width: 100%;
  z-index: 1;
  max-height: 310px;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
  overflow-y: scroll;
}

.pizza-size-filter-selector-widget .pizza-size-filter-options > .option {
  cursor: pointer;
  border-bottom: 1px solid #dee0e6;
  padding: 12px;
}

.pizza-size-filter-selector-widget .pizza-size-filter-options > .option.selected {
  background-image: url("icon-check-alt.4ffb5dc4.svg");
  background-position: right 14px top 50%;
  background-repeat: no-repeat;
  background-size: 20px;
}

.pizza-size-filter-selector-widget .pizza-size-filter-options > .option:last-of-type {
  border-bottom: 0;
}

.table-taken-warning-widget {
  width: 357px;
  text-align: center;
  justify-items: center;
  padding: 24px;
  display: grid;
}

.table-taken-warning-widget .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 12px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.table-taken-warning-widget .subtitle {
  color: #4c4e68;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.table-taken-warning-widget .actions {
  width: 100%;
  gap: 16px;
  display: grid;
}

.table-taken-warning-widget .actions .merge-orders-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.table-taken-warning-widget .actions .merge-orders-button:focus {
  border: none;
  outline: none;
}

.table-taken-warning-widget .actions .choose-table-button {
  color: #1c1d3e;
  background-color: #dee0e6;
  border-color: #dee0e6;
  border-radius: 5px;
  padding: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.table-taken-warning-widget .actions .back-to-cart-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  margin: 0;
  padding: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  transition: all .2s ease-in-out;
}

.order-in-use-warning-widget {
  width: 376px;
  text-align: center;
  justify-items: center;
  padding: 24px;
  display: grid;
}

.order-in-use-warning-widget img {
  margin-bottom: 16px;
}

.order-in-use-warning-widget .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 12px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.order-in-use-warning-widget .subtitle {
  color: #4c4e68;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.order-in-use-warning-widget .actions {
  width: 100%;
  justify-items: center;
  gap: 16px;
  display: grid;
}

.order-in-use-warning-widget .actions button {
  width: 100%;
}

.order-in-use-warning-widget .actions .cancel-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.order-in-use-warning-widget .actions .cancel-button:focus {
  border: none;
  outline: none;
}

.order-in-use-warning-widget .actions .continue-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-size: 15px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.order-error-widget {
  text-align: center;
  width: 50vw;
  min-width: 357px;
  max-width: 573px;
  box-sizing: border-box;
  z-index: 1001;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  display: flex;
}

.order-error-widget > .error-icon {
  width: 64px;
}

.order-error-widget > .title {
  color: #1c1d3e;
  margin-top: 16px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.order-error-widget > .content {
  color: #4c4e68;
  margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
}

.order-error-widget a {
  color: #3f9c86;
  text-decoration: none;
}

.order-error-widget > .confirm-button {
  color: #fff;
  width: 100%;
  height: min-content;
  background-color: #3f9c86;
  border-radius: 4px;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 500;
}

.linkly-payment-summary-widget > .header {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.linkly-payment-summary-widget > .header > .label {
  color: #1c1d3e;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

.linkly-payment-summary-widget > .header > .icon {
  height: 16px;
  width: 16px;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  display: flex;
}

.linkly-payment-summary-widget > .header > .icon > img {
  height: 100%;
}

.linkly-payment-summary-widget > .transaction-infos:not(:first-child) {
  margin-top: 12px;
}

.linkly-payment-summary-widget > .transaction-infos > .payment-transaction-info {
  color: #7d7e92;
  border-top: 1px solid #dee0e6;
  grid-template-columns: 1fr auto;
  grid-template-areas: "label amount"
                       "transaction-reference ."
                       "surcharge-label surcharge-amount"
                       "tip-label tip-amount"
                       "items items";
  place-items: center;
  gap: 0 8px;
  margin-top: 12px;
  padding-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.linkly-payment-summary-widget > .transaction-infos > .payment-transaction-info:first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.linkly-payment-summary-widget > .transaction-infos > .payment-transaction-info > .payment {
  color: #1c1d3e;
  display: contents;
}

.linkly-payment-summary-widget > .transaction-infos > .payment-transaction-info > .payment > .label {
  grid-area: label;
  justify-self: start;
  align-items: center;
  display: flex;
}

.linkly-payment-summary-widget > .transaction-infos > .payment-transaction-info > .payment > .label > .type {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.linkly-payment-summary-widget > .transaction-infos > .payment-transaction-info > .payment > .label > .bullet {
  color: #4c4e68;
  margin: 0 8px;
}

.linkly-payment-summary-widget > .transaction-infos > .payment-transaction-info > .payment > .amount {
  grid-area: amount;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.linkly-payment-summary-widget > .transaction-infos > .payment-transaction-info > .payment > .amount.refund {
  color: #9f1239;
}

.linkly-payment-summary-widget > .transaction-infos > .payment-transaction-info > .transaction-reference {
  color: #7d7e92;
  grid-area: transaction-reference;
  justify-self: start;
}

.linkly-payment-summary-widget > .transaction-infos > .payment-transaction-info > .surcharge {
  color: #7d7e92;
  display: contents;
}

.linkly-payment-summary-widget > .transaction-infos > .payment-transaction-info > .surcharge > .label {
  grid-area: surcharge-label;
  justify-self: start;
}

.linkly-payment-summary-widget > .transaction-infos > .payment-transaction-info > .surcharge > .amount {
  grid-area: surcharge-amount;
  justify-self: end;
}

.linkly-payment-summary-widget > .transaction-infos > .payment-transaction-info > .tip {
  color: #7d7e92;
  display: contents;
}

.linkly-payment-summary-widget > .transaction-infos > .payment-transaction-info > .tip > .label {
  grid-area: tip-label;
  justify-self: start;
}

.linkly-payment-summary-widget > .transaction-infos > .payment-transaction-info > .tip > .amount {
  grid-area: tip-amount;
  justify-self: end;
}

.linkly-payment-summary-widget > .transaction-infos > .refund-transaction-info {
  color: #7d7e92;
  border-top: 1px solid #dee0e6;
  grid-template-columns: 1fr auto;
  grid-template-areas: "label amount"
                       "transaction-reference ."
                       "surcharge-label surcharge-amount"
                       "tip-label tip-amount"
                       "items items";
  place-items: center;
  gap: 0 8px;
  margin-top: 12px;
  padding-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.linkly-payment-summary-widget > .transaction-infos > .refund-transaction-info:first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.linkly-payment-summary-widget > .transaction-infos > .refund-transaction-info > .payment {
  color: #1c1d3e;
  display: contents;
}

.linkly-payment-summary-widget > .transaction-infos > .refund-transaction-info > .payment > .label {
  grid-area: label;
  justify-self: start;
  align-items: center;
  display: flex;
}

.linkly-payment-summary-widget > .transaction-infos > .refund-transaction-info > .payment > .label > .type {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.linkly-payment-summary-widget > .transaction-infos > .refund-transaction-info > .payment > .label > .bullet {
  color: #4c4e68;
  margin: 0 8px;
}

.linkly-payment-summary-widget > .transaction-infos > .refund-transaction-info > .payment > .amount {
  grid-area: amount;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.linkly-payment-summary-widget > .transaction-infos > .refund-transaction-info > .payment > .amount.refund {
  color: #9f1239;
}

.linkly-payment-summary-widget > .transaction-infos > .refund-transaction-info > .transaction-reference {
  color: #7d7e92;
  grid-area: transaction-reference;
  justify-self: start;
}

.linkly-payment-summary-widget > .transaction-infos > .refund-transaction-info > .surcharge {
  color: #7d7e92;
  display: contents;
}

.linkly-payment-summary-widget > .transaction-infos > .refund-transaction-info > .surcharge > .label {
  grid-area: surcharge-label;
  justify-self: start;
}

.linkly-payment-summary-widget > .transaction-infos > .refund-transaction-info > .surcharge > .amount {
  grid-area: surcharge-amount;
  justify-self: end;
}

.linkly-payment-summary-widget > .transaction-infos > .refund-transaction-info > .tip {
  color: #7d7e92;
  display: contents;
}

.linkly-payment-summary-widget > .transaction-infos > .refund-transaction-info > .tip > .label {
  grid-area: tip-label;
  justify-self: start;
}

.linkly-payment-summary-widget > .transaction-infos > .refund-transaction-info > .tip > .amount {
  grid-area: tip-amount;
  justify-self: end;
}

.linkly-settings-widget {
  grid-template-columns: 1fr max-content;
  grid-template-areas: "sub-header sub-header"
                       "login-form help"
                       "printing-settings printing-settings";
  gap: 32px 56px;
  display: grid;
}

.linkly-settings-widget > .sub-header {
  grid-area: sub-header;
}

.linkly-settings-widget > .sub-header .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.linkly-settings-widget > .sub-header .text {
  color: #4c4e68;
}

.linkly-settings-widget > .linkly-pinpad-pairing-widget {
  grid-area: login-form;
}

.linkly-settings-widget > .help {
  width: 424px;
  background: #fafafa;
  grid-area: help;
  padding: 24px;
}

.linkly-settings-widget > .help .sub-header {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 400;
  display: flex;
}

.linkly-settings-widget > .help .sub-header .help-icon {
  margin-right: 8px;
}

.linkly-settings-widget > .help .content {
  color: #1c1d3e;
  padding-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  display: grid;
}

.linkly-settings-widget > .help .content .label {
  letter-spacing: 0;
  color: #1c1d3e;
  cursor: pointer;
  background: url("icon-chevron-down-grey.b17677ff.svg") 100% no-repeat;
  padding: 17.5px 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.linkly-settings-widget > .help .content .open > .label {
  background: url("icon-chevron-up-grey.924dbfb6.svg") 100% no-repeat;
}

.linkly-settings-widget > .help .content .help-item:not(:last-child) {
  border-bottom: 1px solid gray;
}

.linkly-settings-widget > .help .content .time-limit-note {
  color: #4c4e68;
  margin-top: 20px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
}

.linkly-settings-widget > .help .content .time-limit-note .info-icon {
  align-self: baseline;
  margin-right: 8px;
}

.linkly-settings-widget > .help .content ol {
  padding-left: 16px;
}

.linkly-settings-widget > .printing-settings {
  grid-area: printing-settings;
}

.linkly-settings-widget > .printing-settings .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.linkly-settings-widget > .printing-settings .print-last-transaction {
  border: 1px solid #dee0e6;
  border-radius: 12px;
  grid-template-columns: 1fr max-content;
  grid-template-areas: "header print-button"
                       "sub-header print-button";
  padding: 24px;
  display: grid;
}

.linkly-settings-widget > .printing-settings .print-last-transaction .header {
  color: #1c1d3e;
  grid-area: header;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.linkly-settings-widget > .printing-settings .print-last-transaction .sub-header {
  color: #4c4e68;
  grid-area: sub-header;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.linkly-settings-widget > .printing-settings .print-last-transaction .print-button {
  color: #7d7e92;
  background: none;
  border: 1px solid #7d7e92;
  grid-area: print-button;
}

.unpair-linkly-from-terminal-prompt > .swal2-title {
  margin-top: 0 !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 26px !important;
}

.unpair-linkly-from-terminal-prompt > .swal2-content {
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
}

.unpair-linkly-from-terminal-prompt > .swal2-actions {
  padding-top: 20px !important;
}

.unpair-linkly-from-terminal-prompt > .swal2-actions > button {
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.unpair-linkly-from-terminal-prompt > .swal2-actions > .confirm-button {
  background-color: #9f1239 !important;
  border-radius: 5px !important;
}

.unpair-linkly-from-terminal-prompt > .swal2-actions > .swal2-cancel.cancel-button {
  border: 1px solid #dee0e6;
  color: #1c1d3e !important;
}

.linkly-pinpad-pairing-widget.paired {
  height: min-content;
  max-width: 423.5px;
  border: 1px solid #dee0e6;
  border-radius: 12px;
  grid-template-columns: 1fr min-content;
  grid-template-areas: "username unpair-button"
                       "paired-label unpair-button";
  padding: 24px;
  display: grid;
}

.linkly-pinpad-pairing-widget.paired .username {
  color: #1c1d3e;
  grid-area: username;
}

.linkly-pinpad-pairing-widget.paired .paired-label {
  color: #3f9c86;
  grid-area: paired-label;
  font-size: 14px;
}

.linkly-pinpad-pairing-widget.paired .unpair-button {
  height: min-content;
  grid-area: unpair-button;
  margin-left: 24px;
}

.linkly-pinpad-pairing-widget.unpaired input:-webkit-autofill, .linkly-pinpad-pairing-widget.unpaired input:-webkit-autofill:hover, .linkly-pinpad-pairing-widget.unpaired input:-webkit-autofill:focus, .linkly-pinpad-pairing-widget.unpaired input:-webkit-autofill:active {
  -webkit-box-shadow: inset 0 0 0 30px #fff !important;
}

.linkly-pinpad-pairing-widget.unpaired ::placeholder {
  color: #9597a7;
  font-size: 16px;
  font-weight: 400;
}

.linkly-pinpad-pairing-widget.unpaired .form-input > div {
  max-width: 423.5px;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  flex-direction: column;
  padding: 8px 12px;
  display: flex;
}

.linkly-pinpad-pairing-widget.unpaired .form-input > div .prepend-text {
  all: unset;
  color: #7d7e92;
  font-size: 14px;
  font-weight: 400;
}

.linkly-pinpad-pairing-widget.unpaired .form-input > div input {
  height: 24px;
  color: #1c1d3e;
  border: none;
  outline: none;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
}

.linkly-pinpad-pairing-widget.unpaired .form-item.password > .form-input > div {
  grid-template-columns: 1fr min-content;
  grid-template-areas: "password-label show-password"
                       "password-input show-password";
  display: grid;
}

.linkly-pinpad-pairing-widget.unpaired .form-item.password > .form-input > div .prepend-text {
  grid-area: password-label;
}

.linkly-pinpad-pairing-widget.unpaired .form-item.password > .form-input > div input {
  grid-area: password-input;
  transition: none;
  display: block;
}

.linkly-pinpad-pairing-widget.unpaired .form-item.password > .form-input > div .show-password {
  cursor: pointer;
  grid-area: show-password;
  align-self: center;
}

.linkly-pinpad-pairing-widget.unpaired .form-item.password > .form-input > div input[type="password"] {
  height: 24px;
  font-size: 30px;
}

.linkly-pinpad-pairing-widget.unpaired .form-input.invalid > div {
  border: 1px solid #9f1239;
}

.linkly-pinpad-pairing-widget.unpaired .error {
  color: #9f1239;
}

.linkly-pinpad-pairing-widget.unpaired .help-text {
  color: #7d7e92;
  margin-top: 4px;
  font-size: 14px;
}

.linkly-pinpad-pairing-widget.unpaired .submit-btn {
  background-color: #3f9c86;
  border-radius: 4px;
  padding: 9.5px 24px;
}

.linkly-refund-transaction-request-success-widget {
  width: 570px;
  text-align: center;
  padding: 32px 48px;
}

.linkly-refund-transaction-request-success-widget > img {
  padding-top: 16px;
  padding-bottom: 10px;
}

.linkly-refund-transaction-request-success-widget > .label {
  color: #1c1d3e;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
}

.linkly-refund-transaction-request-success-widget .sub-label {
  color: #4c4e68;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.linkly-refund-transaction-request-success-widget .sub-label .highlight {
  color: #3f9c86;
}

.linkly-refund-transaction-request-success-widget .sub-label .business-days {
  text-decoration: underline;
}

.linkly-refund-transaction-request-success-widget .session-id {
  color: #7d7e92;
}

.linkly-refund-transaction-request-success-widget .use-other-payment-methods {
  cursor: pointer;
  text-decoration: underline;
}

.linkly-refund-transaction-request-success-widget .actions {
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 16px;
  margin-top: 30px;
  display: grid;
}

.linkly-refund-transaction-request-success-widget .actions .ok-button, .linkly-refund-transaction-request-success-widget .actions .try-again-button, .linkly-refund-transaction-request-success-widget .actions .go-to-linkly-cloud-button {
  width: 100%;
  color: #fff;
  background: #3f9c86;
  border: 1px solid #3f9c86;
  border-radius: 4px;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.linkly-refund-transaction-request-success-widget .actions .cancel-button {
  width: 100%;
  color: #4c4e68;
  background: none;
  border: 1px solid #4c4e68;
  border-radius: 4px;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.linkly-refund-transaction-request-success-widget .use-other-payment-methods {
  margin-top: 20px;
}

.linkly-purchase-transaction-request-success-widget {
  width: 570px;
  text-align: center;
  padding: 32px 48px;
}

.linkly-purchase-transaction-request-success-widget > img {
  padding-top: 16px;
  padding-bottom: 10px;
}

.linkly-purchase-transaction-request-success-widget > .label {
  color: #1c1d3e;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
}

.linkly-purchase-transaction-request-success-widget .sub-label {
  color: #4c4e68;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.linkly-purchase-transaction-request-success-widget .sub-label .highlight {
  color: #3f9c86;
}

.linkly-purchase-transaction-request-success-widget .sub-label .business-days {
  text-decoration: underline;
}

.linkly-purchase-transaction-request-success-widget .session-id {
  color: #7d7e92;
}

.linkly-purchase-transaction-request-success-widget .use-other-payment-methods {
  cursor: pointer;
  text-decoration: underline;
}

.linkly-purchase-transaction-request-success-widget .actions {
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 16px;
  margin-top: 30px;
  display: grid;
}

.linkly-purchase-transaction-request-success-widget .actions .ok-button, .linkly-purchase-transaction-request-success-widget .actions .try-again-button, .linkly-purchase-transaction-request-success-widget .actions .go-to-linkly-cloud-button {
  width: 100%;
  color: #fff;
  background: #3f9c86;
  border: 1px solid #3f9c86;
  border-radius: 4px;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.linkly-purchase-transaction-request-success-widget .actions .cancel-button {
  width: 100%;
  color: #4c4e68;
  background: none;
  border: 1px solid #4c4e68;
  border-radius: 4px;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.linkly-purchase-transaction-request-success-widget .use-other-payment-methods {
  margin-top: 20px;
}

.linkly-transaction-request-failed-widget {
  width: 570px;
  text-align: center;
  padding: 32px 48px;
}

.linkly-transaction-request-failed-widget > img {
  padding-top: 16px;
  padding-bottom: 10px;
}

.linkly-transaction-request-failed-widget > .label {
  color: #1c1d3e;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
}

.linkly-transaction-request-failed-widget .sub-label {
  color: #4c4e68;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.linkly-transaction-request-failed-widget .sub-label .highlight {
  color: #3f9c86;
}

.linkly-transaction-request-failed-widget .sub-label .business-days {
  text-decoration: underline;
}

.linkly-transaction-request-failed-widget .session-id {
  color: #7d7e92;
}

.linkly-transaction-request-failed-widget .use-other-payment-methods {
  cursor: pointer;
  text-decoration: underline;
}

.linkly-transaction-request-failed-widget .actions {
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 16px;
  margin-top: 30px;
  display: grid;
}

.linkly-transaction-request-failed-widget .actions .ok-button, .linkly-transaction-request-failed-widget .actions .try-again-button, .linkly-transaction-request-failed-widget .actions .go-to-linkly-cloud-button {
  width: 100%;
  color: #fff;
  background: #3f9c86;
  border: 1px solid #3f9c86;
  border-radius: 4px;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.linkly-transaction-request-failed-widget .actions .cancel-button {
  width: 100%;
  color: #4c4e68;
  background: none;
  border: 1px solid #4c4e68;
  border-radius: 4px;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.linkly-transaction-request-failed-widget .use-other-payment-methods {
  margin-top: 20px;
}

.linkly-recovery-mode-transaction-widget {
  width: 570px;
  text-align: center;
  width: 573px;
  padding: 32px 24px;
}

.linkly-recovery-mode-transaction-widget > img {
  padding-top: 16px;
  padding-bottom: 10px;
}

.linkly-recovery-mode-transaction-widget > .label {
  color: #1c1d3e;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
}

.linkly-recovery-mode-transaction-widget .sub-label {
  color: #4c4e68;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.linkly-recovery-mode-transaction-widget .sub-label .highlight {
  color: #3f9c86;
}

.linkly-recovery-mode-transaction-widget .sub-label .business-days {
  text-decoration: underline;
}

.linkly-recovery-mode-transaction-widget .session-id {
  color: #7d7e92;
}

.linkly-recovery-mode-transaction-widget .use-other-payment-methods {
  cursor: pointer;
  text-decoration: underline;
}

.linkly-recovery-mode-transaction-widget .actions {
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 16px;
  margin-top: 30px;
  display: grid;
}

.linkly-recovery-mode-transaction-widget .actions .ok-button, .linkly-recovery-mode-transaction-widget .actions .try-again-button, .linkly-recovery-mode-transaction-widget .actions .go-to-linkly-cloud-button {
  width: 100%;
  color: #fff;
  background: #3f9c86;
  border: 1px solid #3f9c86;
  border-radius: 4px;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.linkly-recovery-mode-transaction-widget .actions .cancel-button {
  width: 100%;
  color: #4c4e68;
  background: none;
  border: 1px solid #4c4e68;
  border-radius: 4px;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.linkly-recovery-mode-transaction-widget .use-other-payment-methods {
  margin-top: 20px;
}

.linkly-recovery-mode-transaction-widget > img {
  margin-bottom: 16px;
  padding-bottom: 0;
}

.linkly-recovery-mode-transaction-widget > .actions {
  margin: 30px 24px 0;
}

.linkly-incomplete-transaction-banner-widget {
  z-index: 998;
  width: 100vw;
  background-color: #ffe4e6;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px 32px 7.5px 102px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 4px 24px #00000026;
}

.linkly-incomplete-transaction-banner-widget > .text {
  display: inherit;
  align-items: inherit;
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.linkly-incomplete-transaction-banner-widget > .text > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.linkly-incomplete-transaction-banner-widget > .text > .icon > img {
  height: 100%;
}

.linkly-incomplete-transaction-banner-widget > .text > .highlight {
  display: inherit;
  align-items: inherit;
  margin-right: 8px;
  font-weight: 500;
}

.linkly-incomplete-transaction-banner-widget > .actions {
  justify-content: center;
  align-items: center;
  display: flex;
}

.linkly-incomplete-transaction-banner-widget > .actions > * {
  margin-left: 12px;
}

.linkly-incomplete-transaction-banner-widget > .actions > :first-child {
  margin-left: 0;
}

.linkly-incomplete-transaction-banner-widget > .actions .refresh-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0;
  color: #1c1d3e;
  color: #fff;
  background-color: #3f9c86;
  border-radius: 4px;
  margin: 0;
  padding: 7px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.linkly-incomplete-transaction-banner-widget > .actions .refresh-button:focus {
  border: none;
  outline: none;
}

.linkly-incomplete-transaction-banner-widget > .actions .later-button {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #9f1239;
  background-color: #0000;
  border: 1px solid #d97706;
  border-radius: 4px;
  padding: 7px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.linkly-incomplete-transaction-banner-widget > .actions > .continue-transaction-button, .linkly-incomplete-transaction-banner-widget > .actions .void-transaction-button {
  cursor: pointer;
  border-radius: 4px;
  padding: 7px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.linkly-incomplete-transaction-banner-widget > .actions > .continue-transaction-button {
  color: #fff;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.linkly-incomplete-transaction-banner-widget > .actions > .void-transaction-button {
  color: #9f1239;
  background-color: #0000;
  border: 1px solid #f43f5e;
}

.linkly-incomplete-transaction-banner-widget > .actions > .void-transaction-button.transaction-failed {
  color: #fff;
  background-color: #9f1239;
  border: none;
}

.linkly-incomplete-transaction-banner-widget > .actions > .go-online-button {
  border: 1px solid #0000;
}

.linkly-incomplete-transaction-banner-widget > .actions > .dismiss-button {
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
}

.linkly-incomplete-transaction-banner-widget > .actions > .enable-audio-button {
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.linkly-incomplete-transaction-banner-widget > .text > .highlight {
  color: #9f1239;
}

.swal2-container.void-transaction-modal .swal2-confirm {
  background-color: #9f1239 !important;
}

.uber-eats.order-history-list-item-widget {
  padding: 0 24px 16px;
}

.uber-eats.order-history-list-item-widget .order-details-info {
  white-space: nowrap;
  color: #1c1d3e;
  cursor: pointer;
  grid-template-columns: 1.25fr 1.25fr .5fr 1.25fr 1fr .5fr 1fr .35fr;
  grid-auto-flow: column;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-customer-info {
  min-width: 0;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "order-logo customer-name"
                       "order-logo customer-phone-number";
  gap: 0 10px;
  display: grid;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo {
  grid-area: order-logo;
  display: grid;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo .google-logo, .uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo .uber-eats-logo {
  align-self: center;
  width: 28px !important;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo > .optimized-image {
  align-self: center;
  display: grid;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .order-logo > .optimized-image > img {
  border-radius: 50%;
  width: 28px !important;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .customer-name {
  text-overflow: ellipsis;
  color: #1c1d3e;
  grid-area: customer-name;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  overflow: hidden;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-customer-info .customer-phone-number {
  color: #7d7e92;
  grid-area: customer-phone-number;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-collection-info {
  max-width: 100%;
  overflow: hidden;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .collection-type {
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .collection-type > span {
  color: #7d7e92;
  margin-top: 2px;
  margin-left: 3px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .pickup-in-label {
  color: #1e40af;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .requested-for {
  color: #7d7e92;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .table-info {
  color: #7d7e92;
  max-width: 100%;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .table-info img {
  margin-right: 4px;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-collection-info .table-info .table-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-grand-total {
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-order-status {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-payment-status {
  grid-template-columns: min-content min-content 1fr;
  align-items: center;
  display: grid;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .payment-status {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .payment-status.paid {
  color: #fff;
  background-color: #059669;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .payment-status.not-paid {
  color: #fff;
  background-color: #f43f5e;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .payment-status.refunded {
  color: #1c1d3e;
  background-color: #f59e0b;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-payment-status > .bullet {
  margin: 0 4px;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-payment-status .payment-type {
  color: #1c1d3e;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  overflow: hidden;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-docket-number {
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-placed-date-time {
  color: #1c1d3e;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-print-order-button {
  height: 22px;
  width: 22px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats.order-history-list-item-widget .order-details-info .orders-list-item-print-order-button > img {
  height: 100%;
}

.uber-eats.order-history-list-item-widget .orders-list-item-collection-info .past-pickup-time {
  color: #9f1239;
}

.uber-eats.order-history-list-item-widget .orders-list-item-order-status.to-be-confirmed {
  color: #d97706;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.uber-eats.order-history-list-item-widget .orders-list-item-order-status.to-be-confirmed:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #d97706;
  border-radius: 100%;
  margin-right: 8px;
}

.uber-eats.order-history-list-item-widget .orders-list-item-order-status.accepted, .uber-eats.order-history-list-item-widget .orders-list-item-order-status.handed-off, .uber-eats.order-history-list-item-widget .orders-list-item-order-status.delivered {
  color: #059669;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.uber-eats.order-history-list-item-widget .orders-list-item-order-status.accepted:before, .uber-eats.order-history-list-item-widget .orders-list-item-order-status.handed-off:before, .uber-eats.order-history-list-item-widget .orders-list-item-order-status.delivered:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #059669;
  border-radius: 100%;
  margin-right: 8px;
}

.uber-eats.order-history-list-item-widget .orders-list-item-order-status.failed, .uber-eats.order-history-list-item-widget .orders-list-item-order-status.cancelled, .uber-eats.order-history-list-item-widget .orders-list-item-order-status.rejected, .uber-eats.order-history-list-item-widget .orders-list-item-order-status.unknown {
  color: #f43f5e;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.uber-eats.order-history-list-item-widget .orders-list-item-order-status.failed:before, .uber-eats.order-history-list-item-widget .orders-list-item-order-status.cancelled:before, .uber-eats.order-history-list-item-widget .orders-list-item-order-status.rejected:before, .uber-eats.order-history-list-item-widget .orders-list-item-order-status.unknown:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #f43f5e;
  border-radius: 100%;
  margin-right: 8px;
}

.uber-eats.order-history-list-item-widget .orders-list-item-store-name {
  margin-left: 0;
}

.uber-eats-order-list-item-widget {
  height: -moz-fit-content;
  height: fit-content;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 10px;
  grid-template-columns: 1fr min-content;
  align-items: center;
  padding: 13px 20px;
  display: grid;
  box-shadow: 0 3px 10px -3px #0000004d;
}

.uber-eats-order-list-item-widget .order-data {
  grid-template-columns: 20% 15% 10% 20% 10% 20%;
  grid-auto-flow: column;
  align-items: center;
  gap: 1%;
  display: grid;
}

.uber-eats-order-list-item-widget .orders-list-item-customer-info {
  padding: 0 10px;
}

.uber-eats-order-list-item-widget .orders-list-item-customer-info .customer-name {
  color: #1c1d3e;
  text-overflow: ellipsis;
  overflow: hidden;
}

.uber-eats-order-list-item-widget .orders-list-item-customer-info .customer-phone-number {
  color: #7d7e92;
  font-size: 14px;
}

.uber-eats-order-list-item-widget .orders-list-item-collection-info .collection-type span {
  color: #7d7e92;
}

.uber-eats-order-list-item-widget .orders-list-item-collection-info .pickup-in-label {
  color: #1e40af;
}

.uber-eats-order-list-item-widget .orders-list-item-grand-total, .uber-eats-order-list-item-widget .orders-list-item-docket-number {
  text-align: left;
  margin: 0;
  font-size: 18px;
}

.uber-eats-order-list-item-widget .orders-list-item-order-status {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 30px;
  padding: 5px 15px;
  font-weight: 600;
}

.uber-eats-order-list-item-widget .orders-list-item-order-status.to-be-confirmed {
  color: #d97706;
  background-color: #fef3c7;
}

.uber-eats-order-list-item-widget .orders-list-item-order-status.accepted, .uber-eats-order-list-item-widget .orders-list-item-order-status.handed-off, .uber-eats-order-list-item-widget .orders-list-item-order-status.delivered {
  color: #065f46;
  background-color: #d1fae5;
}

.uber-eats-order-list-item-widget .orders-list-item-order-status.failed, .uber-eats-order-list-item-widget .orders-list-item-order-status.cancelled, .uber-eats-order-list-item-widget .orders-list-item-order-status.rejected, .uber-eats-order-list-item-widget .orders-list-item-order-status.unknown {
  color: #9f1239;
  background-color: #ffe4e6;
}

.uber-eats-order-list-item-widget .actions {
  cursor: pointer;
  gap: 20px;
  display: flex;
}

.uber-eats-order-list-item-widget .orders-list-item-print-order-button {
  opacity: .8;
  justify-self: end;
  padding: 0 10px;
}

.uber-eats-order-list-item-widget .edit-icon {
  margin-left: auto;
}

.uber-eats-reject-reason-selector-widget {
  max-width: 760px;
  width: 55vw;
  max-height: 90vh;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  color: #1c1d3e;
  gap: 24px;
  padding: 40px 32px 32px;
  display: grid;
  overflow: auto;
}

.uber-eats-reject-reason-selector-widget::-webkit-scrollbar {
  width: 1px;
}

.uber-eats-reject-reason-selector-widget::-webkit-scrollbar-track {
  background: none;
}

.uber-eats-reject-reason-selector-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.uber-eats-reject-reason-selector-widget .header {
  letter-spacing: .1px;
  justify-content: center;
  align-items: baseline;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
}

.uber-eats-reject-reason-selector-widget .header > span {
  letter-spacing: .09px;
  color: #4c4e68;
  margin-left: 6px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.uber-eats-reject-reason-selector-widget .reject-reasons {
  grid-template-rows: repeat(8, 1fr);
  grid-auto-flow: column;
  gap: 16px 64px;
  display: grid;
}

.uber-eats-reject-reason-selector-widget .reject-reasons .reject-reason {
  grid-template-columns: max-content 1fr;
  align-items: center;
  display: grid;
}

.uber-eats-reject-reason-selector-widget .reject-reasons .reject-reason .checkbox {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats-reject-reason-selector-widget .reject-reasons .reject-reason .checkbox > img {
  height: 100%;
}

.uber-eats-reject-reason-selector-widget .reject-reasons .reject-reason .label {
  letter-spacing: .09px;
  margin-left: 8px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.uber-eats-reject-reason-selector-widget .additional-info {
  gap: 4px;
  display: grid;
}

.uber-eats-reject-reason-selector-widget .additional-info .form-item .meta {
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
  display: flex;
}

.uber-eats-reject-reason-selector-widget .additional-info .form-item .meta .help {
  margin-left: auto;
}

.uber-eats-reject-reason-selector-widget .additional-info input {
  border-color: #dee0e6;
  border-radius: 8px;
  padding: 20px 12px;
}

.uber-eats-reject-reason-selector-widget .additional-info input::placeholder {
  letter-spacing: .08px;
  color: #9597a7;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.uber-eats-reject-reason-selector-widget .additional-info .character-count {
  text-align: end;
  color: #7d7e92;
}

.uber-eats-reject-reason-selector-widget > .actions {
  height: min-content;
  grid-auto-flow: column;
  gap: 16px;
  padding: 0 24px;
  display: grid;
}

.uber-eats-reject-reason-selector-widget > .actions .button {
  width: 100%;
  text-align: center;
  border: 1px solid;
  border-radius: 4px;
  padding: 14px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats-reject-reason-selector-widget > .actions .cancel {
  color: #4c4e68;
  background-color: #0000;
  border-color: #7d7e92;
}

.uber-eats-reject-reason-selector-widget > .actions .reject-order {
  color: #fff;
  background-color: #9f1239;
  border-color: #9f1239;
}

.uber-eats-reject-reason-selector-widget > .actions .reject-order.disabled {
  color: #7d7e92;
  background-color: #dee0e6;
  border-color: #dee0e6;
}

.uber-eats-order-delivery-status-widget .completed-status {
  background-color: #10b981;
  border-radius: 16px;
  align-items: center;
  padding: 24px;
  display: flex;
  box-shadow: 0 0 1px #00000040, 0 2px 1px #0000000d;
}

.uber-eats-order-delivery-status-widget .completed-status .icon {
  margin-right: 12px;
}

.uber-eats-order-delivery-status-widget .completed-status .label {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.uber-eats-order-delivery-status-widget .cancelled-status .header {
  background-color: #f43f5e;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  align-items: center;
  padding: 24px;
  display: flex;
}

.uber-eats-order-delivery-status-widget .cancelled-status .header .icon {
  margin-right: 12px;
}

.uber-eats-order-delivery-status-widget .cancelled-status .header .label {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.uber-eats-order-delivery-status-widget .cancelled-status .info {
  letter-spacing: .08px;
  color: #1c1d3e;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  box-shadow: 0 0 1px #00000040, 0 2px 1px #0000000d;
}

.uber-eats-store-status-banner-widget {
  z-index: 998;
  width: 100vw;
  z-index: 997;
  background: #ffe4e6;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px 32px 7.5px 102px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 4px 24px #00000026;
}

.uber-eats-store-status-banner-widget > .text {
  display: inherit;
  align-items: inherit;
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.uber-eats-store-status-banner-widget > .text > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.uber-eats-store-status-banner-widget > .text > .icon > img {
  height: 100%;
}

.uber-eats-store-status-banner-widget > .text > .highlight {
  display: inherit;
  align-items: inherit;
  margin-right: 8px;
  font-weight: 500;
}

.uber-eats-store-status-banner-widget > .actions {
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats-store-status-banner-widget > .actions > * {
  margin-left: 12px;
}

.uber-eats-store-status-banner-widget > .actions > :first-child {
  margin-left: 0;
}

.uber-eats-store-status-banner-widget > .actions .refresh-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0;
  color: #1c1d3e;
  color: #fff;
  background-color: #3f9c86;
  border-radius: 4px;
  margin: 0;
  padding: 7px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.uber-eats-store-status-banner-widget > .actions .refresh-button:focus {
  border: none;
  outline: none;
}

.uber-eats-store-status-banner-widget > .actions .later-button {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #9f1239;
  background-color: #0000;
  border: 1px solid #d97706;
  border-radius: 4px;
  padding: 7px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.uber-eats-store-status-banner-widget > .actions > .continue-transaction-button, .uber-eats-store-status-banner-widget > .actions .void-transaction-button {
  cursor: pointer;
  border-radius: 4px;
  padding: 7px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.uber-eats-store-status-banner-widget > .actions > .continue-transaction-button {
  color: #fff;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.uber-eats-store-status-banner-widget > .actions > .void-transaction-button {
  color: #9f1239;
  background-color: #0000;
  border: 1px solid #f43f5e;
}

.uber-eats-store-status-banner-widget > .actions > .void-transaction-button.transaction-failed {
  color: #fff;
  background-color: #9f1239;
  border: none;
}

.uber-eats-store-status-banner-widget > .actions > .go-online-button {
  border: 1px solid #0000;
}

.uber-eats-store-status-banner-widget > .actions > .dismiss-button {
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
}

.uber-eats-store-status-banner-widget > .actions > .enable-audio-button {
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.uber-eats-store-status-banner-widget > .text > .highlight {
  color: #9f1239;
}

.uber-eats-store-status-banner-widget button {
  cursor: pointer;
  white-space: nowrap;
  border-radius: 4px;
  padding: 7px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.uber-eats.order-view-widget {
  height: 90vh;
  width: 90vw;
  max-width: 1200px;
  color: #1c1d3e;
  display: flex;
}

.uber-eats.order-view-widget .order-view-main-panel-widget {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  flex-direction: column;
  flex: 1;
  padding: 24px 32px;
  display: flex;
  overflow-y: auto;
}

.uber-eats.order-view-widget .order-view-main-panel-widget::-webkit-scrollbar {
  width: 1px;
}

.uber-eats.order-view-widget .order-view-main-panel-widget::-webkit-scrollbar-track {
  background: none;
}

.uber-eats.order-view-widget .order-view-main-panel-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.uber-eats.order-view-widget .order-view-main-panel-widget > * {
  margin-top: 30px;
}

.uber-eats.order-view-widget .order-view-main-panel-widget > :first-child {
  margin-top: 0;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget {
  grid-template-columns: max-content max-content 1fr;
  grid-template-areas: "collection-type order-source requested-for"
                       "order-id order-id requested-for";
  align-items: center;
  display: grid;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .collection-type {
  grid-area: collection-type;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .order-source {
  white-space: nowrap;
  height: -moz-fit-content;
  height: fit-content;
  color: #4c4e68;
  border: 1px solid #d3d3d9;
  border-radius: 99px;
  grid-area: order-source;
  margin-left: 10px;
  padding: 3px 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for {
  grid-area: requested-for;
  align-self: flex-start;
  margin-left: auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for .schedule > .label {
  margin: auto;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for .date-time.schedule > .value {
  color: #047857;
  background-color: #ecfdf5;
  border-radius: 100px;
  margin-left: 10px;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for .asap.schedule > .value, .uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .requested-for .eta.schedule > .value {
  color: #9f1239;
  background-color: #ffe4e6;
  border-radius: 100px;
  margin-left: 10px;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .time-since-label-widget {
  color: #4c4e68;
  text-align: right;
  margin-top: 4px;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .time-since-label-widget:first-letter {
  text-transform: uppercase;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .order-id {
  color: #4c4e68;
  letter-spacing: .035px;
  grid-area: order-id;
  margin-top: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-header-widget .order-id span {
  text-transform: uppercase;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header {
  align-items: center;
  display: flex;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .icon > img {
  height: 100%;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .label {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .missing-items {
  color: #d97706;
  place-items: center;
  margin-left: 10px;
  display: flex;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > .header > .missing-items:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #d97706;
  border-radius: 9999px;
  margin-right: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: block;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > * {
  border-top: 1px solid #dee0e6;
  margin-top: 12px;
  padding-top: 12px;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-item-list-widget > .items > :first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget {
  color: #4c4e68;
  border-top: 1px solid #dee0e6;
  margin-top: 16px;
  padding-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget .amount {
  justify-content: space-between;
  margin-top: 2px;
  display: flex;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget .grand-total {
  border-top: 1px solid #dee0e6;
  margin: 12px 0;
  padding-top: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget .grand-total .value {
  color: #3f9c86;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .order-view-amount-list-widget .gst-amount {
  font-weight: 500;
}

.uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .tyro-payment-summary-widget, .uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .linkly-payment-summary-widget, .uber-eats.order-view-widget .order-view-main-panel-widget .order-view-main-panel-body-widget .anz-worldline-payment-summary-widget {
  margin-top: 16px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #f6f8fb;
  border-radius: 0 24px 24px 0;
  flex-direction: column;
  flex-basis: 30%;
  padding: 23px;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
}

.uber-eats.order-view-widget .order-view-info-panel-widget::-webkit-scrollbar {
  width: 1px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget::-webkit-scrollbar-track {
  background: none;
}

.uber-eats.order-view-widget .order-view-info-panel-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.uber-eats.order-view-widget .order-view-info-panel-widget > * {
  margin-top: 24px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget > :first-child {
  margin-top: 0;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header {
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: 1fr auto;
  grid-template-areas: "title close-button";
  display: grid;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title {
  grid-area: title;
  grid-template-columns: repeat(2, max-content);
  grid-template-areas: "failed-status-tag failed-status-tag"
                       "currency-icon title-text";
  display: grid;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title .failed-status-tag {
  color: #f43f5e;
  grid-area: failed-status-tag;
  align-items: center;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title .failed-status-tag:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #f43f5e;
  border-radius: 100%;
  margin-right: 8px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title .currency-icon {
  width: 16px;
  height: 16px;
  color: #fff;
  text-align: center;
  background-color: #7d7e92;
  border-radius: 16px;
  grid-area: currency-icon;
  margin-top: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .title .text {
  grid-area: title-text;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .header .close-button {
  grid-area: close-button;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .grand-total {
  margin-top: 8px;
  font-size: 31px;
  font-weight: 500;
  line-height: 38px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info {
  align-items: center;
  margin-top: 8px;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info .paid {
  color: #fff;
  background-color: #059669;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info .not-paid {
  color: #fff;
  background-color: #f43f5e;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info .refunded {
  color: #1c1d3e;
  background-color: #f59e0b;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .payment-info .bullet {
  margin: 0 4px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-payment-info-widget .tax-invoice-number {
  width: max-content;
  color: #4c4e68;
  border: 1px solid #d3d3d9;
  border-radius: 4px;
  margin-top: 16px;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .info-container {
  overflow-y: scroll;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .info-container .label {
  color: #7d7e92;
}

@media (max-height: 752px) {
  .uber-eats.order-view-widget .order-view-info-panel-widget .info-container {
    scrollbar-width: thin;
    scrollbar-color: auto;
  }

  .uber-eats.order-view-widget .order-view-info-panel-widget .info-container::-webkit-scrollbar {
    width: auto;
  }

  .uber-eats.order-view-widget .order-view-info-panel-widget .info-container::-webkit-scrollbar-track {
    background: auto;
  }

  .uber-eats.order-view-widget .order-view-info-panel-widget .info-container::-webkit-scrollbar-thumb {
    background-color: auto;
  }
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header {
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .icon > img {
  height: 100%;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget > .header > .chevron > img {
  height: 100%;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info {
  display: none;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info.visible {
  display: block;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info > .key-value-pair {
  grid-template-columns: .5fr 1fr;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info > .key-value-pair > .text {
  color: #7d7e92;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-table-info-widget .table-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header {
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .icon > img {
  height: 100%;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget > .header > .chevron > img {
  height: 100%;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info {
  display: none;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info.visible {
  display: block;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair {
  gap: 12px;
  align-items: unset;
  grid-template-columns: .5fr 1fr;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair > .text {
  color: #7d7e92;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .customer-info > .key-value-pair.uber-eats .value {
  color: #7d7e92;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .dropoff-notes {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #343553;
  background: linear-gradient(#f7f9fc 0%, #fff 100%), #f6f8fb;
  border-radius: 8px;
  margin-top: 8px;
  padding: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  display: block;
  box-shadow: 0 2px 1px #0000000d, 0 0 1px #00000040;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-customer-info-widget .dropoff-notes > div {
  font-weight: 600;
  display: inline;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header {
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .icon > img {
  height: 100%;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget > .header > .chevron > img {
  height: 100%;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info {
  display: none;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info.visible {
  display: block;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info > .key-value-pair {
  grid-template-columns: .5fr 1fr;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info > .key-value-pair > .text {
  color: #7d7e92;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-employee-info-widget .employee-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header {
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .icon > img {
  height: 100%;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header > .chevron > img {
  height: 100%;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header) {
  grid-template-columns: .5fr 1fr;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: none;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header):nth-child(2) {
  margin-top: 10px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header).visible {
  display: grid;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header) > .label {
  color: #7d7e92;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > :not(.header) > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget > .header .icon {
  margin-top: 2px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-details {
  align-items: center;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-image {
  width: 100%;
  border-radius: 50%;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-name {
  color: #1c1d3e;
  font-size: 16px;
  font-weight: 500;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-vehicle {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 400;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-uber-courier-info-widget .courier-contact-number {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header {
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .icon > img {
  height: 100%;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .chevron > img {
  height: 100%;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .additional-info {
  letter-spacing: .048px;
  color: #059669;
  align-self: center;
  margin-left: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .additional-info.ARRIVED_AT_PICKUP {
  color: #3b82f6;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .additional-info.EN_ROUTE_TO_DROPOFF {
  color: #d97706;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header > .additional-info.FAILED {
  color: #f43f5e;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget > .header .icon {
  margin-top: 2px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info {
  display: none;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info.visible {
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info {
  align-items: center;
  margin-top: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .icon {
  height: 74px;
  width: 74px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .icon > img {
  height: 100%;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .icon img {
  border-radius: 50%;
  margin-right: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .details .driver-name {
  color: #1c1d3e;
  font-size: 16px;
  font-weight: 500;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .details .vehicle-info {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 400;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .driver-info .details .driver-contact-number {
  color: #4c4e68;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status {
  display: none;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status.visible {
  display: block;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair {
  grid-template-columns: .5fr 1fr;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair:first-child {
  margin-top: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair > .text {
  color: #7d7e92;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair .text {
  color: #7d7e92;
  letter-spacing: .25px;
  font-size: 14px;
  line-height: 21px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair > .value {
  letter-spacing: .25px;
  color: #059669;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair.ARRIVED_AT_PICKUP > .value {
  color: #3b82f6;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair.EN_ROUTE_TO_DROPOFF > .value {
  color: #d97706;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-driver-info-widget .delivery-status > .key-value-pair.FAILED > .value {
  color: #f43f5e;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header {
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .icon > img {
  height: 100%;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget > .header > .chevron > img {
  height: 100%;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info {
  display: none;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info.visible {
  display: block;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info > .key-value-pair {
  gap: 12px;
  align-items: unset;
  grid-template-columns: .5fr 1fr;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info > .key-value-pair > .text {
  color: #7d7e92;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-rejection-info-widget .rejection-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget {
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-left: 1px solid #dee0e6;
  border-right: 1px solid #dee0e6;
  padding: 10px 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget:first-child {
  border-top: 1px solid #dee0e6;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header {
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .icon > img {
  height: 100%;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget > .header > .chevron > img {
  height: 100%;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info {
  display: none;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info.visible {
  display: block;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info > .key-value-pair {
  grid-template-columns: .5fr 1fr;
  align-items: flex-start;
  gap: 12px;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info > .key-value-pair:first-child {
  margin-top: 12px;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info > .key-value-pair > .text {
  color: #7d7e92;
}

.uber-eats.order-view-widget .order-view-info-panel-widget .order-view-store-info-widget .store-info > .key-value-pair > .value {
  word-break: break-word;
  color: #1c1d3e;
}

.uber-eats.order-view-widget .ready-for-pickup-label {
  color: #1e40af;
  background-color: #dbeafe;
  border-radius: 100px;
  grid-area: requested-for;
  align-self: flex-start;
  margin-left: auto;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats.order-view-widget .uber-eats-order-view-actions-widget {
  gap: 16px;
  margin-top: auto;
  padding-top: 24px;
  display: grid;
}

.uber-eats.order-view-widget .uber-eats-order-view-actions-widget .button {
  width: 100%;
  border: 1px solid;
  border-radius: 4px;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats.order-view-widget .uber-eats-order-view-actions-widget .button.ready-for-pickup {
  color: #1e40af;
  background-color: #0000;
  border-color: #60a5fa;
}

.uber-eats.order-view-widget .uber-eats-order-view-actions-widget .button.accept-order, .uber-eats.order-view-widget .uber-eats-order-view-actions-widget .button.make-payment {
  color: #fff;
  background-color: #3f9c86;
  border-color: #3f9c86;
}

.uber-eats.order-view-widget .uber-eats-order-view-actions-widget .button.print-order {
  color: #7d7e92;
  background-color: #0000;
}

.uber-eats.order-view-widget .uber-eats-order-view-actions-widget .button.reject-order {
  color: #9f1239;
  background-color: #0000;
  border: none;
  text-decoration: underline;
}

.uber-eats.order-view-widget .uber-eats-order-view-actions-widget .button.manage-order {
  color: #1c1d3e;
  background-color: #dee0e6;
  border: none;
}

.uber-eats-pickup-time-selector-widget {
  max-width: 850px;
  width: 70vw;
  padding: 16px 32px 18px;
}

.uber-eats-pickup-time-selector-widget .header {
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
}

.uber-eats-pickup-time-selector-widget .header > .title {
  color: #1c1d3e;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.uber-eats-pickup-time-selector-widget .header > .close-button {
  height: 36px;
  width: 36px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  right: 32px;
}

.uber-eats-pickup-time-selector-widget .header > .close-button > img {
  height: 100%;
}

.uber-eats-pickup-time-selector-widget .pickup-time-options {
  grid-auto-flow: row;
  gap: 32px;
  padding: 32px 0;
  display: grid;
}

.uber-eats-pickup-time-selector-widget .pickup-time-options > .option {
  border-radius: 6px;
  align-items: center;
  padding: 40px 16px;
  display: flex;
}

.uber-eats-pickup-time-selector-widget .pickup-time-options > .uber-eats-recommended-time {
  border: 2px solid #4cbba1;
  box-shadow: 0 4px 6px #31303e26, 0 0 5px #31303e26;
}

.uber-eats-pickup-time-selector-widget .pickup-time-options > .uber-eats-recommended-time > .uber-eats-logo {
  margin-right: 8px;
}

.uber-eats-pickup-time-selector-widget .pickup-time-options > .uber-eats-recommended-time > .title {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.uber-eats-pickup-time-selector-widget .pickup-time-options > .uber-eats-recommended-time > .title > .time-since-label {
  font-size: 18px;
  font-weight: 600;
}

.uber-eats-pickup-time-selector-widget .pickup-time-options > .uber-eats-recommended-time > .title > .time-of-day {
  letter-spacing: .08px;
  font-weight: 600;
}

.uber-eats-pickup-time-selector-widget .pickup-time-options > .uber-eats-recommended-time > .label {
  letter-spacing: .035px;
  color: #3f9c86;
  justify-self: end;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.uber-eats-pickup-time-selector-widget .pickup-time-options > .uber-eats-recommended-time > .continue-button {
  color: #fff;
  letter-spacing: .035px;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 4px;
  justify-content: center;
  justify-self: end;
  align-items: center;
  padding: 9.5px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.uber-eats-pickup-time-selector-widget .pickup-time-options > .schedule-another-pickup-time {
  border-radius: 6px;
  justify-content: space-between;
  box-shadow: 0 4px 6px #31303e26, 0 0 5px #31303e26;
}

.uber-eats-pickup-time-selector-widget .pickup-time-options > .schedule-another-pickup-time > .title {
  letter-spacing: .09px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.uber-eats-pickup-time-selector-widget .pickup-time-options > .schedule-another-pickup-time > .continue-button {
  letter-spacing: .035px;
  color: #3f9c86;
  cursor: pointer;
  border: 1px solid #83d0be;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9.5px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.uber-eats-pickup-time-selector-widget .actions {
  margin-top: 18px;
}

.uber-eats-pickup-time-selector-widget .actions > .back-button {
  text-align: center;
  letter-spacing: .08px;
  color: #7d7e92;
  cursor: pointer;
  padding: 14px 28px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats-manage-order-widget {
  color: #1c1d3e;
  padding: 40px 32px 32px;
}

.uber-eats-manage-order-widget .title {
  text-align: center;
  letter-spacing: .36px;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
}

.uber-eats-manage-order-widget .actions {
  letter-spacing: .08px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats-manage-order-widget > .select-step {
  grid-auto-flow: row;
  row-gap: 40px;
  display: grid;
}

.uber-eats-manage-order-widget > .select-step .steps {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
  padding: 0 20px;
  display: grid;
}

.uber-eats-manage-order-widget > .select-step .steps .step {
  letter-spacing: .09px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  place-items: center;
  row-gap: 24px;
  padding: 40px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  display: grid;
  box-shadow: 0 0 24px #00000014;
}

.uber-eats-manage-order-widget > .select-step .actions {
  width: 100%;
  padding: 0 24px;
}

.uber-eats-manage-order-widget > .select-step .actions .cancel {
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  padding: 14px 32px;
}

.uber-eats-manage-order-widget > .delay-order {
  min-width: 780px;
  grid-auto-flow: row;
  row-gap: 40px;
  display: grid;
}

.uber-eats-manage-order-widget > .delay-order .eta-list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.uber-eats-manage-order-widget > .delay-order .eta-list .eta {
  text-align: center;
  letter-spacing: .035px;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  padding: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  position: relative;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.uber-eats-manage-order-widget > .delay-order .eta-list .eta.selected {
  color: #fff;
  background: #1c1d3e;
}

.uber-eats-manage-order-widget > .delay-order .eta-list .eta .check-icon {
  position: absolute;
  top: -6px;
  right: -6px;
}

.uber-eats-manage-order-widget > .delay-order .labels {
  row-gap: 16px;
  display: grid;
}

.uber-eats-manage-order-widget > .delay-order .labels > .info {
  color: #7d7e92;
  letter-spacing: .08px;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.uber-eats-manage-order-widget > .delay-order .labels > .info > img {
  margin-bottom: 2px;
  margin-right: 8px;
}

.uber-eats-manage-order-widget > .delay-order .labels > .pickup-time-labels {
  background-color: #f6f8fb;
  border-radius: 8px;
}

.uber-eats-manage-order-widget > .delay-order .labels > .pickup-time-labels .key-value-pair {
  letter-spacing: .09px;
  justify-content: space-between;
  padding: 12px 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  display: flex;
}

.uber-eats-manage-order-widget > .delay-order .labels > .pickup-time-labels .key-value-pair:first-child {
  border-bottom: 1px solid #dee0e6;
}

.uber-eats-manage-order-widget > .delay-order .labels > .pickup-time-labels .new-pickup-time {
  font-weight: 600;
}

.uber-eats-manage-order-widget > .delay-order .actions {
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 0 24px;
  display: grid;
}

.uber-eats-manage-order-widget > .delay-order .actions .back {
  text-align: center;
  color: #4c4e68;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  padding: 14px 32px;
}

.uber-eats-manage-order-widget > .delay-order .actions .confirm {
  text-align: center;
  color: #fff;
  background: #3f9c86;
  border-radius: 4px;
  padding: 14px 32px;
}

.uber-eats-manage-order-alert {
  z-index: 9999 !important;
}

.uber-eats-error-modal .order-display-id {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.invalid-items-widget {
  width: 100vw;
  height: 100vh;
  max-width: 573px;
  max-height: 590px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.invalid-items-widget > .header {
  text-align: center;
  margin: 24px 32px;
}

.invalid-items-widget > .header > .title {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.invalid-items-widget > .header > .description {
  color: #7d7e92;
  margin-top: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.invalid-items-widget > .main {
  flex-direction: column;
  flex-grow: 1;
  margin: 24px 32px;
  display: flex;
  overflow-y: auto;
}

.invalid-items-widget > .main > .items {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  flex-grow: 1;
  margin-top: 8px;
  padding: 12px;
  overflow-y: auto;
}

.invalid-items-widget > .main > .items > :not(:first-child) {
  margin-top: 24px;
}

.invalid-items-widget > .actions {
  grid-template-areas: "go-back-button accept-order-button"
                       "reject-order-button reject-order-button";
  gap: 12px;
  margin: 24px 32px;
  display: grid;
}

.invalid-items-widget > .actions > .go-back-button {
  width: 100%;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  grid-area: go-back-button;
  padding: 14px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.invalid-items-widget > .actions > .accept-order-button {
  width: 100%;
  color: #fff;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
  border-radius: 4px;
  grid-area: accept-order-button;
  padding: 14px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.invalid-items-widget > .actions > .reject-order-button {
  width: 100%;
  color: #9f1239;
  background-color: #0000;
  border: none;
  border-radius: 4px;
  grid-area: reject-order-button;
  padding: 14px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: underline;
}

.new-order-notification-widget {
  color: #fff;
  background-color: #f43f5e;
  left: -100px;
}

.new-order-notification-widget .link {
  margin: 0;
  padding: 0;
}

.new-order-notification-widget .count {
  padding-right: 12px;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}

.failed-order-notification-widget {
  color: #fff;
  background-color: #ed7301;
  left: -166px;
}

.failed-order-notification-widget .link {
  margin: 0;
  padding: 0;
}

.failed-order-notification-widget .failed-delivery-warning-icon {
  padding: 16px 18px 16px 23px;
}

.failed-order-notification-widget .count {
  padding-right: 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}

.cancelled-order-notification-widget {
  color: #fff;
  background-color: #ed7301;
  left: -174px;
}

.cancelled-order-notification-widget .link {
  margin: 0;
  padding: 0;
}

.cancelled-order-notification-widget .cancelled-order-notification-warning-icon {
  padding: 16px 18px 16px 23px;
}

.cancelled-order-notification-widget .count {
  padding-right: 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}

.troubleshooting-options-widget {
  color: #1c1d3e;
  gap: 32px;
  margin: 0 auto;
  padding: 40px 64px 32px;
  display: grid;
}

.troubleshooting-options-widget .title {
  text-align: center;
  letter-spacing: .36px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
}

.troubleshooting-options-widget .actions {
  padding: 0 24px;
}

.troubleshooting-options-widget .actions button.cancel, .troubleshooting-options-widget .actions button.back {
  width: 100%;
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.troubleshooting-options-widget .header {
  gap: 12px;
  display: grid;
}

.troubleshooting-options-widget .header .title {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
}

.troubleshooting-options-widget .header .subtitle {
  text-align: center;
  color: #4c4e68;
  max-width: 60vw;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.troubleshooting-options-widget .header .subtitle span {
  color: #3f9c86;
}

.troubleshooting-options-widget .troubleshooting-options {
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  gap: 24px;
  margin: 0 32px;
  display: grid;
}

.troubleshooting-options-widget .troubleshooting-options .option {
  letter-spacing: .09px;
  text-align: center;
  cursor: pointer;
  grid-gap: 24px;
  background: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 40px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  display: grid;
  box-shadow: 0 0 24px #00000014;
}

.troubleshooting-options-widget .troubleshooting-options .option:hover {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  outline: 2px solid #4f506a;
}

.troubleshooting-options-widget .troubleshooting-options .option > .icon {
  place-self: center;
}

.troubleshooting-options-widget .troubleshooting-options .option .icon {
  height: 48px;
  width: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.troubleshooting-options-widget .troubleshooting-options .option .icon > img {
  height: 100%;
}

.troubleshooting-options-widget .back-button {
  width: 100%;
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.restart-instructions-widget {
  gap: 24px;
  padding: 32px 24px;
  display: grid;
}

.restart-instructions-widget .header {
  color: #1c1d3e;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.restart-instructions-widget .instructions {
  max-width: 40vw;
  color: #4c4e68;
  gap: 20px;
  padding-left: 32px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.anz-worldline-payment-summary-widget > .header {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.anz-worldline-payment-summary-widget > .header > .label {
  color: #1c1d3e;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

.anz-worldline-payment-summary-widget > .header > .icon {
  height: 16px;
  width: 16px;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  display: flex;
}

.anz-worldline-payment-summary-widget > .header > .icon > img {
  height: 100%;
}

.anz-worldline-payment-summary-widget > .payment-summary > .payment {
  color: #1c1d3e;
  border-top: 1px solid #dee0e6;
  grid-template-columns: 1fr auto;
  grid-template-areas: "transaction-label amount"
                       "transaction-reference ."
                       "surcharge-label surcharge-amount"
                       "tip-label tip-amount"
                       "items items";
  place-items: center;
  gap: 0 8px;
  margin-top: 12px;
  padding-top: 12px;
  display: grid;
}

.anz-worldline-payment-summary-widget > .payment-summary > .payment:first-child {
  border-top: 0;
  padding-top: 0;
}

.anz-worldline-payment-summary-widget > .payment-summary > .payment > .transaction-label {
  color: #1c1d3e;
  grid-area: transaction-label;
  justify-self: start;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.anz-worldline-payment-summary-widget > .payment-summary > .payment > .transaction-label > .bullet {
  color: #4c4e68;
  margin: 0 8px;
}

.anz-worldline-payment-summary-widget > .payment-summary > .payment > .amount {
  grid-area: amount;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-payment-summary-widget > .payment-summary > .payment > .amount.refund {
  color: #9f1239;
}

.anz-worldline-payment-summary-widget > .payment-summary > .payment > .transaction-reference {
  color: #7d7e92;
  grid-area: transaction-reference;
  justify-self: start;
}

.anz-worldline-payment-summary-widget > .payment-summary > .payment > .surcharge {
  color: #7d7e92;
  display: contents;
}

.anz-worldline-payment-summary-widget > .payment-summary > .payment > .surcharge > .label {
  grid-area: surcharge-label;
  justify-self: start;
}

.anz-worldline-payment-summary-widget > .payment-summary > .payment > .surcharge > .amount {
  grid-area: surcharge-amount;
  justify-self: end;
}

.anz-worldline-payment-summary-widget > .payment-summary > .payment > .tip {
  color: #7d7e92;
  display: contents;
}

.anz-worldline-payment-summary-widget > .payment-summary > .payment > .tip > .label {
  grid-area: tip-label;
  justify-self: start;
}

.anz-worldline-payment-summary-widget > .payment-summary > .payment > .tip > .amount {
  grid-area: tip-amount;
  justify-self: end;
}

.anz-worldline-payment-summary-widget > .refund-summary > .payment {
  color: #1c1d3e;
  border-top: 1px solid #dee0e6;
  grid-template-columns: 1fr auto;
  grid-template-areas: "transaction-label amount"
                       "transaction-reference ."
                       "surcharge-label surcharge-amount"
                       "tip-label tip-amount"
                       "items items";
  place-items: center;
  gap: 0 8px;
  margin-top: 12px;
  padding-top: 12px;
  display: grid;
}

.anz-worldline-payment-summary-widget > .refund-summary > .payment:first-child {
  border-top: 0;
  padding-top: 0;
}

.anz-worldline-payment-summary-widget > .refund-summary > .payment > .transaction-label {
  color: #1c1d3e;
  grid-area: transaction-label;
  justify-self: start;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.anz-worldline-payment-summary-widget > .refund-summary > .payment > .transaction-label > .bullet {
  color: #4c4e68;
  margin: 0 8px;
}

.anz-worldline-payment-summary-widget > .refund-summary > .payment > .amount {
  grid-area: amount;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-payment-summary-widget > .refund-summary > .payment > .amount.refund {
  color: #9f1239;
}

.anz-worldline-payment-summary-widget > .refund-summary > .payment > .transaction-reference {
  color: #7d7e92;
  grid-area: transaction-reference;
  justify-self: start;
}

.anz-worldline-payment-summary-widget > .refund-summary > .payment > .surcharge {
  color: #7d7e92;
  display: contents;
}

.anz-worldline-payment-summary-widget > .refund-summary > .payment > .surcharge > .label {
  grid-area: surcharge-label;
  justify-self: start;
}

.anz-worldline-payment-summary-widget > .refund-summary > .payment > .surcharge > .amount {
  grid-area: surcharge-amount;
  justify-self: end;
}

.anz-worldline-payment-summary-widget > .refund-summary > .payment > .tip {
  color: #7d7e92;
  display: contents;
}

.anz-worldline-payment-summary-widget > .refund-summary > .payment > .tip > .label {
  grid-area: tip-label;
  justify-self: start;
}

.anz-worldline-payment-summary-widget > .refund-summary > .payment > .tip > .amount {
  grid-area: tip-amount;
  justify-self: end;
}

.anz-worldline-payment-summary-widget > .refund-summary > .refund {
  color: #1c1d3e;
  border-top: 1px solid #dee0e6;
  grid-template-columns: 1fr auto;
  grid-template-areas: "transaction-label amount"
                       "transaction-reference ."
                       "surcharge-label surcharge-amount"
                       "tip-label tip-amount"
                       "items items";
  place-items: center;
  gap: 0 8px;
  margin-top: 12px;
  padding-top: 12px;
  display: grid;
}

.anz-worldline-payment-summary-widget > .refund-summary > .refund:first-child {
  border-top: 0;
  padding-top: 0;
}

.anz-worldline-payment-summary-widget > .refund-summary > .refund > .transaction-label {
  color: #1c1d3e;
  grid-area: transaction-label;
  justify-self: start;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.anz-worldline-payment-summary-widget > .refund-summary > .refund > .transaction-label > .bullet {
  color: #4c4e68;
  margin: 0 8px;
}

.anz-worldline-payment-summary-widget > .refund-summary > .refund > .amount {
  grid-area: amount;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-payment-summary-widget > .refund-summary > .refund > .amount.refund {
  color: #9f1239;
}

.anz-worldline-payment-summary-widget > .refund-summary > .refund > .transaction-reference {
  color: #7d7e92;
  grid-area: transaction-reference;
  justify-self: start;
}

.anz-worldline-payment-summary-widget > .refund-summary > .refund > .surcharge {
  color: #7d7e92;
  display: contents;
}

.anz-worldline-payment-summary-widget > .refund-summary > .refund > .surcharge > .label {
  grid-area: surcharge-label;
  justify-self: start;
}

.anz-worldline-payment-summary-widget > .refund-summary > .refund > .surcharge > .amount {
  grid-area: surcharge-amount;
  justify-self: end;
}

.anz-worldline-payment-summary-widget > .refund-summary > .refund > .tip {
  color: #7d7e92;
  display: contents;
}

.anz-worldline-payment-summary-widget > .refund-summary > .refund > .tip > .label {
  grid-area: tip-label;
  justify-self: start;
}

.anz-worldline-payment-summary-widget > .refund-summary > .refund > .tip > .amount {
  grid-area: tip-amount;
  justify-self: end;
}

.anz-worldline-payment-summary-widget > .refund-summary > .refund > .amount {
  color: #9f1239;
}

.anz-worldline-payment-summary-widget > .refund-summary > .refund > .amount:before {
  content: "-";
}

.help-instructions-widget {
  background: #fafafa;
  border-radius: 12px;
  gap: 12px;
  padding: 24px;
  display: grid;
}

.help-instructions-widget > .header {
  align-items: center;
  font-size: 14px;
  display: flex;
}

.help-instructions-widget > .header > .title {
  margin-left: 8px;
}

.help-instructions-widget > .header > .icon {
  height: 16px;
}

.help-instructions-widget .body {
  gap: 24px;
  display: grid;
}

.help-instructions-widget .body .help-instruction-widget > .title {
  cursor: pointer;
  letter-spacing: 0;
  color: #1c1d3e;
  background: url("icon-chevron-down-grey.b17677ff.svg") 100% no-repeat;
  padding-right: 25px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.help-instructions-widget .body .help-instruction-widget.open > .title {
  background: url("icon-chevron-up-grey.924dbfb6.svg") 100% no-repeat;
}

.help-instructions-widget .body .help-instruction-widget ol {
  padding-top: 17.5px;
  padding-left: 16px;
}

.help-instructions-widget .body .help-instruction-widget:not(:last-child) ol {
  margin-bottom: 17.5px;
}

.anz-worldline-settings-widget {
  gap: 32px;
  display: grid;
}

.anz-worldline-settings-widget .terminal-connection-settings > .header .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.anz-worldline-settings-widget .terminal-connection-settings > .header .description {
  color: #4c4e68;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.anz-worldline-settings-widget .terminal-connection-settings > .body {
  grid-template-columns: 1fr 1fr;
  margin: 32px 0;
  display: grid;
}

.anz-worldline-settings-widget .terminal-connection-settings > .body form {
  margin-right: 64px;
}

.anz-worldline-settings-widget .terminal-connection-settings > .body form .form-items {
  display: unset;
}

.anz-worldline-settings-widget .terminal-connection-settings > .body form .form-items .form-item label {
  color: #7d7e92;
  margin: 0;
  position: absolute;
  top: 8px;
  left: 12px;
}

.anz-worldline-settings-widget .terminal-connection-settings > .body form .form-items .form-item .form-input input {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 31px 0 8px 12px;
}

.anz-worldline-settings-widget .terminal-connection-settings > .body form .form-items .form-item .form-input input .invalid {
  border: 1px solid #9f1239 !important;
}

.anz-worldline-settings-widget .terminal-connection-settings > .body form .form-items .form-item .meta .error {
  color: #9f1239;
  font-size: 14px;
}

.anz-worldline-settings-widget .terminal-connection-settings > .body form .form-items .form-item ::placeholder {
  color: #c6c7d1;
}

.anz-worldline-settings-widget .terminal-connection-settings > .body form .form-items .meta {
  color: #7d7e92;
  margin: 8px 0 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.anz-worldline-settings-widget .terminal-connection-settings > .body form .form-buttons {
  margin-top: 16px;
}

.anz-worldline-settings-widget .terminal-connection-settings > .body form .form-buttons button {
  border-radius: 4px;
}

.anz-worldline-settings-widget .terminal-connection-settings > .body form .info {
  border: 1px solid #dee0e6;
  border-radius: 10px;
  padding: 20px 24px;
}

.anz-worldline-settings-widget .terminal-connection-settings > .body form .info .key-value-pair {
  color: #1c1d3e;
  grid-template-columns: 154px max-content;
  gap: 4px;
  display: grid;
}

.anz-worldline-settings-widget .terminal-connection-settings > .body form .info .key-value-pair.disconnected {
  color: #adafbc;
}

.anz-worldline-settings-widget .terminal-connection-settings > .body form .info .key-value-pair.connected {
  color: #3f9c86;
}

.anz-worldline-settings-widget .terminal-connection-settings > .body form .info .key-value-pair .label {
  color: #7d7e92;
}

.anz-worldline-settings-widget .transaction-settings > .header .title {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.anz-worldline-settings-widget .transaction-settings > .header .description {
  color: #4c4e68;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.anz-worldline-settings-widget .transaction-settings > .body .setting {
  border: 1px solid #dee0e6;
  border-radius: 10px;
  grid-template-columns: 1fr min-content;
  grid-template-areas: "label action"
                       "description action"
                       "meta action";
  margin-bottom: 12px;
  padding: 20px 24px;
  display: grid;
}

.anz-worldline-settings-widget .transaction-settings > .body .setting > .label {
  color: #1c1d3e;
  grid-area: label;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-settings-widget .transaction-settings > .body .setting .description {
  color: #4c4e68;
  grid-area: description;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.anz-worldline-settings-widget .transaction-settings > .body .setting .meta {
  color: #7d7e92;
  grid-area: meta;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.anz-worldline-settings-widget .transaction-settings > .body .setting .action {
  grid-area: action;
  align-items: center;
  display: flex;
}

.anz-worldline-settings-widget .transaction-settings > .body .setting .action button {
  color: #7d7e92;
  background-color: unset;
  display: unset;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.anz-worldline-request-progress-widget {
  max-width: 573px;
  width: 40vw;
  justify-items: center;
  gap: 16px;
  padding: 32px 24px;
  display: grid;
}

.anz-worldline-request-progress-widget .icon {
  height: 64px;
  width: 64px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.anz-worldline-request-progress-widget .icon > img {
  height: 100%;
}

.anz-worldline-request-progress-widget .title {
  letter-spacing: .005em;
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-request-progress-widget .subtitle {
  letter-spacing: .005em;
  color: #4c4e68;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.anz-worldline-request-progress-widget .subtitle span {
  color: #3f9c86;
}

.anz-worldline-request-progress-widget button {
  width: 100%;
  color: #fff;
  background-color: #3f9c86;
  border-radius: 4px;
}

.updated-cart-items-confirmation-widget {
  height: max-content;
  max-height: 80vh;
  min-width: 75vw;
  max-width: 92vw;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-template-rows: repeat(2, min-content) 1fr min-content;
  padding: 40px 32px 0;
  display: grid;
  overflow: scroll;
}

.updated-cart-items-confirmation-widget::-webkit-scrollbar {
  width: 1px;
}

.updated-cart-items-confirmation-widget::-webkit-scrollbar-track {
  background: none;
}

.updated-cart-items-confirmation-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.updated-cart-items-confirmation-widget.multiple-updates {
  height: 80vh;
}

.updated-cart-items-confirmation-widget .header {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 40px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.updated-cart-items-confirmation-widget .updated-cart-items {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  max-height: 50vh;
  overflow: scroll;
}

.updated-cart-items-confirmation-widget .updated-cart-items::-webkit-scrollbar {
  width: 1px;
}

.updated-cart-items-confirmation-widget .updated-cart-items::-webkit-scrollbar-track {
  background: none;
}

.updated-cart-items-confirmation-widget .updated-cart-items::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.updated-cart-items-confirmation-widget .updated-cart-items .description {
  color: #4c4e68;
  margin-bottom: 12px;
  font-size: 16px;
}

.updated-cart-items-confirmation-widget .updated-cart-items .gst-inclusive-items, .updated-cart-items-confirmation-widget .updated-cart-items .gst-free-items, .updated-cart-items-confirmation-widget .updated-cart-items .sold-out-items {
  border: 1px solid #dee0e6;
  border-radius: 12px;
  margin-top: 8px;
  padding: 12px;
}

.updated-cart-items-confirmation-widget .updated-cart-items .gst-inclusive-items .header, .updated-cart-items-confirmation-widget .updated-cart-items .gst-free-items .header, .updated-cart-items-confirmation-widget .updated-cart-items .sold-out-items .header {
  margin-bottom: 0;
  display: flex;
}

.updated-cart-items-confirmation-widget .updated-cart-items .gst-inclusive-items .header .icon, .updated-cart-items-confirmation-widget .updated-cart-items .gst-free-items .header .icon, .updated-cart-items-confirmation-widget .updated-cart-items .sold-out-items .header .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.updated-cart-items-confirmation-widget .updated-cart-items .gst-inclusive-items .header .icon > img, .updated-cart-items-confirmation-widget .updated-cart-items .gst-free-items .header .icon > img, .updated-cart-items-confirmation-widget .updated-cart-items .sold-out-items .header .icon > img {
  height: 100%;
}

.updated-cart-items-confirmation-widget .updated-cart-items .gst-inclusive-items .header .title, .updated-cart-items-confirmation-widget .updated-cart-items .gst-free-items .header .title, .updated-cart-items-confirmation-widget .updated-cart-items .sold-out-items .header .title {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.updated-cart-items-confirmation-widget .updated-cart-items .gst-inclusive-items .header .chevron, .updated-cart-items-confirmation-widget .updated-cart-items .gst-free-items .header .chevron, .updated-cart-items-confirmation-widget .updated-cart-items .sold-out-items .header .chevron {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.updated-cart-items-confirmation-widget .updated-cart-items .gst-inclusive-items .header .chevron > img, .updated-cart-items-confirmation-widget .updated-cart-items .gst-free-items .header .chevron > img, .updated-cart-items-confirmation-widget .updated-cart-items .sold-out-items .header .chevron > img {
  height: 100%;
}

.updated-cart-items-confirmation-widget .updated-cart-items .gst-inclusive-items .items, .updated-cart-items-confirmation-widget .updated-cart-items .gst-free-items .items, .updated-cart-items-confirmation-widget .updated-cart-items .sold-out-items .items {
  color: #4c4e68;
  margin-top: 8px;
  padding-left: 20px;
  line-height: 24px;
  display: none;
}

.updated-cart-items-confirmation-widget .updated-cart-items .gst-inclusive-items .items.visible, .updated-cart-items-confirmation-widget .updated-cart-items .gst-free-items .items.visible, .updated-cart-items-confirmation-widget .updated-cart-items .sold-out-items .items.visible {
  display: block;
}

.updated-cart-items-confirmation-widget .actions {
  height: -moz-fit-content;
  height: fit-content;
  align-self: self-end;
  padding: 32px 24px;
  display: flex;
}

.updated-cart-items-confirmation-widget .actions > button {
  flex: 1 1 0;
}

.updated-cart-items-confirmation-widget .actions .proceed-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.updated-cart-items-confirmation-widget .actions .proceed-button:focus {
  border: none;
  outline: none;
}

.updated-cart-items-confirmation-widget .actions .cancel-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 5px;
  margin: 0 15px 0 0;
  padding: 14px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
}

.updated-cart-items-confirmation-widget .actions .cancel-button:focus {
  border: 1px solid #7d7e92;
  outline: none;
}

.pos-home-quick-actions-colors-widget .form-items {
  flex-wrap: wrap;
  padding: 12px;
  display: flex;
  overflow: hidden;
}

.pos-home-quick-actions-colors-widget .form-item {
  margin-left: 24px;
}

.pos-home-quick-actions-colors-widget .form-item .form-input .header {
  background: linear-gradient(#f2f7fd 0%, #fff 69.29%), #fff;
  flex-direction: column;
  align-items: center;
}

.pos-home-quick-actions-colors-widget .form-item .form-input .header .label {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
}

.pos-home-quick-actions-colors-widget .form-item .form-input .color {
  box-sizing: border-box;
}

.alert-widget {
  text-align: center;
  width: 50vw;
  min-width: 357px;
  max-width: 573px;
  box-sizing: border-box;
  z-index: 1001;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  display: flex;
}

.alert-widget > .icon {
  width: 64px;
}

.alert-widget > .title {
  color: #1c1d3e;
  margin-top: 16px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.alert-widget > .content {
  color: #4c4e68;
  margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
}

.alert-widget > .actions {
  width: 100%;
  height: min-content;
  margin-top: 24px;
}

.alert-widget > .actions button {
  color: #fff;
  width: inherit;
  background-color: #3f9c86;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
}

.alert-widget a {
  color: #3f9c86;
  text-decoration: none;
}

.alert-widget button {
  padding: 14px 32px;
}

.alert-widget.retry, .alert-widget.confirmation {
  width: 40vw;
  min-width: 573px;
}

.alert-widget.retry > .actions, .alert-widget.confirmation > .actions {
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  display: grid;
}

.alert-widget.retry > .actions .cancel-button, .alert-widget.confirmation > .actions .cancel-button {
  color: #7d7e92;
  background: none;
  border: 1px solid #7d7e92;
}

.alert-widget.success .icon {
  width: unset;
}

.alert-widget.warning, .alert-widget.info {
  max-width: 357px;
}

.alert-widget.warning > .title, .alert-widget.info > .title {
  font-size: 18px;
}

.alert-widget.warning > .actions, .alert-widget.info > .actions {
  gap: 16px;
  display: grid;
}

.alert-widget.warning > .actions .cancel-button, .alert-widget.info > .actions .cancel-button {
  color: #7d7e92;
  background: none;
  border: 1px solid #7d7e92;
}

.alert-widget.warning > .actions .proceed-button, .alert-widget.info > .actions .proceed-button {
  color: #fff;
  background-color: #9f1239;
}

.anz-worldline-incomplete-transaction-banner-widget {
  z-index: 998;
  width: 100vw;
  background-color: #ffe4e6;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px 32px 7.5px 102px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 4px 24px #00000026;
}

.anz-worldline-incomplete-transaction-banner-widget > .text {
  display: inherit;
  align-items: inherit;
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.anz-worldline-incomplete-transaction-banner-widget > .text > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.anz-worldline-incomplete-transaction-banner-widget > .text > .icon > img {
  height: 100%;
}

.anz-worldline-incomplete-transaction-banner-widget > .text > .highlight {
  display: inherit;
  align-items: inherit;
  margin-right: 8px;
  font-weight: 500;
}

.anz-worldline-incomplete-transaction-banner-widget > .actions {
  justify-content: center;
  align-items: center;
  display: flex;
}

.anz-worldline-incomplete-transaction-banner-widget > .actions > * {
  margin-left: 12px;
}

.anz-worldline-incomplete-transaction-banner-widget > .actions > :first-child {
  margin-left: 0;
}

.anz-worldline-incomplete-transaction-banner-widget > .actions .refresh-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0;
  color: #1c1d3e;
  color: #fff;
  background-color: #3f9c86;
  border-radius: 4px;
  margin: 0;
  padding: 7px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.anz-worldline-incomplete-transaction-banner-widget > .actions .refresh-button:focus {
  border: none;
  outline: none;
}

.anz-worldline-incomplete-transaction-banner-widget > .actions .later-button {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #9f1239;
  background-color: #0000;
  border: 1px solid #d97706;
  border-radius: 4px;
  padding: 7px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.anz-worldline-incomplete-transaction-banner-widget > .actions > .continue-transaction-button, .anz-worldline-incomplete-transaction-banner-widget > .actions .void-transaction-button {
  cursor: pointer;
  border-radius: 4px;
  padding: 7px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.anz-worldline-incomplete-transaction-banner-widget > .actions > .continue-transaction-button {
  color: #fff;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.anz-worldline-incomplete-transaction-banner-widget > .actions > .void-transaction-button {
  color: #9f1239;
  background-color: #0000;
  border: 1px solid #f43f5e;
}

.anz-worldline-incomplete-transaction-banner-widget > .actions > .void-transaction-button.transaction-failed {
  color: #fff;
  background-color: #9f1239;
  border: none;
}

.anz-worldline-incomplete-transaction-banner-widget > .actions > .go-online-button {
  border: 1px solid #0000;
}

.anz-worldline-incomplete-transaction-banner-widget > .actions > .dismiss-button {
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
}

.anz-worldline-incomplete-transaction-banner-widget > .actions > .enable-audio-button {
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.anz-worldline-incomplete-transaction-banner-widget > .text > .highlight {
  color: #9f1239;
}

.anz-worldline-incomplete-transaction-banner-widget > .actions > .view-status-button {
  cursor: pointer;
  white-space: nowrap;
  color: #fff;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
  border-radius: 4px;
  padding: 7px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.swal2-container .anz-worldline-incomplete-transaction-banner-widget {
  max-width: 375px;
  width: 100% !important;
  padding: 24px 0 !important;
}

.swal2-container .anz-worldline-incomplete-transaction-banner-widget .swal2-header {
  padding: 0 24px;
}

.swal2-container .anz-worldline-incomplete-transaction-banner-widget .swal2-header .swal2-title {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.swal2-container .anz-worldline-incomplete-transaction-banner-widget .swal2-content {
  color: #7d7e92;
  margin-top: 12px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.swal2-container .anz-worldline-incomplete-transaction-banner-widget .swal2-actions {
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  padding: 0 24px;
}

.swal2-container .anz-worldline-incomplete-transaction-banner-widget .swal2-actions .confirm-button {
  width: 100%;
  text-transform: capitalize;
  background-color: #9f1239;
  border-radius: 4px;
  margin: 0;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container .anz-worldline-incomplete-transaction-banner-widget .swal2-actions .cancel-button {
  width: 100%;
  color: #4c4e68;
  text-transform: capitalize;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  background-color: #fff !important;
}

.anz-worldline-refund-widget {
  width: calc(100vw - 32px);
  max-width: 844px;
  padding: 32px 24px;
}

.anz-worldline-refund-widget > .header {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
}

.anz-worldline-refund-widget > .refund-amount {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.anz-worldline-refund-widget > .refund-amount > .amount {
  color: #3f9c86;
}

.anz-worldline-refund-widget > .payments {
  background-color: #f6f8fb;
  border-radius: 8px;
  margin-top: 16px;
}

.anz-worldline-refund-widget > .payments.partial-refund {
  margin-top: 8px;
}

.anz-worldline-refund-widget > .payments > :not(:first-child) {
  border-top: 1px solid #dee0e6;
}

.anz-worldline-refund-widget > .payments > .payment {
  grid-template-columns: max-content 1fr max-content;
  grid-template-areas: "radio label amount"
                       ". transaction-reference transaction-reference";
  align-items: center;
  padding: 16px 20px;
  display: grid;
}

.anz-worldline-refund-widget > .payments > .payment:not(:first-child) {
  border-top: 1px solid #dee0e6;
}

.anz-worldline-refund-widget > .payments > .payment.disabled {
  color: #7d7e92;
  cursor: not-allowed;
}

.anz-worldline-refund-widget > .payments > .payment > .radio {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: 1.2px solid #dee0e6;
  border-radius: 50%;
  grid-area: radio;
  margin-right: 14px;
}

.anz-worldline-refund-widget > .payments > .payment > .radio:checked {
  border: 5px solid #4cbba1;
}

.anz-worldline-refund-widget > .payments > .payment > .label {
  grid-area: label;
  align-items: center;
  display: flex;
}

.anz-worldline-refund-widget > .payments > .payment > .label > .payment-order {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-refund-widget > .payments > .payment > .label > .bullet {
  margin: 0 8px;
}

.anz-worldline-refund-widget > .payments > .payment > .label > .payment-type {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.anz-worldline-refund-widget > .payments > .payment > .amount {
  grid-area: amount;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-refund-widget > .payments > .payment > .amount.refund {
  color: #9f1239;
}

.anz-worldline-refund-widget > .payments > .payment > .transaction-reference {
  letter-spacing: .035px;
  color: #7d7e92;
  grid-area: transaction-reference;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.anz-worldline-refund-widget > .actions {
  margin-top: 24px;
  display: flex;
}

.anz-worldline-refund-widget > .actions > :not(:first-child) {
  margin-left: 16px;
}

.anz-worldline-refund-widget > .actions > .back-button {
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  padding: 14px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-refund-widget > .actions > .refund-button {
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  color: #fff;
  background-color: #3f9c86;
  border-radius: 4px;
  padding: 14px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-refund-widget > .actions > .refund-button.disabled {
  color: #83d0be;
  cursor: not-allowed;
}

.anz-worldline-order-split-bill-refund-widget {
  width: calc(100vw - 32px);
  max-width: 844px;
  padding: 32px 24px;
}

.anz-worldline-order-split-bill-refund-widget > .header {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments {
  background-color: #f6f8fb;
  border-radius: 8px;
  margin-top: 16px;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments.partial-refund {
  margin-top: 8px;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > :not(:first-child) {
  border-top: 1px solid #dee0e6;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment {
  grid-template-columns: max-content 1fr max-content;
  grid-template-areas: "radio label amount"
                       ". transaction-reference transaction-reference"
                       ". surcharge-label surcharge-amount"
                       ". items items";
  align-items: center;
  padding: 16px 20px;
  display: grid;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment:not(:first-child) {
  border-top: 1px solid #dee0e6;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment.disabled {
  color: #7d7e92;
  cursor: not-allowed;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .radio {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: 1.2px solid #dee0e6;
  border-radius: 50%;
  grid-area: radio;
  margin-right: 14px;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .radio:checked {
  border: 5px solid #4cbba1;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .label {
  grid-area: label;
  align-items: center;
  display: flex;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .label > .payment-order {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .label > .bullet {
  margin: 0 8px;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .label > .payment-type {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .amount {
  grid-area: amount;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .amount.refund {
  color: #9f1239;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .transaction-reference {
  letter-spacing: .035px;
  color: #7d7e92;
  grid-area: transaction-reference;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .surcharge {
  letter-spacing: .035px;
  color: #7d7e92;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  display: contents;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .surcharge > .label {
  grid-area: surcharge-label;
  justify-self: start;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .surcharge > .amount {
  grid-area: surcharge-amount;
  justify-self: end;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .split-bill-items {
  color: #7d7e92;
  grid-area: items;
  margin-top: 8px;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .split-bill-items > .split-bill-item {
  margin-left: 40px;
  display: list-item;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .split-bill-items > .split-bill-item > .header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .split-bill-items > .split-bill-item > .header > .name {
  color: #1c1d3e;
  letter-spacing: .08px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-order-split-bill-refund-widget > .split-payments > .split-payment > .split-bill-items > .split-bill-item > .header > .amount {
  letter-spacing: .035px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.anz-worldline-order-split-bill-refund-widget > .actions {
  margin-top: 24px;
  display: flex;
}

.anz-worldline-order-split-bill-refund-widget > .actions > :not(:first-child) {
  margin-left: 16px;
}

.anz-worldline-order-split-bill-refund-widget > .actions > .back-button {
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  padding: 14px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-order-split-bill-refund-widget > .actions > .refund-button {
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  color: #fff;
  background-color: #3f9c86;
  border-radius: 4px;
  padding: 14px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-order-split-bill-refund-widget > .actions > .refund-button.disabled {
  color: #83d0be;
  cursor: not-allowed;
}

.anz-worldline-ongoing-split-bill-refund-widget {
  width: calc(100vw - 32px);
  max-width: 844px;
  padding: 32px 24px;
}

.anz-worldline-ongoing-split-bill-refund-widget > .header {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments {
  background-color: #f6f8fb;
  border-radius: 8px;
  margin-top: 16px;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments.partial-refund {
  margin-top: 8px;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > :not(:first-child) {
  border-top: 1px solid #dee0e6;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment {
  grid-template-columns: max-content 1fr max-content;
  grid-template-areas: "radio label amount"
                       ". transaction-reference transaction-reference"
                       ". surcharge-label surcharge-amount"
                       ". items items";
  align-items: center;
  padding: 16px 20px;
  display: grid;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment:not(:first-child) {
  border-top: 1px solid #dee0e6;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment.disabled {
  color: #7d7e92;
  cursor: not-allowed;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .radio {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: 1.2px solid #dee0e6;
  border-radius: 50%;
  grid-area: radio;
  margin-right: 14px;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .radio:checked {
  border: 5px solid #4cbba1;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .label {
  grid-area: label;
  align-items: center;
  display: flex;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .label > .payment-order {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .label > .bullet {
  margin: 0 8px;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .label > .payment-type {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .amount {
  grid-area: amount;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .amount.refund {
  color: #9f1239;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .transaction-reference {
  letter-spacing: .035px;
  color: #7d7e92;
  grid-area: transaction-reference;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .surcharge {
  letter-spacing: .035px;
  color: #7d7e92;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  display: contents;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .surcharge > .label {
  grid-area: surcharge-label;
  justify-self: start;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .surcharge > .amount {
  grid-area: surcharge-amount;
  justify-self: end;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .split-bill-items {
  color: #7d7e92;
  grid-area: items;
  margin-top: 8px;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .split-bill-items > .split-bill-item {
  margin-left: 40px;
  display: list-item;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .split-bill-items > .split-bill-item > .header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .split-bill-items > .split-bill-item > .header > .name {
  color: #1c1d3e;
  letter-spacing: .08px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-ongoing-split-bill-refund-widget > .split-payments > .split-payment > .split-bill-items > .split-bill-item > .header > .amount {
  letter-spacing: .035px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.anz-worldline-ongoing-split-bill-refund-widget > .actions {
  margin-top: 24px;
  display: flex;
}

.anz-worldline-ongoing-split-bill-refund-widget > .actions > :not(:first-child) {
  margin-left: 16px;
}

.anz-worldline-ongoing-split-bill-refund-widget > .actions > .refund-button {
  width: 100%;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 4px;
  padding: 14px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-ongoing-split-bill-refund-widget > .actions > .refund-button.disabled {
  color: #83d0be;
  cursor: not-allowed;
}

.marketing-campaign-widget {
  box-sizing: border-box;
  color: #1c1d3e;
  background-color: #f6f8fb;
  border-radius: 8px;
  grid-column: 1 / 3;
  padding: 12px 20px;
}

.marketing-campaign-widget > .info {
  align-items: center;
  display: flex;
  padding: unset !important;
}

.marketing-campaign-widget > .info img {
  margin-right: 8px;
}

.marketing-campaign-widget > .info .label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.marketing-campaign-widget .description {
  padding: 12px 0 0 32px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.third-party-deliveries-widget {
  grid-auto-rows: min-content min-content 1fr;
  padding: 0 24px;
  display: grid;
  overflow: hidden;
}

.third-party-deliveries-widget > .categories {
  margin-bottom: 24px;
  display: flex;
}

.third-party-deliveries-widget > .categories > .category {
  color: #343553;
  cursor: pointer;
  background: #f6f8fb;
  border-radius: 8px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.third-party-deliveries-widget > .categories > .category:first-child {
  margin-left: 0;
}

.third-party-deliveries-widget > .categories > .category:last-child {
  margin-right: 0;
}

.third-party-deliveries-widget > .categories > .category.failed {
  color: #9f1239;
  background: #fff1f2;
}

.third-party-deliveries-widget > .categories > .category.failed > .count {
  color: #fff;
  background: #9f1239;
}

.third-party-deliveries-widget > .categories > .category.active {
  color: #fff;
  background: #343553;
}

.third-party-deliveries-widget > .categories > .category.active > .count {
  color: #4c4e68;
  background: #e9e9ec;
}

.third-party-deliveries-widget > .categories > .category > .count {
  width: 21px;
  height: 21px;
  background: #e9e9ec;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
}

.third-party-deliveries-widget > .labels {
  color: #7d7e92;
  grid-template-columns: 2.5fr repeat(4, 1fr) 1.5fr;
  gap: 12px;
  margin-bottom: 10px;
  padding: 12px 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  display: grid;
}

.third-party-deliveries-widget > .labels.delivery-failed {
  grid-template-columns: 3fr repeat(3, 1fr) 1.5fr;
}

.third-party-completed-deliveries-widget {
  grid-auto-rows: min-content 1fr;
  padding: 0 24px;
  display: grid;
  overflow: hidden;
}

.third-party-completed-deliveries-widget > .labels {
  color: #7d7e92;
  grid-template-columns: 3fr repeat(3, 1fr) 1.5fr;
  gap: 12px;
  margin-bottom: 10px;
  padding: 12px 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  display: grid;
}

.order-delivery-info-list-widget {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  flex-direction: column;
  display: flex;
  overflow: auto;
}

.order-delivery-info-list-widget::-webkit-scrollbar {
  width: 1px;
}

.order-delivery-info-list-widget::-webkit-scrollbar-track {
  background: none;
}

.order-delivery-info-list-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.order-delivery-info-list-widget > div {
  border: 1px solid #dee0e6;
  border-radius: 8px;
}

.order-delivery-info-list-widget .no-orders {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 69px;
  display: flex;
}

.order-delivery-info-list-widget .no-orders .label {
  color: #4c4e68;
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.order-delivery-info-list-widget .list > div:last-child {
  border-bottom: none;
}

.order-delivery-info-list-item-widget {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #1c1d3e;
  cursor: pointer;
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: 2.5fr repeat(4, 1fr) 1.5fr;
  align-items: center;
  gap: 12px;
  padding: 16px 24px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: grid;
}

.order-delivery-info-list-item-widget.highlighted {
  background: #fffbeb80;
  border: 1px solid #ff7c00 !important;
}

.order-delivery-info-list-item-widget.highlighted + div {
  border-top: none;
}

.order-delivery-info-list-item-widget.delivery-complete, .order-delivery-info-list-item-widget.delivery-failed {
  grid-template-columns: 3fr repeat(3, 1fr) 1.5fr;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-customer-info .name {
  font-weight: 500;
  line-height: 24px;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-customer-info .address, .order-delivery-info-list-item-widget > .order-delivery-info-list-item-customer-info .mobile {
  color: #7d7e92;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-customer-info .mobile {
  font-weight: 500;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-grand-total {
  letter-spacing: .0025em;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-driver-status {
  width: max-content;
  border-radius: 100px;
  padding: 4px 12px;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-driver-status.confirmed, .order-delivery-info-list-item-widget > .order-delivery-info-list-item-driver-status.in-transit-to-pickup, .order-delivery-info-list-item-widget > .order-delivery-info-list-item-driver-status.completed {
  color: #047857;
  background: #ecfdf5;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-driver-status.arrived-for-pickup {
  color: #1e40af;
  background: #dbeafe;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-driver-status.out-for-delivery, .order-delivery-info-list-item-widget > .order-delivery-info-list-item-driver-status.arrived-at-dropoff {
  color: #b45309;
  background: #fef3c7;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-driver-status.failed {
  color: #9f1239;
  background: #ffe4e6;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-driver-eta {
  letter-spacing: .0025em;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-driver-info {
  grid-template-columns: max-content 1fr;
  grid-template-areas: "image name"
                       "image vehicle"
                       "image mobile";
  align-items: center;
  column-gap: 10px;
  display: grid;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-driver-info img {
  width: 52px;
  border-radius: 50%;
  grid-area: image;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-driver-info .name {
  grid-area: name;
  font-weight: 500;
  line-height: 24px;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-driver-info .vehicle-type {
  grid-area: vehicle;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-driver-info .mobile {
  grid-area: mobile;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-driver-info .vehicle-type, .order-delivery-info-list-item-widget > .order-delivery-info-list-item-driver-info .mobile {
  color: #7d7e92;
}

.order-delivery-info-list-item-widget > .order-delivery-info-list-item-date-completed {
  letter-spacing: .0025em;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.dine-in-widget {
  grid-template-rows: min-content 1fr;
  display: grid;
}

.dine-in-widget > .header {
  height: 80px;
  border: 1px solid #dee0e6;
  grid-template-columns: 1fr max-content max-content;
  align-items: center;
  gap: 24px;
  padding: 14px 24px;
  display: grid;
}

.dine-in-widget > .header .title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.dine-in-widget > .header .time {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.dine-in-widget > .header > .more-actions-button {
  cursor: pointer;
}

.dine-in-widget > .header > .more-actions-button .icon {
  align-items: center;
  display: grid;
}

.dine-in-widget > .header.edit-mode {
  color: #fff;
  background-color: #1e40af;
  border: none;
  grid-template-columns: 1fr max-content;
  position: relative;
}

.dine-in-widget > .header.edit-mode > .title {
  text-align: center;
}

.dine-in-widget > .header.edit-mode > .close-button {
  cursor: pointer;
  position: absolute;
  right: 24px;
}

.dine-in-widget > .table-map-widget {
  background-color: #f4f4f6;
}

.dine-in-widget > .actions {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

.dine-in-widget > .actions .start-order {
  cursor: pointer;
  background-color: #f6f8fb;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  padding: 9.5px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.table-map-widget {
  position: relative;
}

.table-map-widget > .actions-toolbar {
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  grid-template-columns: repeat(4, max-content);
  grid-auto-rows: 1fr;
  align-items: stretch;
  display: grid;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 8px #00000014;
}

.table-map-widget > .actions-toolbar .action {
  cursor: pointer;
  border-right: 1px solid #dee0e6;
  align-items: center;
  display: grid;
}

.table-map-widget > .actions-toolbar .action > .icon {
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  display: flex;
}

.table-map-widget > .actions-toolbar .action > button {
  color: #fff;
  background-color: #3f9c86;
  border-radius: 4px;
  margin: 8px 12px;
  padding: 9.5px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.table-map-widget > .actions-toolbar .action:last-child {
  border: none;
}

.table-map-widget > .actions-toolbar .zoom-control {
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  gap: 12px;
  padding: 0 16px;
  font-size: 14px;
  display: grid;
}

.table-map-widget > .actions-toolbar .zoom-control .icon {
  cursor: pointer;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.table-map-widget > .actions-toolbar .zoom-control .icon > img {
  height: 100%;
}

.table-map-table-edit-widget {
  width: max(500px, 30vw);
  display: grid;
}

.table-map-table-edit-widget .header {
  border-bottom: 1px solid #dee0e6;
  padding: 32px 32px 24px;
  position: relative;
}

.table-map-table-edit-widget .header > .title {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.table-map-table-edit-widget .header > .close-button {
  height: 28px;
  width: 28px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 32px;
  right: 32px;
}

.table-map-table-edit-widget .header > .close-button > img {
  height: 100%;
}

.table-map-table-edit-widget .content {
  gap: 10px;
  padding: 32px;
  display: grid;
}

.table-map-table-edit-widget .content .form-item {
  gap: 8px;
  display: grid;
}

.table-map-table-edit-widget .content .form-item > label, .table-map-table-edit-widget .content .form-item > .label {
  color: #7d7e92;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.table-map-table-edit-widget .content .form-item input {
  letter-spacing: .005em;
  color: #1c1d3e;
  width: 100%;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.table-map-table-edit-widget .content .form-item input:focus {
  outline: none;
}

.table-map-table-edit-widget .content .form-item input::placeholder {
  color: #65667d;
}

.table-map-table-edit-widget .content .form-item .meta {
  text-align: right;
  color: #7d7e92;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.table-map-table-edit-widget .content .seating-capacity-input .form-input {
  grid-template-columns: max-content 1fr max-content;
  grid-template-areas: "label label label"
                       "icon1 input icon2";
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: grid;
}

.table-map-table-edit-widget .content .seating-capacity-input .form-input .icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.table-map-table-edit-widget .content .seating-capacity-input .form-input .icon > img {
  height: 100%;
}

.table-map-table-edit-widget .content .seating-capacity-input .form-input .icon.disabled {
  opacity: .35;
}

.table-map-table-edit-widget .content .seating-capacity-input .form-input .minus {
  grid-area: icon1;
}

.table-map-table-edit-widget .content .seating-capacity-input .form-input .plus {
  grid-area: icon2;
}

.table-map-table-edit-widget .content .seating-capacity-input .form-input > .label {
  color: #7d7e92;
  grid-area: label;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.table-map-table-edit-widget .content .seating-capacity-input .form-input input {
  text-align: center;
  grid-area: input;
}

.table-map-table-edit-widget .content .seating-capacity-input .form-input input::-webkit-outer-spin-button, .table-map-table-edit-widget .content .seating-capacity-input .form-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.table-map-table-edit-widget .content .seating-capacity-input .form-input .meta {
  display: none;
}

.table-map-table-edit-widget .actions {
  cursor: pointer;
  background-color: #fafafa;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 32px;
  display: flex;
}

.table-map-table-edit-widget .actions .delete-button {
  color: #9f1239;
  padding: 9.5px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-decoration: underline;
}

.table-map-table-edit-widget .actions .save-button {
  color: #fff;
  background-color: #3f9c86;
  border-radius: 4px;
  padding: 9.5px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.popout-table-map-more-actions-widget {
  letter-spacing: 0;
  white-space: nowrap;
  margin-top: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.popout-table-map-more-actions-widget > .edit-tables-button {
  cursor: pointer;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 12px;
  display: grid;
}

.popout-table-map-more-actions-widget > .edit-tables-button > .icon {
  align-items: center;
  display: grid;
}

.popout-table-map-add-tables-widget {
  margin-top: -8px;
  padding: 0;
}

.popout-table-map-add-tables-widget .header {
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  display: flex;
}

.popout-table-map-add-tables-widget .header .title {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.popout-table-map-add-tables-widget .header .close-button {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.popout-table-map-add-tables-widget .header .close-button > img {
  height: 100%;
}

.popout-table-map-add-tables-widget .content .table {
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: max-content 1fr;
  align-items: center;
  gap: 20px;
  padding: 16px 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.popout-table-map-add-tables-widget .content .table .icon {
  height: 36px;
}

.popout-table-map-add-tables-widget .content .table:first-child {
  border-top: 1px solid #dee0e6;
}

.popout-table-map-add-tables-widget .content .table.isDragging {
  cursor: grab;
  background-color: #e9e9ec;
}

.popout-table-map-add-tables-widget .content .rectangle .icon {
  height: 32px;
}

.popout-table-map-add-tables-widget .info {
  grid-template-columns: max-content 1fr;
  align-items: center;
  gap: 4px;
  padding: 16px 24px;
  display: grid;
}

.popout-table-map-add-tables-widget .info .icon {
  height: 16px;
  width: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.popout-table-map-add-tables-widget .info .icon > img {
  height: 100%;
}

.popout-table-map-add-tables-widget .info .label {
  color: #7d7e92;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
}

.popout-zoom-options-widget {
  grid-template-columns: 110px;
  grid-auto-flow: row;
  margin-top: -24px;
  padding: 4px 0;
  display: grid;
}

.popout-zoom-options-widget .zoom-option {
  padding: 12px 16px;
}

.popout-zoom-options-widget .zoom-option.fit {
  border-bottom: 1px solid #dee0e6;
}

.customer-reviews-widget {
  flex-direction: column;
  margin-top: 7px;
  display: flex;
  overflow: auto;
}

.customer-reviews-widget .customer-reviews-list-info {
  margin-top: -122px;
  transition: margin .3s ease-out;
}

.customer-reviews-widget .customer-reviews-list-info.show-date-time-filter {
  margin-top: 0;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar {
  border-top: 1px solid #dee0e6;
  margin-bottom: 20px;
  padding: 24px 31px 2px;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .labels {
  color: #dee0e6;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .labels .total-reviews-spinner-container {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .labels .total-reviews-spinner-container .message {
  color: #4c4e68;
  padding-left: 8px;
  font-size: 14px;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .labels .total-reviews {
  color: #4c4e68;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .labels .rating {
  color: #1c1d3e;
  font-size: 16px;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .labels .sad-review-percentage, .customer-reviews-widget .customer-reviews-list-info .customer-review-bar .labels .happy-review-percentage {
  align-items: center;
  display: flex;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .labels .sad-review-percentage span, .customer-reviews-widget .customer-reviews-list-info .customer-review-bar .labels .happy-review-percentage span {
  font-weight: 600;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .labels .sad-review-percentage .divider, .customer-reviews-widget .customer-reviews-list-info .customer-review-bar .labels .happy-review-percentage .divider {
  width: 1px;
  height: 24px;
  background-color: #dee0e6;
  margin: 0 8px;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .labels .sad-review-percentage .percentage, .customer-reviews-widget .customer-reviews-list-info .customer-review-bar .labels .happy-review-percentage .percentage {
  font-weight: 600;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .labels .sad-review-percentage > .percentage {
  color: #3b82f6;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .labels .happy-review-percentage > .percentage {
  color: #92400e;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .bar {
  margin: 15px 0;
  display: flex;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .bar div {
  height: 8px;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .bar .sad-review-bar {
  background-color: #3b82f6;
  border-radius: 10px 0 0 10px;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .bar .sad-review-bar.full {
  border-radius: 10px;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .bar .sad-review-bar.no-reviews {
  background-color: #dee0e6;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .bar .happy-review-bar {
  background-color: #f59e0b;
  border-radius: 0 10px 10px 0;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .bar .happy-review-bar.full {
  border-radius: 10px;
}

.customer-reviews-widget .customer-reviews-list-info .customer-review-bar .bar .happy-review-bar.no-reviews {
  background-color: #dee0e6;
}

.customer-reviews-widget .customer-reviews-list-info .rating-selector {
  color: #7d7e92;
  margin-bottom: 5px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.customer-reviews-widget .customer-reviews-list-info .rating-selector > .rating {
  text-align: center;
  cursor: pointer;
  background-color: #f6f8fb;
  border-radius: 8px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  padding: 12px 14px;
  display: flex;
}

.customer-reviews-widget .customer-reviews-list-info .rating-selector > .rating:first-child {
  margin-left: 0;
}

.customer-reviews-widget .customer-reviews-list-info .rating-selector > .rating:last-child {
  margin-right: 0;
}

.customer-reviews-widget .customer-reviews-list-info .rating-selector > .rating.active {
  color: #fff;
  background-color: #343553;
}

.customer-reviews-widget .customer-reviews-list-info .rating-selector > .rating img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.customer-reviews-list-widget {
  flex-grow: 1;
  padding: 0 24px;
  position: relative;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-top: 24px;
  display: flex;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .info {
  width: 25%;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 16px;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .info .customer-info {
  border-bottom: 1px solid #dee0e6;
  margin-bottom: 12px;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .info .customer-info .name-and-phone-number {
  align-items: center;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .info .customer-info .name-and-phone-number .name {
  margin-right: 5px;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .info .customer-info .name-and-phone-number .phone-number {
  margin-left: 5px;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .info .customer-info .name-and-phone-number img {
  width: 11.67px;
  height: 18.33px;
  margin-left: 8.16px;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .info .customer-info .email {
  color: #4c4e68;
  margin-bottom: 8px;
  font-size: 14px;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .info .order-info > div {
  color: #1c1d3e;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .info .order-info > div > span {
  color: #4c4e68;
  margin-left: 8px;
  font-weight: 400;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .customer-feedback {
  width: 75%;
  grid-template-areas: "feedback feedback"
                       "additionalInfo action";
  padding: 16px 24px 16px 36px;
  display: grid;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .customer-feedback .feedback {
  max-height: 120px;
  grid-area: feedback;
  font-size: 16px;
  line-height: 24px;
  transition: max-height .3s ease-out;
  overflow: hidden;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .customer-feedback .feedback.expanded {
  max-height: 500px;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .customer-feedback .additional-info {
  grid-area: additionalInfo;
  align-items: center;
  margin-top: 14px;
  font-size: 12px;
  display: flex;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .customer-feedback .additional-info .date-created {
  color: #7d7e92;
  margin-right: 12px;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .customer-feedback .additional-info .review-source {
  color: #4c4e68;
  border: 1px solid #dee0e6;
  border-radius: 100px;
  padding: 4px 8px;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .customer-feedback .action {
  cursor: pointer;
  color: #1e40af;
  grid-area: action;
  justify-self: end;
  align-items: center;
  margin-top: 14px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .customer-feedback .action img.up {
  transform: rotateX(180deg);
}

.customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .customer-feedback .action span {
  margin-left: 10px;
}

.customer-reviews-list-widget .customer-reviews .customer-happy-reviews-list .labels {
  white-space: nowrap;
  color: #7d7e92;
  grid-template-columns: 1fr 1fr .5fr .8fr .5fr .8fr .8fr;
  grid-auto-flow: column;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
  padding: 12px 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  display: grid;
}

.customer-reviews-list-widget .customer-reviews .customer-happy-reviews-list .customer-happy-reviews-list-item-widget {
  color: #1c1d3e;
  border: 1px solid #dee0e6;
  grid-template-columns: 1fr 1fr .5fr .8fr .5fr .8fr .8fr;
  grid-auto-flow: column;
  align-items: center;
  gap: 12px;
  padding: 12px 24px;
  font-size: 12px;
  display: grid;
}

.customer-reviews-list-widget .customer-reviews .customer-happy-reviews-list .customer-happy-reviews-list-item-widget:nth-child(2) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 0;
}

.customer-reviews-list-widget .customer-reviews .customer-happy-reviews-list .customer-happy-reviews-list-item-widget:nth-last-child(1) {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.customer-reviews-list-widget .customer-reviews .customer-happy-reviews-list .customer-happy-reviews-list-item-widget .customer-name-and-phone-number {
  font-size: 14px;
}

.customer-reviews-list-widget .customer-reviews .customer-happy-reviews-list .customer-happy-reviews-list-item-widget .customer-name-and-phone-number .name {
  font-weight: 600;
}

.customer-reviews-list-widget .customer-reviews .customer-happy-reviews-list .customer-happy-reviews-list-item-widget .customer-name-and-phone-number .phone-number {
  color: #7d7e92;
}

.customer-reviews-list-widget .customer-reviews .customer-happy-reviews-list .customer-happy-reviews-list-item-widget .review-source {
  color: #4c4e68;
  width: max-content;
  border: 1px solid #dee0e6;
  border-radius: 100px;
  padding: 4px 8px;
}

.customer-reviews-list-widget .no-reviews {
  height: 90%;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  display: flex;
}

.customer-reviews-list-widget .no-reviews > .label {
  color: #4c4e68;
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.customer-reviews-list-widget .back-to-top-button {
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 30px;
  padding: 13px;
  position: fixed;
  top: 81vh;
  right: 20px;
  box-shadow: 0 2px 8px #687a9b40;
}

@media screen and (max-width: 1130px) {
  .customer-reviews-list-widget .customer-reviews .customer-sad-reviews-list .customer-sad-reviews-list-item-widget .customer-feedback .feedback {
    max-height: 168px;
  }
}

@font-face {
  font-family: Sailec-Medium;
  src: url("Sailec-Medium.7fce2372.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Sailec-Regular;
  src: url("Sailec-Regular.80b73846.ttf") format("truetype");
}

@font-face {
  font-family: Sailec-Bold;
  src: url("Sailec-Bold.b115272d.otf") format("truetype");
}

@font-face {
  font-family: kredit;
  src: url("kredit.fcba7869.ttf") format("truetype");
}

@font-face {
  font-family: larabie;
  src: url("larabie.08226e3a.ttf") format("truetype");
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overscroll-behavior-y: none;
  background-color: #fff;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

body #root:has(.pos-layout > .lock-screen-widget) .refresh-menu-banner, .firebase-emulator-warning {
  display: none;
}

.pac-container {
  max-height: 135px;
  overflow: auto;
}

/*# sourceMappingURL=index.cbe58afc.css.map */
