.sales-trend-table-view-toggle {
  display: flex;
  justify-content: left;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  .right-arrow-icon {
    margin-right: 12px;

    &.hide > img {
      transform: rotate(-90deg);
    }
  }
}
